import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {hasRight} from "../../../Common/UserCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {DoneAll, Edit, RotateLeft} from "@mui/icons-material";
import ButtonComponent from "../../../Component/ButtonComponent";
import {initFormHandler} from "../../../Handler/FormHandler";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";

function SettingPackSizeList() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [packSize, setPackSize] = React.useState(null);
    const [isUpdateSylius, setIsUpdateSylius] = React.useState(true);
    const [isLoadingSylius, setIsLoadingSylius] = React.useState(true);

    // Sylius
    const updateSylius = () => {
        setIsLoadingSylius(true);
        ApiHandler.get({
            route: 'api_setting_pack_size_sylius'
        }, (response) => {
            if (response.status === 200) {
                setIsUpdateSylius(response.data.isUpdate);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
                setIsUpdateSylius(false);
            }

            setIsLoadingSylius(false);
        });
    };
    React.useEffect(updateSylius, []);

    // Global
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        },
        {
            name: "code",
            label: "Code",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE') || hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE_EDIT'))) {
            return (
                <>
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton
                            onClick={() => {
                                handlerForm.reset();
                                setPackSize(row.id);
                                handlerForm.setValue('label', row.label);
                                handlerForm.setValue('code', row.code);
                                setOpenDialog(true);
                            }}
                        ><Edit style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Edit/>
                    </IconButton>
                </>
            );
        }
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des conditionnements',
            context: 'Paramétrage',
            description: '',
            links: []
        });
    }, []);

    // Form
    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Saisissez le libellé du conditionnement.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        code: {
            name: 'code',
            label: 'Code',
            textHelper: 'Saisissez le code du conditionnement pour la génération du SKU.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'], equal: 2}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (packSize) {
                ApiHandler.post({
                    route: 'api_setting_pack_size_edit',
                    data: handlerForm.getData(),
                    params: {id: packSize}
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_pack_size_list'));
                        setPackSize(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Taille de conditionnement modifiée avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_pack_size');
                        updateSylius();
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_setting_pack_size_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_pack_size_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Taille de conditionnement créée avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_pack_size');
                        updateSylius();
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_setting_pack_size_list'}
                title={'Tailles de conditionnement'}
                columns={columns}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE') || hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE_ADD')) ?
                        {
                            label: 'Ajouter un conditionnement', onClick: () => {
                                setPackSize(null);
                                handlerForm.reset();
                                setOpenDialog(true);
                            }
                        } : false
                }
                actionSecond={
                    (hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE') || hasRight(authenticationReducer, 'ROLE_SETTING_PACK_SIZE_LIST')) ?
                        {
                            label: isUpdateSylius ? <span>Sylius est à jour <DoneAll style={{verticalAlign: 'top'}}/></span> : <span>Mettre à jour Sylius <RotateLeft style={{verticalAlign: 'top'}}/></span>,
                            color: isUpdateSylius ? '#006500' : '#ffc107',
                            loading: isLoadingSylius,
                            outlined: true,
                            onClick: isUpdateSylius ? () => {
                            } : updateSylius
                        } : false
                }
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_setting_pack_size_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{packSize ? 'Modifier un conditionnement' : 'Ajouter un conditionnement'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent id={'label'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent id={'code'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} disabled={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default SettingPackSizeList;
