import React from 'react'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";

export default function CustomToolbar({fileName, getRowsToExport}) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport
                csvOptions={{
                    fileName: fileName,
                    getRowsToExport: getRowsToExport
                }}
                printOptions={{
                    fileName: fileName,
                    hideToolbar: true,
                    hideFooter: true
                }}
            />
            <GridToolbarQuickFilter sx={{
                marginLeft: 'auto',
                '& .MuiInputBase-input': {
                    fontSize: '0.8rem',
                }
            }}/>
        </GridToolbarContainer>
    );
}
