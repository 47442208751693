import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {Grid, IconButton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Article, CloudDownload, DeleteOutline, Edit, Face, FaceRetouchingOff} from "@mui/icons-material";
import {initFormHandler} from '../../../Handler/FormHandler';
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import {TableReload} from '../../../Action/TableAction';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {dispatch} from '../../../App';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import moment from "moment";
import ButtonComponent from "../../../Component/ButtonComponent";
import {useNavigate} from "react-router-dom";
import {LinearProgressMainLayoutActivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useSelector} from "react-redux";
import Paper from "@mui/material/Paper";

function ShopSalesListToday() {
    const navigate = useNavigate();
    const [loadingContent, setLoadingContent] = React.useState(true);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsOrderClientState, setOptionsOrderClientState] = React.useState([]);
    const [totalDay, setTotalDay] = React.useState(null);
    const [totalTTCDebitDay, setTotalTTCDebitDay] = React.useState(null);
    const [totalTTCCreditDay, setTotalTTCCreditDay] = React.useState(null);
    const [opened, setOpened] = React.useState(null);
    const [canClosed, setCanClosed] = React.useState(null);

    const columns = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "clientName",
            label: "Client",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientState",
            label: "Etat",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "isGuest",
            label: "Guest",
            options: {filter: true, sort: true}
        },
        {
            name: "priceTTC",
            label: "Prix TTC",
            options: {filter: false, sort: false}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const columnsCredit = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "clientName",
            label: "Client",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "priceTTC",
            label: "Prix TTC",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if(row.orderClientState == 'Panier'){
            return (
                <>
                    <Tooltip title={'Visualiser'} placement="left">
                        <Link to={getRoutePathname('shop_sales_select_products', {clientId: row.clientUuid, shopId: authenticationReducer.shopSelected})}>
                            <IconButton><Edit/></IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title={'Supprimer'} placement="left" onClick={() => removeOrder(row.uuid)}>
                            <IconButton><DeleteOutline style={{color: '#982525'}}/></IconButton>
                    </Tooltip>
                </>
            );
        } else {
            return (
                <>
                    <Tooltip title={'Visualiser'} placement="left">
                        <Link to={getRoutePathname('shop_seller_order_client', {uuid: row.uuid})}>
                            <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                        </Link>
                    </Tooltip>
                </>
            );
        }
    };

    const getActionAvoir = (row) => {
        if(row.orderClientState == 'Panier'){
            return (
                <>
                    <Tooltip title={'Visualiser'} placement="left">
                        <Link to={getRoutePathname('shop_sales_select_products', {clientId: row.clientUuid, shopId: authenticationReducer.shopSelected})}>
                            <IconButton><Edit/></IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title={'Supprimer'} placement="left" onClick={() => removeOrder(row.uuid)}>
                        <IconButton><DeleteOutline style={{color: '#982525'}}/></IconButton>
                    </Tooltip>
                </>
            );
        } else {
            return (
                <>
                    <Tooltip title={'Télécharger l\'avoir'} placement="left">
                        <IconButton onClick={() => {
                            fetch(ApiHandler.route({route: 'api_billing_credit_download', params: {uuid: row.litigeId}}), {method: 'GET', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                                .then(res => res.blob())
                                .then(res => {
                                    const aElement = document.createElement('a');
                                    aElement.setAttribute('download', row.uuid + '.pdf');
                                    const href = URL.createObjectURL(res);
                                    aElement.href = href;
                                    aElement.setAttribute('target', '_blank');
                                    aElement.click();
                                    URL.revokeObjectURL(href);
                                });
                        }}>
                            <CloudDownload style={{color: '#006500'}}/>
                        </IconButton>
                    </Tooltip>
                </>
            );
        }
    };

    const removeOrder = (orderClientId) => {
        if(orderClientId != null) {
            ApiHandler.post({
                    route: 'api_shop_sales_delete_order_client',
                    params: {orderClientId: orderClientId, shopId: authenticationReducer.shopSelected }
                },
                (response) => {
                    dispatch(TableReload('api_shop_order_client_list_today'));
                });
        }
    };

    // FormSearch
    const [formSearch, setFormSearch] = React.useState({
        number: {
            name: 'number',
            label: 'Référence',
            textHelper: 'Rechercher par référence.',
            type: 'text',
            defaultValue: cacheTableServer['api_shop_order_client_list_today'] && cacheTableServer['api_shop_order_client_list_today'].number ? cacheTableServer['api_shop_order_client_list_today'].number : '',
            options: {}
        },
        clientName: {
            name: 'clientName',
            label: 'Client',
            textHelper: 'Rechercher par client.',
            type: 'text',
            defaultValue: cacheTableServer['api_shop_order_client_list_today'] && cacheTableServer['api_shop_order_client_list_today'].clientName ? cacheTableServer['api_shop_order_client_list_today'].clientName : '',
            options: {}
        },
        orderClientState: {
            name: 'orderClientState',
            label: 'État',
            textHelper: 'Rechercher par état.',
            type: 'integer',
            defaultValue: cacheTableServer['api_shop_order_client_list_today'] && cacheTableServer['api_shop_order_client_list_today'].orderClientState ? cacheTableServer['api_shop_order_client_list_today'].orderClientState : 0,
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_shop_order_client_list_today'] = 0;
        cacheTableServer['api_shop_order_client_list_today'].page = 0;
        cacheTableServer['api_shop_order_client_list_today'][index] = value;
        dispatch(TableReload('api_shop_order_client_list_today'))
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_order_client_state'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientState(data);
        });

        ApiHandler.post({
            route: 'api_shop_sales_check_opening',
            params: {shopId: authenticationReducer.shopSelected }
        },
        (response) => {
            setOpened(response.data.open);
            setCanClosed(response.data.canBeClosed);

            if(response.data.redirectToSign == 1){
                navigate(getRoutePathname('shop_sales_sign_opening'));
            }
        });

        setBreadcrumbs({
            title: 'Ventes du jour',
            context: 'Vente',
            description: '',
            links: []
        });

        setLoadingContent(false);
    }, []);

    const openShop = () => {
        navigate(getRoutePathname('shop'));
    };
    const closeShop = () => {
        ApiHandler.post({
            route: 'api_shop_sales_check_closing',
            params: {shopId: authenticationReducer.shopSelected }
        },
        (response) => {
            if(response.data.redirectToClose == 1){
                navigate(getRoutePathname('shop_closing'));
            } else {
                dispatch(
                    SnackbarOpen({
                        text: 'Vous avez des ventes ouvertes, merci de les clôturer ou de les annuler pour fermer votre caisse.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const openOrderClient = () => {
        navigate(getRoutePathname('shop_sales_client_list'), {shopId: authenticationReducer.shopSelected});
    };

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {opened && opened == 1 && canClosed && canClosed == 1 && <>
                        <ButtonComponent label={'Fermer la caisse'} onClick={closeShop} style={{margin:5}}/>
                    </>}
                    {opened && opened == 0 && <>
                        <ButtonComponent label={'Ouvrir la caisse'} onClick={openShop} style={{margin:5}}/>
                    </>}
                    <ButtonComponent label={'Nouvelle vente'} onClick={openOrderClient} style={{margin:5}}/>

                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                                <TextFieldComponent id={'number'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('number', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                                <TextFieldComponent id={'clientName'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('clientName', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                                <SelectComponent id={'orderClientState'} options={optionsOrderClientState} handler={handlerFormSearch} onChange={(val) => inputSearchChange('orderClientState', val)}/>
                            </Grid>

                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>
            <TableComponent
                id={'api_shop_order_client_list_today'}
                title={'Les ventes du jour'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        number: formSearch.number.value ? formSearch.number.value : '',
                        clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
                        orderClientState: (datas.orderClientState === 0 || !datas.orderClientState) ? '' : datas.orderClientState,
                    };

                    ApiHandler.get({
                            route: 'api_shop_order_client_list_today',
                            params: {shopId: authenticationReducer.shopSelected},
                            data: filters
                        },
                        (response) => {
                            let data = response.data.orders.data;
                            setTotalDay(response.data);
                            setTotalTTCDebitDay(response.data.totalTTCDebitDay);
                            setTotalTTCCreditDay(response.data.totalTTCCreditDay);
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].priceTTC = data[index].priceTTC ?? '-';
                                data[index].clientName = data[index].clientName ?? '-';
                                data[index].orderClientState = data[index].orderClientState ?? '-';
                                data[index].isGuest = data[index].isGuest ? <Tooltip title={'Guest'} placement="left"><FaceRetouchingOff style={{color: '#ffc107'}}/></Tooltip> : <Face style={{color: '#28a745'}}/>;
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />
            <br/>
            <TableComponent
                id={'api_shop_order_client_list_today'}
                title={'Les avoirs du jour'}
                columns={columnsCredit}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        number: formSearch.number.value ? formSearch.number.value : '',
                        clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
                        orderClientState: (datas.orderClientState === 0 || !datas.orderClientState) ? '' : datas.orderClientState,
                    };

                    ApiHandler.get({
                            route: 'api_shop_order_client_list_today',
                            params: {shopId: authenticationReducer.shopSelected},
                            data: filters
                        },
                        (response) => {
                            let data = response.data.ordersCredit.data;
                            for (let index in data) {
                                data[index].action = getActionAvoir(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].clientName = data[index].clientName ?? '-';
                                data[index].priceTTC = data[index].priceTTC ?? '-';
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />
            <br/>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={2}></Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                    <h3>Récapitulatif des ventes</h3>
                    <Grid container spacing={1}>
                        {totalDay && <>
                            <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" ></TableCell>
                                        <TableCell align="center" style={{padding: '10px', background: '#D3D3D3', color: '#000', fontWeight: 'bold'}}>Journalier</TableCell>
                                        <TableCell align="center" style={{padding: '10px', background: '#DCDCDC', color: '#000', fontWeight: 'bold'}}>Mensuel</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Total TTC</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCDay).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCDayMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Total HT</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalHTDay).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalHTDayMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Reçus TTC</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCDebitDay).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCDebitDayMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Reçus HT</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalHTDebitDay).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalHTDebitDayMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Avoirs TTC</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCCreditDay).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.totalTTCCreditDayMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Total Marque Exclu</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.cumulTotalExclusiveTTC).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.cumulTotalExclusiveTTCMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Total HT Marque Exclu</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.cumulTotalExclusiveHT).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.cumulTotalExclusiveHTMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>% des ventes</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.percentMarqueExclusive).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} %</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.percentMarqueExclusiveMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} %</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Panier TTC</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.panierMoyenTTC).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.panierMoyenTTCMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" style={{padding: '10px', color: '#000', fontWeight: 'bold'}}>Panier HT </TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.panierMoyenHT).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                        <TableCell align="right">{parseFloat(totalDay.panierMoyenHTMonth).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </>}
                    </Grid>
                </Grid>
            </Grid>

            <br/>
        </ContentViewComponent>
    );
}

export default ShopSalesListToday;
