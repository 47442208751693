import React from 'react';
import {makeStyles} from '@mui/styles';
import {Select, InputLabel, FormControl, FormHelperText, MenuItem} from "@mui/material";

function SelectComponent(props) {
    // Info
    const id = props.id;
    const input = props.handler.form[id];
    const label = input.label;

    const classes = useStyles();
    const [error, setError] = React.useState(input.error);
    const [isFocus, setFocus] = React.useState(false);

    // Value
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        if (input.value !== undefined && input.value !== value) setValue(input.value.toString());
    }, [input.value]);
    React.useEffect(() => {
        if (input.error !== error) setError(input.error);
    }, [input.error]);

    // On
    const onChange = (event) => {
        let newValue = event.target.value ?? '';
        setValue(newValue);

        if (props.onChange) props.onChange(newValue);
    }
    const onBlur = () => {
        setFocus(false);
        props.handler.setValue(id, value);
        let error = props.handler.getErrorByField(input);
        setError(error);
        props.handler.setError(id, error);

        if (props.onBlur) props.onBlur(value);
    }
    const onFocus = () => {
        setFocus(true);
    }

    return (
        <div className={classes.content}>
            <FormControl variant="outlined"
                         className={`
                        ${classes.select}
                        ${isFocus && (!input.disabled && !props.disabled) ? classes.selectFocus : error && (!input.disabled && !props.disabled) ? classes.selectError : value && (!input.disabled && !props.disabled) ? classes.selectValid : ''}
                    `}
            >
                <InputLabel htmlFor="outlined" style={value ? {transform: 'translate(15px, -4px) scale(0.75)'} : {}}>{label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}</InputLabel>
                <Select
                    value={value}
                    defaultValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    label={label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}
                    inputProps={{name: label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : ''), id: props.name}}
                    disabled={input.disabled || props.disabled}
                    MenuProps={{
                        className: classes.menu
                    }}
                >
                    {
                        props.options.map((data, key) => {
                            return <MenuItem key={key} className={data.customClass ?? ''} value={data.value}>{data.label}</MenuItem>;
                        })
                    }
                </Select>
                <FormHelperText>{error && !isFocus ? error : input.textHelper}</FormHelperText>
            </FormControl>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        padding: 5
    },
    select: {
        width: '100%',
        marginTop: 0,
        '& .MuiSelect-select': {
            padding: '7px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiSelect-select:focus': {
            background: '#FFFFFF'
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 7px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(13px, -7px) scale(0.75) !important',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: 'rgba(0, 0, 0, 0.23) !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },
        '& label': {
            color: 'rgba(0, 0, 0, 0.54) !important',
            backgroundColor: '#FFFFFF',
            padding: '0 2px'
        }
    },
    selectFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    selectValid: {
        '& fieldset': {
            borderColor: '#006500 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#006500 !important'
        },
        '& label': {
            color: '#006500 !important'
        }
    },
    selectError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    },
    menu: {
        '& li': {
            fontSize: 12,
            padding: '4px 10px',
            lineHeight: '16px',
            minHeight: '16px'
        }
    }
});

export default SelectComponent;
