import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import {useDispatch, useSelector} from "react-redux";
import {inArray} from "../../../Common/ArrayCommon";
import {dispatch} from "../../../App";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ShopCashRegisterForm from "./ShopCashRegisterComponent/ShopCashRegisterForm";
import ApiHandler from "../../../Handler/ApiHandler";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import {LinearProgressMainLayoutActivate} from "../../../Action/LinearProgressMainLayoutAction";
import {initFormHandler} from "../../../Handler/FormHandler";

function ShopCashRegister() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [shop, setShop] = React.useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [reload, setReload] = React.useState(false);

    const saveForm = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_shop_sales_validate_sign',
                data: handlerForm.getData(),
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Ouverture / Fermeture de caisse validée et signée',
                            variant: 'success',
                        })
                    );

                    navigate(getRoutePathname('shop_sales_list_today', {shopId: authenticationReducer.shopSelected}));
                }
                else if (response.status === 400) {
                    handlerForm.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                handlerForm.setFormLoading(false);
            });
        }
    };

    const [form, setForm] = React.useState({
        token: {
            name: 'token',
            label: 'Code signature',
            textHelper: 'Saisissez le code reçu par sms',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerForm = initFormHandler(form, setForm);

    React.useEffect(() => {
        if(authenticationReducer.shopSelected) {
            ApiHandler.get({
                route: 'api_shop_sales_shop_info',
                params: {id: authenticationReducer.shopSelected}
            }, (response) => {
                if (response.status === 200) {
                    setShop(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [reload, authenticationReducer.shopSelected]);

    return (
        <ContentViewShopComponent>
            {shop && <>
                <p>Validation d'ouverture et fermeture de caisse</p>

                <Grid container spacing={1}>
                    <Grid item xs={8} sm={2}>
                        <TextFieldComponent id={'token'} handler={handlerForm} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ButtonComponent label={'Valider'} onClick={saveForm}/>
                    </Grid>
                </Grid>
            </>}
        </ContentViewShopComponent>

    );
}

export default ShopCashRegister;
