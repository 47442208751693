import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {makeStyles} from "@mui/styles";
import {
    Fade,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {initFormHandler} from "../../../Handler/FormHandler";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {AuthenticationLogin} from "../../../Action/AuthenticationAction";
import {hasRight} from "../../../Common/UserCommon";
import SelectComponent from "../../../Component/SelectComponent";
import {inArray} from "../../../Common/ArrayCommon";
import Paper from "@mui/material/Paper";
import {DeleteOutline} from "@mui/icons-material";

function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();

    // Size
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingShop, setIsLoadingShop] = React.useState(false);
    const [isFadeIn, setIsFadeIn] = React.useState(true);
    const [isFadeInShop, setIsFadeInShop] = React.useState(false);
    const [dataUser, setDataUser] = React.useState({});
    const [erpConnect, setErpConnect] = React.useState({});
    const [optionsShop, setOptionsShop] = React.useState([]);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);
        }
    }, [screenReducer.screen]);

    // Form
    const [form, setForm] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Votre adresse email.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'password',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerForm = initFormHandler(form, setForm);
    React.useEffect(handlerForm.start, []);

    // Form Shop
    const [formShop, setFormShop] = React.useState({
        shop: {
            name: 'shop',
            label: 'Boutique',
            textHelper: 'Choisissez votre boutique.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerFormShop = initFormHandler(formShop, setFormShop);
    React.useEffect(handlerFormShop.start, []);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_shop'}, (response) => setOptionsShop(response.data));
    }, []);

    const login = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoading(true);

            ApiHandler.post({
                route: 'api_authentication_login',
                data: handlerForm.getData()
            }, (response) => {
                if (response.status === 200) {
                    setIsFadeIn(false);
                    let data = response.data;
                    setDataUser(response.data);
                    if (hasRight(data, 'ROLE_SHOP')) {
                        let optionsShopNew = [];
                        for (let index in optionsShop) {
                            if (inArray(optionsShop[index].value, response.data.shops)) {
                                optionsShopNew.push(optionsShop[index]);
                            }
                        }
                        setOptionsShop(optionsShopNew);
                        setTimeout(() => setIsFadeInShop(true), 1000);
                    }
                    else {
                        setTimeout(() => dispatch(AuthenticationLogin(response.data)), 1500);
                    }
                    if (hasRight(data, 'ROLE_WEB')) {
                        setErpConnect(1);
                    }
                }
                else if (response.status === 400) {
                    handlerForm.setErrorApi(response.error);
                    handlerForm.setFormLoading(false);
                    setIsLoading(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                    handlerForm.setFormLoading(false);
                    setIsLoading(false);
                }
            });
        }
    };
    const loginShop = () => {
        if (handlerFormShop.checkError() < 1) {
            handlerFormShop.setFormLoading(true);
            setIsLoadingShop(true);

            let data = handlerFormShop.getData();
            dataUser.shopSelected = data['shop'];
            for (let index in optionsShop) {
                if (dataUser.shopSelected === optionsShop[index].value) {
                    dataUser.shopNameSelected = optionsShop[index].label;
                    break;
                }
            }
            dataUser.defaultRoute = 'shop';
            dispatch(AuthenticationLogin(dataUser));
        }
    };

    return (<>
            <Fade in={isFadeIn} {...{timeout: 1500}}>
                <div>
                    <ShadowBoxComponent className={isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}>
                        <img src={'/image/logo.webp'} alt="logo" className={classes.logo}/>

                        <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                            <TextFieldComponent id={'email'} handler={handlerForm}/>
                            <TextFieldComponent id={'password'} handler={handlerForm}/><br/>
                            <ButtonComponent style={{fontSize: '15px !important'}} type="submit" label={'Se connecter'} onClick={login} loading={isLoading}/>
                        </div>
                    </ShadowBoxComponent>
                </div>
            </Fade>
            <Fade in={isFadeInShop} {...{timeout: 1500}}>
                <div>
                    <ShadowBoxComponent className={isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}>
                        <img src={'/image/logo.webp'} alt="logo" className={classes.logo}/>

                        <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                            <SelectComponent id={'shop'} options={optionsShop} handler={handlerFormShop}/>
                            <br/>
                            <ButtonComponent style={{fontSize: '15px !important'}} type="submit" label={'Se connecter à la boutique'} onClick={loginShop} loading={isLoadingShop}/>
                            <br/><br/>

                            {(() => {
                                if (erpConnect == 1) {
                                    return (
                                        <p style={{fontSize: 12, cursor: 'pointer'}} onClick={() => {
                                            dispatch(AuthenticationLogin(dataUser));
                                        }}>Se connecter à l'ERP principal</p>
                                    )
                                }
                            })()}

                        </div>
                    </ShadowBoxComponent>
                </div>
            </Fade>
        </>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    logo: {
        width: '50%',
        margin: 'auto'
    },
    shadowBox: {
        padding: 25,
        height: 310,
        width: 576,
        top: 'calc(50vh - 155px)',
        left: 'calc(50vw - 288px)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left'
    },
    shadowBoxSmall: {
        padding: 25,
        height: '100vh',
        width: '100vw',
        margin: 'auto',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left'
    },
    shadowBoxContent: {
        marginTop: 30,
    },
    shadowBoxSmallContent: {
        marginTop: '50vh',
        transform: 'translateY(calc(-35vh + 25px))',
    }
});

export default Login;
