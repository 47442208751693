import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import ApiHandler from "../../../../Handler/ApiHandler";
import TableComponent from "../../../../Component/TableComponent";
import {hasRight} from "../../../../Common/UserCommon";
import {useDispatch, useSelector} from "react-redux";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {initFormHandler} from "../../../../Handler/FormHandler";
import AutoCompleteComponent from "../../../../Component/AutoCompleteComponent";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../../Action/TableAction";
import {DeleteOutline} from '@mui/icons-material';
import InputFileComponent from "../../../../Component/InputFileComponent";

function printLabels(shopId) {
    fetch(ApiHandler.route({
            route: 'api_shop_management_shop_print_label',
            params: {shopId: shopId}}),
        {method: 'GET', mode: 'no-cors', referrerPolicy: 'no-referrer'}
    ).then(res => res.blob())
        .then(res => {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', shopId + '-labels.pdf');
            const href = URL.createObjectURL(res);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
        });
}

function ShopManagementByProductList(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');
    // LIST
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum / Couleur",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Taille du conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if (!justWatch) {
            return (
                <div style={{width: 100}}>
                    <Tooltip title={'Supprimer la relation'} placement="left">
                        <IconButton onClick={() => setShopDelete(row.id)}>
                            <DeleteOutline style={{color: '#982525'}}/>
                        </IconButton>
                    </Tooltip>
                </div>
            );
        }
        else {
            return (
                <div style={{width: 100}}>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <DeleteOutline/>
                    </IconButton>
                </div>
            );
        }
    };

    // ADD
    const [showAdd, setShowAdd] = React.useState(false);
    const [isLoadingAdd, setIsLoadingAdd] = React.useState(false);
    const [formByProduct, setFormByProduct] = React.useState({
        byProduct: {
            name: 'byProduct',
            label: 'Sous article',
            textHelper: 'Choisissez un sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
    });
    const handlerFormByProduct = initFormHandler(formByProduct, setFormByProduct);
    const loadByProduct = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_by_product',
            data: {text: text}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const saveAdd = () => {
        if (handlerFormByProduct.checkError() < 1) {
            handlerFormByProduct.setFormLoading(true);
            setIsLoadingAdd(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_shop_management_shop_by_product_add',
                params: {shopId: props.shop.id},
                data: handlerFormByProduct.getData()
            }, (response) => {
                if (response.status === 200) {
                    dispatch(TableReload('api_shop_management_shop_by_product_list_admin'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Sous-produit ajouté avec succès.',
                            variant: 'success',
                        })
                    );
                    handlerFormByProduct.reset();
                    setShowAdd(false);
                }
                else if (response.status === 400) {
                    handlerFormByProduct.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormByProduct.setFormLoading(false);
                setIsLoadingAdd(false);
            });
        }
    };

    //IMPORT
    const [showImport, setShowImport] = React.useState(false);
    const [isLoadingImport, setIsLoadingImport] = React.useState(false);

    // DELETE
    const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
    const [shopDelete, setShopDelete] = React.useState(false);
    const remove = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoadingDelete(true);
        ApiHandler.delete({
            route: 'api_shop_management_shop_by_product_delete',
            data: handlerFormByProduct.getData(),
            params: {shopId: props.shop.id, id: shopDelete},
        }, (response) => {
            if (response.status === 200) {
                dispatch(TableReload('api_shop_management_shop_by_product_list_admin'));
                setShopDelete(null);
                dispatch(
                    SnackbarOpen({
                        text: 'Adresse supprimée avec succès.',
                        variant: 'success',
                    })
                );
            }
            else if (response.status === 400) {
                handlerFormByProduct.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            setIsLoadingDelete(false);
        });
    };

    //RELOAD DATA AFTER IMPORT
    React.useEffect(() => {
        if(!showImport) dispatch(TableReload('api_shop_management_shop_by_product_list_admin'))
    }, [showImport]);

    return (
        <>
            <TableComponent
                id={'api_shop_management_shop_by_product_list_admin'}
                title={'Sous articles'}
                columns={columns}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                        {label: 'Ajouter un sous-produit', onClick: () => setShowAdd(true)} : false
                }
                actionSecond={
                    (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                        {label: 'Importer', onClick: () => setShowImport(true)} : false
                }
                actionThird={
                    (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                        {label: 'Imprimer plaquette', onClick: () => printLabels(props.shop.id)
                        } : false
                }


                promiseData={(resolve) => {
                    ApiHandler.get({
                            route: 'api_shop_management_shop_by_product_list_admin',
                            params: {shopId: props.shop.id}
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={showImport} maxWidth={'xl'} onClose={() => setShowImport(false)}>
                <DialogTitle style={{fontSize: 15}}>Importer un CSV</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputFileComponent
                                urlUpload={ApiHandler.route({route: 'api_shop_management_shop_by_product_csv_upload', params: {shopId: props.shop.id}})}
                                label={'Fichier CSV'}
                                csvUpload={true}
                                setShowModal={setShowImport}
                                setIsLoadingButton={setIsLoadingImport}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowImport(false)} loading={isLoadingImport}/>
                </DialogActions>
            </Dialog>

            <Dialog open={showAdd} maxWidth={'xl'} onClose={() => setShowAdd(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter un sous-produit</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AutoCompleteComponent id={'byProduct'} handler={handlerFormByProduct} loadOptions={loadByProduct}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowAdd(false)} disabled={isLoadingAdd}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveAdd} loading={isLoadingAdd}/>
                </DialogActions>
            </Dialog>

            <Dialog open={!!shopDelete} maxWidth={'xl'} onClose={() => setShopDelete(null)}>
                <DialogTitle style={{fontSize: 15}}>Supprimer la relation</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Êtes-vous sûr de vouloir supprimer la relation ?
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Non'} onClick={() => setShopDelete(null)}/>
                    <ButtonComponent label={'Oui'} onClick={remove} loading={isLoadingDelete}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ShopManagementByProductList;
