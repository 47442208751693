import React from 'react';
import {useDispatch} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import ApiHandler from "../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import AdministratorUserForm from "./AdministratorUserComponents/AdministratorUserForm";
import AdministratorUserRoleList from "./AdministratorUserComponents/AdministratorUserRoleList";

function AdministratorUser() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [user, setUser] = React.useState(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);

    React.useEffect(() => {
        if (params.id === 'add') {
            setBreadcrumbs({
                title: 'Ajouter un utilisateur',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_user_list'), label: 'Liste des utilisateurs'}
                ]
            });

            setIsLoadingContent(false);
        }
        else {
            setBreadcrumbs({
                title: 'Éditer un utilisateur',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_user_list'), label: 'Liste des utilisateurs'}
                ]
            });

            ApiHandler.get({
                route: 'api_administrator_user_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setUser(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>
            <AdministratorUserForm user={user}/>
            <br/>
            {user && <AdministratorUserRoleList user={user}/>}
            <br/><br/>
        </ContentViewComponent>
    );
}

export default AdministratorUser;
