import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {Fade} from "@mui/material";
import UseAnimations from "react-useanimations";
import loading2 from "react-useanimations/lib/loading2";
import ShadowBoxComponent from "./ShadowBoxComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../Action/LinearProgressMainLayoutAction";

function ContentViewShopComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        dispatch(props.loading ? LinearProgressMainLayoutActivate() : LinearProgressMainLayoutDeactivate());
    }, [props.loading]);

    return (
        <div className={`${classes.contentView} ${isSmallView && classes.contentViewSmall}`} style={isSmallView ? {padding: '0 10px'} : {}}>
            {
                props.loading ?
                    (
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/image/logo.webp'} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={loading2} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                    )
                    :
                    <>
                        {props.children}
                    </>
            }
        </div>
    );
}

const useStyles = makeStyles({
    contentView: {
        padding: '0 25px',
        position: 'relative'
    },
    contentViewSmall: {
        padding: '0 5px',
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 300,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 20
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
});

export default ContentViewShopComponent;
