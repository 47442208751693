import React from 'react';
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import {Grid} from "@mui/material";
import CheckboxComponent from "../../../../Component/CheckboxComponent";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {inArray} from "../../../../Common/ArrayCommon";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {makeStyles} from "@mui/styles";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {clone} from "../../../../Common/CloneCommon";

function AdministratorUserForm(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(true);
    const [loadingForm, setLoadingForm] = React.useState(true);
    const [globalRoles, setGlobalRoles] = React.useState([]);
    const [inputs, setInputs] = React.useState({});

    let handlerForm = initFormHandler(inputs, setInputs);
    React.useEffect(() => {
        ApiHandler.get({
            route: 'api_administrator_user_role_list',
            params: {userId: props.user.id}
        }, (response) => {
            if (response.status === 200) {
                setGlobalRoles(response.data['globalRoles']);

                let roles = {};
                for (let index in response.data['globalRoles']) {
                    roles[response.data['globalRoles'][index].id] = {
                        name: response.data['globalRoles'][index].id,
                        label: 'Tous',
                        textHelper: '',
                        type: 'boolean',
                        defaultValue: inArray(response.data['globalRoles'][index].id, response.data['userRoles']),
                        value: inArray(response.data['globalRoles'][index].id, response.data['userRoles']),
                        options: {}
                    }

                    for (let index2 in response.data['globalRoles'][index].roles) {
                        roles[response.data['globalRoles'][index].roles[index2].id] = {
                            name: response.data['globalRoles'][index].roles[index2].id,
                            label: response.data['globalRoles'][index].roles[index2].label,
                            textHelper: '',
                            type: 'boolean',
                            defaultValue: inArray(response.data['globalRoles'][index].roles[index2].id, response.data['userRoles']),
                            value: inArray(response.data['globalRoles'][index].roles[index2].id, response.data['userRoles']),
                            options: {}
                        }
                    }
                }
                setInputs(roles);
                setLoading(false);
                setLoadingForm(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, [])
    React.useEffect(handlerForm.start, []);

    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoadingForm(true);

            let data = handlerForm.getData(true);
            let roles = [];
            for (let index in data) {
                if (data[index]) roles.push(index);
            }

            ApiHandler.post({
                route: 'api_administrator_user_role_change',
                data: {roles: roles},
                params: {userId: props.user.id}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Rôles modifiés avec succès.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handlerForm.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                handlerForm.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoadingForm(false);
            });
        }
    };
    const onChange = (id, value) => {
        if (value) {
            for (let index in globalRoles) {
                if (globalRoles[index].id === id) {
                    for (let index2 in globalRoles[index].roles) {
                        inputs[globalRoles[index].roles[index2].id].value = false;
                    }
                }
            }

            setInputs(clone(inputs));
        }
    };

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Roles'}/>

            {
                loading ? <p>Chargement des rôles...</p> : globalRoles.map((globalRole, key) => {
                    return <div key={key}>
                        <h3>{globalRole.label}</h3>
                        <Grid container spacing={0}>
                            <Grid item xs={2} key={key}>
                                <CheckboxComponent id={globalRole.id} handler={handlerForm} onChange={onChange}/>
                            </Grid>
                            {
                                globalRole.roles.map((role, key) => {
                                    return <Grid item xs={2} key={key}>
                                        <CheckboxComponent id={role.id} handler={handlerForm} disabled={inputs[globalRole.id].value}/>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </div>
                })
            }

            <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={loadingForm}/>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
});

export default AdministratorUserForm;

