import React from 'react';
import TableComponent from "../../../../Component/TableComponent";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {AppRegistration, DeleteOutline} from "@mui/icons-material";
import AutoCompleteComponent from "../../../../Component/AutoCompleteComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {TableReload} from "../../../../Action/TableAction";
import {hasRight} from "../../../../Common/UserCommon";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {isNumber} from "../../../../Util/NumberTool";

function BuyProductByProductByProductList(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');


    // By Product List
    const [compoByProduct, setCompoByProduct] = React.useState(null);
    const getActionCompoByProduct = (row) => {
        if (!justWatch) {
            return (
                <div style={{width: 65}}>
                    <Tooltip title={'Modifier la quantité'} placement="left">
                        <IconButton onClick={() => {
                            handlerFormByProduct.reset();
                            handlerFormByProduct.setValue('compoByProduct', row.id);
                            handlerFormByProduct.setValue('amount', row.amount);
                            handlerFormByProduct.setValueText('compoByProduct', row.sku + ' - ' + row.name);
                            handlerFormByProduct.setDisabled('compoByProduct', true);
                            setCompoByProduct(row.id);
                        }}>
                            <AppRegistration style={{color: '#17a2b8'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Supprimer l\'article'} placement="left">
                        <IconButton onClick={() => setCompoByProductDelete(row)}>
                            <DeleteOutline style={{color: '#982525'}}/>
                        </IconButton>
                    </Tooltip>
                </div>
            );
        }
        else {
            return (
                <div style={{width: 65}}>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <AppRegistration/>
                    </IconButton>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <DeleteOutline/>
                    </IconButton>
                </div>
            );
        }
    };
    const columnsCompoByProduct = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité",
            options: {filter: true, sort: true}
        },
        {
            name: "priceText",
            label: "DPA/Moins cher",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const [isLoadingFormCompoByProduct, setIsLoadingCompoFormByProduct] = React.useState(false);
    const [formCompoByProduct, setFormCompoByProduct] = React.useState({
        compoByProduct: {
            name: 'compoByProduct',
            label: 'Article de conditionnement',
            textHelper: 'Choisissez un article de conditionnement.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        amount: {
            name: 'amount',
            label: 'Quantité',
            textHelper: 'Saisissez la quantité d\'article de conditionnement.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerFormByProduct = initFormHandler(formCompoByProduct, setFormCompoByProduct);
    const loadCompoByProduct = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_by_product',
            data: {text: text, type: [2, 3]}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const saveCompoByProduct = () => {
        if (handlerFormByProduct.checkError() < 1) {
            handlerFormByProduct.setFormLoading(true);
            setIsLoadingCompoFormByProduct(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (compoByProduct === 0) {
                ApiHandler.post({
                    route: 'api_buy_product_by_product_by_product_add',
                    data: handlerFormByProduct.getData(),
                    params: {productId: props.product.id, byProductId: props.byProduct.id},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(TableReload('api_buy_product_by_product_by_product_list'));
                        setCompoByProduct(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Article de conditionnement ajouté avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerFormByProduct.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerFormByProduct.setFormLoading(false);
                    setIsLoadingCompoFormByProduct(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_buy_product_by_product_by_product_change_amount',
                    data: handlerFormByProduct.getData(),
                    params: {productId: props.product.id, byProductId: props.byProduct.id, id: compoByProduct},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(TableReload('api_buy_product_by_product_by_product_list'));
                        setCompoByProduct(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Article de conditionnement modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerFormByProduct.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerFormByProduct.setFormLoading(false);
                    setIsLoadingCompoFormByProduct(false);
                });
            }
        }
    };

    // By Product Delete
    const [compoByProductDelete, setCompoByProductDelete] = React.useState(null);
    const deleteCompoByProductDelete = () => {
        setCompoByProductDelete(null);
        dispatch(LinearProgressMainLayoutActivate());

        ApiHandler.delete({
            route: 'api_buy_product_by_product_by_product_delete',
            params: {productId: props.product.id, byProductId: props.byProduct.id, id: compoByProductDelete.id},
        }, (response) => {
            if (response.status === 200) {
                dispatch(TableReload('api_buy_product_by_product_by_product_list'));

                dispatch(
                    SnackbarOpen({
                        text: 'Article de conditionnement supprimé avec succès.',
                        variant: 'success',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
        });
    };

    return (
        <>
            <TableComponent
                noShadow={true}
                id={'api_buy_product_by_product_by_product_list'}
                title={'Article de conditionnement'}
                columns={columnsCompoByProduct}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') || hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT')) ?
                        {
                            label: 'Ajouter un article de conditionnement',
                            onClick: () => {
                                handlerFormByProduct.reset();
                                handlerFormByProduct.setDisabled('compoByProduct', false);
                                setCompoByProduct(0);
                            }
                        } : false
                }
                promiseData={(resolve) => {
                    ApiHandler.get({
                            route: 'api_buy_product_by_product_by_product_list',
                            params: {productId: props.product.id, byProductId: props.byProduct.id}
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getActionCompoByProduct(data[index]);
                                data[index].amountText = isNumber(data[index].amount) ? data[index].amount.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].priceText = isNumber(data[index].price) ? parseFloat(data[index].price).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={compoByProduct !== null} maxWidth={'xl'} onClose={() => setCompoByProduct(null)}>
                <DialogTitle style={{fontSize: 15}}>{compoByProduct ? 'Modifier un article de conditionnement' : 'Ajouter un article de conditionnement'}</DialogTitle>
                <DialogContent style={{minWidth: '40vw', height: 150}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AutoCompleteComponent id={'compoByProduct'} handler={handlerFormByProduct} loadOptions={loadCompoByProduct}/>
                            <TextFieldComponent id={'amount'} handler={handlerFormByProduct}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setCompoByProduct(null)} disabled={isLoadingFormCompoByProduct}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveCompoByProduct} loading={isLoadingFormCompoByProduct}/>
                </DialogActions>
            </Dialog>

            <Dialog open={!!compoByProductDelete} maxWidth={'xl'} onClose={() => setCompoByProductDelete(null)}>
                <DialogTitle style={{fontSize: 15}}>{compoByProductDelete ? compoByProductDelete.name : ''}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Êtes-vous sûr de vouloir supprimer l'article "{compoByProductDelete ? compoByProductDelete.name : ''}" de la liste de conditionnement ?
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Non'} onClick={() => setCompoByProductDelete(null)}/>
                    <ButtonComponent label={'Oui'} onClick={deleteCompoByProductDelete}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BuyProductByProductByProductList;
