import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {Visibility} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton, Slide} from "@mui/material";
import {DataGrid, frFR, GridColDef, GridRenderCellParams, GridRowParams,} from "@mui/x-data-grid";

import {dispatch} from "../../../App";
import {cacheTableServer} from "../../../Cache";
import ApiHandler from "../../../Handler/ApiHandler";
import {displayErrorMessage} from "../../../Error/Errors";
import {initFormHandler} from "../../../Handler/FormHandler";
import {floatSortComparator} from "../../../Util/SortNumber";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import {fetchPurveyors, fetchPurveyorTypes} from "../../../Filter/FetchDropdownData";
import {exportFloatFormat} from "../../../Util/ExportNumberFormatter";
import CustomToolbar from "../../../Component/DataGrid/CustomToolbar";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {setEndDateFilter, setStartDateFilter} from "../../../Filter/DateFilters";
import {CustomPagination} from "../../../Component/DataGrid/CustomPagination";
import FooterWithTotalGeneral from "../../../Component/DataGrid/FooterWithTotalGeneral";
import {formatCurrencyToFloat, formatNumberToEurCurrency} from "../../../Util/NumberTool";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {listingStyles} from "../../../Style/useStyles";
import {styleDataGridDefault} from "../../../Style/styleDataGridDefault";
import DoubleRowHeader from "../../../Component/DataGrid/DoubleRowHeader";

function StatisticBuyCa() {
    const [loadingContent, setLoadingContent] = useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState({});
    const [optionsPurveyor, setOptionsPurveyor] = useState([]);
    const [optionsPurveyorType, setOptionsPurveyorType] = React.useState([]);
    const [cellsTable, setCellsTable] = useState([]);

    const [generalTotalCA, setGeneralTotalCA] = useState([]);

    let totalGeneralValue = 0;
    if (Object.values(cellsTable).length > 0) {
        totalGeneralValue = generalTotalCA;
    }

    const classes = listingStyles();

    const [formSearch, setFormSearch] = React.useState({
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_buy_ca'] && cacheTableServer['api_statistic_buy_ca'].startDate ? cacheTableServer['api_statistic_buy_ca'].startDate : '',
            options: {validation: ['date']}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_buy_ca'] && cacheTableServer['api_statistic_buy_ca'].endDate ? cacheTableServer['api_statistic_buy_ca'].endDate : '',
            options: {validation: ['date']}
        },
        purveyor: {
            name: 'purveyor',
            label: 'Fournisseur',
            textHelper: 'Rechercher par fournisseur.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_buy_ca'] && cacheTableServer['api_statistic_buy_ca'].purveyor ? cacheTableServer['api_statistic_buy_ca'].purveyor : null,
            options: {}
        },
        purveyorType: {
            name: 'purveyorType',
            label: 'Type de fournisseur',
            textHelper: 'Rechercher par types de fournisseur.',
            type: 'integer',
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
    }

    useEffect(() => {
        handlerFormSearch.start();
        fetchPurveyors(setOptionsPurveyor);
        fetchPurveyorTypes(setOptionsPurveyorType);

        setBreadcrumbs({
            title: 'Stats fournisseur CA',
            context: 'Statistiques',
            description: '',
            links: []
        });

    }, [])

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();

        let filters = {
            startDate: setStartDateFilter(formSearch.startDate.value),
            endDate: setEndDateFilter(formSearch.endDate.value),
            purveyor: (datas.purveyor === null || !datas.purveyor) ? '' : datas.purveyor,
            purveyorType: (datas.purveyorType === 0 || !datas.purveyorType) ? '' : datas.purveyorType,
        };

        ApiHandler.get({
                route: 'api_statistic_buy_ca',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    const {generalTotalCA, ...dataByPurveyor} = response.data;

                    setCellsTable(dataByPurveyor);
                    setGeneralTotalCA(generalTotalCA);
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                } else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    displayErrorMessage(response)
                }
            }
        );
    };

    const navigate = useNavigate();
    const navigateWithParams = (baseUrl, params) => {
        const queryString = new URLSearchParams(params);
        const url = baseUrl + `?${queryString}`;

        navigate(url, {target: "_blank"});
    };

    const renderOrderDetails = (params) => {
        if (!params) return;

        const baseUrl = '/statistic/buy/portfolio-details';
        const paramStr = "purveyorId=" + params.row.id;

        navigateWithParams(baseUrl, paramStr);
    };

    const exportFileName = 'stats_purveyor_ca';
    const highCa = 100 * 1000;
    const lowCa = 10 * 1000;

    const columns: GridColDef[] = [
        {
            field: "purveyorId",
            headerName: "ID",
            hidden: true,
            minWidth: 110,
        },
        {
            field: 'purveyor',
            headerName: 'Fournisseur',
            hideable: false,
            flex: 2,
            minWidth: 300,
        },
        {
            field: "purveyorType",
            headerName: "Type de fournisseur",
            flex: 1,
            minWidth: 150,
            renderHeader: () => <DoubleRowHeader firstLineText="Type de" secondLineText="fournisseur"/>,
        },
        {
            field: 'totalCAperPurveyor',
            headerName: 'Chiffre d\'affaire facturé',
            flex: 1,
            minWidth: 200,
            sortComparator: floatSortComparator,
            headerAlign: "right",
            align: "right",
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <strong>{formatNumberToEurCurrency(params.value)}</strong>
            ),
            valueFormatter: (params) => {
                return exportFloatFormat(params.value);
            },
            cellClassName: (params) => {
                return clsx('amount', {
                    smaller: formatCurrencyToFloat(params.value) < lowCa,
                    greater: formatCurrencyToFloat(params.value) > highCa,
                });
            },
        },
        {
            field: 'actions',
            headerName: 'Détails',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <IconButton onClick={() => renderOrderDetails(params)}><Visibility/></IconButton>,
            ]
        }
    ]

    const rows = Object.values(cellsTable).map((row, index) => (
        {...row, id: row.purveyorId}
    ));

    const ignoreDiacritics = true
    const disableRowSelectionOnClick = true
    const styleDataGridCustom = {
        '& .amount.greater': {
            color: 'brown',
        },
        '& .amount.smaller': {
            color: 'gray',
        },
    }
    const sxDataGrid = {
        ...styleDataGridDefault,
        ...styleDataGridCustom,
    };

    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'startDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('startDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'endDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('endDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <SelectComponent id={'purveyor'}
                                                 options={optionsPurveyor}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('purveyor', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <SelectComponent id={'purveyorType'}
                                                 options={optionsPurveyorType}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('purveyorType', val)}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={1} xl={1}>
                                <ButtonComponent className={classes.buttonActionFirst}
                                                 label={"Rechercher"}
                                                 onClick={() => onSearch()}
                                                 disabled={disabledStatusButtonSearch}
                                                 loading={loadingContent}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <Box sx={{height: 600, width: '100%'}}>
                <DataGrid
                    sx={sxDataGrid}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={rows}
                    disableColumnMenu
                    rowHeight={25}
                    slots={{
                        toolbar: () => <CustomToolbar fileName={exportFileName}/>,
                        pagination: CustomPagination,
                        footer: () => <FooterWithTotalGeneral totalGeneralValue={totalGeneralValue}/>,
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                purveyorId: false,
                            },
                        },
                        ...rows.initialState,
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    ignoreDiacritics={ignoreDiacritics}
                    disableRowSelectionOnClick={disableRowSelectionOnClick}
                />
            </Box>

        </ContentViewComponent>
    )
}

export default StatisticBuyCa;
