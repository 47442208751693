import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {hasRight} from "../../../Common/UserCommon";
import {IconButton, Tooltip} from "@mui/material";
import {Edit, Article} from "@mui/icons-material";
import {Link, useNavigate, useParams} from "react-router-dom";

function ListReappro() {
    const params = useParams();
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loading, setLoading] = React.useState(true);

    // List Deposits
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "deposit",
            label: "Dépôt source",
            options: {filter: true, sort: true}
        },
        {
            name: "fromDeposit",
            label: "Dépôt demandeur",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date création",
            options: {filter: true, sort: true}
        },
        {
            name: "reapproStateLabel",
            label: "Statut",
            options: {filter: true, sort: true}
        },
        {
            name: "shop",
            label: "Boutique",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    
    const getAction = (row) => {
        return (
            <>
                {
                        <Tooltip title={'Modifier'} placement="left">
                            <Link to={getRoutePathname('shop_reappro_reception', {reapproId: row.id})}>
                                <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                            </Link>
                        </Tooltip>
                }
            </>
        );
    };

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Liste des réapprovisionnements',
            context: 'Gestion boutique',
            description: '',
            links: []
        });
        
        setLoading(false);
    }, [])

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_shop_management_shop_list_reappro'}
                title={'Boutiques'}
                columns={columns}
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_shop_reappro_list_reappro_by_shop', params: {shopId: authenticationReducer.shopSelected}},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = data[index].createdAt ? data[index].createdAt : '-';
                                data[index].deposit = data[index].deposit ? data[index].deposit : '-';
                                data[index].fromDeposit = data[index].fromDeposit ? data[index].fromDeposit : '-';
                                data[index].reapproStateLabel = data[index].reapproStateLabel ? data[index].reapproStateLabel : '-';
                                data[index].shop = data[index].shop ? data[index].shop : '-';
                            }
                            resolve(data);
                        });
                }}
            />
            <br/><br/>
        </ContentViewComponent>
    );
}

export default ListReappro;
