import React, {useEffect, useState} from "react";
import {Grid, Slide} from "@mui/material";
import {makeStyles} from "@mui/styles";

import {dispatch} from "../../../App";
import {cacheTableServer} from "../../../Cache";
import ApiHandler from "../../../Handler/ApiHandler";
import {TableReload} from "../../../Action/TableAction";
import {displayErrorMessage} from "../../../Error/Errors";
import {initFormHandler} from "../../../Handler/FormHandler";
import TitleComponent from "../../../Component/TitleComponent";
import TableComponent from "../../../Component/TableComponent";
import {momentDateFormatter} from "../../../Util/MomentDateFormatter";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import {formatNumberToEurCurrency, formatNumberWithThousandsSeparator} from "../../../Util/NumberTool";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {floatSortComparatorForTableComponent} from "../../../Util/SortNumber";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";

function StatisticMark() {
    const [loadingContent, setLoadingContent] = useState(false);
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
    const [disabledStatusButtonDownload, setDisabledStatusButtonDownload] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState({});
    const [optionsMark, setOptionsMark] = useState([]);
    const [optionsChannel, setOptionsChannel] = useState([]);
    const [optionsFamily, setOptionsFamily] = useState([]);
    const [optionsSubfamily, setOptionsSubfamily] = useState([]);
    const [statsMarkDTO, setStatsMarkDTO] = useState([]);

    const classes = useStyles();

    const [formSearch, setFormSearch] = useState({
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_marks'] && cacheTableServer['api_statistic_marks'].startDate ? cacheTableServer['api_statistic_marks'].startDate : '',
            options: {validation: ['date']}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_marks'] && cacheTableServer['api_statistic_marks'].endDate ? cacheTableServer['api_statistic_marks'].endDate : '',
            options: {validation: ['date']}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marque.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_marks'] && cacheTableServer['api_statistic_marks'].mark ? cacheTableServer['api_statistic_marks'].mark : '',
            options: {}
        },
        channels: {
            name: 'channels',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        family: {
            name: 'family',
            label: 'Famille',
            textHelper: 'Choisissez la famille de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        subfamily: {
            name: 'subfamily',
            label: 'Sous Famille',
            textHelper: 'Choisissez la sous famille de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        product: {
            name: 'product',
            label: 'Produit ou sku',
            textHelper: 'Recherche par article ou sku.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);

        if (index === "mark") {
            ApiHandler.get({route: 'api_select_family', data: {mark: formSearch.mark.value}}, (response) => {
                let data = [{value: 0, label: 'Tous'}, ...response.data];
                setOptionsFamily(data);
            });
        }
    }

    useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Tous', topList: false}, ...response.data];
            for (let index in data) {
                data[index].customClass = data[index].topList ? classes.terravitaMarksText : ''
            }
            setOptionsMark(data);
        });

        ApiHandler.get({
            route: 'api_select_channel',
            data: {'limited': true, 'from': 'stats'}
        }, (response) => {
            let filteredChannels = response.data.filter(channel => channel.value !== 7); // exclude ReapproBoutique
            setOptionsChannel(filteredChannels);
        })

        ApiHandler.get({route: 'api_select_family'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsFamily(data);
        });

        setBreadcrumbs({
            title: 'Stats marques',
            context: 'Statistiques',
            description: '',
            links: []
        });

    }, [])

    useEffect(() => {
        ApiHandler.get({route: 'api_select_subfamily', data: {family: formSearch.family.value}}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsSubfamily(data);
            let inData = false;
            for (let index in data) {
                if (data[index].value === formSearch.subfamily.value) inData = true;
            }

            if (!inData) handlerFormSearch.setValue('subfamily', '');
        });
    }, [formSearch.family.value]);

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();

        let filters = {
            startDate: formSearch.startDate.value ? momentDateFormatter.formatStartOfDay(formSearch.startDate.value) : '',
            endDate: formSearch.endDate.value ? momentDateFormatter.formatEndOfDay(formSearch.endDate.value) : '',
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark,
            channels: (datas.channels === 0 || !datas.channels) ? '' : datas.channels.join("|"),
            family: (datas.family === 0 || !datas.family) ? '' : datas.family,
            subfamily: (datas.subfamily === 0 || !datas.subfamily) ? '' : datas.subfamily,
            product: (datas.product === 0 || !datas.product) ? '' : datas.product
        };

        ApiHandler.get({
                route: 'api_statistic_marks',
                data: filters
            },
            (response) => {

                if (response.status >= 200 && response.status < 300) {
                    let data = response.data.data ?? [];

                    setStatsMarkDTO(data);

                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('api_statistic_marks'));
                } else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    displayErrorMessage(response)
                }
            }
        );
    };

    useEffect(() => {
        dispatch(TableReload('api_statistic_marks'));
    }, [statsMarkDTO]);


    const downloadAction = () => {
        setDisabledStatusButtonDownload(true);
        setLoadingCSV(true);

        let datas = handlerFormSearch.getData();

        let params = {
            startDate: formSearch.startDate.value ? momentDateFormatter.formatStartOfDay(formSearch.startDate.value) : '',
            endDate: formSearch.endDate.value ? momentDateFormatter.formatEndOfDay(formSearch.endDate.value) : '',
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark,
            channels: (datas.channels === 0 || !datas.channels) ? '' : datas.channels.join("|"),
            family: (datas.family === 0 || !datas.family) ? '' : datas.family,
            subfamily: (datas.subfamily === 0 || !datas.subfamily) ? '' : datas.subfamily,
            product: (datas.product === 0 || !datas.product) ? '' : datas.product
        }

        let route = ApiHandler.route({route: 'api_statistic_marks_download', data: params});

        fetch(route, {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
            .then(res => res.blob())
            .then(res => {
                setDisabledStatusButtonDownload(false);
                setLoadingCSV(false);
                const aElement = document.createElement('a');
                aElement.setAttribute('download', 'stats_marks.xls');
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    }

    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (statsMarkDTO.length > 0) {
            const channelsData = statsMarkDTO[0].channels;

            const columns = [
                {
                    name: "mark",
                    label: "Marque",
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                minWidth: "105px",
                                maxWidth: "105px",
                                position: "sticky",
                                left: 0,
                                background: "white",
                                zIndex: 101,
                            }
                        }),
                        setCellProps: () => ({
                            style: {
                                minWidth: "105px",
                                maxWidth: "105px",
                                position: "sticky",
                                left: 0,
                                background: "white",
                                zIndex: 100,
                                paddingLeft: '25px',
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            }
                        })
                    }
                },
                {
                    name: "productName",
                    label: "Nom produit",
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                minWidth: "300px",
                                maxWidth: "300px",
                                position: "sticky",
                                left: '140px',
                                background: "white",
                                zIndex: 101
                            }
                        }),
                        setCellProps: () => ({
                            style: {
                                minWidth: "300px",
                                maxWidth: "300px",
                                position: "sticky",
                                left: '140px',
                                background: "white",
                                zIndex: 100,
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                paddingRight: 0
                            }
                        })
                    }
                },
                {
                    name: "sku",
                    label: "Sku",
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                minWidth: "130px",
                                maxWidth: "130px",
                            }
                        }),
                        setCellProps: () => ({
                            style: {
                                minWidth: "130px",
                                maxWidth: "130px",
                            }
                        })
                    }
                },
            ];

            channelsData.forEach((item) => {
                if (item.channelLabel.toUpperCase() === 'TOUTELANUTRITION') {
                    item.channelLabel = 'TLN';
                }
                let indexNbUnit = columns.length
                let nbUnitColumn = {
                    name: 'nbUnit' + item.channelId,
                    label: item.channelLabel,
                    options: {
                        filter: true,
                        sort: false,
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(indexNbUnit)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>{columnMeta.label}</p>
                                <p>Nb d'unité</p>
                            </th>
                        ),
                        customBodyRender: (value) => {
                            return formatNumberWithThousandsSeparator(value)
                        },
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                };

                let indexCaHt = columns.length + 1
                let caHtColumn = {
                    name: 'caHt' + item.channelId,
                    label: item.channelLabel,
                    options: {
                        sortCompare: (order) => {
                            return (val1, val2) => {
                                return floatSortComparatorForTableComponent(order, val1, val2);
                            };
                        },
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(indexCaHt)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>{columnMeta.label}</p>
                                <p>CA HT</p>
                            </th>
                        ),
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                };

                let indexMarge = columns.length + 2
                let margeColumn = {
                    name: 'marge' + item.channelId,
                    label: item.channelLabel,
                    options: {
                        sortCompare: (order) => {
                            return (val1, val2) => {
                                return floatSortComparatorForTableComponent(order, val1, val2);
                            };
                        },
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(indexMarge)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>{columnMeta.label}</p>
                                <p>Marge</p>
                            </th>
                        ),
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                };
                columns.push(nbUnitColumn, caHtColumn, margeColumn);
            });

            const totalColumns = [
                {
                    name: "totalNumberUnit",
                    label: "Nb d'unité",
                    options: {
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(columns.length)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>Total</p>
                                <p>NB Unité</p>
                            </th>
                        ),
                        customBodyRender: (value) => {
                            return formatNumberWithThousandsSeparator(value)
                        },
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                },
                {
                    name: "totalCaHt",
                    label: "Total CA HT",
                    options: {
                        sortCompare: (order) => {
                            return (val1, val2) => {
                                return floatSortComparatorForTableComponent(order, val1, val2);
                            };
                        },
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(columns.length + 1)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>Total</p>
                                <p>CA HT</p>
                            </th>
                        ),
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                },
                {
                    name: "totalMarge",
                    label: "Total Marge",
                    options: {
                        sortCompare: (order) => {
                            return (val1, val2) => {
                                return floatSortComparatorForTableComponent(order, val1, val2);
                            };
                        },
                        customHeadRender: (columnMeta, updateDirection) => (
                            <th key={columnMeta.name} onClick={() => updateDirection(columns.length + 2)}
                                style={{cursor: 'pointer'}}
                                className={classes.customTh}>
                                <p>Total</p>
                                <p>Marge</p>
                            </th>
                        ),
                        setCellProps: () => {
                            return {align: "center"}
                        }
                    }
                },
            ];

            setColumns([...columns, ...totalColumns]);
        }
    }, [statsMarkDTO]);

    const height = '520px'
    
    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                                <DatePickerComponent id={'startDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('startDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                                <DatePickerComponent id={'endDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('endDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={4} xl={2}>
                                <SelectComponent
                                    id={'mark'}
                                    options={optionsMark}
                                    handler={handlerFormSearch}
                                    onChange={(val) => inputSearchChange('mark', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                                <MultipleSelectComponent id={'channels'}
                                                         options={optionsChannel}
                                                         handler={handlerFormSearch}
                                                         onChange={(val) => inputSearchChange('channels', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                                <SelectComponent id={'family'}
                                                 options={optionsFamily}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('family', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={4} xl={2}>
                                <SelectComponent id={'subfamily'}
                                                 options={optionsSubfamily}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('subfamily', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <TextFieldComponent id={'product'}
                                                    handler={handlerFormSearch}
                                                    onChange={(val) => inputSearchChange('product', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <ButtonComponent className={classes.buttonActionFirst}
                                                 label={"Rechercher"}
                                                 onClick={() => onSearch()}
                                                 disabled={disabledStatusButtonSearch}
                                                 loading={loadingContent}
                                />
                                <ButtonComponent className={classes.buttonActionSecond}
                                                 label={"CSV"}
                                                 onClick={() => downloadAction()}
                                                 disabled={disabledStatusButtonDownload}
                                                 loading={loadingCSV}
                                                 color={'#35A2EB'}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <TableComponent
                id={'api_statistic_marks'}
                title={'Stats marques'}
                height={height}
                columns={columns}
                search={false}
                promiseData={(resolve) => {
                    let data = statsMarkDTO.map((row) => {
                        let rowData = {
                            id: row.id,
                            mark: row.mark,
                            productName: row.productName ?? '-',
                            sku: row.sku ?? '-',
                            totalNumberUnit: row.totalNumberUnit ?? '-',
                            totalCaHt: formatNumberToEurCurrency(row.totalCaHt) ?? '-',
                            totalMarge: formatNumberToEurCurrency(row.totalMarge) ?? '-',
                        };

                        row.channels.forEach((channel) => {
                            rowData['nbUnit' + channel.channelId] = channel.numberUnit;
                            rowData['caHt' + channel.channelId] = formatNumberToEurCurrency(channel.caHt);
                            rowData['marge' + channel.channelId] = formatNumberToEurCurrency(channel.marge);
                        });

                        return rowData;
                    });
                    resolve(data);
                }}

            />
        </ContentViewComponent>
    )
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60,
        paddingTop: 60
    },
    button: {
        margin: '15px 15px 15px 0 !important',
    },
    buttonTop: {
        margin: '15px !important',
        top: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    terravitaMarksText: {
        fontWeight: '700 !important',
    },
    customTh: {
        zIndex: 1,
        position: 'sticky',
        top: '0px',
        backgroundColor: '#fff',
        minWidth: '63px',
        padding: '0 7px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
});

export default StatisticMark;
