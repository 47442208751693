import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import Store from "./Store";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persistor}>
            <App/>
        </PersistGate>
    </Provider>,
);

reportWebVitals();
