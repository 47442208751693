import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {hasRight} from "../../../Common/UserCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ButtonComponent from "../../../Component/ButtonComponent";
import {initFormHandler} from "../../../Handler/FormHandler";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";
import SelectComponent from '../../../Component/SelectComponent';

function SettingChannelList() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [channel, setChannel] = React.useState(null);
    const [optionsCountry, setOptionsCountry] = React.useState([]);

    // Global
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "address1",
            label: "Adresse 1",
            options: {filter: true, sort: true}
        },
        {
            name: "address1",
            label: "Adresse 2",
            options: {filter: true, sort: true}
        },
        {
            name: "zipcode",
            label: "Code postal",
            options: {filter: true, sort: true}
        },
        {
            name: "city",
            label: "Ville",
            options: {filter: true, sort: true}
        },
        {
            name: "countryText",
            label: "Pays",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SETTING_CHANNEL') || hasRight(authenticationReducer, 'ROLE_SETTING_CHANNEL_EDIT'))) {
            return (
                <>
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton
                            onClick={() => {
                                handlerForm.reset();
                                setChannel(row.id);
                                handlerForm.setValue('label', row.label);
                                handlerForm.setValue('name', row.name);
                                handlerForm.setValue('address1', row.address1);
                                handlerForm.setValue('address2', row.address2);
                                handlerForm.setValue('zipcode', row.zipcode);
                                handlerForm.setValue('city', row.city);
                                handlerForm.setValue('country', row.countryId);
                                setOpenDialog(true);
                            }}
                        ><Edit style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Edit/>
                    </IconButton>
                </>
            );
        }
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des canaux',
            context: 'Paramétrage',
            description: '',
            links: []
        });
        ApiHandler.get({route: 'api_select_country'}, (response) => { setOptionsCountry(response.data)});
    }, []);

    // Form
    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Saisissez le libellé de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        address1: {
            name: 'address1',
            label: 'Adresse 1',
            textHelper: 'Saisissez l\'adresse 1 de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        address2: {
            name: 'address2',
            label: 'Adresse 2',
            textHelper: 'Saisissez l\'adresse 2 de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        zipcode: {
            name: 'zipcode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville de la canal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        country: {
            name: 'country',
            label: 'Pays',
            textHelper: 'Selectionnez le pays de la canal.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (channel) {
                ApiHandler.post({
                    route: 'api_setting_channel_edit',
                    data: handlerForm.getData(),
                    params: {id: channel}
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_channel_list'));
                        setChannel(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Canal modifiée avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_channel');
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_setting_channel_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_channel_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Canal créée avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_channel');
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };

    React.useEffect(handlerForm.start, []);

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_setting_channel_list'}
                title={'Canaux'}
                columns={columns}
                /*actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_SETTING_CHANNEL') || hasRight(authenticationReducer, 'ROLE_SETTING_CHANNEL_ADD')) ?
                        {
                            label: 'Ajouter une canal', onClick: () => {
                                setChannel(null);
                                handlerForm.reset();
                                setOpenDialog(true);
                            }
                        } : false
                }*/
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_setting_channel_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{channel ? 'Modifier une canal' : 'Ajouter une canal'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent id={'label'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent id={'name'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent id={'address1'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent id={'address2'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent id={'zipcode'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent id={'city'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SelectComponent id={'country'} options={optionsCountry} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} disabled={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default SettingChannelList;
