import moment from "moment";

export const momentDateFormatter = {
    formatMomentDate: (date, format) => {
        return moment(date).format(format);
    },

    formatStartOfDay: (date, format = 'YYYY-MM-DD') => {
        return moment(date).format(format + ' 00:00:00');
    },

    formatEndOfDay: (date, format = 'YYYY-MM-DD') => {
        return moment(date).format(format + ' 23:59:59');
    },

    dateTimeToFormatFr: (date: string): string => {
        return new Date(date).toLocaleDateString('fr-FR');
    },
};
