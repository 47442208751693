import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {hasRight} from "../../../../Common/UserCommon";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {makeStyles} from "@mui/styles";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../../Action/LinearProgressMainLayoutAction";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid} from "@mui/material";
import SelectComponent from "../../../../Component/SelectComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";

function ClientBusinessPriceListForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [optionsPriceList, setOptionsPriceList] = React.useState([]);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

    const [form, setForm] = React.useState({
        priceList: {
            name: 'priceList',
            label: 'Grille tarifaire',
            textHelper: 'Choisissez la grille tarifaire.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);

    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.clientBusiness) {
                ApiHandler.post({
                    route: 'api_b2b_client_business_edit_price_list',
                    data: handlerForm.getData(),
                    params: {id: props.clientBusiness[0].id}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Business modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.clientBusiness) {
            handlerForm.setDataApi(props.clientBusiness[0]);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_price_list'}, (response) => {
            let data = [{value: '', label: ''}, ...response.data];
            setOptionsPriceList(data)
        });
    }, []);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={props.clientBusiness[0].priceList !== null ? 'Grille tarifaire' : 'Grille tarifaire (Vous devez sélectionner une grille tarifaire avant de pouvoir créer une proposition commerciale)'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'priceList'} options={optionsPriceList} handler={handlerForm}/>
                </Grid>

            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ClientBusinessPriceListForm;