import React from 'react'
import {TextField, InputAdornment, IconButton} from "@mui/material";
import {makeStyles} from '@mui/styles';
import UseAnimations from "react-useanimations";
import visibility from 'react-useanimations/lib/visibility';

function TextFieldComponent(props) {

    // Info
    const id = props.id;
    const input = props.handler.form[id];
    const label = input.label;
    const type = input.type;

    const classes = useStyles();
    const [error, setError] = React.useState(input.error);
    const [isFocus, setFocus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(type === 'password');
    const isVisibility = type === 'password';

    // Value
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        if (input.value !== undefined && input.value !== value) setValue(input.value.toString());
    }, [input.value]);
    React.useEffect(() => {
        if (input.error !== error) setError(input.error);
    }, [input.error]);

    // On
    const onChange = (event) => {
        let newValue = event.target.value ?? '';

        if (newValue) {
            if (type === 'integer') {
                if ((newValue.toString().match(/^-?\d*$/i) === null)) {
                    newValue = value;
                }
            }
            else if (type === 'float') {
                if ((newValue.toString().match(/^-?[0-9]*\.?[0-9]*$/i) === null)) {
                    newValue = value;
                }
            }

            if (input.options.max) {
                let length = (type === 'text' || type === 'password') ? newValue.toString().length : newValue;
                if (!(input.options.max && length <= input.options.max)) {
                    newValue = value;
                }
            }

            if (input.options.inf) {
                let length = (type === 'text' || type === 'password') ? newValue.toString().length : newValue;
                if (!(input.options.inf && length < input.options.inf)) {
                    newValue = value;
                }
            }

            if (input.options.equal) {
                let length = (type === 'text' || type === 'password') ? newValue.toString().length : newValue;
                if (!(input.options.equal && length <= input.options.equal)) {
                    newValue = value;
                }
            }
        }

        setValue(newValue);
        if(props.onChange) props.onChange(newValue);

        if (!isFocus) {
            setFocus(false);
            props.handler.setValue(id, newValue);
            let error = props.handler.getErrorByField(input);
            setError(error);
            props.handler.setError(id, error);
        }
    };
    const onBlur = () => {
        setFocus(false);
        props.handler.setValue(id, value);
        let error = props.handler.getErrorByField(input);
        setError(error);
        props.handler.setError(id, error);

        if (props.onBlur) setTimeout(props.onBlur, 250);
    };
    const onFocus = () => {
        setFocus(true);
        if (props.onFocus) setTimeout(props.onFocus, 250);
    }


    return (
        <div className={classes.content}>
            <TextField
                style={props.style ? props.style : {}}
                inputRef={props.inputRef ?? null}
                autoComplete={props.autoComplete === false ? 'off' : 'on'}
                onKeyDown={(e) => {
                    if (props.onScan && e.key === 'Enter') {
                        props.onScan(value);
                        setTimeout(() => {
                            props.handler.setValue(id, '');
                            setValue('');
                        },100);
                    }
                }}
                multiline={props.multiline}
                inputProps={{autoFocus: props.autoFocus}}
                id={id}
                label={label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                variant="outlined"
                type={showPassword ? 'password' : 'text'}
                disabled={input.disabled || props.disabled}
                className={`
                    ${classes.textField}
                    ${isFocus ? classes.textFieldFocus : error ? classes.textFieldError : value && (!input.disabled && !props.disabled) ? classes.textFieldValid : ''}
                `}
                helperText={error && !isFocus ? error : input.textHelper}
                InputProps={props.adornmentOrientation && props.adornmentOrientation === 'left' ? {
                    startAdornment: type === 'password' ? (
                        <InputAdornment position="end" disabled={input.disabled || props.disabled}>
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                disabled={input.disabled || props.disabled}
                            >
                                {isVisibility && <UseAnimations animation={visibility} reverse={showPassword}/>}
                            </IconButton>
                        </InputAdornment>
                    ) : props.adornment ? props.adornment : <></>
                } : {
                    endAdornment: type === 'password' ? (
                        <InputAdornment position="end" disabled={input.disabled || props.disabled}>
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                disabled={input.disabled || props.disabled}
                            >
                                {isVisibility && <UseAnimations animation={visibility} reverse={showPassword}/>}
                            </IconButton>
                        </InputAdornment>
                    ) : props.adornment ? props.adornment : <></>
                }}
            />
        </div>
    )
}

const useStyles = makeStyles({
    content: {
        padding: 5
    },
    textField: {
        width: '100%',
        '& input': {
            padding: '7px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 7px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            minHeight: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    textFieldFocus: {
        '& .MuiFormHelperText-root': {
            color: '#3F51BF'
        }
    },
    textFieldValid: {
        '& fieldset': {
            borderColor: '#006500 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#006500'
        },
        '& label': {
            color: '#006500'
        }
    },
    textFieldError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525'
        },
        '& label': {
            color: '#982525'
        }
    }
});

export default TextFieldComponent;
