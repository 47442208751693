import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import {Toolbar, AppBar as AppBarMUI, IconButton, Tooltip} from '@mui/material';
import ApiHandler from "../../../Handler/ApiHandler";
import {Edit, PowerSettingsNew} from '@mui/icons-material';
import {AuthenticationLogout} from "../../../Action/AuthenticationAction";
import LinearProgress from "./LinearProgress";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";

function AppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const logout = () => {
        ApiHandler.get({route: 'api_authentication_logout'}, () => {});
        dispatch(AuthenticationLogout());
        window.location.reload();
    };

    return (
        <AppBarMUI position="fixed" className={classes.appBar} style={{textAlign: 'center'}}>
            <Link to={getRoutePathname('shop_sales_list_today', {shopId: authenticationReducer.shopSelected})}>
                <img src={'/image/shop_1.png'} alt="logo" className={classes.appBarLogo} style={{position: 'absolute', left: 0, top: '10px'}}/>
            </Link>
            <Tooltip title="Déconnexion" aria-label="Déconnexion" style={{position: 'absolute', right: 0, top: '0px'}}>
                <IconButton className={classes.appBarLogout} onClick={logout}>
                    <PowerSettingsNew className={classes.appBarLogoutIcon}/>
                </IconButton>
            </Tooltip>
            <Toolbar style={{margin: '0 auto'}}>
                <Tooltip title={'Nouvelle vente'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('shop_sales_client_list', {shopId: authenticationReducer.shopSelected})}>
                        <IconButton style={{fontSize: 18, color: '#fff'}} >Nouvelle vente</IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title={'Historique des ventes'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('shop_sales_list', {shopId: authenticationReducer.shopSelected})}>
                        <IconButton style={{fontSize: 18, color: '#fff'}} >Historique des ventes</IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title={'demande de réappro'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('shop_reappro_create', {id: authenticationReducer.shopSelected})}>
                        <IconButton style={{fontSize: 18, color: '#fff'}}>Demande appro</IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title={'récéption réappro'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('shop_reappro_list', {shopId: authenticationReducer.shopSelected})}>
                        <IconButton style={{fontSize: 18, color: '#fff'}}>Réceptionner appro</IconButton>
                    </Link>
                </Tooltip>
                <br/>
                <Tooltip title={'inventaire'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('inventory_shop_management_inventory_list')}>
                        <IconButton style={{fontSize: 18, color: '#fff'}}>Inventaire</IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title={'retour article'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('inventory_shop_management_return_order')}>
                        <IconButton style={{fontSize: 18, color: '#fff'}}>Retour article</IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title={'voir les articles'} placement="left">
                    <Link style={{border: '1px solid black', margin: '0 5px', background: '#0484c7'}} to={getRoutePathname('shop_list_products', {shopId: authenticationReducer.shopSelected})}>
                        <IconButton style={{fontSize: 18, color: '#fff'}}>Articles et stocks</IconButton>
                    </Link>
                </Tooltip>
            </Toolbar>
            <LinearProgress/>
        </AppBarMUI>
    );
}

const useStyles = makeStyles({
    appBar: {
        zIndex: 2,
        backgroundColor: '#FFFFFF !important',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 58,
        '& .MuiToolbar-regular': {
            height: 58,
            minHeight: '58px !important',
            paddingLeft: 23,
            paddingRight: 23,
        },
    },
    appBarButton: {
        marginRight: 24,
        padding: 0,
        cursor: 'pointer',
        color: '#5E6E82'

    },
    appBarLogo: {
        height: 35,
        paddingLeft: 26,
        float: 'right'
    },
    appBarLogout: {
        right: 0,
        top: 0,
        position: 'absolute !important',
        padding: '10px !important',
        margin: '8px !important',
        marginRight: '12px !important',
        color: '#5E6E82 !important'
    },
    appBarLogoutIcon: {
        fontSize: 22
    }
});

export default AppBar;
