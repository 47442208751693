import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import InventoryManagementPreparerManagerPreparerList from "./PreparerManagerComponents/InventoryManagementPreparerManagerPreparerList";
import InventoryManagementPreparerManagerOrderList from "./PreparerManagerComponents/InventoryManagementPreparerManagerOrderList";

function InventoryManagementPreparerManager() {
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Manager préparateur de commande',
            context: 'Gestion des stocks',
            description: '',
            links: []
        });
    }, [])

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs}>
            <InventoryManagementPreparerManagerPreparerList/>
            <br/>
            <InventoryManagementPreparerManagerOrderList/>
        </ContentViewComponent>
    );
}

export default InventoryManagementPreparerManager;
