import React from 'react';
import {Fade, Grid} from "@mui/material";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ShopManagementShopForm from './ShopManagementShopComponent/ShopManagementShopForm';
import ShopManagementByProductList from './ShopManagementShopComponent/ShopManagementByProductList';

function ShopManagementShop() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [shop, setShop] = React.useState(null);

    React.useEffect(() => {
        if (params.id === 'add') {
            setShop(null);

            setBreadcrumbs({
                title: 'Ajouter une boutique',
                context: 'Gestion boutique',
                description: '',
                links: [
                    {path: getRoutePathname('shop_management_shop_list'), label: 'Liste des boutiques'}
                ]
            });
            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else {
            setShop(null);
            setIsLoadingContent(true)
            setBreadcrumbs({
                title: 'Éditer une boutique',
                context: 'Gestion boutique',
                description: '',
                links: [
                    {path: getRoutePathname('shop_management_shop_list'), label: 'Liste des boutiques'}
                ]
            });

            ApiHandler.get({
                route: 'api_shop_management_shop_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setShop(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>

            <Fade in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShopManagementShopForm shop={shop}/>
                </Grid>
            </Fade>

            <br/>

            {shop && <ShopManagementByProductList shop={shop}/>}

            <br/><br/>

        </ContentViewComponent>
    );
}

export default ShopManagementShop;
