import React from 'react';
import {Fade, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@mui/material";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import TitleComponent from "../../../Component/TitleComponent";
import Paper from "@mui/material/Paper";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {makeStyles} from "@mui/styles";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";

function ClosingShopEdit() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [shopClose, setShopClose] = React.useState(null);
    const classes = useStyles();
    const [recap, setRecap] = React.useState(null);
    const [totalCashDay, setTotalCashDay] = React.useState(null);
    const [caisseEspece, setCaisseEspece] = React.useState(null);
    const [ecartEspece, setEcartEspece] = React.useState(null);
    const [ecartCb, setEcartCb] = React.useState(null);
    const [totalCBDay, setTotalCBDay] = React.useState(null);
    const [totalRemise, setTotalRemise] = React.useState(null);

    React.useEffect(() => {
        if (params.id === 'add') {

        }
        else {
            setIsLoadingContent(true)
            setBreadcrumbs({
                title: 'Consulter une cloture de caisse',
                context: 'Gestion boutique',
                description: '',
                links: [
                    {path: getRoutePathname('shop_management_shop_list'), label: 'Liste des boutiques'}
                ]
            });

            ApiHandler.get({
                route: 'api_shop_management_shop_closing_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setShopClose(response.data);

                    setTimeout(() => {
                            ApiHandler.post({
                                route: 'api_shop_management_shop_closing_get_recap_day',
                                params: {closeId: response.data.id }
                            },
                            (response2) => {
                                setRecap(response2.data);
                                setTotalCBDay(0);

                                var totalCaisseEspece = parseFloat(response.data.env500 * 500) + parseFloat(response.data.env200 * 200) + parseFloat(response.data.env100*100) + parseFloat(response.data.env50*50) + parseFloat(response.data.env20*20) + parseFloat(response.data.env10*10) + parseFloat(response.data.env5*5) ;
                                setCaisseEspece((totalCaisseEspece));

                                var total = parseFloat(response.data.b500 * 500) + parseFloat(response.data.b200 * 200) + parseFloat(response.data.b100*100) + parseFloat(response.data.b50*50) + parseFloat(response.data.b20*20) + parseFloat(response.data.b10*10) + parseFloat(response.data.b5*5) + parseFloat(response.data.p2*2) + parseFloat(response.data.p1*1) + parseFloat(response.data.p050*0.5) + parseFloat(response.data.p020*0.2) + parseFloat(response.data.p010*0.1) + parseFloat(response.data.p005*0.05) + parseFloat(response.data.p002*0.02) + parseFloat(response.data.p001*0.01);
                                setTotalCashDay((total).toFixed(2));
                                setEcartEspece(( (parseFloat(total) + parseFloat(totalCaisseEspece)) - (parseFloat(response2.data.totalOpening) + parseFloat(response2.data.totalCashDay))));

                                var totalCBDayFromReporting = response.data.totalCBreceived;

                                setTotalCBDay(parseFloat(totalCBDayFromReporting).toFixed(2));
                                setEcartCb((parseFloat(totalCBDayFromReporting) - parseFloat(response2.data.totalCBDay)));
                                let tempTotal = totalRemise;

                                setTotalRemise(parseFloat(response2.data.totalAvoirDay) + parseFloat(totalCBDayFromReporting) + parseFloat(totalCaisseEspece));
                                dispatch(TableReload('recapClotureTable'))
                            });
                    }, 500);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewShopComponent>
            {recap != null && shopClose != null && <>
                <br/>
                <TitleComponent title={'Récapitulatif'}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        Boutique : {recap.shop}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        Date de clôture : {recap.dateCloture}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        Utilisateur : {recap.user}
                    </Grid>
                </Grid>
                <br/>
                <TableContainer component={Paper} id={"recapClotureTable"}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead sx={{ backgroundColor: "lightgrey"}}>
                            <TableRow>
                                <TableCell style={{fontWeight: 900, fontSize: 12}}></TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Fonds de caisse à l'ouverture</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Chiffres d'affaires</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Ventilation encaissements</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Avoirs émis</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Remise au siège</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Fond de caisse après clôture</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Ecart</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Carte bancaire</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCBDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCBDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(totalCBDay)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">{parseFloat(ecartCb).toFixed(2)} €</TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Espèce</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalOpening).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCashDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCashDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(caisseEspece)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(totalCashDay)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">{(parseFloat(ecartEspece).toFixed(2))} €</TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Avoirs</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">-</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay - recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">- {(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">-</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">0</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: "lightgrey"}}>
                            <TableRow>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Total</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(recap.totalOpening).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(recap.totalCashDay + recap.totalCBDay + (recap.totalAvoirDay - recap.totalCreditToday)).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 12}} component="th" align="center">{(recap.totalCBDay + recap.totalCashDay + recap.totalAvoirDay ).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 12}} component="th" align="center">- {(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(parseFloat(totalRemise)).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">-</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 14}} align="center">{(parseFloat(ecartCb) + parseFloat(ecartEspece)).toFixed(2)} €</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <br/>
                <hr/>
                <br/>
                <ShadowBoxComponent className={classes.shadowBox}>
                    <TitleComponent title={'Espèces remis au siège'}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            500€ : {500 * shopClose.env500} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            200€ : {200 * shopClose.env200} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            100€ : {100 * shopClose.env100} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                           50€ : {50*shopClose.env50} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            20€ : {20 * shopClose.env20} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            10€ : {10 * shopClose.env10} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            5€ : {5 * shopClose.env5} €
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <TitleComponent title={'Fonds de caisse à la cloture'}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            500€ : {500 * shopClose.b5} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            200€ : {200 * shopClose.b200} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            100€ : {100 * shopClose.b100} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            50€ : {50 * shopClose.b50} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            20€ : {20 * shopClose.b20} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            10€ :  {10 * shopClose.b10} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            5€ : {5 * shopClose.b5} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            2€ : {2 * shopClose.p2} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            1€ : {1 * shopClose.p1} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.5€ : {0.5 * shopClose.p050} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.2€ : {0.2 * shopClose.p020} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.1€ : {0.1 * shopClose.p010} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.05€ : {0.05 * shopClose.p005} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.02€ : {0.02 * shopClose.p002} €
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            0.01€ : {0.01 * shopClose.p001} €
                        </Grid>
                    </Grid>
                    <br/>
                    <hr/>
                    <h4>Liste des paiements CB</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopCard > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{(row.shopCard).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center"></TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={3} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{(recap.totalCBDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Remis au siège</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{parseFloat(shopClose.totalCBreceived).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des paiements Espèces</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopCash > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} scope="row"
                                                           align="center">{parseFloat(row.shopCash - row.cashBack).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={2} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{parseFloat(recap.totalCashDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des paiements Avoirs</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopAvoir > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{parseFloat(row.shopAvoir).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={2} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{parseFloat(recap.totalAvoirDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des avoirs</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersCreditDay.length > 0) ? recap.ordersCreditDay.map((row) => {
                                    return (
                                        <TableRow key={row.uuid}
                                                  sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                       align="center">{row.orderNumber}</TableCell>
                                            <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                       align="center">{parseFloat(row.priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                        </TableRow>
                                    )
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={2} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <hr/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            Commentaire :
                            {shopClose.comment}
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </>}
        </ContentViewShopComponent>

    );
}


const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});


export default ClosingShopEdit;
