import React from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import {LinearProgress as LinearProgressMUI} from '@mui/material';

function LinearProgress() {
    const classes = useStyles();
    const [isSmallView, setSmallView] = React.useState(false);

    const linearProgressMainLayoutReducer = useSelector(state => state.LinearProgressMainLayoutReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return linearProgressMainLayoutReducer.isActivated ? <LinearProgressMUI className={`${classes.linearProgress} ${isSmallView && classes.linearProgressSmall}`} hidden={!linearProgressMainLayoutReducer.isActivated}/> : <></>;
}

const useStyles = makeStyles({
    linearProgress: {
        position: 'absolute !important',
        top: 58,
        width: '100vw',
        height: '2px !important',
        backgroundColor: 'rgba(87,165,133,0.25) !important',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#57A585'
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    linearProgressSmall: {
        top: 56,
    }
});

export default LinearProgress;
