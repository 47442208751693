import {useSelector} from "react-redux";
import React from "react";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import {initFormHandler} from "../../../Handler/FormHandler";
import {dispatch} from "../../../App";
import ApiHandler from "../../../Handler/ApiHandler";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Grid, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import SelectComponent from "../../../Component/SelectComponent";
import {isNumber} from "../../../Util/NumberTool";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Paper from "@mui/material/Paper";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import ButtonComponent from "../../../Component/ButtonComponent";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import TableComponent from "../../../Component/TableComponent";
import {TableReload} from "../../../Action/TableAction";

function StatisticTradeRetail() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadingContent, setLoadingContent] = React.useState(false);
    const [loadingCSV, setLoadingCSV] = React.useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
    const [disabledStatusButtonDownload, setDisabledStatusButtonDownload] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [optionsRegion, setOptionsRegion] = React.useState([]);
    const [optionsShop, setOptionsShop] = React.useState([]);
    const [optionsByProduct, setOptionsByProduct] = React.useState([]);
    const [cellsTable, setCellsTable] = React.useState([]);

    const classes = useStyles();
    const params = useParams();
    const navigate = useNavigate();

    const columnsStats = [
        {
            name: "sellPlaceChannel",
            label: "Boutique",
            options: {filter: true, sort: true}
        },
        {
            name: "product",
            label: "Produit",
            options: {filter: true, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: true, sort: true}
        },
        {
            name: "totalPriceHt",
            label: "CA Total",
            options: {filter: true, sort: true},
            style: {
                textAlign: 'right'
            }
        },
        {
            name: "marge",
            label: "Marge",
            options: {filter: true, sort: true}
        },
        {
            name: "numberOfOrders",
            label: "Nombre de commandes",
            options: {filter: true, sort: true},
        },
        {
            name: "numberOfSellProducts",
            label: "Nombre de produits vendus",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtFive",
            label: "CA HT 5,5%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtTwenty",
            label: "CA HT 20%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtSix",
            label: "CA HT 6%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtTen",
            label: "CA HT 10%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaFive",
            label: "TVA 5,5%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaTwenty",
            label: "TVA 20%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaSix",
            label: "TVA 6%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaTen",
            label: "TVA 10%",
            options: {filter: true, sort: true}
        },
    ];

    const [formSearch, setFormSearch] = React.useState({
        fromStartAt: {
            name: 'fromStartAt',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromStartAt ? cacheTableServer['api_statistic_trades'].fromStartAt : '',
            options: {validation: ['date']}
        },
        fromEndAt: {
            name: 'fromEndAt',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromEndAt ? cacheTableServer['api_statistic_trades'].fromEndAt : '',
            options: {validation: ['date']}
        },
        region: {
            name: 'region',
            label: 'Région',
            textHelper: 'Rechercher par region.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        shop: {
            name: 'shop',
            label: 'Boutique',
            textHelper: 'Rechercher par boutique.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        byProduct: {
            name: 'byProduct',
            label: 'Produit',
            textHelper: 'Rechercher par produit.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].byProduct ? cacheTableServer['api_statistic_trades'].byProduct : 0,
            options: {}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marque.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].mark ? cacheTableServer['api_statistic_trades'].mark : 0,
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        if(index == "region") {
            ApiHandler.get({route: 'api_select_shop',params: {regionId: value}}, (response) => {
                let data = [{value: 0, label: 'Tous'}, ...response.data];
                setOptionsShop(data);
            });
        }
    }

    React.useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMark(data);
        });

        ApiHandler.get({route: 'api_select_by_product'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsByProduct(data);
        });

        ApiHandler.get({route: 'api_select_region', data: {'limited': true}}, (response) => {
            setOptionsRegion(response.data);
        });

        ApiHandler.get({route: 'api_select_shop'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsShop(data);
        });

        setBreadcrumbs({
            title: 'Stats de vente RETAIL',
            context: 'Statistiques',
            description: '',
            links: []
        });

    }, [])

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();
        let filters = {
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
            shop: (datas.shop === 0 || !datas.shop) ? '' : datas.shop.join("|"),
            byProduct: (datas.byProduct === 0 || !datas.byProduct) ? '' : datas.byProduct,
            region: (datas.region === 0 || !datas.region) ? '' : datas.region,
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark,
        };

        ApiHandler.get({
                route: 'api_statistic_trades_retail',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    setCellsTable(response.data);

                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                    setTimeout(function () {
                        dispatch(TableReload('table_api_statistic_trades_retail'));
                    }, 2000);
                }
                else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            }
        );
    };

    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromStartAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromStartAt', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromEndAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromEndAt', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <SelectComponent id={'region'} options={optionsRegion} handler={handlerFormSearch} onChange={(val) => inputSearchChange('region', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <MultipleSelectComponent id={'shop'} options={optionsShop} handler={handlerFormSearch} onChange={(val) => inputSearchChange('shop', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormSearch} onChange={(val) => inputSearchChange('mark', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <SelectComponent id={'byProduct'} options={optionsByProduct} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProduct', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <ButtonComponent className={classes.buttonActionFirst} label={"Rechercher"} onClick={() => onSearch()} disabled={disabledStatusButtonSearch} loading={loadingContent}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>
            <ShadowBoxComponent className={classes.shadowBox}>
                <TableComponent
                    id={'table_api_statistic_trades_retail'}
                    title={'Stats de vente RETAIL'}
                    columns={columnsStats}
                    search={false}
                    height={10}
                    promiseData={(resolve) => {
                        let data = cellsTable;
                        for (let index in data) {
                            data[index].sellPlaceChannel = data[index].sellPlaceChannel;
                            data[index].numberOfSellProducts = data[index].numberOfSellProducts;
                            data[index].numberOfOrders = data[index].numberOfOrders;
                            data[index].marge = isNumber(data[index].marge) ? parseFloat(data[index].marge).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalPriceHt = isNumber(data[index].totalPriceHt) ? parseFloat(data[index].totalPriceHt).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalCaHtFive = isNumber(data[index].totalCaHtFive) ? parseFloat(data[index].totalCaHtFive).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalTvaFive = isNumber(data[index].totalTvaFive) ? parseFloat(data[index].totalTvaFive).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalCaHtTwenty = isNumber(data[index].totalCaHtTwenty) ? parseFloat(data[index].totalCaHtTwenty).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalTvaTwenty = isNumber(data[index].totalTvaTwenty) ? parseFloat(data[index].totalTvaTwenty).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalCaHtSix = isNumber(data[index].totalCaHtSix) ? parseFloat(data[index].totalCaHtSix).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalTvaSix = isNumber(data[index].totalTvaSix) ? parseFloat(data[index].totalTvaSix).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalCaHtTen = isNumber(data[index].totalPriceHt) ? parseFloat(data[index].totalCaHtTen).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                            data[index].totalTvaTen = isNumber(data[index].totalTvaTen) ? parseFloat(data[index].totalTvaTen).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '-';
                        }
                        resolve(data);
                    }}
                />
            </ShadowBoxComponent>
        </ContentViewComponent>
    )
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60,
        paddingTop: 60
    },
    button: {
        margin: '15px 15px 15px 0 !important',
    },
    buttonTop: {
        margin: '15px !important',
        top: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
});

export default StatisticTradeRetail;
