
import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {hasRight} from "../../../Common/UserCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ButtonComponent from "../../../Component/ButtonComponent";
import {initFormHandler} from "../../../Handler/FormHandler";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";

function SettingCarrierList() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [carrier, setCarrier] = React.useState(null);

    // Global
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        }
    ];
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SETTING_CARRIER') || hasRight(authenticationReducer, 'ROLE_SETTING_CARRIER_EDIT'))) {
            return (
                <>
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton
                            onClick={() => {
                                handlerForm.reset();
                                setCarrier(row.id);
                                handlerForm.setValue('label', row.label);
                                setOpenDialog(true);
                            }}
                        ><Edit style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Edit/>
                    </IconButton>
                </>
            );
        }
    };
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des transporteurs',
            context: 'Paramétrage',
            description: '',
            links: []
        });
    }, []);

    // Form
    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Saisissez le libellé du transporteur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (carrier) {
                ApiHandler.post({
                    route: 'api_setting_carrier_edit',
                    data: handlerForm.getData(),
                    params: {id: carrier}
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_carrier_list'));
                        setCarrier(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Transporteur modifié avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_carrier');
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_setting_carrier_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_setting_carrier_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Transporteur créé avec succès.',
                                variant: 'success',
                            })
                        );

                        ApiHandler.clearCache('api_select_carrier');
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_setting_carrier_list'}
                title={'Transporteurs'}
                columns={columns}
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_setting_carrier_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{carrier ? 'Modifier un transporteur' : 'Ajouter un transporteur'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent id={'label'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} disabled={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default SettingCarrierList;
