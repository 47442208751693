import {useDispatch} from "react-redux";
import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {AddShoppingCart} from "@mui/icons-material";
import TableComponent from "../../../../Component/TableComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {isNumber} from "../../../../Util/NumberTool";
import {initFormHandler} from "../../../../Handler/FormHandler";

function ClientBusinessOrderProductsForm(props) {

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
    const [priceListItem, setPriceListItem] = React.useState(null);

    const columns = [
        {
            name: "sku",
            label: "SKU",
            options: {filter: false, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: false, sort: true}
        },
        {
            name: "byProductName",
            label: "Nom du produit",
            options: {filter: false, sort: true}
        },
        {
            name: "theoreticalWeight",
            label: "Poids",
            options: {filter: false, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum",
            options: {filter: false, sort: true}
        },
        {
            name: "officialSellPriceHT",
            label: "Prix officiel",
            options: {filter: false, sort: true}
        },
        {
            name: "sellerSellPriceHT",
            label: "Prix revendeur",
            options: {filter: false, sort: true}
        },
        {
            name: "percentage",
            label: "% Réduction",
            options: {filter: false, sort: true}
        },
        {
            name: "marge",
            label: "Marge",
            options: {filter: false, sort: true}
        },
        {
            name: "carrierTax",
            label: "Frais de port",
            options: {filter: false, sort: true}
        },
        {
            name: "stock",
            label: "Stock disponible",
            options: {filter: false, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ]

    const handleSelectRow = (row) => {
        handlerFormAddInCart.reset();
        handlerFormAddInCart.setValue('sellerSellPriceHT', (isNumber(row.sellerSellPriceHTValue) ? row.sellerSellPriceHTValue : 0));
        handlerFormAddInCart.setValue('quantity', (isNumber(row.quantity) ? row.quantity : 1));
        setPriceListItem(row);
        setOpenDialogEdit(true)
    }

    const closeModal = () => {
        setPriceListItem(null);
        setOpenDialogEdit(false)
    }

    let getAction = (row) => {
        if(row.stock > 0)
        {
            return (
                <>

                    <IconButton onClick={() => handleSelectRow(row)}><AddShoppingCart style={{color: '#17a2b8'}}/></IconButton>
                </>
            );
        }
        else
        {
            return (
                <>
                    <IconButton><AddShoppingCart style={{color: 'red'}}/></IconButton>
                </>
            );
        }
    }

    const save = () => {

        if(!(formAddInCart.quantity.value > 0))
        {
            dispatch(
                SnackbarOpen({
                    text: 'La quantité ne peut pas être négative ou égale à zéro ',
                    variant: 'error',
                })
            );
            return;
        }

        if(formAddInCart.quantity.value > priceListItem.stockValue)
        {
            dispatch(
                SnackbarOpen({
                    text: 'La quantité maximum disponible pour ce produit est : ' + priceListItem.stockValue,
                    variant: 'error',
                })
            );
            return;
        }

        let itemToAdd = {
            sku: priceListItem.sku,
            mark: priceListItem.mark,
            byProductName: priceListItem.byProductName,
            byProductId: priceListItem.byProductId,
            flavor: priceListItem.flavor,
            sellerSellPriceHT: priceListItem.sellerSellPriceHTValue,
            quantity: formAddInCart.quantity.value
        }

        let resultAdd = false;
        let updatedQuantity = false;
        let alreadyInCartQuantity = false;
        let yetDispoQuantity = false;

        props.setCartItems((prevItems) => {
            let itemIndex = prevItems.findIndex(item => item.byProductId === itemToAdd.byProductId);

            if (itemIndex !== -1) {
                updatedQuantity = prevItems[itemIndex].quantity + itemToAdd.quantity;
                yetDispoQuantity = priceListItem.stockValue - prevItems[itemIndex].quantity;
                alreadyInCartQuantity = prevItems[itemIndex].quantity;

                if(updatedQuantity > priceListItem.stockValue){
                    resultAdd = "ERROR";
                    return prevItems.map((item, index) => index === itemIndex ? {
                        ...item,
                    } : item);
                } else {
                    return prevItems.map((item, index) => index === itemIndex ? {
                        ...item,
                        quantity: updatedQuantity
                    } : item);
                }
            } else {
                return [...prevItems, itemToAdd];
            }
        });

        if(resultAdd === "ERROR")
        {
            dispatch(
                SnackbarOpen({
                    text: 'Vous avez déjà une quantité de ' + alreadyInCartQuantity + ' dans votre panier. Vous pouvez ajouter encore ' + yetDispoQuantity + ' au maximum.',
                    variant: 'error',
                })
            );
        }
        else
        {
            setOpenDialogEdit(false);
            dispatch(
                SnackbarOpen({
                    text: 'Produit ajouté au panier avec succès.',
                    variant: 'success',
                })
            );
        }
    }

    const [formAddInCart, setFormAddInCart] = React.useState({
        sellerSellPriceHT: {
            name: 'sellerSellPriceHT',
            label: 'Prix revendeur HT',
            textHelper: '',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        quantity: {
            name: 'quantity',
            label: 'Quantité',
            textHelper: 'Saisissez la quantité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
    })

    const handlerFormAddInCart = initFormHandler(formAddInCart, setFormAddInCart);

    return (
        <>
            <TableComponent
                id={'api_b2b_client_business_price_list_products_already_priced_get'}
                title={'Liste des produits disponibles'}
                columns={columns}
                search={false}

                promiseServerData={(resolve) => {

                    ApiHandler.get({
                            route: 'api_b2b_client_business_price_list_products_already_priced_get',
                            params: {id: props.priceList},
                            data: props.filters
                        },
                        (response) => {

                            let data = response.data.data ?? [];

                            for (let index in data) {

                                data[index].action = getAction(data[index]);
                                data[index].sellerSellPriceHTValue = data[index].sellerSellPriceHT;
                                data[index].carrierTaxValue = data[index].carrierTax;
                                data[index].stockValue = data[index].stock;
                                data[index].officialSellPriceHT = isNumber(data[index].officialSellPriceHT) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].officialSellPriceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].sellerSellPriceHT = isNumber(data[index].sellerSellPriceHT) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].sellerSellPriceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].carrierTax = isNumber(data[index].carrierTax) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].carrierTax).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].percentage = isNumber(data[index].percentage) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].percentage).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].stock = isNumber(data[index].stock) ? <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].stock}</span> : '-';
                            }

                            resolve(data, response.data.count);
                        });
                }}
            />

            <Dialog open={openDialogEdit} maxWidth={'xl'} onClose={() => setOpenDialogEdit(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter un produit au panier</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p style={{fontSize: 14, margin: 2}}>{priceListItem ? priceListItem.byProductName : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'sellerSellPriceHT'} handler={handlerFormAddInCart} disabled={true}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'quantity'} handler={handlerFormAddInCart}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => closeModal()} disabled={loading}/>
                    <ButtonComponent label={'Ajouter au panier'} onClick={save} loading={loading}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ClientBusinessOrderProductsForm;
