import React from "react";
import ApiHandler from "../../../Handler/ApiHandler";
import TableComponent from "../../../Component/TableComponent";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {hasRight} from "../../../Common/UserCommon";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {useSelector} from "react-redux";
import {IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {Article, Block, Contactless, Contacts, Edit} from "@mui/icons-material";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";

function ClientBusinessPriceLists() {

    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "name",
            label: "Nom",
            options: {filter: false, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ]

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Liste des grilles tarifaires',
            context: 'BtoB',
            description: '',
            links: []
        });

        setLoadingContent(false);
    }, [])

    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B_MANAGE_PRICE') ||
            hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B_EDIT') ||
            hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B')
        )) {
            return (
                <>
                    {
                        (
                            hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B_MANAGE_PRICE') ||
                            hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B_EDIT') ||
                            hasRight(authenticationReducer, 'ROLE_RESPONSABLE_B2B')
                        ) &&
                            <Tooltip title={'Modifier'} placement="left">
                                <Link to={getRoutePathname('b2b_client_business_price_list_add', {id: row.id})}>
                                    <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                                </Link>
                            </Tooltip>
                    }
                </>
            );
        }
    };

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_b2b_client_business_price_list'}
                title={'B to B'}
                columns={columns}
                actionFirst={hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD') ? {
                    label: 'Ajouter une grille tarifaire',
                    link: getRoutePathname('b2b_client_business_price_list_add', {id: 'add'})
                } : false}
                search={false}

                promiseServerData={(resolve) => {

                    let filters = {}

                    ApiHandler.get({
                            route: 'api_b2b_client_business_price_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data ?? [];
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data, response.data.count);
                        });
                }}
            />
            <br/><br/>

        </ContentViewComponent>
    );
}

export default ClientBusinessPriceLists;