import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import {Toolbar, AppBar as AppBarMUI, IconButton, Tooltip} from '@mui/material';
import {Menu, MenuOpen} from '@mui/icons-material';
import {DrawerMainLayoutClose, DrawerMainLayoutOpen} from "../../../Action/DrawerMainLayoutAction";
import ApiHandler from "../../../Handler/ApiHandler";
import {PowerSettingsNew} from '@mui/icons-material';
import {AuthenticationLogout} from "../../../Action/AuthenticationAction";
import LinearProgress from "./LinearProgress";

function AppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);

    const changeDrawer = (isOpenDrawer) => {
        if (isOpenDrawer) dispatch(DrawerMainLayoutOpen());
        else dispatch(DrawerMainLayoutClose());
    };
    const logout = () => {
        dispatch(AuthenticationLogout());
        ApiHandler.get({route: 'api_authentication_logout'}, () => {});
    };

    return (
        <AppBarMUI position="fixed" className={classes.appBar}>
            <Toolbar>

                {
                    drawerMainLayoutReducer.isOpen ?
                        <MenuOpen className={classes.appBarButton} onClick={() => changeDrawer(!drawerMainLayoutReducer.isOpen)}/> :
                        <Menu className={classes.appBarButton} onClick={() => changeDrawer(!drawerMainLayoutReducer.isOpen)}/>
                }


                <img src={'/image/logo.webp'} alt="logo" className={classes.appBarLogo}/>

                <Tooltip title="Déconnexion" aria-label="Déconnexion">
                    <IconButton className={classes.appBarLogout} onClick={logout}>
                        <PowerSettingsNew className={classes.appBarLogoutIcon}/>
                    </IconButton>
                </Tooltip>

            </Toolbar>
            <LinearProgress/>
        </AppBarMUI>
    );
}

const useStyles = makeStyles({
    appBar: {
        zIndex: 2,
        backgroundColor: '#FFFFFF !important',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 58,
        '& .MuiToolbar-regular': {
            height: 58,
            minHeight: '58px !important',
            paddingLeft: 23,
            paddingRight: 23,
        },
    },
    appBarButton: {
        marginRight: 24,
        padding: 0,
        cursor: 'pointer',
        color: '#5E6E82'

    },
    appBarLogo: {
        height: 35,
        paddingLeft: 26,
        float: 'right'
    },
    appBarLogout: {
        right: 0,
        top: 0,
        position: 'absolute !important',
        padding: '10px !important',
        margin: '8px !important',
        marginRight: '12px !important',
        color: '#5E6E82 !important'
    },
    appBarLogoutIcon: {
        fontSize: 22
    }
});

export default AppBar;
