import React from 'react';
import {hasRight} from "../Common/UserCommon";
import {getRoutePathname} from "../Handler/RouteHandler";
import TableComponent from "./TableComponent";
import {useSelector} from "react-redux";
import moment from "moment";
import {floatSortComparatorForTableComponent} from "../Util/SortNumber";
import {sortDateForTableComponent} from "../Util/SortDateForTableComponent";

function OrderPurveyorListComponent(props) {

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    let columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        }
    ];

    if (props.displayPurveyor) {
        columns.push({
            name: "purveyor",
            label: "Fournisseur",
            options: {filter: true, sort: true}
        });
    }
    if (props.displayDeposit) {
        columns.push({
            name: "deposit",
            label: "Dépôt",
            options: {filter: true, sort: true}
        });
    }

    columns = columns.concat(
        {
            name: "amountTotal",
            label: "Montant HT",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        },
        {
            name: "stateText",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "deliveryAt",
            label: "Date prévue de livraison",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return sortDateForTableComponent(order, val1, val2);
                    }
                }
            }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return sortDateForTableComponent(order, val1, val2);
                    }
                }
            }
        }
    );

    if (props.displayActions) {
        columns.push({
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        });
    }

    return (
        <TableComponent
            id={props.tableId}
            title={'Commandes Fournisseur'}
            columns={columns}
            search={false}
            actionFirst={
                (props.displayActions && (hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_ADD'))) ?
                    {
                        label: 'Créer une commande',
                        link: getRoutePathname('buy_order_purveyor', {id: 'add'})
                    } : false
            }
            promiseServerData={props.promiseServerData}
            promiseData={props.promiseData}
        />
    );
}

export default OrderPurveyorListComponent;
