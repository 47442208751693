import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import {useDispatch, useSelector} from "react-redux";
import {inArray} from "../../../Common/ArrayCommon";
import {dispatch} from "../../../App";
import {Link, useParams} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ShopCashRegisterForm from "./ShopCashRegisterComponent/ShopCashRegisterForm";
import ApiHandler from "../../../Handler/ApiHandler";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";

function ShopCashRegister() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [shop, setShop] = React.useState(null);
    const dispatch = useDispatch();
    const params = useParams();
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        if(authenticationReducer.shopSelected) {
            ApiHandler.get({
                route: 'api_shop_sales_shop_info',
                params: {id: authenticationReducer.shopSelected}
            }, (response) => {
                if (response.status === 200) {
                    setShop(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [reload, authenticationReducer.shopSelected]);

    return (
        <ContentViewShopComponent>
            {shop && <>
                <p>Boutique : {authenticationReducer.shopNameSelected}</p>
                <p>Responsable Regional : {inArray(authenticationReducer.shopSelected, authenticationReducer.rightShops.region) ? 'Oui' : 'Non'}</p>
                <p>Responsable National : {inArray(authenticationReducer.shopSelected, authenticationReducer.rightShops.national) ? 'Oui' : 'Non'}</p>
                <p>Responsable Logistique : {inArray(authenticationReducer.shopSelected, authenticationReducer.rightShops.logistique) ? 'Oui' : 'Non'}</p>
                <p>Responsable Vendeur : {inArray(authenticationReducer.shopSelected, authenticationReducer.rightShops.seller) ? 'Oui' : 'Non'}</p>

                {(() => {
                    if (shop.isOpen == 1) {
                        return (
                            <div>Votre caisse est déjà ouverte.</div>
                        )
                    } else {
                        return (
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <ShopCashRegisterForm shopId={authenticationReducer.shopSelected}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                })()}


            </>}
        </ContentViewShopComponent>

    );
}

export default ShopCashRegister;
