import ApiHandler from '../Handler/ApiHandler';

export const fetchPurveyors = (setOptionsPurveyor) => {
    ApiHandler.get({route: 'api_select_purveyor'}, (response) => {
        let data = [{value: 0, label: 'Tous'}, ...response.data];
        setOptionsPurveyor(data);
    });
};

export const fetchPurveyorTypes = (setOptionsPurveyorType) => {
    ApiHandler.get({route: 'api_select_purveyor_type'}, (response) => {
        let data = [{value: 0, label: 'Tous'}, ...response.data];
        setOptionsPurveyorType(data)
    });
};

export const fetchStatsChannels = (setOptionsStatsChannels) => {
    ApiHandler.get({
        route: 'api_select_channel_stats',
        data: {'limited': true, 'from': 'stats'}
    }, (response) => {
        let options = [{value: null, label: 'Tous'}, ...response.data];
        setOptionsStatsChannels(options);
    });
};

export const fetchPaymentMethods = (setOptionsPaymentMethods) => {
    ApiHandler.get({route: 'api_select_payment_method'}, (response) => {
        let data = [{value: null, label: 'Tous'}, ...response.data];
        setOptionsPaymentMethods(data);
    });
};
