import React from 'react';
import {useSelector} from "react-redux";
import TableComponent from "../../../../Component/TableComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {hasRight} from "../../../../Common/UserCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import {dispatch} from "../../../../App";
import {TableReload} from "../../../../Action/TableAction";

function BuyPurveyorContactList(props) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [purveyorContact, setPurveyorContact] = React.useState(null);

    // Global
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "phone",
            label: "Phone",
            options: {filter: true, sort: true}
        },
        {
            name: "comment",
            label: "Commentaire",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_EDIT'))) {
            return (
                <>
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton
                            onClick={() => {
                                handlerForm.reset();
                                setPurveyorContact(row.id);
                                handlerForm.setValue('firstname', row.firstname);
                                handlerForm.setValue('lastname', row.lastname);
                                handlerForm.setValue('email', row.email);
                                handlerForm.setValue('phone', row.phone);
                                handlerForm.setValue('comment', row.comment);
                                setOpenDialog(true);
                            }}
                        ><Edit style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Edit/>
                    </IconButton>
                </>
            );
        }
    };

    // Form
    const [form, setForm] = React.useState({
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom du contact fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez le nom du contact fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'email du contact fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le téléphone du contact fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez le commentaire du contact fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (purveyorContact) {
                ApiHandler.post({
                    route: 'api_buy_purveyor_contact_edit',
                    data: handlerForm.getData(),
                    params: {purveyorId: props.purveyor.id, id: purveyorContact}
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_buy_purveyor_contact_list'));
                        setPurveyorContact(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact fournisseur modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_buy_purveyor_contact_add',
                    params: {purveyorId: props.purveyor.id},
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setOpenDialog(false);
                        dispatch(TableReload('api_buy_purveyor_contact_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact fournisseur créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };

    React.useEffect(handlerForm.start, []);

    return (
        <>
            <TableComponent
                id={'api_buy_purveyor_contact_list'}
                title={'Contact fournisseur'}
                columns={columns}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_EDIT')) ?
                        {
                            label: 'Ajouter un contact fournisseur', onClick: () => {
                                setPurveyorContact(null);
                                handlerForm.reset();
                                setOpenDialog(true);
                            }
                        } : false
                }
                promiseData={(resolve) => {
                    ApiHandler.get({
                        route: 'api_buy_purveyor_contact_list',
                        params: {purveyorId: props.purveyor.id}
                    }, (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{purveyorContact ? 'Modifier un contact fournisseur' : 'Ajouter un contact fournisseur'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent id={'firstname'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent id={'lastname'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent id={'email'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'phone'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextFieldComponent id={'comment'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} disabled={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BuyPurveyorContactList;
