/*
 * Sort dates formated as "DD/MM/YYYY"
 * TODO: handle other formats
 */
export const sortDateForTableComponent = (order, val1, val2) => {
    val1 = typeof val1.data === 'object' ? val1.data.props.children : val1.data;
    val2 = typeof val2.data === 'object' ? val2.data.props.children : val2.data;

    const date1 = new Date(val1.split('/').reverse().valueOf());
    const date2 = new Date(val2.split('/').reverse().valueOf());

    return (date1 - date2) * (order === 'asc' ? 1 : -1);
};
