import React from "react";
import {useSelector} from "react-redux";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import {initFormHandler} from "../../../Handler/FormHandler";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";
import ApiHandler from "../../../Handler/ApiHandler";
import TitleComponent from "../../../Component/TitleComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import SelectComponent from "../../../Component/SelectComponent";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Grid, Slide} from "@mui/material";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TableComponent from "../../../Component/TableComponent";
import {hasRight} from "../../../Common/UserCommon";
import moment from "moment";
import {isNumber} from "../../../Util/NumberTool";
import ButtonComponent from "../../../Component/ButtonComponent";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {makeStyles} from "@mui/styles";

function StatisticTrade() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
    const [disabledStatusButtonDownload, setDisabledStatusButtonDownload] = React.useState(false);
    const [optionsByProduct, setOptionsByProduct] = React.useState([]);
    const [optionsChannel, setOptionsChannel] = React.useState([]);
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [cellsTable, setCellsTable] = React.useState([]);
    const classes = useStyles();

    const columns = [
        {
            name: "channel",
            label: "Canal",
            options: {filter: true, sort: true}
        },
        {
            name: "product",
            label: "Produit",
            options: {filter: true, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: true, sort: true}
        },
        {
            name: "priceHtMinusCarrierPrice",
            label: "CA HT Hors frais de port",
            options: {filter: true, sort: true}
        },
        {
            name: "carrierPrice",
            label: "CA Frais de port",
            options: {filter: true, sort: true}
        },
        {
            name: "paypalPrice",
            label: "CA Paypal",
            options: {filter: true, sort: true}
        },
        {
            name: "totalPriceHt",
            label: "CA Total",
            options: {filter: true, sort: true}
        },
        {
            name: "marge",
            label: "Marge",
            options: {filter: true, sort: true}
        },
        {
            name: "numberOfOrders",
            label: "Nombre de commandes",
            options: {filter: true, sort: true}
        },
        {
            name: "numberOfSellProducts",
            label: "Nombre de produits vendus",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtFive",
            label: "CA HT 5,5%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtTwenty",
            label: "CA HT 20%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHt17",
            label: "CA HT 17%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHt21",
            label: "CA HT 21%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtSix",
            label: "CA HT 6%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCaHtTen",
            label: "CA HT 10%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTva17",
            label: "TVA 17%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTva21",
            label: "TVA 21%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaFive",
            label: "TVA 5,5%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaTwenty",
            label: "TVA 20%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaSix",
            label: "TVA 6%",
            options: {filter: true, sort: true}
        },
        {
            name: "totalTvaTen",
            label: "TVA 10%",
            options: {filter: true, sort: true}
        },
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        fromStartAt: {
            name: 'fromStartAt',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromStartAt ? cacheTableServer['api_statistic_trades'].fromStartAt : '',
            options: {validation: ['date']}
        },
        fromEndAt: {
            name: 'fromEndAt',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].fromEndAt ? cacheTableServer['api_statistic_trades'].fromEndAt : '',
            options: {validation: ['date']}
        },
        channel: {
            name: 'channel',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        byProduct: {
            name: 'byProduct',
            label: 'Produit',
            textHelper: 'Rechercher par produit.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_trades'] && cacheTableServer['api_statistic_trades'].byProduct ? cacheTableServer['api_statistic_trades'].byProduct : 0,
            options: {}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marque.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        if(index == "mark") {
            ApiHandler.get({route: 'api_select_by_product', data: {mark: formSearch.mark.value}}, (response) => {
                let data = [{value: 0, label: 'Tous'}, ...response.data];
                setOptionsByProduct(data);
            });
        }
    }
    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();
        let filters = {
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
            byProduct: (datas.byProduct === 0 || !datas.byProduct) ? '' : datas.byProduct,
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark
        };

        ApiHandler.get({
                route: 'api_statistic_trades',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    setCellsTable(response.data);

                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                    setTimeout(function () {
                        dispatch(TableReload('api_statistic_trades'));
                    }, 2000);
                }
                else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            }
        );
    };

    const downloadAction = () => {
        let datas = handlerFormSearch.getData();
        let params = {
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel.join("|"),
            byProduct: (datas.byProduct === 0 || !datas.byProduct) ? '' : datas.byProduct,
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark.join("|")
        }
        let route = ApiHandler.route({route: 'api_statistic_trades_download', data: params});

        fetch(route,  {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
            .then(res => res.blob())
            .then(res => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', 'stats_sells.xls');
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });

    }

    React.useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_by_product'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsByProduct(data);
        });

        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMark(data);
        });

        ApiHandler.get({route: 'api_select_channel_stats', data: {'limited': true, 'from': 'stats'}}, (response) => setOptionsChannel(response.data));

        setBreadcrumbs({
            title: 'Stats ventes',
            context: 'Statistiques',
            description: '',
            links: []
        });

        setLoadingContent(false);

    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromStartAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromEndAt', val)} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromEndAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromEndAt', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <MultipleSelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} onChange={(val) => inputSearchChange('channel', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <MultipleSelectComponent id={'mark'} options={optionsMark} handler={handlerFormSearch} onChange={(val) => inputSearchChange('mark', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <SelectComponent id={'byProduct'} options={optionsByProduct} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProduct', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <ButtonComponent className={classes.buttonActionFirst} label={"Rechercher"} onClick={() => onSearch()} disabled={disabledStatusButtonSearch} loading={loadingContent}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>
            <TableComponent
                id={'api_statistic_trades'}
                title={'Stats ventes'}
                columns={columns}
                search={false}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_PREPARER_MANAGER')) ?
                        {
                            label: 'Télécharger un CSV',
                            onClick: () => downloadAction(),
                        } : false
                }
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
                        fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
                        channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
                        byProduct: (datas.byProduct === 0 || !datas.byProduct) ? '' : datas.byProduct,
                        mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark
                    };
                    ApiHandler.get({
                        route: 'api_statistic_trades',
                        data: filters
                    },
                    (response) => {
                        let data = response.data.data ?? [];
                        for (let index in data) {
                            data[index].priceHtMinusCarrierPrice = isNumber(data[index].priceHtMinusCarrierPrice) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].priceHtMinusCarrierPrice).toLocaleString('fr-FR', {maximumFractionDigits: 2})}</span> : '-';
                            data[index].carrierPrice = isNumber(data[index].carrierPrice) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].carrierPrice).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].paypalPrice = isNumber(data[index].paypalPrice) ?  <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].paypalPrice).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalPriceHt = isNumber(data[index].totalPriceHt) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalPriceHt).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalCaHtFive = isNumber(data[index].totalCaHtFive) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHtFive).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalCaHtSix= isNumber(data[index].totalCaHtSix) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHtSix).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalCaHtTen= isNumber(data[index].totalCaHtTen) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHtTen).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalCaHtTwenty = isNumber(data[index].totalCaHtTwenty) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHtTwenty).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalTvaFive = isNumber(data[index].totalTvaFive) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTvaFive).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span> : '-';
                            data[index].totalTvaSix = isNumber(data[index].totalTvaSix) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTvaSix).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalTvaTen = isNumber(data[index].totalTvaTen) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTvaTen).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalTvaTwenty = isNumber(data[index].totalTvaTwenty) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTvaTwenty).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].numberOfOrders = isNumber(data[index].numberOfOrders) ? <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].numberOfOrders}</span> : '-';
                            data[index].numberOfSellProducts = isNumber(data[index].numberOfSellProducts) ? <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].numberOfSellProducts}</span> : '-';
                            data[index].marge = isNumber(data[index].marge) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].marge).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalCaHt17 = isNumber(data[index].totalCaHt17) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHt17).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalTva17 = isNumber(data[index].totalTva17) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTva17).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span> : '-';
                            data[index].totalCaHt21 = isNumber(data[index].totalCaHt21) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCaHt21).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> : '-';
                            data[index].totalTva21 = isNumber(data[index].totalTva21) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalTva21).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span> : '-';

                        }
                        resolve(data, response.data.count);
                    });
                }}
            />
            <br/><br/>
        </ContentViewComponent>
    )
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60,
        paddingTop: 60
    },
    button: {
        margin: '15px 15px 15px 0 !important',
    },
    buttonTop: {
        margin: '15px !important',
        top: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
});

export default StatisticTrade;
