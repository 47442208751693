import React from 'react';
import {makeStyles} from '@mui/styles';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TextField} from "@mui/material";
import moment from 'moment';

function DatePickerComponent(props) {

    // Info
    const id = props.id;
    const input = props.handler.form[id];

    const classes = useStyles();
    const [error, setError] = React.useState(input.error);
    const [isFocus, setFocus] = React.useState(false);

    // Value
    const [value, setValue] = React.useState(null);
    React.useEffect(() => {
        if (input.value !== undefined && (!value || input.value !== value.format('YYYY-MM-DD')) && input.value !== 'Invalid Date') {
            setValue(moment(input.value));
        }
    }, [input.value]);
    React.useEffect(() => {
        if (input.error !== error) setError(input.error);
    }, [input.error]);

    // On
    const onChange = (date) => {
        setValue(date);
        if (props.onChange) {
            props.onChange(date ? date.format('YYYY-MM-DD') : null);
            setFocus(false);
        }
        if (date && date.isValid()) {
            setFocus(false);
            props.handler.setValue(id, date);
            let error = props.handler.getErrorByField(input);
            setError(error);
            props.handler.setError(id, error);
        }
        else {
            setFocus(false);
            props.handler.setValue(id, null);
            let error = props.handler.getErrorByField(input);
            setError(error);
            props.handler.setError(id, error);
        }
    }
    const onBlur = () => {
        setFocus(false);
    }
    const onFocus = () => {
        setFocus(true);
    }

    return (
        <div className={classes.content}>
            <DatePicker
                className={`
                ${classes.datePicker}
                ${isFocus ? classes.datePickerFocus : error ? classes.datePickerError : value && value.isValid() && (!input.disabled && !props.disabled) ? classes.datePickerValid : ''}
            `}
                renderInput={(props) => (
                    <TextField {...props} helperText={error && !isFocus ? error : input.textHelper}/>
                )}
                inputVariant="outlined"
                okLabel='Ok'
                cancelLabel='Annuler'
                margin="normal"
                id={props.name}
                label={input.label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}
                format="dd/MM/yyyy"
                value={value}
                helperText={error && !isFocus ? error : input.textHelper}
                onChange={onChange}
                onClose={onBlur}
                onOpen={onFocus}
                disabled={input.disabled || props.disabled}
            />
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        padding: 5
    },
    datePicker: {
        width: '100%',
        marginTop: 0,
        '& input': {
            padding: '7px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 7px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px',
            color: 'rgba(0, 0, 0, 0.5) !important'
        },
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: 'rgba(0, 0, 0, 0.3) !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },
        '& svg': {
            width: 16,
            height: 16
        },
        '& label': {
            color: 'rgba(0, 0, 0, 0.5) !important'
        }
    },
    datePickerFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    datePickerValid: {
        '& fieldset': {
            borderColor: '#006500 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#006500 !important'
        },
        '& label': {
            color: '#006500 !important'
        }
    },
    datePickerError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    }
});

export default DatePickerComponent;
