import {formatCurrencyToFloat} from "./NumberTool";
import {gridNumberComparator,} from "@mui/x-data-grid";

export const floatSortComparator = (val1, val2) => {
    // Exclude rows that do not contain a number value
    if (val1 === "-") {
        val1 = "0";
    }
    if (val2 === "-") {
        val2 = "0";
    }

    val1 = formatCurrencyToFloat(val1);
    val2 = formatCurrencyToFloat(val2);

    return val1 - val2;
};

export const floatSortComparatorForTableComponent = (order, val1, val2) => {
    if (val1.data === "-") {
        val1.data = "0";
    }
    if (val2.data === "-") {
        val2.data = "0";
    }

    val1 = formatCurrencyToFloat(val1.data);
    val2 = formatCurrencyToFloat(val2.data);

    return (val1 - val2) * (order === 'asc' ? 1 : -1);
};

export const gridNumberComparatorWithThousandsSeparator = (value1, value2) => {
    let formattedValue1 = parseInt(value1.replace(/\s/g, ''));
    let formattedValue2 = parseInt(value2.replace(/\s/g, ''));

    if (isNaN(formattedValue1)) {
        formattedValue1 = -1;
    }
    if (isNaN(formattedValue2)) {
        formattedValue2 = -1;
    }

    return gridNumberComparator(formattedValue1, formattedValue2);
};
