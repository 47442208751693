import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {dispatch} from "../../../App";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useParams} from "react-router-dom";
import {Grid} from "@mui/material";
import TabsComponent from '../../../Component/TabsComponent';
import SaleCreatePromoForm from "./SalePromoComponents/SaleCreatePromoForm";


function SalePromo() {
    const params = useParams();
    const [loading, setLoading] = React.useState(true);
    const [promo, setPromo] = React.useState(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Code promo',
            context: 'Vente',
            description: '',
            links: [
                {path: getRoutePathname('sale_promo_list'), label: 'Liste des promos'}
            ]
        });

        if (!params.id) {
            ApiHandler.get({
                route: 'api_sale_promo_add'
            }, (response) => {
                if (response.status === 200) {
                    setPromo(response.data);
                    setLoading(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        } else {
            ApiHandler.get({
                route: 'api_sale_promo',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setPromo(response.data);
                    setLoading(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }

    }, []);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {promo && <>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SaleCreatePromoForm promo={promo}/>
                    </Grid>
                </Grid>
                <br/>

            </>}

            <br/>
            <br/>

        </ContentViewComponent>
    );
}

export default SalePromo;
