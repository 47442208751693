import React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';

const CheckboxToFilterData = ({label, checked, setterBoolValue}) => {
    const handleChange = (e) => {
        setterBoolValue(e.target.checked);
    };

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={label}
        />
    );
};

export default CheckboxToFilterData;
