import React from 'react'
import {TextField} from "@mui/material";
import {makeStyles} from '@mui/styles';

function AutoCompleteComponent(props) {

    // Info
    const id = props.id;
    const input = props.handler.form[id];
    const label = input.label;
    const type = input.type;

    const classes = useStyles();
    const [error, setError] = React.useState(input.error);
    const [isFocus, setFocus] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    // Value
    const [valueText, setValueText] = React.useState('');
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        if (input.value !== undefined && input.value !== value) setValue(input.value.toString());
    }, [input.value]);
    React.useEffect(() => {
        if (input.valueText !== undefined && input.valueText !== valueText) {
            setValueText(input.valueText.toString());
            setOptions([]);
        }
    }, [input.valueText]);
    React.useEffect(() => {
        if (input.error !== error) setError(input.error);
    }, [input.error]);

    // On
    const onChange = (event) => {
        let newValueText = event.target.value ?? '';

        if (newValueText) {
            if (input.options.max) {
                let length = (type === 'text') ? newValueText.toString().length : newValueText;
                if (!(input.options.max && length <= input.options.max)) {
                    newValueText = value;
                }
            }

            if (input.options.inf) {
                let length = (type === 'text') ? newValueText.toString().length : newValueText;
                if (!(input.options.inf && length < input.options.inf)) {
                    newValueText = value;
                }
            }

            if (input.options.equal) {
                let length = (type === 'text') ? newValueText.toString().length : newValueText;
                if (!(input.options.equal && length <= input.options.equal)) {
                    newValueText = value;
                }
            }
        }

        setValue('');
        setValueText(newValueText);

        props.loadOptions(newValueText, (opt) => setOptions(opt));
    };
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);
    const onClick = (value, label) => {
        setValue(value);
        setValueText(label);

        setFocus(false);
        props.handler.setValue(id, value);
        props.handler.setValueText(id, label);
        let error = props.handler.getErrorByField(input);
        setError(error);
        props.handler.setError(id, error);

        if (props.onClick) props.onClick(value);
    };

    return (
        <div className={classes.content}>
            <TextField
                inputProps={{autoFocus: props.autoFocus, autoComplete: 'off'}}
                id={id}
                label={label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}
                value={valueText}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={(value && isFocus) ? onBlur : () => {
                }}
                variant="outlined"
                type={'text'}
                disabled={input.disabled || props.disabled}
                className={`
                    ${classes.textField}
                    ${isFocus ? classes.textFieldFocus : error ? classes.textFieldError : value && (!input.disabled && !props.disabled) ? classes.textFieldValid : ''}
                `}
                helperText={error && !isFocus ? error : input.textHelper}
            />

            {
                (!value && isFocus && options.length > 0) &&
                <>
                    <div onClick={onBlur} className={classes.listBackground}></div>
                    <div className={classes.list}>
                        {
                            options.map((option) => {
                                return <div key={option.value} className={classes.item} onClick={() => onClick(option.value, option.label)}>{option.label}</div>;
                            })
                        }
                    </div>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles({
    content: {
        padding: 5,
        boxSizing: 'border-box',
        position: 'relative'
    },
    textField: {
        width: '100%',
        '& input': {
            padding: '7px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 7px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            minHeight: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    textFieldFocus: {
        '& .MuiFormHelperText-root': {
            color: '#3F51BF'
        }
    },
    textFieldValid: {
        '& fieldset': {
            borderColor: '#006500 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#006500'
        },
        '& label': {
            color: '#006500'
        }
    },
    textFieldError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525'
        },
        '& label': {
            color: '#982525'
        }
    },
    list: {
        position: 'absolute',
        top: 38,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: '#FFF',
        width: 'calc(100% - 10px)',
        boxSizing: 'border-box',
        borderRadius: '0 0 5px 5px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        zIndex: 3,
        maxHeight: 120,
        overflow: 'scroll'
    },
    item: {
        color: '#000',
        zIndex: 3,
        padding: '4px 10px',
        cursor: 'pointer',
        fontSize: 12,
        minHeight: 16,
        lineHeight: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        }
    },
    listBackground: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        zIndex: 2,
    }
});

export default AutoCompleteComponent;



