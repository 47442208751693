import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {hasRight} from "../../../Common/UserCommon";
import {IconButton, Tooltip} from "@mui/material";
import {Edit, Article} from "@mui/icons-material";
import {Link} from "react-router-dom";

function InventoryManagementDepositList() {
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loading, setLoading] = React.useState(true);

    // List Deposits
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "openingTime",
            label: "Horaire d'ouverture",
            options: {filter: true, sort: true}
        },
        {
            name: "closingTime",
            label: "horaire de fermeture",
            options: {filter: true, sort: true}
        },
        {
            name: "shopRegionText",
            label: "Région boutique",
            options: {filter: true, sort: true}
        },
        {
            name: "userRegionText",
            label: "Utilisateur région",
            options: {filter: true, sort: true}
        },
        {
            name: "userNationalText",
            label: "Utilisateur national",
            options: {filter: true, sort: true}
        },
        {
            name: "userLogistiqueText",
            label: "Utilisateur logistique",
            options: {filter: true, sort: true}
        },
        {
            name: "openCloseCode",
            label: "Code ouverture/fermeture",
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') ||
            hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET'))) {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <Tooltip title={'Modifier'} placement="left">
                                <Link to={getRoutePathname('shop_management_shop', {id: row.id})}>
                                    <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                                </Link>
                            </Tooltip> : (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET')) ?
                                <Tooltip title={'Visualiser'} placement="left">
                                    <Link to={getRoutePathname('shop_management_shop', {id: row.id})}>
                                        <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                                    </Link>
                                </Tooltip> : <IconButton disabled={true}><Edit style={{color: '#17a2b8'}}/></IconButton>
                    }
                </>
            );
        }
        else {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Edit/>
                            </IconButton> :
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Article/>
                            </IconButton>
                    }
                </>
            );
        }
    };

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Liste des boutiques',
            context: 'Gestion boutique',
            description: '',
            links: []
        });
        
        setLoading(false);
    }, [])

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_shop_management_shop_list'}
                title={'Boutiques'}
                columns={columns}
                actionFirst={hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD') ? {
                    label: 'Ajouter une boutique',
                    link: getRoutePathname('shop_management_shop', {id: 'add'})
                } : false}
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_shop_management_shop_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].openingTime = data[index].openingTime ? data[index].openingTime : '-';
                                data[index].closingTime = data[index].closingTime ? data[index].closingTime : '-';
                                data[index].shopRegionText = data[index].shopRegionText ? data[index].shopRegionText : '-';
                                data[index].userRegionText = data[index].userRegionText ? data[index].userRegionText : '-';
                                data[index].userNationalText = data[index].userNationalText ? data[index].userNationalText : '-';
                                data[index].userLogistiqueText = data[index].userLogistiqueText ? data[index].userLogistiqueText : '-';
                                data[index].openCloseCode = data[index].openCloseCode ? data[index].openCloseCode : '-';
                            }
                            resolve(data);
                        });
                }}
            />
            <br/><br/>
        </ContentViewComponent>
    );
}

export default InventoryManagementDepositList;
