// Layouts
import AuthenticationLayout from "../Layout/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "../Layout/MainLayout/MainLayout";
import ShopLayout from "../Layout/ShopLayout/ShopLayout";

// Views
import AuthenticationLogin from "../View/Authentication/Login/Login";
import BuyOrderPurveyorList from "../View/Buy/OrderPurveyor/BuyOrderPurveyorList";
import BuyProductList from "../View/Buy/Product/BuyProductList";
import BuyOrderPurveyor from "../View/Buy/OrderPurveyor/BuyOrderPurveyor";
import BuyProduct from "../View/Buy/Product/BuyProduct";
import BuyPurveyorList from "../View/Buy/Purveyor/BuyPurveyorList";
import BuyPurveyor from "../View/Buy/Purveyor/BuyPurveyor";
import InventoryManagementDepositList from "../View/InventoryManagement/Deposit/InventoryManagementDepositList";
import InventoryManagementDeposit from "../View/InventoryManagement/Deposit/InventoryManagementDeposit";
import InventoryManagementDepositMoveList from "../View/InventoryManagement/Movement/InventoryManagementMovementList";
import AdministratorUserList from "../View/Administrator/User/AdministratorUserList";
import AdministratorUser from "../View/Administrator/User/AdministratorUser";
import SettingMarkList from "../View/Setting/MarkList/SettingMarkList";
import SettingProductTypeList from "../View/Setting/ProductTypeList/SettingProductTypeList";
import SettingGalenicList from "../View/Setting/GalenicList/SettingGalenicList";
import SettingSectorList from "../View/Setting/SectorList/SettingSectorList";
import SettingPackSizeList from "../View/Setting/PackSizeList/SettingPackSizeList";
import SettingFlavorList from "../View/Setting/FlavorList/SettingFlavorList";
import SettingPurveyorTypeList from '../View/Setting/PurveyorTypeList/SettingPurveyorTypeList';
import SettingPaymentTermsList from '../View/Setting/PaymentTermsList/SettingPaymentTermsList';
import SettingLifeCycleList from "../View/Setting/LifeCycleList/SettingLifeCycleList";
import SettingTvaList from "../View/Setting/TvaList/SettingSectorList";
import SaleClientList from '../View/Sale/Client/SaleClientList';
import SalePromoList from '../View/Sale/Promo/SalePromoList';
import SalePromo from '../View/Sale/Promo/SalePromo';
import SaleClient from '../View/Sale/Client/SaleClient';
import SaleOrderClientList from '../View/Sale/OrderClient/SaleOrderClientList';
import SaleOrderClient from "../View/Sale/OrderClient/SaleOrderClient";
import SettingChannelList from "../View/Setting/ChannelList/SettingChannelList";
import InventoryManagementReceiptOrderPurveyorList
    from "../View/InventoryManagement/ReceiptOrderPurveyor/InventoryManagementReceiptOrderPurveyorList";
import InventoryManagementReceiptOrderPurveyor
    from "../View/InventoryManagement/ReceiptOrderPurveyor/InventoryManagementReceiptOrderPurveyor";
import SettingFamilyList from '../View/Setting/FamilyList/SettingFamilyList';
import SettingCarrierList from '../View/Setting/CarrierList/SettingCarrierList';
import SettingPrinterList from '../View/Setting/PrinterList/SettingPrinterList';
import InventoryManagementPreparerManager
    from "../View/InventoryManagement/PreparerManager/InventoryManagementPreparerManager";
import InventoryManagementPreparerOrderList
    from "../View/InventoryManagement/Preparer/InventoryManagementPreparerOrderList";
import InventoryManagementInventoryList from "../View/InventoryManagement/Inventory/InventoryManagementInventoryList";
import InventoryManagementInventoryDepositInventory
    from "../View/InventoryManagement/Inventory/InventoryManagementInventoryDepositInventory";
import SettingSubfamilyList from '../View/Setting/SubfamilyList/SettingSubfamilyList';
import SettingLabelPrinterList from '../View/Setting/LabelPrinterList/SettingLabelPrinterList';
import SettingCountryList from '../View/Setting/CountryList/SettingCountryList';
import SettingTvaCountryList from '../View/Setting/TvaCountryList/SettingTvaCountryList';
import AdministratorLogSyliusList from '../View/Administrator/LogSylius/AdministratorLogSyliusList';
import AdministratorRequestAsyncList from '../View/Administrator/RequestAsync/AdministratorRequestAsyncList';
import SettingChannelClientList from '../View/Setting/ChannelClientList/SettingChannelClientList';
import AdministratorLogPrinterList from '../View/Administrator/LogPrinter/AdministratorLogPrinterList';
import AdministratorLogCarrierList from '../View/Administrator/LogCarrier/AdministratorLogCarrierList';
import BuyPurveyorContactList from '../View/Buy/Purveyor/BuyPurveyorComponents/BuyPurveyorContactList';
import InventoryManagementValorisationStockList
    from '../View/InventoryManagement/ValorisationStock/InventoryManagementValorisationStockList';
import SettingOrderClientLitigeTypeList
    from '../View/Setting/OrderClientLitigeTypeList/SettingOrderClientLitigeTypeList';
import ClientServiceOrderClientLitigeList
    from '../View/ClientService/OrderClientLitige/ClientServiceOrderClientLitigeList';
import SettingOrderClientForwardingList
    from '../View/Setting/OrderClientForwardingList/SettingOrderClientForwardingList';
import SettingOrderClientRefundList from '../View/Setting/OrderClientRefundList/SettingOrderClientRefundList';
import InventoryManagementReturnOrder from '../View/InventoryManagement/ReturnOrder/InventoryManagementReturnOrder';
import ShopManagementShopRegionList from '../View/ShopManagement/Region/ShopManagementRegionList';
import SettingUserFunctionList from '../View/Setting/UserFunction/SettingUserFunctionList';
import SettingShopMovementList from '../View/Setting/ShopMovement/SettingShopMovementList';
import ShopManagementShopList from '../View/ShopManagement/Shop/ShopManagementShopList';
import ClosingShopList from '../View/ShopManagement/Gestion/ClosingShopList';
import ShopManagementListReappro from '../View/ShopManagement/Reappro/ShopManagementReapproList';
import ShopManagementShop from '../View/ShopManagement/Shop/ShopManagementShop';
import ClosingShopEdit from '../View/ShopManagement/Gestion/ClosingShopEdit';
import ClientServiceOrderClientLitige from '../View/ClientService/OrderClientLitige/ClientServiceOrderClientLitige';
import BillingInvoiceList from "../View/Billing/InvoiceList/BillingInvoiceList";
import BillingCreditList from "../View/Billing/CreditList/BillingCreditList";
import BillingRefundList from "../View/Billing/RefundList/BillingRefundList";
import StatisticPreparation from "../View/Statistic/Preparation/StatisticPreparation";
import StatisticTrade from "../View/Statistic/Trade/StatisticTrade";
import StatisticTradeRetail from "../View/Statistic/Trade/StatisticTradeRetail";
import StatisticTradeReporting from "../View/Statistic/Trade/StatisticTradeReporting";
import StatisticMark from "../View/Statistic/Mark/StatisticMark";
import StatisticBuyCa from "../View/Statistic/Buy/StatisticBuyCa";
import StatisticBuyPortfolioDetails from "../View/Statistic/Buy/StatisticBuyPortfolioDetails";
import SaleOrderClientListByStatus from "../View/Sale/OrderClient/SaleOrderClientListByStatus";
import ClientBusinessList from "../View/B2B/ClientBusiness/ClientBusinessList";
import ClientBusiness from "../View/B2B/ClientBusiness/ClientBusiness";
import ClientBusinessContact from "../View/B2B/ClientBusinessContact/ClientBusinessContact";
import BuyProductManageWeightList from "../View/Buy/Product/BuyProductManageWeightList";
import ShopCashRegister from "../View/Shop/CashRegister/ShopCashRegister";
import ShopCashSignOpening from "../View/Shop/CashRegister/ShopCashSignOpening";
import ShopCashRegisterClosed from "../View/Shop/CashRegister/ShopCashRegisterClosed";
import ShopProductsList from "../View/Shop/Products/ShopProductsList";
import ShopSalesList from "../View/Shop/Sale/ShopSalesList";
import ShopSalesListToday from "../View/Shop/Sale/ShopSalesListToday";
import ShopSaleOrderClient from '../View/Shop/Sale/ShopSaleOrderClient';
import SelectClient from '../View/Shop/Sale/SelectClient';
import EditClient from '../View/Shop/Sale/EditClient';
import SelectProducts from '../View/Shop/Sale/SelectProducts';
import ShopStocks from '../View/Shop/Stock/ShopListByProducts';
import CreateReappro from '../View/Shop/Reappro/CreateReappro';
import ReceptionReappro from '../View/Shop/Reappro/ReceptionReappro';
import ListReappro from '../View/Shop/Reappro/ListReappro';
import ShopEditReappro from '../View/Shop/Reappro/EditReappro';
import InventoryManagementInventoryListShop
    from "../View/InventoryManagement/Inventory/SellerInventoryManagementInventoryList";
import InventoryManagementInventoryDepositInventoryShop
    from "../View/InventoryManagement/Inventory/SellerInventoryManagementInventoryDepositInventory";
import InventoryManagementReturnOrderShop
    from '../View/InventoryManagement/ReturnOrder/SellerInventoryManagementReturnOrder';
import ClientBusinessPriceLists from "../View/B2B/ClientBusinessPriceList/ClientBusinessPriceLists";
import ClientBusinessPriceList from "../View/B2B/ClientBusinessPriceList/ClientBusinessPriceList";
import ClientBusinessOrder from "../View/B2B/ClientBusinessOrder/ClientBusinessOrder";
import StatisticSalePayment from "../View/Statistic/Sale/StatisticSalePayment";

const routing = {
    // Authentication
    authentication_login: {
        path: '/authentication/login',
        view: AuthenticationLogin,
        layout: AuthenticationLayout,
        roles: []
    },

    // Buy
    buy_product_list: {
        path: '/buy/product/list',
        view: BuyProductList,
        layout: MainLayout,
        roles: ['ROLE_BUY_PRODUCT', 'ROLE_BUY_PRODUCT_LIST']
    },
    buy_product: {
        path: '/buy/product/:id',
        match: /\/buy\/product.*/,
        view: BuyProduct,
        layout: MainLayout,
        roles: ['ROLE_BUY_PRODUCT', 'ROLE_BUY_PRODUCT_GET', 'ROLE_BUY_PRODUCT_ADD']
    },
    buy_order_purveyor_list: {
        path: '/buy/order-purveyor/list',
        view: BuyOrderPurveyorList,
        layout: MainLayout,
        roles: ['ROLE_BUY_ORDER_PURVEYOR', 'ROLE_BUY_ORDER_PURVEYOR_LIST']
    },
    buy_product_manage_weight_list: {
        path: '/buy/product/manage-weight/list',
        view: BuyProductManageWeightList,
        layout: MainLayout,
        roles: ['ROLE_MANAGER_ENTREPOT', 'ROLE_MANAGER_ENTREPOT_ARTICLES_EDIT']
    },
    buy_order_purveyor: {
        path: '/buy/order-purveyor/:id',
        match: /\/buy\/order-purveyor.*/,
        view: BuyOrderPurveyor,
        layout: MainLayout,
        roles: ['ROLE_BUY_ORDER_PURVEYOR', 'ROLE_BUY_ORDER_PURVEYOR_GET', 'ROLE_BUY_ORDER_PURVEYOR_ADD']
    },
    buy_purveyor_list: {
        path: '/buy/purveyor/list',
        view: BuyPurveyorList,
        layout: MainLayout,
        roles: ['ROLE_BUY_PURVEYOR', 'ROLE_BUY_PURVEYOR_LIST']
    },
    buy_purveyor: {
        path: '/buy/purveyor/:id',
        match: /\/buy\/purveyor.*/,
        view: BuyPurveyor,
        layout: MainLayout,
        roles: ['ROLE_BUY_PURVEYOR', 'ROLE_BUY_PURVEYOR_ADD', 'ROLE_BUY_PRODUCT_GET']
    },
    buy_purveyor_contact_list: {
        path: '/buy/purveyor/:id/contact/list',
        view: BuyPurveyorContactList,
        layout: MainLayout,
        roles: ['ROLE_BUY_PURVEYOR', 'ROLE_BUY_PURVEYOR_LIST']
    },

    // Inventory Management
    inventory_management_deposit_list: {
        path: '/inventory-management/deposit/list',
        view: InventoryManagementDepositList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_DEPOSIT', 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_LIST']
    },
    inventory_management_deposit: {
        path: '/inventory-management/deposit/:id',
        match: /\/inventory-management\/deposit.*/,
        view: InventoryManagementDeposit,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_DEPOSIT', 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_ADD', 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_GET']
    },
    inventory_management_inventory_list: {
        path: '/inventory-management/inventory/list',
        view: InventoryManagementInventoryList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_INVENTORY', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_LIST']
    },
    inventory_management_inventory_deposit_inventory: {
        path: '/inventory-management/inventory/deposit/:deposit/inventory',
        match: /\/inventory-management\/inventory\/deposit.*/,
        view: InventoryManagementInventoryDepositInventory,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_INVENTORY', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED']
    },
    inventory_management_movement_list: {
        path: '/inventory-management/movement/list',
        view: InventoryManagementDepositMoveList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_MOVEMENT', 'ROLE_INVENTORY_MANAGEMENT_MOVEMENT_LIST']
    },
    inventory_management_receipt_order_purveyor_list: {
        path: '/inventory-management/receipt-order-purveyor/list',
        view: InventoryManagementReceiptOrderPurveyorList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR', 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR_LIST']
    },
    inventory_management_receipt_order_purveyor: {
        path: '/inventory-management/receipt-order-purveyor/:id',
        match: /\/inventory-management\/receipt-order-purveyor.*/,
        view: InventoryManagementReceiptOrderPurveyor,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR', 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR_EDIT', 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR_GET']
    },
    inventory_management_preparer_manager: {
        path: '/inventory-management/preparer-manager',
        view: InventoryManagementPreparerManager,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_PREPARER_MANAGER']
    },
    inventory_management_preparer: {
        path: '/inventory-management/preparer',
        view: InventoryManagementPreparerOrderList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_PREPARER']
    },
    inventory_management_valorisation_stock_list: {
        path: '/inventory-management/valorisation-stock/list',
        view: InventoryManagementValorisationStockList,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_VALORISATION_STOCK']
    },
    inventory_management_return_order: {
        path: '/inventory-management/return-order',
        view: InventoryManagementReturnOrder,
        layout: MainLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_RETURN_ORDER']
    },
    inventory_shop_management_inventory_list: {
        path: '/shop/inventory-management/inventory/list',
        view: InventoryManagementInventoryListShop,
        layout: ShopLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_INVENTORY', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_LIST']
    },
    inventory_shop_management_inventory_deposit_inventory: {
        path: '/shop/inventory-management/inventory/deposit/:deposit/inventory',
        match: /\/inventory-management\/inventory\/deposit.*/,
        view: InventoryManagementInventoryDepositInventoryShop,
        layout: ShopLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_INVENTORY', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD', 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED']
    },
    inventory_shop_management_return_order: {
        path: '/shop/inventory-management/return-order',
        view: InventoryManagementReturnOrderShop,
        layout: ShopLayout,
        roles: ['ROLE_INVENTORY_MANAGEMENT_RETURN_ORDER']
    },

    // Sale
    sale_client_list: {
        path: '/sale/client/list',
        view: SaleClientList,
        layout: MainLayout,
        roles: ['ROLE_SALE_CLIENT', 'ROLE_SALE_CLIENT_LIST']
    },
    sale_client: {
        path: '/sale/client/:uuid',
        match: /\/sale\/client.*/,
        view: SaleClient,
        layout: MainLayout,
        roles: ['ROLE_SALE_CLIENT', 'ROLE_SALE_CLIENT_GET']
    },
    sale_order_client_list: {
        path: '/sale/order-client/list',
        view: SaleOrderClientList,
        layout: MainLayout,
        roles: ['ROLE_SALE_ORDER_CLIENT', 'ROLE_SALE_ORDER_CLIENT_LIST']
    },
    sale_order_client: {
        path: '/sale/order-client/:uuid',
        match: /\/sale\/order-client.*/,
        view: SaleOrderClient,
        layout: MainLayout,
        roles: ['ROLE_SALE_ORDER_CLIENT', 'ROLE_SALE_ORDER_CLIENT_GET']
    },
    sale_order_client_list_by_status: {
        path: '/sale/order-client/list/by-status/:orderClientState',
        match: /\/sale\/order-client\/list\/by-status.*/,
        view: SaleOrderClientListByStatus,
        layout: MainLayout,
        roles: ['ROLE_SALE_ORDER_CLIENT', 'ROLE_SALE_ORDER_CLIENT_LIST']
    },
    sale_promo_list: {
        path: '/sale/promo/list',
        view: SalePromoList,
        layout: MainLayout,
        roles: ['ROLE_SALE_PROMO', 'ROLE_SALE_PROMO_LIST']
    },
    sale_promo: {
        path: '/sale/promo/edit/:id',
        match: /\/sale\/promo.*/,
        view: SalePromo,
        layout: MainLayout,
        roles: ['ROLE_SALE_PROMO', 'ROLE_SALE_PROMO_GET']
    },
    sale_promo_add: {
        path: '/sale/promo/add',
        view: SalePromo,
        layout: MainLayout,
        roles: ['ROLE_SALE_PROMO', 'ROLE_SALE_PROMO_GET']
    },

    // Shop Management
    shop_management_shop_list: {
        path: '/shop-management/shop/list',
        view: ShopManagementShopList,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP', 'ROLE_SHOP_MANAGEMENT_SHOP_LIST']
    },
    shop_management_shop: {
        path: '/shop-management/shop/:id',
        match: /\/shop-management\/shop.*/,
        view: ShopManagementShop,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP', 'ROLE_SHOP_MANAGEMENT_SHOP_ADD', 'ROLE_SHOP_MANAGEMENT_SHOP_GET']
    },
    shop_management_shop_region_list: {
        path: '/shop-management/shop-region/list',
        view: ShopManagementShopRegionList,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP_REGION', 'ROLE_SHOP_MANAGEMENT_SHOP_REGION_LIST']
    },
    shop_management_shop_list_reappro: {
        path: '/shop-management/shop/list/reappro',
        view: ShopManagementListReappro,
        layout: MainLayout,
        roles: ['ROLE_REAPPRO_SHOP', 'ROLE_REAPPRO_SHOP_LIST']
    },
    shop_management_shop_reappro: {
        path: '/shop-management/shop/reappro/edit/:id',
        match: /\/shop-management\/shop\/reappro\/edit.*/,
        view: ShopEditReappro,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP_GET_REAPPRO', 'ROLE_SHOP_MANAGEMENT_SHOP']
    },
    shop_management_shop_closing_list: {
        path: '/shop-management/shop/closing/list',
        view: ClosingShopList,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP', 'ROLE_SHOP_MANAGEMENT_SHOP_CLOSING']
    },
    shop_management_shop_closing: {
        path: '/shop-management/shop/closing/:id',
        match: /\/shop-management\/shop\/closing.*/,
        view: ClosingShopEdit,
        layout: MainLayout,
        roles: ['ROLE_SHOP_MANAGEMENT_SHOP', 'ROLE_SHOP_MANAGEMENT_SHOP_ADD', 'ROLE_SHOP_MANAGEMENT_SHOP_GET']
    },

    // Billing
    billing_invoice_list: {
        path: '/billing/invoice/list',
        view: BillingInvoiceList,
        layout: MainLayout,
        roles: ['ROLE_BILLING_INVOICE', 'ROLE_BILLING_INVOICE_LIST']
    },
    billing_credit_list: {
        path: '/billing/credit/list',
        view: BillingCreditList,
        layout: MainLayout,
        roles: ['ROLE_BILLING_CREDIT', 'ROLE_BILLING_CREDIT_LIST']
    },
    billing_refund_list: {
        path: '/billing/refund/list',
        view: BillingRefundList,
        layout: MainLayout,
        roles: ['ROLE_BILLING_CREDIT', 'ROLE_BILLING_CREDIT_LIST']
    },

    // Client Service
    client_service_order_client_litige_list: {
        path: '/client-service/order-client-litige/list',
        view: ClientServiceOrderClientLitigeList,
        layout: MainLayout,
        roles: ['ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE', 'ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE_LIST']
    },
    client_service_order_client_litige: {
        path: '/client-service/order-client-litige/:uuid',
        match: /\/client-service\/order-client-litige.*/,
        view: ClientServiceOrderClientLitige,
        layout: MainLayout,
        roles: ['ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE', 'ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE_GET']
    },

    // Setting
    setting_mark_list: {
        path: '/setting/mark/list',
        view: SettingMarkList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_MARK', 'ROLE_SETTING_MARK_LIST']
    },
    setting_product_type_list: {
        path: '/setting/product-type/list',
        view: SettingProductTypeList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_PRODUCT_TYPE', 'ROLE_SETTING_PRODUCT_TYPE_LIST']
    },
    setting_galenic_list: {
        path: '/setting/galenic/list',
        view: SettingGalenicList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_GALENIC', 'ROLE_SETTING_GALENIC_LIST']
    },
    setting_sector_list: {
        path: '/setting/sector/list',
        view: SettingSectorList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_SECTOR', 'ROLE_SETTING_SECTOR_LIST']
    },
    setting_pack_size_list: {
        path: '/setting/pack-size/list',
        view: SettingPackSizeList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_PACK_SIZE', 'ROLE_SETTING_PACK_SIZE_LIST']
    },
    setting_flavor_list: {
        path: '/setting/flavor/list',
        view: SettingFlavorList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_FLAVOR', 'ROLE_SETTING_FLAVOR_LIST']
    },
    setting_purveyor_type_list: {
        path: '/setting/purveyor-type/list',
        view: SettingPurveyorTypeList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_PURVEYOR_TYPE', 'ROLE_SETTING_PURVEYOR_TYPE_LIST']
    },
    setting_payment_terms_list: {
        path: '/setting/payment-terms/list',
        view: SettingPaymentTermsList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_PAYMENT_TERMS', 'ROLE_SETTING_PAYMENT_TERMS_LIST']
    },
    setting_life_cycle_list: {
        path: '/setting/life-cycle/list',
        view: SettingLifeCycleList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_LIFE_CYCLE', 'ROLE_SETTING_LIFE_CYCLE_LIST']
    },
    setting_tva_list: {
        path: '/setting/tva/list',
        view: SettingTvaList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_TVA', 'ROLE_SETTING_TVA_LIST']
    },
    setting_channel_list: {
        path: '/setting/channel/list',
        view: SettingChannelList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_CHANNEL', 'ROLE_SETTING_CHANNEL_LIST']
    },
    setting_channel_client_list: {
        path: '/setting/channel-client/list',
        view: SettingChannelClientList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_CHANNEL_CLIENT', 'ROLE_SETTING_CHANNEL_CLIENT_LIST']
    },
    setting_family_list: {
        path: '/setting/family/list',
        view: SettingFamilyList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_FAMILY', 'ROLE_SETTING_FAMILY_LIST']
    },
    setting_subfamily_list: {
        path: '/setting/subfamily/list',
        view: SettingSubfamilyList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_SUBFAMILY', 'ROLE_SETTING_SUBFAMILY_LIST']
    },
    setting_carrier_list: {
        path: '/setting/carrier/list',
        view: SettingCarrierList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_CARRIER', 'ROLE_SETTING_CARRIER_LIST']
    },
    setting_printer_list: {
        path: '/setting/printer/list',
        view: SettingPrinterList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_PRINTER', 'ROLE_SETTING_PRINTER_LIST']
    },
    setting_label_printer_list: {
        path: '/setting/label-printer/list',
        view: SettingLabelPrinterList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_LABEL_PRINTER', 'ROLE_SETTING_LABEL_PRINTER_LIST']
    },
    setting_country_list: {
        path: '/setting/country/list',
        view: SettingCountryList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_COUNTRY', 'ROLE_SETTING_COUNTRY_LIST']
    },
    setting_tva_country_list: {
        path: '/setting/tva-country/list',
        view: SettingTvaCountryList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_TVA_COUNTRY', 'ROLE_SETTING_TVA_COUNTRY_LIST']
    },
    setting_order_client_litige_type_list: {
        path: '/setting/order-client-litige-type/list',
        view: SettingOrderClientLitigeTypeList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_ORDER_CLIENT_TYPE_LITIGE', 'ROLE_SETTING_ORDER_CLIENT_TYPE_LITIGE_LIST']
    },
    setting_order_client_forwarding_list: {
        path: '/setting/order-client-forwarding/list',
        view: SettingOrderClientForwardingList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_ORDER_CLIENT_FORWARDING', 'ROLE_SETTING_ORDER_CLIENT_FORWARDING_LIST']
    },
    setting_order_client_refund_list: {
        path: '/setting/order-client-refund/list',
        view: SettingOrderClientRefundList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_ORDER_CLIENT_REFUND', 'ROLE_SETTING_ORDER_CLIENT_REFUND_LIST']
    },
    setting_user_function_list: {
        path: '/setting/user-function/list',
        view: SettingUserFunctionList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_USER_FUNCTION', 'ROLE_SETTING_USER_FUNCTION_LIST']
    },
    setting_shop_movement_list: {
        path: '/setting/shop-movement/list',
        view: SettingShopMovementList,
        layout: MainLayout,
        roles: ['ROLE_SETTING_SHOP_MOVEMENT', 'ROLE_SETTING_SHOP_MOVEMENT_LIST']
    },

    // Administrator
    administrator_user_list: {
        path: '/administrator/user/list',
        view: AdministratorUserList,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_USER', 'ROLE_ADMINISTRATOR_USER_LIST']
    },
    administrator_user: {
        path: '/administrator/user/:id',
        match: /\/administrator\/user.*/,
        view: AdministratorUser,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_USER', 'ROLE_ADMINISTRATOR_USER_ADD']
    },
    administrator_log_sylius_list: {
        path: '/administrator/log-sylius/list',
        view: AdministratorLogSyliusList,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_LOG_SYLIUS', 'ROLE_ADMINISTRATOR_LOG_SYLIUS_LIST']
    },
    administrator_log_printer_list: {
        path: '/administrator/log-printer/list',
        view: AdministratorLogPrinterList,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_LOG_PRINTER', 'ROLE_ADMINISTRATOR_LOG_PRINTER_LIST']
    },
    administrator_log_carrier_list: {
        path: '/administrator/log-carrier/list',
        view: AdministratorLogCarrierList,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_LOG_CARRIER', 'ROLE_ADMINISTRATOR_LOG_CARRIER_LIST']
    },
    administrator_request_async_list: {
        path: '/administrator/request-async/list',
        view: AdministratorRequestAsyncList,
        layout: MainLayout,
        roles: ['ROLE_ADMINISTRATOR_REQUEST_ASYNC', 'ROLE_ADMINISTRATOR_REQUEST_ASYNC_LIST']
    },

    // Statistic
    statistic_preparations: {
        path: '/statistic/preparations',
        view: StatisticPreparation,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_PREPARATEUR']
    },
    statistic_sale_payment: {
        path: '/statistic/sale/payment',
        view: StatisticSalePayment,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_CA_PAYMENT']
    },
    statistic_trades: {
        path: '/statistic/trades',
        view: StatisticTrade,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_CACHANNELS']
    },
    statistic_trades_retail: {
        path: '/statistic/trades/retail',
        view: StatisticTradeRetail,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_RETAIL']
    },
    statistic_trades_reporting: {
        path: '/statistic/trades/reporting',
        view: StatisticTradeReporting,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_REPORTING']
    },
    statistic_marks: {
        path: '/statistic/marks',
        view: StatisticMark,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_BY_MARK']
    },
    statistic_buy_ca: {
        path: '/statistic/buy/ca',
        view: StatisticBuyCa,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_BUY_CA']
    },
    statistic_buy_portfolio_details: {
        path: '/statistic/buy/portfolio-details',
        view: StatisticBuyPortfolioDetails,
        layout: MainLayout,
        roles: ['ROLE_STATS_LUCY', 'ROLE_STATS_LUCY_BUY_PORTFOLIO_DETAILS']
    },

    // Shop
    shop: {
        path: '/shop',
        view: ShopCashRegister,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_closing: {
        path: '/shop/close',
        view: ShopCashRegisterClosed,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_reappro_create: {
        path: '/shop/reappro/create',
        view: CreateReappro,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_reappro_reception: {
        path: '/shop/reappro/reception/:reapproId',
        match: /\/shop\/reappro\/reception.*/,
        view: ReceptionReappro,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_list_products: {
        path: '/shop/products-shop/:shopId',
        match: /\/shop\/products-shop.*/,
        view: ShopProductsList,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_sales_list: {
        path: '/shop/sales/list/:shopId',
        match: /\/shop\/sales\/list.*/,
        view: ShopSalesList,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_sales_list_today: {
        path: '/shop/sales/list/today/:shopId',
        match: /\/shop\/sales\/list\/today.*/,
        view: ShopSalesListToday,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_sales_client_list: {
        path: '/shop/sales/client/list/:shopId',
        match: /\/shop\/sales\/client\/list.*/,
        view: SelectClient,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_sales_client_edit: {
        path: '/shop/sales/client/edit/:id/:shopId',
        match: /\/shop\/sales\/client\/edit.*/,
        view: EditClient,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_sales_select_products: {
        path: '/shop/sales/products/select/:shopId/:clientId',
        match: /\/shop\/sales\/products\/select.*/,
        view: SelectProducts,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_seller_get_stocks_by_shop: {
        path: 'shop-seller/stock/shop/:id',
        match: /\/shop-seller\/stock\/shop.*/,
        view: ShopStocks,
        layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_sales_sign_opening: {
        path: '/shop/sign', view: ShopCashSignOpening, layout: ShopLayout,
        roles: ['ROLE_SHOP']
    },
    shop_seller_order_client: {
        path: '/shop/seller/order-client/:uuid',
        match: /\/shop\/seller\/order-client.*/,
        view: ShopSaleOrderClient,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },
    shop_reappro_list: {
        path: '/shop/reappro/list/:shopId',
        match: /\/shop\/reappro\/list.*/,
        view: ListReappro,
        layout: ShopLayout,
        roles: ['ROLE_SHOP', 'ROLE_SHOP_SALES_LIST']
    },

    //B2B : Client Business
    b2b_client_business_list: {
        path: '/b2b/client-business/list',
        view: ClientBusinessList,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_LIST']
    },
    b2b_client_business: {
        path: '/b2b/client-business/:id',
        match: /\/b2b\/client-business.*/,
        view: ClientBusiness,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_EDIT']
    },

    //B2B : Client Business Contact
    b2b_client_business_contact_add: {
        path: '/b2b/client-business-contact/add/:clientBusinessId',
        match: /\/b2b\/client-business-contact\/add.*/,
        view: ClientBusinessContact,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_MANAGE_CONTACT']
    },
    b2b_client_business_contact_edit: {
        path: '/b2b/client-business-contact/edit/:clientBusinessContactId',
        match: /\/b2b\/client-business-contact\/edit.*/,
        view: ClientBusinessContact,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_MANAGE_CONTACT']
    },

    //B2B : Price List
    b2b_client_business_price_list: {
        path: '/b2b/client-business/price-list',
        view: ClientBusinessPriceLists,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_MANAGE_PRICE']
    },
    b2b_client_business_price_list_add: {
        path: '/b2b/client-business/price-list/:id',
        match: /\/b2b\/client-business.*/,
        view: ClientBusinessPriceList,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_EDIT']
    },

    //B2B : Client Business Contact
    b2b_client_business_order_add: {
        path: '/b2b/client-business-order/add/:clientBusinessId',
        match: /\/b2b\/client-business-order\/add.*/,
        view: ClientBusinessOrder,
        layout: MainLayout,
        roles: ['ROLE_RESPONSABLE_B2B', 'ROLE_RESPONSABLE_B2B_EDIT']
    },
}

let routes = {};
let defaultPath = {};

// Security
export const updateRouting = (authenticationReducer) => {
    routes = {};

    if (authenticationReducer && Object.entries(authenticationReducer).length > 0) { // Login
        for (let index in routing) {
            if (authenticationReducer.roles.filter(value => routing[index].roles.includes(value)).length > 0) {
                routes[index] = routing[index];
            }
        }

        if (authenticationReducer.defaultRoute && routes[authenticationReducer.defaultRoute]) {
            defaultPath = routes[authenticationReducer.defaultRoute].path;
        } else {
            if (Object.keys(routes)[0]) {
                defaultPath = routes[Object.keys(routes)[0]].path
            } else { // Logout
                routes.authentication_login = routing['authentication_login'];
                defaultPath = getRoutePathname('authentication_login');
            }
        }
    } else { // Logout
        routes.authentication_login = routing['authentication_login'];
        defaultPath = getRoutePathname('authentication_login');
    }
};

export const getRoutes = () => {
    return routes;
};

export const getPathDefault = () => {
    return defaultPath;
};


export const getRoute = (id) => {
    let routes = getRoutes();
    let route = routes[id];
    return route ? {...route, id: id} : {};
};

export const getRoutePathname = (id, params = []) => {
    let route = getRoute(id);
    let path = route.path ?? '/';

    for (let index in params) {
        let regex = new RegExp('/:' + index + '[?]{0,1}', 'gi')
        path = path.replace(regex, params[index] ? '/' + params[index] : '');
    }

    return path;
};

export const getRouteByPathname = (pathname) => {
    let routes = getRoutes();

    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return {...routes[index], id: index};
        } else if (routes[index].path === pathname) {
            return {...routes[index], id: index};
        }
    }

    return {};
};
