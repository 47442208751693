import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Article} from "@mui/icons-material";
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import {initFormHandler} from '../../../Handler/FormHandler';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import moment from "moment";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import ShopCashRegisterForm from "../CashRegister/ShopCashRegisterComponent/ShopCashRegisterForm";
import ShopCreateClientForm from "./ShopSaleComponent/ShopCreateClientForm";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ButtonComponent from "../../../Component/ButtonComponent";
import {useNavigate} from "react-router-dom";

function EditClient(props) {
    const navigate = useNavigate();
    const [loadingContent, setLoadingContent] = React.useState(true);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [client, setClient] = React.useState(null);
    const params = useParams();

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Choisir'} placement="left">
                    <Link to={getRoutePathname('shop_sales_select_products', {clientId: row.uuid, shopId: authenticationReducer.shopSelected})}>
                        <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    };
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des clients',
            context: 'Vente',
            description: '',
            links: []
        });

        setLoadingContent(false);

        ApiHandler.get({
            route: 'api_shop_sales_client_info',
            params: {id: params.id, shopId: authenticationReducer.shopSelected}
        }, (response) => {
            if (response.status === 200) {
                setClient(response.data.client);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, [])

    const openShop = () =>{
        let path = `shop`;
        navigate(path);
    }

    return (
        <ContentViewShopComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            {client && <>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShopCreateClientForm shopId={authenticationReducer.shopSelected} client={client}/>
                    </Grid>
                </Grid>
            </>}
        </ContentViewShopComponent>
    );
}

export default EditClient;
