import React from 'react';
import {makeStyles} from '@mui/styles';
import {Select, InputLabel, FormControl, FormHelperText, MenuItem, Checkbox} from "@mui/material";

export default function MultipleSelectComponent(props) {

    // Info
    const id = props.id;
    const input = props.handler.form[id];
    const label = input.label;

    const classes = useStyles();
    const [error, setError] = React.useState(input.error);
    const [isFocus, setFocus] = React.useState(false);

    // Value
    const [value, setValue] = React.useState([]);
    React.useEffect(() => {
        if (input.value !== undefined && input.value !== value) setValue(input.value);
    }, [input.value]);
    React.useEffect(() => {
        if (input.error !== error) setError(input.error);
    }, [input.error]);

    // On
    const onChange = (event) => {
        let newValue = event.target.value ?? [];
        setValue(newValue);

        if (props.onChange) props.onChange(newValue);
    }
    const onBlur = () => {
        setFocus(false);
        props.handler.setValue(id, value);
        let error = props.handler.getErrorByField(input);
        setError(error);
        props.handler.setError(id, error);
    }
    const onFocus = () => {
        setFocus(true);
    }

    return (
        <div className={classes.content}>
            <FormControl variant="outlined"
                         className={`
                        ${classes.multipleSelect}
                        ${isFocus ? classes.multipleSelectFocus : error ? classes.multipleSelectError : (value && value.length > 0) && !input.disabled ? classes.multipleSelectValid : ''}
                    `}
            >
                <InputLabel htmlFor="outlined">{label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}</InputLabel>
                <Select
                    labelId={input.name}
                    multiple={true}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={false}
                    inputProps={{name: label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}}
                    MenuProps={{
                        anchorOrigin: {vertical: "bottom", horizontal: "left"},
                        className: classes.menu
                    }}
                    label={label + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? ' *' : '')}
                    disabled={input.disabled}
                >
                    {
                        props.options.map(data => (
                            <MenuItem key={data.value} value={data.value}>
                                <Checkbox style={{padding: 0}} checked={value.indexOf(data.value) > -1} />
                                {data.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{error && !isFocus ? error : input.textHelper}</FormHelperText>
            </FormControl>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        padding: 5
    },
    multipleSelect: {
        width: '100%',
        marginTop: 0,
        '& .MuiSelect-select': {
            padding: '7px 14px',
            fontSize: 13
        },
        '& .MuiSelect-select:focus': {
            background: '#FFFFFF'
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 7px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(13px, -7px) scale(0.75) !important',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    multipleSelectFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    multipleSelectValid: {
        '& fieldset': {
            borderColor: '#006500 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#006500 !important'
        },
        '& label': {
            color: '#006500 !important'
        }
    },
    multipleSelectError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    },
    menu: {
        '& li': {
            fontSize: 12,
            padding: '4px 10px',
            lineHeight: '16px',
            minHeight: '16px'
        }
    }
});
