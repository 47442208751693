import React from 'react';
import {makeStyles} from '@mui/styles';

export default function AuthenticationLayout(props) {
    const classes = useStyles();

    return (
        <div className={classes.authenticationLayout}>
            <props.view/>
        </div>
    );
}

const useStyles = makeStyles({
    authenticationLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    }
});
