import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import AutoCompleteComponent from "../../../../Component/AutoCompleteComponent";
import {TableReload} from "../../../../Action/TableAction";
import TableComponent from "../../../../Component/TableComponent";
import {Article, DeleteOutline} from "@mui/icons-material";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {hasRight} from "../../../../Common/UserCommon";

function BuyProductPurveyor() {
    const dispatch = useDispatch();
    const params = useParams();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');

    // Purveyor List
    const columnsPurveyor = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getActionPurveyor = (row) => {
        if (
            (hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_LIST')) && !justWatch
        ) {
            return (
                <>
                    <Tooltip title={'Visualiser le fournisseur'} placement="left">
                        <Link to={getRoutePathname('buy_purveyor', {id: row.id})}>
                            <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title={'Supprimer le fournisseur'} placement="left">
                        <IconButton onClick={() => setPurveyorDelete(row)}>
                            <DeleteOutline style={{color: '#982525'}}/>
                        </IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Article/>
                    </IconButton>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <DeleteOutline/>
                    </IconButton>
                </>
            );
        }
    };


    // Purveyor Form
    const loadPurveyor = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_purveyor',
            data: {text: text}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const [formPurveyor, setFormPurveyor] = React.useState({
        purveyorId: {
            name: 'purveyorId',
            label: 'Fournisseur',
            textHelper: 'Recherchez un fournisseur à ajouter à l\'article.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handlerFormPurveyor = initFormHandler(formPurveyor, setFormPurveyor);
    const savePurveyor = () => {

        let data = handlerFormPurveyor.getData();

        if (handlerFormPurveyor.checkError() < 1) {
            handlerFormPurveyor.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_buy_product_purveyor_add',
                data: data,
                params: {productId: params.id}
            }, (response) => {
                if (response.status === 200) {
                    handlerFormPurveyor.reset();

                    dispatch(TableReload('api_buy_product_purveyor_list'));
                    setTimeout(() => dispatch(TableReload('api_buy_product_by_product_purveyor_list')), 500);

                    dispatch(
                        SnackbarOpen({
                            text: 'Fournisseur ajouté à l\'article avec succès.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handlerFormPurveyor.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormPurveyor.setFormLoading(false);
            });
        }
    };

    React.useEffect(handlerFormPurveyor.start, []);
    React.useEffect(() => {
        if (params.id !== 'add') {
            ApiHandler.get({
                route: 'api_buy_product_purveyor_list',
                params: {productId: params.id}
            }, (response) => {
                if (response.status === 200) {
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [])

    // Purveyor delete
    const [purveyorDelete, setPurveyorDelete] = React.useState(null);
    const deletePurveyor = () => {
        setPurveyorDelete(null);
        dispatch(LinearProgressMainLayoutActivate());

        ApiHandler.delete({
            route: 'api_buy_product_purveyor_delete',
            params: {productId: params.id, id: purveyorDelete.id}
        }, (response) => {
            if (response.status === 200) {
                dispatch(TableReload('api_buy_product_purveyor_list'));
                setTimeout(() => dispatch(TableReload('api_buy_product_by_product_purveyor_list')), 500);

                dispatch(
                    SnackbarOpen({
                        text: 'Fournisseur de l\'article supprimé avec succès.',
                        variant: 'success',
                    })
                );
            }
            else if (response.status === 400) {
                handlerFormPurveyor.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
        });
    };

    return (
        <>
            <ShadowBoxComponent style={{height: '100%'}}>
                <TitleComponent title={'Fournisseurs'}/>
                <AutoCompleteComponent
                    id={'purveyorId'}
                    handler={handlerFormPurveyor}
                    loadOptions={loadPurveyor}
                    onClick={savePurveyor}
                    disabled={!(hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_LIST')) || justWatch}
                />

                <div>
                    <TableComponent
                        noShadow={true}
                        id={'api_buy_product_purveyor_list'}
                        title={'Fournisseurs'}
                        columns={columnsPurveyor}
                        promiseData={(resolve) => {
                            ApiHandler.get({
                                    route: 'api_buy_product_purveyor_list',
                                    params: {productId: params.id}
                                },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].action = getActionPurveyor(data[index]);
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                </div>
            </ShadowBoxComponent>

            <Dialog open={!!purveyorDelete} maxWidth={'xl'} onClose={() => setPurveyorDelete(null)}>
                <DialogTitle style={{fontSize: 15}}>{purveyorDelete ? purveyorDelete.name : ''}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Êtes-vous sûr de vouloir supprimer le fournisseur "{purveyorDelete ? purveyorDelete.name : ''}" de l'artiche ?
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Non'} onClick={() => setPurveyorDelete(null)}/>
                    <ButtonComponent label={'Oui'} onClick={deletePurveyor}/>
                </DialogActions>
            </Dialog>
        </>
    );
}


export default BuyProductPurveyor;
