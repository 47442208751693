import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Fade, Grid, IconButton} from "@mui/material";
import ClientBusinessOrderProductsForm from "./ClientBusinessOrderComponents/ClientBusinessOrderProductsForm";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {Delete} from "@mui/icons-material";
import TableComponent from "../../../Component/TableComponent";
import {TableReload} from "../../../Action/TableAction";
import {hasRight} from "../../../Common/UserCommon";

function ClientBusinessOrder() {

    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const [cartItems, setCartItems] = React.useState([]);
    const [clientBusiness, setClientBusiness] = React.useState(null);
    const [disabledButton, setDisabledButton] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingContent(false);
    }, [clientBusiness])

    React.useEffect(() => {
        setClientBusiness(null);
        setIsLoadingContent(true)

        ApiHandler.get({
            route: 'api_b2b_client_business_get',
            params: {id: params.clientBusinessId}
        }, (response) => {
            if (response.status === 200) {
                setClientBusiness(response.data[0]);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, [location])

    const columns = [
        {
            name: "sku",
            label: "SKU",
            options: {filter: false, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: false, sort: true}
        },
        {
            name: "byProductName",
            label: "Nom du produit",
            options: {filter: false, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum",
            options: {filter: false, sort: true}
        },
        {
            name: "sellerSellPriceHT",
            label: "Prix revendeur",
            options: {filter: false, sort: true}
        },
        {
            name: "quantity",
            label: "Quantité",
            options: {filter: false, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ]

    const deleteFromCart = (row) => {
        setCartItems((prevItems) => prevItems.filter(item => item.byProductId !== row.byProductId));
    }

    const createOrder = () => {

        if(!(cartItems.length > 0))
        {
            dispatch(
                SnackbarOpen({
                    text: 'Votre panier est vide.',
                    variant: 'error',
                })
            );
            return;
        }

        setDisabledButton(true);

       let input = {
           "cartItems" : cartItems,
           "id": params.clientBusinessId
       }

        ApiHandler.post({
            route: 'api_b2b_client_business_order_add',
            data: input,
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Commande créé avec succès.',
                        variant: 'success',
                    })
                );
                navigate(getRoutePathname('b2b_client_business', {id: params.clientBusinessId}));
                setDisabledButton(false);
            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur dans le formulaire.',
                        variant: 'error',
                    })
                );
                setDisabledButton(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
                setDisabledButton(false);
            }
        });

    }

    let getAction = (row) => {
        return (
            <>
                <IconButton onClick={() => deleteFromCart(row)}><Delete style={{color: 'red'}}/></IconButton>
            </>
        );
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Créer une proposition',
            context: 'Gestion des commandes',
            description: '',
            links: [
                {path: getRoutePathname('b2b_client_business',{id: params.clientBusinessId}), label: 'Revendeur'}
            ]
        });
    }, [location])

    React.useEffect(() => {
        dispatch(TableReload('api_b2b_client_business_cart'));
    }, [cartItems])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>

            <Fade in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <TableComponent
                            id={'api_b2b_client_business_cart'}
                            title={'Panier'}
                            columns={columns}
                            actionFirst={hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD') ? {
                                label: 'Valider mon panier',
                                onClick: createOrder,
                                disabled:{disabledButton}
                            } : false}
                            search={false}
                            promiseServerData={(resolve) => {

                                for (let index in cartItems) {
                                    cartItems[index].action = getAction(cartItems[index]);
                                }

                                resolve(cartItems, cartItems.length);
                            }}
                        />

                        <br/><br/>
                        {clientBusiness &&
                            <ClientBusinessOrderProductsForm priceList={clientBusiness.priceList} cartItems={cartItems} setCartItems={setCartItems} />
                        }
                </Grid>
            </Fade>
            <br/><br/>

        </ContentViewComponent>
    )
}

export default ClientBusinessOrder;