import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import {useDispatch, useSelector} from "react-redux";
import {inArray} from "../../../Common/ArrayCommon";
import {Link, useNavigate} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Tooltip
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ShopCashClosingForm from "./ShopCashRegisterComponent/ShopCashClosingForm";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import Paper from "@mui/material/Paper";
import {initFormHandler} from "../../../Handler/FormHandler";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ButtonComponent from "../../../Component/ButtonComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {hasRight} from "../../../Common/UserCommon";
import {makeStyles} from "@mui/styles";

function ShopCashRegisterClosed(props) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [recap, setRecap] = React.useState(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_SHOP_SALES') && !hasRight(authenticationReducer, 'ROLE_SHOP_SELLER');
    const [totalCashDay, setTotalCashDay] = React.useState(null);
    const [caisseEspece, setCaisseEspece] = React.useState(null);
    const [ecartEspece, setEcartEspece] = React.useState(null);
    const [ecartCb, setEcartCb] = React.useState(null);
    const [totalCBDay, setTotalCBDay] = React.useState(null);
    const [totalRemise, setTotalRemise] = React.useState(null);

    const [form, setForm] = React.useState({
        b500: {
            name: 'b500',
            label: '500€',
            textHelper: 'Saisissez le nombre de billet de 500 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b200: {
            name: 'b200',
            label: '200€',
            textHelper: 'Saisissez le nombre de billet de 200 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b100: {
            name: 'b100',
            label: '100€',
            textHelper: 'Saisissez le nombre de billet de 100 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b50: {
            name: 'b50',
            label: '50€',
            textHelper: 'Saisissez le nombre de billet de 50 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b20: {
            name: 'b20',
            label: '20€',
            textHelper: 'Saisissez le nombre de billet de 20 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b10: {
            name: 'b10',
            label: '10€',
            textHelper: 'Saisissez le nombre de billet de 10 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        b5: {
            name: 'b5',
            label: '5€',
            textHelper: 'Saisissez le nombre de billet de 5 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env500: {
            name: 'env500',
            label: '500€',
            textHelper: 'Saisissez le nombre de billet de 500 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env200: {
            name: 'env200',
            label: '200€',
            textHelper: 'Saisissez le nombre de billet de 200 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env100: {
            name: 'env100',
            label: '100€',
            textHelper: 'Saisissez le nombre de billet de 100 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env50: {
            name: 'env50',
            label: '50€',
            textHelper: 'Saisissez le nombre de billet de 50 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env20: {
            name: 'env20',
            label: '20€',
            textHelper: 'Saisissez le nombre de billet de 20 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env10: {
            name: 'env10',
            label: '10€',
            textHelper: 'Saisissez le nombre de billet de 10 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        env5: {
            name: 'env5',
            label: '5€',
            textHelper: 'Saisissez le nombre de billet de 5 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p2: {
            name: 'p2',
            label: '2€',
            textHelper: 'Saisissez le nombre de pièces de 2 euros.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p1: {
            name: 'p1',
            label: '1€',
            textHelper: 'Saisissez le nombre de pièces de 1 euro.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p050: {
            name: 'p050',
            label: '0.5€',
            textHelper: 'Saisissez le nombre de pièces de 50 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p020: {
            name: 'p020',
            label: '0.2€',
            textHelper: 'Saisissez le nombre de pièces de 20 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p010: {
            name: 'p010',
            label: '0.1€',
            textHelper: 'Saisissez le nombre de pièces de 10 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p005: {
            name: 'p005',
            label: '0.5€',
            textHelper: 'Saisissez le nombre de pièces de 5 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p002: {
            name: 'p002',
            label: '0.2€',
            textHelper: 'Saisissez le nombre de pièces de 2 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        p001: {
            name: 'p001',
            label: '0.1€',
            textHelper: 'Saisissez le nombre de pièces de 1 cents.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez vos remarques.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        totalCBreceived: {
            name: 'totalCBreceived',
            label: 'Total remis au siège',
            textHelper: 'Montant CB encaissé aujourdhui',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (authenticationReducer.shopSelected) {
                ApiHandler.post({
                    route: 'api_shop_sales_close',
                    data: handlerForm.getData(),
                    params: {id: authenticationReducer.shopSelected}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Caisse fermée avec succès.',
                                variant: 'success',
                            })
                        );
                        navigate(getRoutePathname('shop_sales_sign_opening'));

                    } else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);

                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.shop) {
            handlerForm.setDataApi(props.shop);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    const calculateTotalCash = (value) => {
        var b500 = document.getElementById("b500").value;
        var b200 = document.getElementById("b200").value;
        var b100 = document.getElementById("b100").value;
        var b50 = document.getElementById("b50").value;
        var b20 = document.getElementById("b20").value;
        var b10 = document.getElementById("b10").value;
        var b5 = document.getElementById("b5").value;

        var p2 = document.getElementById("p2").value;
        var p1 = document.getElementById("p1").value;
        var p050 = document.getElementById("p050").value;
        var p020 = document.getElementById("p020").value;
        var p010 = document.getElementById("p010").value;
        var p005 = document.getElementById("p005").value;
        var p002 = document.getElementById("p002").value;
        var p001 = document.getElementById("p001").value;

        var total = parseFloat(b500 * 500) + parseFloat(b200 * 200) + parseFloat(b100*100) + parseFloat(b50*50) + parseFloat(b20*20) + parseFloat(b10*10) + parseFloat(b5*5) + parseFloat(p2*2) + parseFloat(p1*1) + parseFloat(p050*0.5) + parseFloat(p020*0.2) + parseFloat(p010*0.1) + parseFloat(p005*0.05) + parseFloat(p002*0.02) + parseFloat(p001*0.01);

        setTotalCashDay((total).toFixed(2));

        setEcartEspece(( (parseFloat(total) + parseFloat(caisseEspece)) - (parseFloat(recap.totalOpening) + parseFloat(recap.totalCashDay))));
    };

    const calculateCaisseEspece = (value) => {
        var env500 = document.getElementById("env500").value;
        var env200 = document.getElementById("env200").value;
        var env100 = document.getElementById("env100").value;
        var env50 = document.getElementById("env50").value;
        var env20 = document.getElementById("env20").value;
        var env10 = document.getElementById("env10").value;
        var env5 = document.getElementById("env5").value;

        var total = parseFloat(env500 * 500) + parseFloat(env200 * 200) + parseFloat(env100*100) + parseFloat(env50*50) + parseFloat(env20*20) + parseFloat(env10*10) + parseFloat(env5*5) ;

        setCaisseEspece((total));
        setTotalRemise(parseFloat(recap.totalAvoirDay) + parseFloat(totalCBDay) + parseFloat(total));
        setEcartEspece((parseFloat(total) + parseFloat(totalCashDay)) - (parseFloat(recap.totalOpening) + parseFloat(recap.totalCashDay)));
    };

    React.useEffect(() => {
        ApiHandler.post({
                route: 'api_shop_sales_get_recap_day',
                params: {shopId: authenticationReducer.shopSelected }
            },
            (response) => {
                setRecap(response.data);
                setTotalCBDay(0);
            });

        setLoadingContent(false);
    }, []);

    const calculateCaisseCb = (val) => {
        var totalCBDayFromReporting = document.getElementById("totalCBreceived").value;

        setTotalCBDay(parseFloat(totalCBDayFromReporting).toFixed(2));
        setEcartCb((parseFloat(totalCBDayFromReporting) - parseFloat(recap.totalCBDay)));
        let tempTotal = totalRemise;
        setTotalRemise(parseFloat(totalRemise) + parseFloat(totalCBDayFromReporting));
    };

    React.useEffect(() => {
        setEcartCb(0);
        setCaisseEspece(0);
        setTotalCashDay(0);
        setTotalRemise(0);
        setTotalCBDay(0);
        setEcartEspece(0);
        ApiHandler.post({
                route: 'api_shop_sales_get_recap_day',
                params: {shopId: authenticationReducer.shopSelected }
            },
            (response) => {
                setRecap(response.data);
                setTotalRemise(response.data.totalAvoirDay + totalCBDay);
                setEcartEspece(response.data.totalOpening + response.data.totalCashDay);
            });

        setLoadingContent(false);
    }, []);

    return (
        <ContentViewShopComponent>
            {recap && <>
                <br/>
                <TitleComponent title={'Récapitulatif'}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        Date de clôture : {recap.dateCloture}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        Utilisateur : {recap.user}
                    </Grid>
                </Grid>
                <br/>
                <TableContainer component={Paper} id={"byproductsList"}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead sx={{ backgroundColor: "lightgrey"}}>
                            <TableRow>
                                <TableCell style={{fontWeight: 900, fontSize: 12}}></TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Fonds de caisse à l'ouverture</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Chiffres d'affaires</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Ventilation encaissements</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Avoirs émis</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Remise au siège</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Fond de caisse après clôture</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Ecart</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Carte bancaire</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCBDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCBDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(totalCBDay)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">{parseFloat(ecartCb).toFixed(2)} €</TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Espèce</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalOpening).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCashDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalCashDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center"></TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(caisseEspece)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(totalCashDay)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">{(parseFloat(ecartEspece).toFixed(2))} €</TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell style={{fontSize: 11}} component="th">Avoirs</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">-</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay - recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">- {(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">{(recap.totalAvoirDay).toFixed(2)} €</TableCell>
                                <TableCell style={{fontSize: 11}} component="th" align="center">-</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 14}} component="th" align="center">0</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: "lightgrey"}}>
                            <TableRow>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Total</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(recap.totalOpening).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(recap.totalCashDay + recap.totalCBDay + (recap.totalAvoirDay - recap.totalCreditToday)).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 12}} component="th" align="center">{(recap.totalCBDay + recap.totalCashDay + recap.totalAvoirDay ).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900,fontSize: 12}} component="th" align="center">- {(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">{(parseFloat(totalRemise)).toFixed(2)} €</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">-</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 14}} align="center">{(parseFloat(ecartCb) + parseFloat(ecartEspece)).toFixed(2)} €</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <br/>
                <hr/>
                <br/>
                <ShadowBoxComponent className={classes.shadowBox}>
                    <TitleComponent title={'Espèces remis au siège'}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env500'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env200'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env100'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env50'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env20'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env10'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'env5'} handler={handlerForm} onBlur={(val) => calculateCaisseEspece(val)}/>
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <TitleComponent title={'Fonds de caisse à la cloture'}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b500'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b200'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b100'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b50'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b20'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b10'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'b5'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p2'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p1'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p050'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p020'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p010'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p005'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p002'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldComponent id={'p001'} handler={handlerForm} onBlur={(val) => calculateTotalCash(val)}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <hr/>
                    <h4>Liste des paiements CB du jour</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopCard > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{(row.shopCard).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center"></TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{(recap.totalCBDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Remis au siège</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"><TextFieldComponent id={'totalCBreceived'} value={totalCBDay} handler={handlerForm} onBlur={(val) => calculateCaisseCb(val)}/></TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des paiements Espèces du jour</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopCash > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{parseFloat(row.shopCash).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center"></TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{parseFloat(recap.totalCashDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des paiements Avoirs du jour</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersDay.length > 0) ? recap.ordersDay.map((row) => {
                                    if(row.shopAvoir > 0) {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.number}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{parseFloat(row.shopAvoir).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center"></TableCell>
                                            </TableRow>
                                        )
                                    }
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{parseFloat(recap.totalAvoirDay).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <h4>Liste des avoirs émis du jour</h4>
                    <TableContainer component={Paper} id={"ordersList"} sx={{ backgroundColor: "lightgrey"}}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Num</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center">Montant</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}} align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(recap.ordersCreditDay.length > 0) ? recap.ordersCreditDay.map((row) => {
                                        return (
                                            <TableRow key={row.uuid}
                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{row.orderNumber}</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center">{parseFloat(row.priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2})} €</TableCell>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row"
                                                           align="center"></TableCell>
                                            </TableRow>
                                        )
                                }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                </TableRow>}
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">Total</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center">{(recap.totalCreditToday).toFixed(2)} €</TableCell>
                                    <TableCell style={{fontSize: 11}} component="th" scope="row" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <hr/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextFieldComponent id={'comment'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                    {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
                </ShadowBoxComponent>
            </>}
        </ContentViewShopComponent>

    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ShopCashRegisterClosed;
