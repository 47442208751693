import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {AppBar, Box, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from 'prop-types';

export default function TabsComponent(props) {

    let color = props.color ? props.color : '#57A585';
    const classes = useStyles(color)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);

    let tabs = props.tabs ? props.tabs : [];
    let tab = props.tab ? props.tab : '';
    let tabPanels = Array.isArray(props.children) ? props.children : [props.children];

    const [value, setValue] = React.useState(props.tabDefault ? props.tabDefault : '');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (tab) setValue(tab);
    }, [tab]);
    useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <div className={`${classes.root} ${!isTall && classes.rootSmall}`}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} centered={props.centered} TabIndicatorProps={{style: {background: color}}}>
                    {
                        tabs
                            .filter((tab) => !tab.hidden)
                            .map((tab) => {
                                return (
                                    <Tab
                                        key={tab.key}
                                        {...a11yProps(tab.key)}
                                        value={tab.key}
                                        label={tab.label}
                                        disabled={tab.disabled}
                                        wrapped={tab.wrapped}/>
                                )
                            })
                    }
                </Tabs>
            </AppBar>
            {
                tabPanels.map((tabPanel, key) => {
                    return (
                        <TabPanel key={key} className={classes.tab} value={value} index={tabPanel.key}>
                            {tabPanel}
                        </TabPanel>
                    )
                })
            }
        </div>
    );
}

const useStyles = (color) => makeStyles({
    root: {
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
        borderRadius: 5,
        flexGrow: 1,
        '& > header': {
            background: 'transparent',
            boxShadow: 'none',
            display: 'block',
            height: 30,
            '& .MuiTabs-scroller': {
                height: 29,
                borderBottom: '1px solid #ccc'
            },
            '& .MuiTabs-root': {
                background: 'none',
                height: 30,
                minHeight: 30,
                minWidth: 100,
                padding: 0,
                '& .MuiTabs-indicator': {
                    background: color
                },
                '& .MuiTabs-flexContainer': {
                    background: '#ffffff',
                    height: 30,
                    minHeight: 30,
                    borderRadius: '5px 5px 0 0',
                    '& .MuiButtonBase-root': {
                        background: 'none',
                        fontSize: 10,
                        color: '#5E6E82',
                        fontWeight: 400,
                        padding: '0 15px',
                        height: 30,
                        minHeight: 30,
                        lineHeight: '30px',
                        '&.Mui-selected ': {
                            color: color,
                        },
                        '& .MuiTab-wrapper': {
                            textTransform: 'none'
                        }
                    }
                }
            }
        }
    },
    rootSmall: {
        '& header': {
            '& .MuiTabs-root': {
                '& .MuiTabs-flexContainer': {
                    '& .MuiButtonBase-root': {
                        fontSize: 10,
                    }
                }
            }
        }
    },
    tab: {
        background: '#fff',
        margin: 0,
        borderRadius: '0 0 5px 5px',
        overflow: 'hidden',
        '& > div': {
            padding: 0,
            width: '100%',
            boxSizing: 'border-box',
            margin: 0,
            '& > div': {
                padding: 0,
                width: '100%',
                boxSizing: 'border-box',
                margin: 0
            }
        }
    }
});

function TabPanel(props) {

    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}
