import {combineReducers, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducer
import AuthenticationReducer from "./Reducer/AuthenticationReducer";
import ScreenReducer from "./Reducer/ScreenReducer";
import SnackbarReducer from "./Reducer/SnackbarReducer";
import DrawerMainLayoutReducer from "./Reducer/DrawerMainLayoutReducer";
import LinearProgressMainLayoutReducer from "./Reducer/LinearProgressMainLayoutReducer";
import TableReducer from "./Reducer/TableReducer";

const rootReducer = combineReducers({
    AuthenticationReducer: AuthenticationReducer,
    ScreenReducer: ScreenReducer,
    SnackbarReducer: SnackbarReducer,
    DrawerMainLayoutReducer: DrawerMainLayoutReducer,
    LinearProgressMainLayoutReducer: LinearProgressMainLayoutReducer,
    TableReducer: TableReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['SnackbarReducer', 'ScreenReducer', 'LinearProgressMainLayoutReducer', 'TableReducer'],
    whitelist: ['AuthenticationReducer', 'DrawerMainLayoutReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

export default {store, persistor};
