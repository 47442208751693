import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {dispatch} from "../../../App";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useParams} from "react-router-dom";
import {Grid} from "@mui/material";
import TabsComponent from '../../../Component/TabsComponent';
import SaleClientOrderClientList from './SaleClientComponents/SaleClientOrderClientList';
import SaleClientCommentList from './SaleClientComponents/SaleClientCommentList';
import SaleClientLitigeList from "./SaleClientComponents/SaleClientLitigeList";
import SaleCreateClientForm from "./SaleClientComponents/SaleCreateClientForm";


function SaleClient() {
    const params = useParams();
    const [loading, setLoading] = React.useState(true);
    const [client, setClient] = React.useState(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Fiche client',
            context: 'Vente',
            description: '',
            links: [
                {path: getRoutePathname('sale_client_list'), label: 'Liste des clients'}
            ]
        });

        ApiHandler.get({
            route: 'api_sale_client',
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                setClient(response.data);
                setLoading(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, []);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {client && <>
                <ShadowBoxComponent style={{height: '100%'}}>
                    <TitleComponent title={'Information client'}/>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Référence</span> : {client.number}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Nom du client</span> : {client.lastname}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Prénom du client</span> : {client.firstname}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Email du client</span> : {client.email}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Téléphone du client</span> : {client.phone}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Code postal du client</span> : {client.zipCode}</p>
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>

                <br/>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SaleCreateClientForm client={client}/>
                    </Grid>
                </Grid>
                <br/>

                <TabsComponent
                    tabDefault={'sale_client_order_client_list'}
                    tabs={[
                        {key: 'sale_client_order_client_list', label: 'Commandes'},
                        {key: 'sale_client_comment_list', label: 'Commentaires'},
                        {key: 'sale_client_litige_list', label: 'Litiges'},
                    ]}
                >
                    <div key={'sale_client_order_client_list'}>
                        <SaleClientOrderClientList client={client} />
                    </div>

                    <div key={'sale_client_comment_list'}>
                        <SaleClientCommentList uuid={params.uuid} />
                    </div>

                    <div key={'sale_client_litige_list'}>
                        <SaleClientLitigeList client={client} />
                    </div>
                </TabsComponent>
            </>}

            <br/>
            <br/>

        </ContentViewComponent>
    );
}

export default SaleClient;
