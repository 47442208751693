import clsx from "clsx";
import {useLocation} from "react-router-dom";
import {Box, Grid, Slide} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DataGrid, frFR, GridColDef, gridExpandedSortedRowIdsSelector, gridNumberComparator} from "@mui/x-data-grid";

import {dispatch} from "../../../App";
import ApiHandler from "../../../Handler/ApiHandler";
import {initFormHandler} from "../../../Handler/FormHandler";
import TitleComponent from "../../../Component/TitleComponent";
import SelectComponent from "../../../Component/SelectComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {momentDateComparator} from "../../../Util/SortMomentDate";
import {momentDateFormatter} from "../../../Util/MomentDateFormatter";
import {exportFloatFormat} from "../../../Util/ExportNumberFormatter";
import CustomToolbar from "../../../Component/DataGrid/CustomToolbar";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import CheckboxToFilterData from "../../../Filter/CheckboxToFilterData";
import DoubleRowHeader from "../../../Component/DataGrid/DoubleRowHeader";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {CustomPagination} from "../../../Component/DataGrid/CustomPagination";
import {fetchPurveyors, fetchPurveyorTypes} from "../../../Filter/FetchDropdownData";
import FooterWithTotalGeneral from "../../../Component/DataGrid/FooterWithTotalGeneral";
import {formatNumberToEurCurrency, formatNumberWithThousandsSeparator} from "../../../Util/NumberTool";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {listingStyles} from "../../../Style/useStyles";
import {styleDataGridDefault} from "../../../Style/styleDataGridDefault";

function StatisticBuyPortfolioDetails() {
    const [loadingContent, setLoadingContent] = React.useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsPurveyor, setOptionsPurveyor] = React.useState([]);
    const [optionsPurveyorType, setOptionsPurveyorType] = React.useState([]);

    const [cellsTable, setCellsTable] = React.useState([]);
    const [withSubTotal, setWithSubTotal] = React.useState(true);

    const [generalTotalCA, setGeneralTotalCA] = useState([]);
    const [subTotalsByOrder, setSubTotalsByOrder] = useState([]);

    let totalGeneralValue = 0;
    if (Object.values(cellsTable).length > 0) {
        totalGeneralValue = generalTotalCA;
    }

    const classes = listingStyles();

    const searchPurveyor = useLocation().search;
    const purveyorIdFromBuyCa = new URLSearchParams(searchPurveyor).get("purveyorId");

    const [formSearch, setFormSearch] = React.useState({
        purveyor: {
            name: 'purveyor',
            label: 'Fournisseur',
            textHelper: 'Rechercher par fournisseur.',
            type: 'integer',
            defaultValue: purveyorIdFromBuyCa ?? null,
            options: {}
        },
        purveyorType: {
            name: 'purveyorType',
            label: 'Type de fournisseur',
            textHelper: 'Rechercher par types de fournisseur.',
            type: 'integer',
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
    }

    useEffect(() => {
        handlerFormSearch.start();
        fetchPurveyors(setOptionsPurveyor);
        fetchPurveyorTypes(setOptionsPurveyorType);

        setBreadcrumbs({
            title: 'Stats portefeuille fournisseur en attente de réception',
            context: 'Statistiques',
            description: '',
            links: []
        });

        if (purveyorIdFromBuyCa !== null) {
            onSearch();
        }
    }, [])

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();
        let filters = {
            purveyor: (datas.purveyor === 0 || !datas.purveyor) ? '' : datas.purveyor,
            purveyorType: (datas.purveyorType === 0 || !datas.purveyorType) ? '' : datas.purveyorType,
        };

        ApiHandler.get({
                route: 'api_statistic_buy_portfolio_details',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    const {generalTotalCA, subTotalsByOrder, ...dataPortfolioDetails} = response.data;

                    setCellsTable(dataPortfolioDetails);
                    setGeneralTotalCA(generalTotalCA);
                    setSubTotalsByOrder(subTotalsByOrder);
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                }
            }
        );
    };

    const columns: GridColDef[] = [
        {
            field: "orderId",
            headerName: "Cmd id",
            headerAlign: "center",
            align: "center",
            hidden: true,
            cellClassName: (params) => {
                return clsx('amount', {
                    subtotalRow: params.row.isSubtotalRow,
                });
            },
        },
        {
            field: "purveyor",
            headerName: "Fournisseur",
            flex: 2,
            colSpan: (value) => {
                if (value.row.isSubtotalRow) {
                    return 8;
                }
            },
            cellClassName: (params) => {
                return clsx('amount', {
                    subtotalRow: params.row.isSubtotalRow,
                    flexEnd: params.row.isSubtotalRow,
                });
            },
        },
        {
            field: "purveyorType",
            headerName: "Type de fournisseur",
            flex: 1,
            minWidth: 150,
            renderHeader: () => <DoubleRowHeader firstLineText="Type de" secondLineText="fournisseur"/>,
        },
        {
            field: "refProduct",
            headerName: "Ref produit (SKU)",
            flex: 1,
            minWidth: 140,
        },
        {
            field: "mark",
            headerName: "Marque",
            flex: 1,
        },
        {
            field: "productName",
            headerName: "Produit",
            flex: 3,
        },
        {
            field: "qtyOrdered",
            headerName: "Quantité",
            headerAlign: "center",
            flex: 1,
            align: "right",
            sortComparator: gridNumberComparator,
            renderCell: (params) => (
                params.row.isSubtotalRow ? '' :
                    <div>{formatNumberWithThousandsSeparator(params.value)}</div>
            ),
            valueFormatter: (params) => {
                return params.value;
            },
        },
        {
            field: "validAt",
            headerName: "Date de validation",
            headerAlign: "center",
            flex: 1,
            align: "right",
            sortComparator: momentDateComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Date de" secondLineText="validation"/>,
            valueGetter: (params) => {
                return params.row.isSubtotalRow ? '' :
                    momentDateFormatter.formatMomentDate(params.value, 'DD/MM/YYYY');
            },
        },
        {
            field: "deliveryAt",
            headerName: "Date de livraison",
            headerAlign: "center",
            flex: 1,
            align: "right",
            sortComparator: momentDateComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Date de" secondLineText="livraison"/>,
            valueGetter: (params) => {
                return params.row.isSubtotalRow ? '' :
                    momentDateFormatter.formatMomentDate(params.value, 'DD/MM/YYYY');
            },
        },
        {
            field: "priceBuy",
            headerName: "Prix d'achat",
            headerAlign: "center",
            flex: 1,
            align: "right",
            sortComparator: gridNumberComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Prix" secondLineText="d'achat"/>,
            renderCell: (params) => (
                params.row.isSubtotalRow ? (
                    <div>{formatNumberToEurCurrency(subTotalsByOrder[params.row.orderId])}</div>
                ) : (
                    <div>{formatNumberToEurCurrency(params.value, 3)}</div>
                )
            ),
            valueFormatter: (params) => {
                return exportFloatFormat(params.value);
            },
            colSpan: (value) => {
                if (value.row.isSubtotalRow) {
                    return 2;
                }
            },
            cellClassName: (params) => {
                return clsx('amount', {
                    subtotalRow: params.row.isSubtotalRow,
                    flexCenter: params.row.isSubtotalRow,
                });
            },
        },
        {
            field: "totalProduct",
            headerName: "Total Produit",
            headerAlign: "center",
            flex: 1,
            align: "right",
            sortComparator: gridNumberComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Total" secondLineText="Produit"/>,
            renderCell: (params) => (
                <div>{formatNumberToEurCurrency(params.value)}</div>
            ),
            valueFormatter: (params) => {
                return exportFloatFormat(params.value);
            },
        },
    ];

    const rowsWithSubtotals = [];
    let currentOrderId = null;

    const rowsWithoutSubTotal = Object.values(cellsTable).map((row, index) => (
        {...row, id: index}
    ));

    rowsWithoutSubTotal.forEach(row => {
        if (currentOrderId !== null && currentOrderId !== row.orderId) {
            const subtotal = subTotalsByOrder[currentOrderId];
            const lastPurveyor = rowsWithoutSubTotal.find(r => r.orderId === currentOrderId).purveyor;

            if (withSubTotal) {
                // Add a middle row for the previous order subtotal
                rowsWithSubtotals.push({
                    orderId: currentOrderId,
                    purveyor: `Sous-total commande ${currentOrderId} de ${lastPurveyor} :`,
                    totalOrdered: formatNumberToEurCurrency(subtotal),
                    isSubtotalRow: true,
                });
            }
        }

        currentOrderId = row.orderId;
        rowsWithSubtotals.push(row);
    });

    if (withSubTotal) {
        // If the last row is not a subtotal row, add the subtotal for the last order
        if (currentOrderId !== null && !rowsWithSubtotals[rowsWithSubtotals.length - 1].isSubtotalRow) {
            const lastPurveyor = rowsWithoutSubTotal.find(r => r.orderId === currentOrderId).purveyor;

            rowsWithSubtotals.push({
                orderId: currentOrderId,
                purveyor: `Sous-total commande: ${currentOrderId} de ${lastPurveyor} :`,
                totalOrdered: formatNumberToEurCurrency(subTotalsByOrder[currentOrderId]),
                isSubtotalRow: true,
            });
        }
    }

    const allRows = rowsWithSubtotals.map((row, index) => ({
        ...row,
        id: index,
    }));

    const rowsWithoutSubTotalToExport = ({apiRef}) => {
        const rows = gridExpandedSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(rowId => {
            const row = apiRef.current.getRow(rowId);

            return !row.isSubtotalRow;
        });

        return filteredRows
    };

    const exportFileName = 'stats_portfolio_details';
    const ignoreDiacritics = true
    const disableRowSelectionOnClick = true
    const styleDataGridCustom = {
        '& .subtotalRow': {
            fontWeight: 'bold',
            backgroundColor: '#e0e0e0',
        },
        '& .flexEnd': {
            justifyContent: 'flex-end',
        },
        '& .flexCenter': {
            justifyContent: 'center',
        },
    }
    const sxDataGrid = {
        ...styleDataGridDefault,
        ...styleDataGridCustom,
    };

    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <SelectComponent id={'purveyor'}
                                                 options={optionsPurveyor}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('purveyor', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <SelectComponent id={'purveyorType'}
                                                 options={optionsPurveyorType}
                                                 handler={handlerFormSearch}
                                                 onChange={(val) => inputSearchChange('purveyorType', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <CheckboxToFilterData
                                    label="Afficher Sous-Total"
                                    checked={withSubTotal}
                                    setterBoolValue={setWithSubTotal}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={1} xl={1}>
                                <ButtonComponent className={classes.buttonActionFirst}
                                                 label={"Rechercher"}
                                                 onClick={() => onSearch()} disabled={disabledStatusButtonSearch}
                                                 loading={loadingContent}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <Box sx={{height: 600, width: '100%'}}>
                <DataGrid
                    sx={sxDataGrid}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={allRows}
                    disableColumnMenu
                    rowHeight={25}
                    slots={{
                        toolbar: () => <CustomToolbar fileName={exportFileName}
                                                      getRowsToExport={rowsWithoutSubTotalToExport}/>,
                        pagination: CustomPagination,
                        footer: () => <FooterWithTotalGeneral totalGeneralValue={totalGeneralValue}/>,
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                orderId: false,
                            },
                        },
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    ignoreDiacritics={ignoreDiacritics}
                    disableRowSelectionOnClick={disableRowSelectionOnClick}
                />
            </Box>

        </ContentViewComponent>
    )
}

export default StatisticBuyPortfolioDetails;
