import React from 'react';
import {GridFooter, GridFooterContainer, GridPanelFooter} from "@mui/x-data-grid";
import {formatNumberToEurCurrency, formatNumberWithThousandsSeparator} from "../../Util/NumberTool";

export default function FooterWithTotalGeneral({totalGeneralValue, label = "Total Général", valueType = "monetary"}) {
    let formattedValue;

    switch (valueType) {
        case "monetary":
            formattedValue = formatNumberToEurCurrency(totalGeneralValue); // Ex: Total Général: 1 077 949,68 €
            break;
        case "float":
        case "integer":
            formattedValue = formatNumberWithThousandsSeparator(totalGeneralValue); // Ex: Total Général: 1 077 949,68 or 1 077 949
            break;
        default:
            formattedValue = totalGeneralValue;
            break;
    }

    return (
        <GridFooterContainer>
            <GridFooter sx={{border: 'none'}}/>
            <GridPanelFooter sx={{backgroundColor: 'yellow', marginRight: '1rem'}}>
                <strong>{label}: {formattedValue}</strong>
            </GridPanelFooter>
        </GridFooterContainer>
    );
}
