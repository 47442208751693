import React from 'react';
import {useDispatch} from "react-redux";
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';

function AdministratorUserForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [optionDeposits, setOptionDeposits] = React.useState([]);
    const [optionChannels, setOptionChannels] = React.useState([]);
    const [optionRegions, setOptionRegions] = React.useState([]);
    const [optionsUserFunction, setOptionsUserFunction] = React.useState([]);

    // Form
    const [form, setForm] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Adresse email de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Mot de passe de l\'utilisateur.',
            type: 'password',
            defaultValue: '',
            options: {
                validation: ['required'],
                min: 6,
                max: 32,
            }
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Nom de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {validation: []}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Prénom de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {validation: []}
        },
        gender: {
            name: 'gender',
            label: 'Genre',
            textHelper: 'Genre de l\'utilisateur.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        deposits: {
            name: 'deposits',
            label: 'Dépôts',
            textHelper: 'Dépôts de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        channels: {
            name: 'channels',
            label: 'Canaux',
            textHelper: 'Canaux de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        regions: {
            name: 'regions',
            label: 'Régions retail',
            textHelper: 'Régions BU de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        userFunction: {
            name: 'userFunction',
            label: 'Fonction utilisateur',
            textHelper: 'Fonction de l\'utilisateur.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Télépone',
            textHelper: '06XXXXXXXXX.',
            type: 'integer',
            defaultValue: '',
            options: {validation: []}
        },
        sellerCode: {
            name: 'sellerCode',
            label: 'Code vendeur',
            type: 'integer',
            defaultValue: '',
            options: {validation: []}
        },
    });
    const handlerForm = initFormHandler(form, setForm);
    React.useEffect(handlerForm.start, []);
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);

            if (params.id === 'add') {
                ApiHandler.post({
                    route: 'api_administrator_user_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('administrator_user', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Utilisateur créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_administrator_user_edit',
                    data: handlerForm.getData(),
                    params: {id: props.user.id}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Utilisateur modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };

    React.useEffect(() => {
        if (props.user) {
            ApiHandler.get({
                route: 'api_administrator_user_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    handlerForm.setDataApi(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [location])
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_deposit'}, (response) => { setOptionDeposits(response.data) });
        ApiHandler.get({route: 'api_select_channel'}, (response) => { setOptionChannels(response.data) });
        ApiHandler.get({route: 'api_select_region'}, (response) => { setOptionRegions(response.data) });
        ApiHandler.get({route: 'api_select_user_function'}, (response) => { setOptionsUserFunction(response.data) });
    }, []);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information utilisateur'}/>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'email'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'password'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <TextFieldComponent id={'lastname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <TextFieldComponent id={'firstname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <SelectComponent id={'gender'} options={[{value: 1, label: 'Femme'}, {value: 2, label: 'Homme'}]} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <MultipleSelectComponent id={'deposits'} handler={handlerForm} options={optionDeposits}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <MultipleSelectComponent id={'channels'} handler={handlerForm} options={optionChannels}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <MultipleSelectComponent id={'regions'} handler={handlerForm} options={optionRegions}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                    <SelectComponent id={'userFunction'} options={optionsUserFunction} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'phone'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'sellerCode'} handler={handlerForm}/>
                </Grid>
            </Grid>

            <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    }
});

export default AdministratorUserForm;
