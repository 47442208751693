import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import {initFormHandler} from "../../../Handler/FormHandler";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {Grid, Slide} from "@mui/material";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import SelectComponent from "../../../Component/SelectComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";
import TableComponent from "../../../Component/TableComponent";
import {hasRight} from "../../../Common/UserCommon";
import ButtonComponent from "../../../Component/ButtonComponent";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {makeStyles} from "@mui/styles";
import clsx from 'clsx';

function StatisticPreparation() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsChannel, setOptionsChannel] = React.useState([]);
    const [optionsUserPreparer, setOptionsUserPreparer] = React.useState([]);
    const [cellsTable, setCellsTable] = React.useState([]);
    const [cellsTableLastYear, setCellsTableLastYear] = React.useState([]);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = React.useState(false);
    const classes = useStyles();

    const columns = [
        {
            name: "userPreparerName",
            label: "Préparateur",
            options: {filter: true, sort: true},
        },
        {
            name: "hours",
            label: "Heures affectées",
            options: {filter: false, sort: false,setCellHeaderProps: () => { return { align:"center"} },}
        },
        {
            name: "commands",
            label: "Commandes préparées",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => { return { align:"center"} },
            }
        },
        {
            name: "commandsPerHour",
            label: "Commandes par heure",
            options: {filter: false, sort: false,setCellHeaderProps: () => { return { align:"center"} },}
        },
        {
            name: "numberOfProducts",
            label: "Produits préparés",
            options: {filter: false, sort: false,setCellHeaderProps: () => { return { align:"center"} },}
        },
    ]

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        fromStartAt: {
            name: 'fromStartAt',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_preparations'] && cacheTableServer['api_statistic_preparations'].fromStartAt ? cacheTableServer['api_statistic_preparations'].fromStartAt : '',
            options: {validation: ['date']}
        },
        fromEndAt: {
            name: 'fromEndAt',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: cacheTableServer['api_statistic_preparations'] && cacheTableServer['api_statistic_preparations'].fromEndAt ? cacheTableServer['api_statistic_preparations'].fromEndAt : '',
            options: {validation: ['date']}
        },
        channel: {
            name: 'channel',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'integer',
            defaultValue: cacheTableServer['api_statistic_preparations'] && cacheTableServer['api_statistic_preparations'].channel ? cacheTableServer['api_statistic_preparations'].channel : 0,
            options: {}
        },
        userPreparers: {
            name: 'userPreparers',
            label: 'Préparateur',
            textHelper: 'Choisissez un préparateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
    }

    const downloadAction = () => {
        let datas = handlerFormSearch.getData();
        let params = {
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
            userPreparers: (datas.userPreparers === 0 || !datas.userPreparers) ? '' : datas.userPreparers.join("|")
        }
        let route = ApiHandler.route({route: 'api_statistic_preparations_download', data: params});

        fetch(route,  {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
            .then(res => res.blob())
            .then(res => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', 'stats_preparateurs.xls');
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    }

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();
        let filters = {
            fromStartAt: formSearch.fromStartAt.value ? formSearch.fromStartAt.value : '',
            fromEndAt: formSearch.fromEndAt.value ? formSearch.fromEndAt.value : '',
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
            userPreparers: (datas.userPreparers === 0 || !datas.userPreparers) ? '' : datas.userPreparers.join("|")
        };

        ApiHandler.get({
                route: 'api_statistic_preparations',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    setCellsTable(response.data.data);

                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('api_statistic_preparations'));
                }
                else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            }
        );
    };

    const onSearchLastYear = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        var today = new Date(formSearch.fromStartAt.value);
        var curyear = today.getFullYear();
        var curyearMonth = today.getMonth() + 1;
        var curyearDay = today.getDate();
        var lastYear = curyear - 1;
        if ((curyearMonth == 2) && (curyearDay == 29)) {
            curyearDay = 28;
        }
        var lastYearStart = ("0000" + lastYear.toString()).slice(-4) + "-" + ("00" + curyearMonth.toString()).slice(-2) + "-" + ("00" + curyearDay.toString()).slice(-2);

        var today = new Date(formSearch.fromEndAt.value);
        var curyear = today.getFullYear();
        var curyearMonth = today.getMonth() + 1;
        var curyearDay = today.getDate();
        var lastYear = curyear - 1;
        if ((curyearMonth == 2) && (curyearDay == 29)) {
            curyearDay = 28;
        }
        var lastYearEnd = ("0000" + lastYear.toString()).slice(-4) + "-" + ("00" + curyearMonth.toString()).slice(-2) + "-" + ("00" + curyearDay.toString()).slice(-2);

        let datas = handlerFormSearch.getData();
        let filters = {
            fromStartAt: lastYearStart,
            fromEndAt: lastYearEnd,
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
            userPreparers: (datas.userPreparers === 0 || !datas.userPreparers) ? '' : datas.userPreparers.join("|")
        };

        ApiHandler.get({
                route: 'api_statistic_preparations',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    setCellsTableLastYear(response.data.data);

                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('api_statistic_preparations_lastyear'));
                }
                else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            }
        );
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_channel', data: {'limited': true}}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsChannel(data);
        });

        ApiHandler.get({route: 'api_select_user_preparer'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsUserPreparer(data);
        });

        setBreadcrumbs({
            title: 'Stats préparateurs',
            context: 'Statistiques',
            description: '',
            links: []
        });

        setLoadingContent(false);
    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromStartAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromStartAt', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <DatePickerComponent id={'fromEndAt'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('fromEndAt', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <SelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} onChange={(val) => inputSearchChange('channel', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <MultipleSelectComponent id={'userPreparers'} options={optionsUserPreparer} handler={handlerFormSearch} onChange={(val) => inputSearchChange('userPreparers', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                <ButtonComponent className={classes.buttonActionFirst} label={"Rechercher"} onClick={() => onSearch()} disabled={disabledStatusButtonSearch} loading={loadingContent}/>
                                <ButtonComponent className={classes.buttonActionFirst} label={"Voir n-1"} onClick={() => onSearchLastYear()} disabled={disabledStatusButtonSearch} loading={loadingContent}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_statistic_preparations'}
                title={'Stats préparateurs'}
                columns={columns}
                search={false}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_PREPARER_MANAGER')) ?
                        {
                            label: 'Télécharger un CSV',
                            onClick: () => downloadAction(),
                        } : false
                }
                promiseData={(resolve) => {
                        let data = cellsTable;
                        for (let index in data) {
                            data[index].userPreparerName = data[index].userPreparerName ?? '-';
                            data[index].hours = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].hours}</span> ?? '-';
                            data[index].commands = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].commands}</span> ?? '-';
                            data[index].commandsPerHour = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].commandsPerHour}</span> ?? '-';
                            data[index].numberOfProducts = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].numberOfProducts}</span> ?? '-';
                        }
                        resolve(data);
                }}
            />
            <hr/>
            <ShadowBoxComponent className={classes.shadowBox}>
                <TableComponent
                    id={'api_statistic_preparations_lastyear'}
                    title={'Stats n-1'}
                    columns={columns}
                    search={false}
                    promiseData={(resolve) => {
                        let data = cellsTableLastYear;
                        for (let index in data) {
                            data[index].userPreparerName = data[index].userPreparerName ?? '-';
                            data[index].hours = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].hours}</span> ?? '-';
                            data[index].commands = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].commands}</span> ?? '-';
                            data[index].commandsPerHour = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].commandsPerHour}</span> ?? '-';
                            data[index].numberOfProducts = <span style={{display: 'flex',justifyContent: 'end'}}>{data[index].numberOfProducts}</span> ?? '-';
                        }
                        resolve(data);
                    }}
                />
            </ShadowBoxComponent>
            <br/><br/>
        </ContentViewComponent>
    )
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60,
        paddingTop: 60
    },
    button: {
        margin: '15px 15px 15px 0 !important',
    },
    buttonTop: {
        margin: '15px !important',
        top: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    '& .numbersTD': {
        color: 'red',
        fontSize: '30px',
        align: 'right'
    }
});

export default StatisticPreparation;
