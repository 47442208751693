import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ButtonComponent from "../../../Component/ButtonComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {useNavigate, useParams} from "react-router-dom";
import {initFormHandler} from "../../../Handler/FormHandler";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {dispatch} from "../../../App";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import {LocalShipping} from '@mui/icons-material';
import InputFileComponent from "../../../Component/InputFileComponent";
import TitleComponent from "../../../Component/TitleComponent";
import moment from "moment";
import {isNumber} from "../../../Util/NumberTool";

function InventoryManagementReceiptOrderPurveyor() {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const [reload, setReload] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogText, setOpenDialogText] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [orderPurveyor, setOrderPurveyor] = React.useState({});
    const [inputs, setInputs] = React.useState({});

    // Form
    let handlerForm = initFormHandler(inputs, setInputs);
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Réceptions commande fournisseur',
            context: 'Gestion des stocks',
            description: '',
            links: [
                {path: getRoutePathname('inventory_management_receipt_order_purveyor_list'), label: 'Liste des réceptions commande fournisseur'}
            ]
        });

        ApiHandler.get({
                route: 'api_inventory_management_receipt_order_purveyor_get',
                params: {id: params.id}
            },
            (response) => {
                let data = response.data;

                let byProducts = {};
                for (let index in response.data['byProducts']) {
                    if ((orderPurveyor && orderPurveyor.state >= 4) || response.data['byProducts'][index].closedAt) continue;
                    byProducts['amount_' + response.data['byProducts'][index].id] = {
                        name: 'amount_' + response.data['byProducts'][index].id,
                        label: 'Quantité reçu',
                        textHelper: 'Saisissez la quantité reçu de l\'article. (' + data.deliveryQuantityVariation + '% tolérance)',
                        type: 'integer',
                        defaultValue: response.data['byProducts'][index].amount - response.data['byProducts'][index].receipt,
                        value: response.data['byProducts'][index].amount - response.data['byProducts'][index].receipt,
                        options: {min: 0}
                    };

                    byProducts['batch_' + response.data['byProducts'][index].id] = {
                        name: 'batch_' + response.data['byProducts'][index].id,
                        label: 'Lot',
                        textHelper: 'Saisissez le lot des articles.',
                        type: 'text',
                        defaultValue: '',
                        value: '',
                        options: {}
                    };

                    byProducts['partiel_' + response.data['byProducts'][index].id] = {
                        name: 'partiel_' + response.data['byProducts'][index].id,
                        label: 'Réception partielle',
                        textHelper: '',
                        type: 'boolean',
                        defaultValue: false,
                        value: false,
                        options: {}
                    };
                }

                setInputs(byProducts);
                setOrderPurveyor(data);
                setLoading(false);
            });
    }, [reload])

    const save = (withConfirm, orderComplete = true) => {
        if (handlerForm.checkError() < 1) {

            let hasError = false;
            let lines = handlerForm.getData();
            for (let index in orderPurveyor.byProducts) {
                let unconfirmed = (!orderComplete && lines['batch_' + orderPurveyor.byProducts[index].id] === '')
                if (!unconfirmed) {
                    orderPurveyor.byProducts[index].received = lines['amount_' + orderPurveyor.byProducts[index].id];
                    orderPurveyor.byProducts[index].batch = lines['batch_' + orderPurveyor.byProducts[index].id];
                    orderPurveyor.byProducts[index].partiel = lines['partiel_' + orderPurveyor.byProducts[index].id];


                    if (orderPurveyor.byProducts[index].received > 0 && !orderPurveyor.byProducts[index].batch) {
                        handlerForm.setError('batch_' + orderPurveyor.byProducts[index].id, 'Ce champ est obligatoire.');
                        hasError = true;
                    }

                    if (orderPurveyor.byProducts[index].received < 0) {
                        handlerForm.setError('amount_' + orderPurveyor.byProducts[index].id, 'Ce champ doit être supérieur ou égal à 0.');
                        hasError = true;
                    }
                } else {
                    orderPurveyor.byProducts[index].received = 0;
                    orderPurveyor.byProducts[index].batch = '';
                    orderPurveyor.byProducts[index].partiel = true;
                }
            }

            if (hasError) return;
            if (withConfirm) {
                setOpenDialog(true);

                let text = <div>
                    <p style={{fontWeight: 800}}>Récapitulatif de la réception commande : </p>
                    {
                        orderPurveyor.byProducts.map((byProduct, key) => {
                            let info;
                            let state;
                            let min = byProduct.amount * (1 - orderPurveyor.deliveryQuantityVariation / 100) - byProduct.receipt;
                            let max = byProduct.amount * (1 + orderPurveyor.deliveryQuantityVariation / 100) - byProduct.receipt;

                            if (byProduct.closedAt) {
                                info = '...réception déjà terminée';
                                state = 'receiptClosed';
                            }
                            else if (byProduct.partiel && inputs['amount_' + byProduct.id].value < (byProduct.amount - byProduct.receipt)) {
                                info = '...réception partielle';
                                state = 'receiptPartial';
                            }
                            else if (min > byProduct.received || max < byProduct.received) {
                                info = '...réception en litige';
                                state = 'receiptConflit';
                            }
                            else {
                                info = '...réception complète';
                                state = 'receiptComplete';
                            }

                            return <div key={key}>
                                {byProduct.name} <span className={classes[state]}> {info}.</span>
                            </div>;
                        })
                    }
                </div>

                setOpenDialogText(text);
                return;
            }

            handlerForm.setFormLoading(true);
            setLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_inventory_management_receipt_order_purveyor_edit',
                data: {byProducts: orderPurveyor.byProducts},
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setReload(!reload);
                    setOpenDialog(false);
                    setOpenDialogText('');
                    navigate(getRoutePathname('inventory_management_receipt_order_purveyor_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerForm.setFormLoading(false);
                setLoadingForm(false);
            });
        }
    };

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {(orderPurveyor) && <div style={{textAlign: 'center', marginBottom: 20}}>
                <ShadowBoxComponent>
                    <TitleComponent title={'Réception commande fournisseur'}/>
                    {(orderPurveyor && orderPurveyor.stateText) && <span className={classes.state}>{orderPurveyor.stateText}</span>}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <InputFileComponent
                                urlDownload={ApiHandler.route({route: 'api_inventory_management_receipt_purveyor_bc_download', params: {id: params.id}})}
                                urlUpload={''}
                                cantUpload={false}
                                cantDownload={true}
                                namePDF={'BC_PURVEYOR_' + params.id + '.pdf'}
                                defaultPDF={(orderPurveyor && orderPurveyor.BCUploadAt) ? ApiHandler.route({route: 'api_inventory_management_receipt_purveyor_bc_download', params: {id: params.id}}) : ''}
                                label={'Bon de commande'}
                                csvUpload={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div style={{position: 'relative', height: '100%', minHeight: 50}}>
                                <div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', textAlign: 'center', width: '100%'}}>
                                    <span style={{verticalAlign: 'super', padding: '0 25px'}}>{orderPurveyor.purveyor}</span>
                                    <LocalShipping style={{color: '#28a745'}}/>
                                    <span style={{verticalAlign: 'super', padding: '0 25px'}}>{orderPurveyor.deposit}</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <InputFileComponent
                                urlDownload={ApiHandler.route({route: 'api_inventory_management_receipt_purveyor_bl_download', params: {id: params.id}})}
                                urlUpload={ApiHandler.route({route: 'api_inventory_management_receipt_purveyor_bl_upload', params: {id: params.id}})}
                                cantUpload={((orderPurveyor && orderPurveyor.state < 4 )|| !orderPurveyor.BLUploadAt)}
                                cantDownload={true}
                                onUpload={() => {
                                    orderPurveyor.BLUploadAt = moment().format('YYYY-MM-DD');
                                    setOrderPurveyor({...orderPurveyor});
                                }}
                                namePDF={'BL_PURVEYOR_' + params.id + '.pdf'}
                                defaultPDF={(orderPurveyor && orderPurveyor.BLUploadAt) ? ApiHandler.route({route: 'api_inventory_management_receipt_purveyor_bl_download', params: {id: params.id}}) : ''}
                                label={'Bon de livraison'}
                                csvUpload={false}
                            />
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </div>}

            <Grid container spacing={2}>
                {
                    (orderPurveyor && orderPurveyor.byProducts) && orderPurveyor.byProducts.map((byProduct, key) => {

                        let withInputs = (orderPurveyor && orderPurveyor.state < 4) && !byProduct.closedAt;
                        let min = 0;
                        let max = byProduct.amount;
                        let a = false;
                        let p = false;

                        if (withInputs) {
                            min = byProduct.amount * (1 - orderPurveyor.deliveryQuantityVariation / 100) - byProduct.receipt;
                            max = byProduct.amount * (1 + orderPurveyor.deliveryQuantityVariation / 100) - byProduct.receipt;
                            a = inputs['amount_' + byProduct.id].value;
                            p = inputs['partiel_' + byProduct.id].value && inputs['amount_' + byProduct.id].value < (byProduct.amount - byProduct.receipt);
                        }

                        return <Grid key={key} item xs={12} sm={12} md={12}>
                            <ShadowBoxComponent style={{display: 'flex', flexDirection: 'row'}} className={p ? classes.boxShadowWarning : (((a && min > a) || (a && max < a) || a === 0) ? classes.boxShadowError : '')}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4} md={withInputs ? 2 : 3}>
                                        <div style={{position: 'relative', height: '100%', width: '100%', minHeight: 56}}>
                                            <div style={{margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: 12}}>
                                                {byProduct.sku}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={withInputs ? 2 : 3}>
                                        <div style={{position: 'relative', height: '100%', width: '100%', minHeight: 56}}>
                                            <div style={{margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: 12}}>
                                                {byProduct.name}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={withInputs ? 2 : 3}>
                                        <div style={{position: 'relative', height: '100%', width: '100%', minHeight: 56}}>
                                            <div style={{margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: 12}}>
                                                <span style={{fontWeight: 700}}>Quantité attendue</span> : {isNumber((byProduct.amount - byProduct.receipt)) ? (byProduct.amount - byProduct.receipt).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} <br/>
                                                <span style={{fontWeight: 700}}>Quantité Reçue</span> : {isNumber((byProduct.receipt ?? 0)) ? (byProduct.receipt ?? 0).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'}
                                            </div>
                                        </div>
                                    </Grid>
                                    {
                                        withInputs ? <>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <CheckboxComponent style={inputs['amount_' + byProduct.id].value >= (byProduct.amount - byProduct.receipt) ? {display: 'none'} : {}} orientation={'left'} id={'partiel_' + byProduct.id} handler={handlerForm} disabled={inputs['amount_' + byProduct.id].value >= (byProduct.amount - byProduct.receipt)} />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextFieldComponent id={'amount_' + byProduct.id} handler={handlerForm}/>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextFieldComponent id={'batch_' + byProduct.id} handler={handlerForm}/>
                                            </Grid>
                                        </> : <Grid item xs={12} sm={4} md={withInputs ? 2 : 3}>
                                            <div style={{position: 'relative', height: '100%', width: '100%', minHeight: 56}}>
                                                <div style={{margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: 12}}>
                                                    Réceptionné le {byProduct.closedAt} {((byProduct.amount * (1 - orderPurveyor.deliveryQuantityVariation / 100)) > byProduct.receipt && orderPurveyor.state !== 5) ? <span className={classes.receiptConflit}>(En litige)</span> : ''}
                                                </div>
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>;
                    })
                }
            </Grid>

            {(orderPurveyor && orderPurveyor.state < 4) && <div style={{padding: '20px 0', height: 60}}>
                <ButtonComponent onClick={() => save(true, true)} label={'Valider'} className={classes.button} loading={loadingForm} disabled={!orderPurveyor.BLUploadAt} color={!orderPurveyor.BLUploadAt ? 'rgba(132,132,132,0.48)' : ''}/>
                <ButtonComponent onClick={() => save(false, false)} label={'Enregistrer (partiel)'} color={'#5E6E82'} className={classes.button} loading={loadingForm}/>
            </div>}

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{'Confirmation réception'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    {openDialogText}
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => {
                        save(false);
                    }}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45
    },
    button: {
        float: 'right'
    },
    boxShadowError: {
        boxShadow: '0 2px 10px 0px #982525',
    },
    boxShadowWarning: {
        boxShadow: '0 2px 10px 0px #a47b00',
    },
    receiptPartial: {
        color: '#a47b00'
    },
    receiptConflit: {
        color: '#982525'
    },
    receiptComplete: {
        color: '#28a745'
    },
    receiptClosed: {
        color: '#35A2EB'
    },
    state: {
        backgroundColor: '#9c27b0',
        color: '#ffffff',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default InventoryManagementReceiptOrderPurveyor;
