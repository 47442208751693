import {useDispatch, useSelector} from "react-redux";
import React from "react";
import TableComponent from "../../../Component/TableComponent";
import {hasRight} from "../../../Common/UserCommon";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {Article, Block, Contactless, Contacts, Edit} from "@mui/icons-material";
import {TableReload} from "../../../Action/TableAction";
import InputFileComponent from "../../../Component/InputFileComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {isNumber} from "../../../Util/NumberTool";

function ClientBusinessList() {
    const dispatch = useDispatch();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loading, setLoading] = React.useState(true);

    const columns = [
        {
            name: "socialName",
            label: "Raison social",
            options: {filter: true, sort: true}
        },
        {
            name: "siren",
            label: "Siren",
            options: {filter: true, sort: true}
        },
        {
            name: "address",
            label: "Adresse",
            options: {filter: true, sort: true}
        },
        {
            name: "zipCode",
            label: "CP",
            options: {filter: true, sort: true}
        },
        {
            name: "city",
            label: "Ville",
            options: {filter: true, sort: true}
        },
        {
            name: "region",
            label: "Région",
            options: {filter: true, sort: true}
        },
        {
            name: "country",
            label: "Pays",
            options: {filter: true, sort: true}
        },
        {
            name: "website",
            label: "Site web",
            options: {filter: true, sort: true}
        },
        {
            name: "totalCredit",
            label: "En cours crédit",
            options: {filter: true, sort: true}
        },
        {
            name: "caThisYear",
            label: "CA Année en cours",
            options: {filter: true, sort: true}
        },
        {
            name: "caLastYear",
            label: "CA année précédente",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Liste des business',
            context: 'Gestion business',
            description: '',
            links: []
        });

        setLoading(false);
    }, [])

    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') ||
            hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET'))) {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <Tooltip title={'Modifier'} placement="left">
                                <Link to={getRoutePathname('b2b_client_business', {id: row.id})}>
                                    <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                                </Link>
                            </Tooltip> : (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET')) ?
                                <Tooltip title={'Visualiser'} placement="left">
                                    <Link to={getRoutePathname('b2b_client_business', {id: row.id})}>
                                        <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                                    </Link>
                                </Tooltip> : <IconButton disabled={true}><Edit style={{color: '#17a2b8'}}/></IconButton>
                    }
                    <Tooltip title={'Premier contact ?'} placement="left">
                        <IconButton disabled={row.firstContact} onClick={() => {
                            ApiHandler.get({route: 'api_b2b_client_business_first_contact', params: {id: row.id}}, (response) => {
                                if (response.status === 200) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Modification enregistré.',
                                            variant: 'success',
                                        })
                                    );
                                    dispatch(TableReload('api_b2b_client_business_list'))
                                }
                                else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                        }}><Contactless style={{color: row.firstContact ? '' : '#9c27b0'}}/></IconButton>
                    </Tooltip>
                    <Tooltip title={'Archiver'} placement="left">
                        <IconButton onClick={() => {
                            ApiHandler.get({route: 'api_b2b_client_business_archive', params: {id: row.id}}, (response) => {
                                if (response.status === 200) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Modification enregistré.',
                                            variant: 'success',
                                        })
                                    );
                                    dispatch(TableReload('api_b2b_client_business_list'))
                                }
                                else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                        }}><Block style={{color: row.archive ? '' : 'red'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Edit/>
                            </IconButton> :
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Article/>
                            </IconButton>
                    }
                </>
            );
        }
    };

    //IMPORT
    const [showImport, setShowImport] = React.useState(false);
    const [isLoadingImport, setIsLoadingImport] = React.useState(false);

    //RELOAD DATA AFTER IMPORT
    React.useEffect(() => {
        if(!showImport) dispatch(TableReload('api_b2b_client_business_list'))
    }, [showImport]);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_b2b_client_business_list'}
                title={'Business'}
                columns={columns}
                actionFirst={hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD') ? {
                    label: 'Ajouter un business',
                    link: getRoutePathname('b2b_client_business', {id: 'add'})
                } : false}
                actionSecond={
                    (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                        {label: 'Importer', onClick: () => setShowImport(true)} : false
                }
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_b2b_client_business_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].socialName = data[index].socialName ? data[index].socialName : '-';
                                data[index].siren = data[index].siren ? data[index].siren : '-';
                                data[index].address = data[index].address ? data[index].address : '-';
                                data[index].city = data[index].city ? data[index].city : '-';
                                data[index].department = data[index].department ? data[index].department : '-';
                                data[index].region = data[index].region ? data[index].region : '-';
                                data[index].country = data[index].countryDTO ? (data[index].countryDTO.label ? data[index].countryDTO.label : '-') : '-';
                                data[index].totalCredit = isNumber(data[index].totalCredit) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].totalCredit).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].caThisYear = isNumber(data[index].caThisYear) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].caThisYear).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                data[index].caLastYear = isNumber(data[index].caLastYear) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].caLastYear).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                           }
                            resolve(data);
                        });
                }}
            />
            <br/><br/>

            <Dialog open={showImport} maxWidth={'xl'} onClose={() => setShowImport(false)}>
                <DialogTitle style={{fontSize: 15}}>Importer un CSV</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputFileComponent
                                urlUpload={ApiHandler.route({route: 'api_b2b_client_business_upload', params: {}})}
                                label={'Fichier CSV'}
                                csvUpload={true}
                                setShowModal={setShowImport}
                                setIsLoadingButton={setIsLoadingImport}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowImport(false)} loading={isLoadingImport}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>

    );
}


export default ClientBusinessList;
