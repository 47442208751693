import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Article} from "@mui/icons-material";
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import {initFormHandler} from '../../../Handler/FormHandler';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import moment from "moment";
import {hasRight} from "../../../Common/UserCommon";
import {useSelector} from "react-redux";

function SalePromoList() {
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "code",
            label: "Code",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Nom promo",
            options: {filter: true, sort: true}
        },
        {
            name: "startAt",
            label: "Date début",
            options: {filter: true, sort: true}
        },
        {
            name: "endAt",
            label: "Date fin",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const [formSearch, setFormSearch] = React.useState({
        code: {
            name: 'code',
            label: 'Code',
            textHelper: 'Rechercher par code promo.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        label: {
            name: 'label',
            label: 'Nom promo',
            textHelper: 'Rechercher par nom promo.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        dispatch(TableReload('api_sale_promo_list'))
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <Link to={getRoutePathname('sale_promo', {id: row.id})}>
                        <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    };
    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des promos',
            context: 'Vente',
            description: '',
            links: []
        });
        setLoadingContent(false);
    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <TextFieldComponent id={'code'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('code', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <TextFieldComponent id={'label'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('label', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_sale_promo_list'}
                title={'Promotions'}
                columns={columns}
                search={false}
                actionFirst={(
                    hasRight(authenticationReducer, 'ROLE_SALE_PROMO') ||
                    hasRight(authenticationReducer, 'ROLE_SALE_PROMO_GET')
                ) ? {
                    label: 'Ajouter un code promo',
                    link: getRoutePathname('sale_promo_add')
                } : false}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        code: formSearch.code.value ? formSearch.code.value : '',
                        label: formSearch.label.value ? formSearch.label.value : '',
                    };

                    ApiHandler.get({
                            route: 'api_sale_promo_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;

                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].startAt = moment(data[index].startAt).format('lll');
                                data[index].endAt = moment(data[index].endAt).format('lll');
                                data[index].code = data[index].code ? data[index].code : '-';
                                data[index].label = data[index].label ? data[index].label : '-';
                            }
                            resolve(data, response.data.count);
                        });
                }}
            />
            <br/><br/>
        </ContentViewComponent>
    );
}

export default SalePromoList;
