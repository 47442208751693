import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import MUIDataTable from "mui-datatables";
import {
    CircularProgress,
    Tooltip,
    IconButton,
    Fade,
} from "@mui/material";
import {Refresh} from '@mui/icons-material';
import {
    cacheTableData,
    cacheTableFilter,
    cacheTablePage,
    cacheTableServer,
    cacheTableOrderBy,
    cacheTableRowsPerPage
} from "../Cache";
import ButtonComponent from "./ButtonComponent";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../Action/LinearProgressMainLayoutAction";
import {Link} from "react-router-dom";

function TableComponent(props = {
    title: '',
    id: '',
    columns: [],
    promiseData: null,
    promiseServerData: null,
    height: '',
    noCache: false,
    actionFirst: {},
    actionSecond: {},
    actionThird: {},
    sortOrder: {},
    actionSelection: {},
    onBeforeRefresh: false,
    search: true
}) {
    const dispatch = useDispatch();
    const classes = useStyles(props.height, props.minHeight, props.small, props.noShadow, props.margin)();
    const reloadTable = useSelector(state => state.TableReducer);

    const [data, setData] = React.useState(cacheTableData[props.id] ?? []);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const loadData = () => {
        if (props.promiseData) {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            props.promiseData((data) => {
                setData([...data]);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (!props.noCache) cacheTableData[props.id] = [...data];
            });
        } else if (props.promiseServerData) {
            onTable('reload');
        }
    };
    const onTable = (action = '', tableState = {}) => {
        if (props.promiseServerData) {
            if (action === 'tableInitialized') {
                if (!cacheTableServer[props.id]) {
                    cacheTableServer[props.id] = {
                        rowsPerPage: tableState.rowsPerPage,
                        page: tableState.page,
                        searchText: '',
                        sortOrder: tableState.sortOrder ? {
                            name: tableState.sortOrder.name,
                            direction: tableState.sortOrder.direction
                        } : {},
                    };
                } else {
                    cacheTableServer[props.id].searchText = '';
                }
            }

            if (
                action === 'reload' ||
                action === 'search' ||
                action === 'sort' ||
                action === 'filterChange' ||
                action === 'changePage' ||
                action === 'changeRowsPerPage'
            ) {
                if (action !== 'reload') {
                    cacheTableServer[props.id]['rowsPerPage'] = tableState.rowsPerPage;
                    cacheTableServer[props.id]['page'] = tableState.page;
                    cacheTableServer[props.id]['searchText'] = tableState.searchText;
                    cacheTableServer[props.id]['sortOrder'] = tableState.sortOrder ? {
                        name: tableState.sortOrder.name,
                        direction: tableState.sortOrder.direction
                    } : {};
                }

                setLoading(true);
                dispatch(LinearProgressMainLayoutActivate());
                props.promiseServerData((data, count) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    setData([...data]);
                    setCount(parseInt(count, 10));
                }, cacheTableServer[props.id]);
            }
        }
    };

    const options = {
        responsive: 'standard',

        // Action
        download: false,
        search: props.search !== false,
        filter: false,
        print: false,
        customToolbar: () => {
            return (
                <>
                    {!props.noReload && <Tooltip title={"Recharger la liste"}>
                        <IconButton onClick={() => {
                            if (props.onBeforeRefresh) props.onBeforeRefresh();
                            loadData();
                        }}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>}
                    {props.actionThird && <ButtonComponent color={'#28a745'} className={classes.buttonActionThird}
                                                           label={props.actionThird.label}
                                                           onClick={props.actionThird.onClick}/>}
                    {
                        props.actionSecond && <ButtonComponent
                            loading={props.actionSecond.loading}
                            color={props.actionSecond.color ?? '#35A2EB'}
                            className={classes.buttonActionSecond}
                            outlined={props.actionSecond.outlined}
                            label={props.actionSecond.label}
                            onClick={props.actionSecond.onClick}
                            disabled={props.actionSecond.disabled}
                        />
                    }
                    {
                        props.actionFirst && (
                            props.actionFirst.link ?
                                <Link to={props.actionFirst.link}><ButtonComponent className={classes.buttonActionFirst}
                                                                                   label={props.actionFirst.label}/></Link> :
                                <ButtonComponent className={classes.buttonActionFirst} label={props.actionFirst.label}
                                                 onClick={props.actionFirst.onClick}/>
                        )
                    }
                </>
            );
        },

        // Traduction
        textLabels: {
            body: {
                noMatch: loading ? 'Chargement...' : 'Aucun résultat.',
                toolTip: "Trier",
                columnHeaderTooltip: column => `Trier par ${column.label}`
            },
            pagination: {
                next: "Page suivante",
                previous: "Page précédente",
                rowsPerPage: "Ligne par page:",
                displayRows: "sur"
            },
            toolbar: {
                search: "Recherche",
                downloadCsv: "Télécharger CSV",
                print: "Imprimer",
                viewColumns: "Colonnes",
                filterTable: "Filtre"
            },
            viewColumns: {
                title: "Affichage des colonnes",
                titleAria: "Afficher/Cacher colonne"
            },
            selectedRows: {
                text: "ligne(s) sélectionnée(s)",
                delete: "Supprimer"
            },
            filter: {
                all: "Tous",
                title: "FILTRES",
                reset: "RESET"
            }
        },

        // Cache
        onFilterChange: (column, list) => {
            cacheTableFilter[props.id] = list;
        },
        onChangePage: (number) => {
            cacheTablePage[props.id] = number;
        },
        onColumnSortChange: (changedColumn, direction) => {
            cacheTableOrderBy[props.id] = {name: changedColumn, direction: direction};
        },
        onChangeRowsPerPage: (numberOfRows) => {
            cacheTableRowsPerPage[props.id] = numberOfRows;
        },
        sortOrder: cacheTableOrderBy[props.id] ?? {name: 'id', direction: 'desc'},
        page: cacheTablePage[props.id] ?? 0,
        rowsPerPage: cacheTableRowsPerPage[props.id] ?? 25,
        rowsPerPageOptions: [10, 25, 50, 100],

        // Select
        selectableRowsHeader: !!props.actionSelection,
        selectableRowsHideCheckboxes: false,
        selectableRowsOnClick: false,
        selectableRows: (props.actionSelection) ? 'multiple' : 'none',
        customToolbarSelect: () => {
            return <>
                {props.actionSelection &&
                    <ButtonComponent className={classes.buttonSelection} label={props.actionSelection.label}
                                     onClick={props.actionSelection.onClick}/>}
            </>;
        },
        onRowsDelete: false,
        isRowSelectable: () => {
            return true;
        },
        rowsSelected: [],

        // Server
        count: count,
        serverSide: !!props.promiseServerData,
        onTableInit: onTable,
        onTableChange: onTable
    };

    React.useEffect(loadData, []);
    React.useEffect(() => {
        if (reloadTable.id === props.id) loadData();
    }, [reloadTable]);

    if (cacheTableFilter[props.id]) {
        for (let index in props.columns) {
            props.columns[index].options.filterList = cacheTableFilter[props.id][index];
        }
    }

    return (
        <Fade in={true} {...{timeout: 1000}}>
            <div style={{height: '100%'}}>
                <MUIDataTable
                    style={props.style ? props.style : {}}
                    className={classes.MUIDataTable}
                    data={data}
                    columns={props.columns}
                    options={options}
                    title={
                        props.loading ||
                        (loading && !props.hideLoading) ?
                            <><CircularProgress size={20} className={classes.circularProgress}/>
                                <span className={classes.span}>Mise à jour des données.</span>
                            </> :
                            props.title ? <span className={classes.title}>{props.title}</span> : ''
                    }

                    muiTablePaginationProps={{
                        showFirstButton: true,
                        showLastButton: true,
                    }}
                />
            </div>
        </Fade>
    )
}

const useStyles = (height, minHeight, small, noShadow, margin) => makeStyles({
    MUIDataTable: {
        boxShadow: noShadow ? 'none !important' : 'none',
        border: noShadow ? '1px solid rgba(0,0,0,0.10)' : 0,
        height: '100%',
        margin: margin ? margin : 0,
        position: 'relative',
        paddingBottom: 37,
        boxSizing: 'border-box',
        '& .MuiToolbar-root': {
            minHeight: 35
        },
        '& .tss-1cdcmys-MUIDataTable-responsiveBase': {
            maxHeight: '540px',
        },
        '& td': small ? {
            paddingTop: 0,
            paddingBottom: 0
        } : {height: 27},
        '& th': {
            textTransform: 'uppercase !important',
            '& button': {
                textTransform: 'uppercase !important',
            },
        },
        '& > div:nth-child(3)': {
            minHeight: height ? height + ' !important' : (minHeight ? minHeight : ''),
            maxHeight: height ? height + ' !important' : '',
        },
        '& .MuiTableCell-root': {
            padding: '0px 10px',
            fontSize: 11,
            '& div': {
                fontSize: 11
            }
        },
        '& .MuiTableCell-head': {
            fontWeight: 600,
            zIndex: 1,
        },
        '& .MuiTableHead-root': {
            height: 45,
        },
        '& .MuiSvgIcon-root': {
            width: 16,
            height: 16
        },
        '& p': {
            fontSize: 11
        },
        '& .MuiSelect-root': {
            fontSize: 11
        },
        '& .MuiTableFooter-root': {
            '& td': {
                padding: 0
            }
        },
        '& > table': {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            left: 0
        }
    },
    circularProgress: {
        margin: 10,
        color: '#57A585'
    },
    title: {
        margin: 10,
        color: '#5E6E82',
        fontSize: 14,
    },
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionThird: {
        margin: '10px -8px 10px 10px !important',
        fontSize: '10px !important'
    },
    buttonSelection: {
        margin: '10px 5px',
        marginRight: 14
    }
});

export default TableComponent;
