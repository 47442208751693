import React from 'react';
import {makeStyles} from "@mui/styles";
import Drawer from "./Component/Drawer";
import AppBar from "./Component/AppBar";

function MainLayout(props) {
    const classes = useStyles();

    return (
        <div className={classes.mainLayout}>

            {/* <== AppBar */}
            <AppBar/>
            {/* AppBar ==> */}

            {/* <== Drawer */}
            <Drawer/>
            {/* Drawer ==> */}

            {/* <== View */}
            <div className={classes.view}>
                <props.view/>
            </div>
            {/* View ==> */}
        </div>
    );
}

const useStyles = makeStyles({
    mainLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex'
    },
    view: {
        flexGrow: 1,
        overflowX: 'scroll',
        paddingTop: 58,
        boxSizing: 'border-box'
    }
});

export default MainLayout;
