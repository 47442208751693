import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import {Grid, Slide} from '@mui/material';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import moment from "moment";

function AdministratorRequestAsyncList() {
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsIsSuccess] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: 'true', label: 'Succès'},
        {value: 'false', label: 'Échec'}
    ]);
    const moveColumns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        },
        {
            name: "method",
            label: "Méthode",
            options: {filter: true, sort: true}
        },
        {
            name: "url",
            label: "Url",
            options: {filter: true, sort: true}
        },
        {
            name: "payload",
            label: "Payload",
            options: {filter: true, sort: true}
        },
        {
            name: "service",
            label: "Service",
            options: {filter: true, sort: true}
        },
        {
            name: "isSuccess",
            label: "Succès",
            options: {filter: true, sort: true}
        },
        {
            name: "queue",
            label: "File d'attente",
            options: {filter: true, sort: true}
        },
        {
            name: "numberTrials",
            label: "Max tentative",
            options: {filter: true, sort: true}
        },
        {
            name: "countTrials",
            label: "Tentative",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Rechercher par libellés.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_request_async_list'] && cacheTableServer['api_administrator_request_async_list'].label ? cacheTableServer['api_administrator_request_async_list'].label : '',
            options: {}
        },
        method: {
            name: 'method',
            label: 'Méthode',
            textHelper: 'Rechercher par méthodes.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_request_async_list'] && cacheTableServer['api_administrator_request_async_list'].method ? cacheTableServer['api_administrator_request_async_list'].method : '',
            options: {}
        },
        isSuccess: {
            name: 'isSuccess',
            label: 'Succès',
            textHelper: 'Rechercher par réussite.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_request_async_list'] && cacheTableServer['api_administrator_request_async_list'].isSuccess ? cacheTableServer['api_administrator_request_async_list'].isSuccess : 'all',
            options: {}
        },
        queue: {
            name: 'queue',
            label: 'File d\'attente',
            textHelper: 'Rechercher par file d\'attente.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_request_async_list'] && cacheTableServer['api_administrator_request_async_list'].queue ? cacheTableServer['api_administrator_request_async_list'].queue : '',
            options: {}
        },
        service: {
            name: 'service',
            label: 'Service',
            textHelper: 'Rechercher par service.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_request_async_list'] && cacheTableServer['api_administrator_request_async_list'].service ? cacheTableServer['api_administrator_request_async_list'].service : '',
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_administrator_request_async_list'] = 0;
        cacheTableServer['api_administrator_request_async_list'].page = 0;
        cacheTableServer['api_administrator_request_async_list'][index] = value;
        dispatch(TableReload('api_administrator_request_async_list'))
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des requêtes asynchrone',
            context: 'Administrateur',
            description: '',
            links: []
        });

        setLoadingContent(false);
    }, []);
    React.useEffect(() => {
        let interval = setInterval(() => dispatch(TableReload('api_administrator_request_async_list')), 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'label'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('label', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                                <TextFieldComponent id={'method'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('method', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                                <SelectComponent id={'isSuccess'} options={optionsIsSuccess} handler={handlerFormSearch} onChange={(val) => inputSearchChange('isSuccess', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                                <TextFieldComponent id={'queue'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('queue', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                                <TextFieldComponent id={'service'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('service', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                hideLoading={true}
                id={'api_administrator_request_async_list'}
                title={'Requête asynchrone'}
                columns={moveColumns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();

                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        label: formSearch.label.value ? formSearch.label.value : '',
                        method: formSearch.method.value ? formSearch.method.value : '',
                        queue: formSearch.queue.value ? formSearch.queue.value : '',
                        service: formSearch.service.value ? formSearch.service.value : '',
                        isSuccess: (datas.isSuccess === 'all') ? '' : (datas.isSuccess === 'true'),
                    };

                    ApiHandler.get({
                            route: 'api_administrator_request_async_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                if (data[index].isSuccess === 1) {
                                    data[index].isSuccess = <span style={{color: '#28a745'}}>Succès</span>
                                }
                                if (data[index].isSuccess === 0) {
                                    data[index].isSuccess = <span style={{color: '#982525'}}>Échec</span>
                                }
                                if (data[index].isSuccess === null) {
                                    data[index].isSuccess = <span style={{color: '#17a2b8'}}>En cours</span>
                                }

                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/><br/>
        </ContentViewComponent>
    );
}

export default AdministratorRequestAsyncList;
