import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {hasRight} from "../../../Common/UserCommon";
import {IconButton, Tooltip} from "@mui/material";
import {DeleteOutline, Edit} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {dispatch} from "../../../App";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ButtonComponent from "../../../Component/ButtonComponent";

function AdministratorUserList() {
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "deposits",
            label: "Dépots",
            options: {filter: true, sort: true}
        },
        {
            name: "userFunction",
            label: "Fonction utilisateur",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <Link to={getRoutePathname('administrator_user', {id: row.id})}>
                        <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                    </Link>
                </Tooltip>
                {
                    (hasRight(authenticationReducer, 'ROLE_ADMINISTRATOR_USER') || hasRight(authenticationReducer, 'ROLE_ADMINISTRATOR_USER_ARCHIVE')) &&
                    <Tooltip title={'Supprimer l\'utilisateur'} placement="left">
                        <IconButton onClick={() => archiveUser(row.id)}>
                            <DeleteOutline style={{color: '#982525'}}/>
                        </IconButton>
                    </Tooltip>
                }

            </>
        );
    };

    const archiveUser = (userId) => {
        dispatch(LinearProgressMainLayoutActivate());

        ApiHandler.post({
            route: 'api_administrator_user_archive',
            params: {'id': userId},
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Utilisateur archivé avec succès.',
                        variant: 'success',
                    })
                );
                window.location.reload();
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
        });
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des utilisateurs',
            context: 'Administrateur',
            description: '',
            links: []
        });
    }, [])

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs}>
            <TableComponent
                id={'api_administrator_user_list'}
                title={'Utilisateurs'}
                columns={columns}
                actionFirst={(
                    hasRight(authenticationReducer, 'ROLE_ADMINISTRATOR_USER') ||
                    hasRight(authenticationReducer, 'ROLE_ADMINISTRATOR_USER_ADD')
                ) ? {
                    label: 'Ajouter un utilisateur',
                    link: getRoutePathname('administrator_user', {id: 'add'})
                } : false}
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_administrator_user_list'},
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />
        </ContentViewComponent>
    );
}

export default AdministratorUserList;
