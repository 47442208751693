import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import React from "react";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Fade, Grid} from "@mui/material";
import ClientBusinessContactForm from "./ClientBusinessContactComponents/ClientBusinessContactForm";

function ClientBusinessContact() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [clientBusinessContact, setClientBusinessContact] = React.useState(null);

    React.useEffect(() => {
        if (params.clientBusinessId) {
            setClientBusinessContact(null);

            setBreadcrumbs({
                title: 'Ajouter un contact',
                context: 'Gestion des contacts',
                description: '',
                links: [
                    {path: getRoutePathname('b2b_client_business',{id: params.clientBusinessId}), label: 'Revendeur'}
                ]
            });
            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else if (params.clientBusinessContactId) {
            setClientBusinessContact(null);
            setIsLoadingContent(true)

            ApiHandler.get({
                route: 'api_b2b_client_business_contact_get',
                params: {id: params.clientBusinessContactId}
            }, (response) => {
                if (response.status === 200) {
                    setClientBusinessContact(response.data[0]);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setIsLoadingContent(false);
            });

            setBreadcrumbs({
                title: 'Éditer un contact',
                context: 'Gestion Contact',
                description: '',
                links: [
                    {path: getRoutePathname('b2b_client_business_contact_list',{clientBusinessId: clientBusinessContact ? clientBusinessContact.clientBusinessId : null}), label: 'Liste des contacts'}
                ]
            });
        }
    }, [location])



    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>

            <Fade in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ClientBusinessContactForm clientBusinessContact={clientBusinessContact} clientBusinessId={params.clientBusinessId ? params.clientBusinessId : (clientBusinessContact ? clientBusinessContact.clientBusinessId : null)}/>
                </Grid>
            </Fade>

            <br/><br/>

        </ContentViewComponent>
    )

}

export default ClientBusinessContact;