import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Slide, Tooltip} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {makeStyles} from '@mui/styles';
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import SelectComponent from '../../../Component/SelectComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import CheckboxComponent from "../../../Component/CheckboxComponent";
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import ApiHandler from "../../../Handler/ApiHandler";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import AutoCompleteComponent from '../../../Component/AutoCompleteComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import {SnackbarOpen} from '../../../Action/SnackbarAction';
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from '../../../Action/LinearProgressMainLayoutAction';
import {PlaylistAddCheck} from '@mui/icons-material';
import {useSelector} from "react-redux";

function InventoryManagementReturnOrder() {
    const classes = useStyles();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [loadingByProduct, setLoadingByProduct] = React.useState(false);
    const [optionsOrderClientState, setOptionsOrderClientState] = React.useState([]);
    const [optionsOrderClientLitigeState, setOptionsOrderClientLitigeState] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogReceive, setOpenDialogReceive] = React.useState(false);
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isLoadingFormReceive, setIsLoadingFormReceive] = React.useState(false);
    const [byProducts, setByProducts] = React.useState([]);
    const [byProductsReceive, setByProductsReceive] = React.useState([]);
    const [litigeUuid, setLitigeUuid] = React.useState();

    const keyWord = {
        received : 'received',
        compliant : 'compliant',
        isRefund : 'isRefund'
    }

    const columns = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "clientEmail",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "clientName",
            label: "Client",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientState",
            label: "Etat",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientLitigeState",
            label: "Etat du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "reason",
            label: "Raison du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "author",
            label: "Auteur du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de la commande",
            options: {filter: true, sort: true}
        },
        {
            name: "litigeAt",
            label: "Date de litige",
            options: {filter: true, sort: true}
        },
        {
            name: "dealAt",
            label: "Date de cloture",
            options: {filter: true, sort: true}
        },
        {
            name: "returnAt",
            label: "Date de réception",
            options: {filter: true, sort: true}
        },
        {
            name: "authorReturn",
            label: "Réceptionné par",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const [formSearch, setFormSearch] = React.useState({
        number: {
            name: 'number',
            label: 'Référence',
            textHelper: 'Rechercher par référence.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_return_order_list'] && cacheTableServer['api_inventory_management_return_order_list'].number ? cacheTableServer['api_inventory_management_return_order_list'].number : '',
            options: {}
        },
        clientEmail: {
            name: 'clientEmail',
            label: 'Email',
            textHelper: 'Rechercher par email.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_return_order_list'] && cacheTableServer['api_inventory_management_return_order_list'].clientEmail ? cacheTableServer['api_inventory_management_return_order_list'].clientEmail : '',
            options: {}
        },
        clientName: {
            name: 'clientName',
            label: 'Client',
            textHelper: 'Rechercher par client.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_return_order_list'] && cacheTableServer['api_inventory_management_return_order_list'].clientName ? cacheTableServer['api_inventory_management_return_order_list'].clientName : '',
            options: {}
        }
    });
    const [form, setForm] = React.useState({
        reason: {
            name: 'reason',
            label: 'Raison',
            textHelper: 'Saisissez une raison.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        orderClient: {
            name: 'orderClient',
            label: 'Commande client',
            textHelper: 'Choisissez une commande client.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });

    const [formByProduct, setFormByProduct] = React.useState({});

    const f = {
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: `Saisissez un commentaire.`,
            type: 'text',
            defaultValue: '',
            options: {}
        }
    };

    const [formByProductReceive, setFormByProductReceive] = React.useState({...f});

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const handlerForm = initFormHandler(form, setForm);
    const handlerFormByProduct = initFormHandler(formByProduct, setFormByProduct);
    const handlerFormByProductReceive = initFormHandler(formByProductReceive, setFormByProductReceive);

    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_inventory_management_return_order_list'] = 0;
        cacheTableServer['api_inventory_management_return_order_list'].page = 0;
        cacheTableServer['api_inventory_management_return_order_list'][index] = value;
        dispatch(TableReload('api_inventory_management_return_order_list'))
    };

    const loadOrderClient = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_order_client',
            data: {text: text}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const loadByProduct = (orderClient, isReceive, litige = '') => {
        if (orderClient) {
            dispatch(LinearProgressMainLayoutActivate());
            if (isReceive) {
                setLoadingByProduct(true);
                setByProductsReceive(null);
                ApiHandler.get({
                    route: 'api_inventory_management_return_order_by_product_list',
                    params: {uuid: orderClient},
                    data: {litige: litige}
                }, (response) => {
                    let newForm = {};
                    let data = response.data;
                    for (let index in data) {
                        let inputId = data[index].id;
                        let inputAmountReceive = inputId + '|' + keyWord.received;
                        let inputAmountCompliant = inputId + '|' + keyWord.compliant;
                        let inputIsRefund = inputId + '|' + keyWord.isRefund;

                        newForm[inputId] = {
                            name: inputId,
                            label: 'Identifiant',
                            textHelper: '',
                            type: 'integer',
                            defaultValue: inputId,
                            options: {}
                        }

                        newForm[inputAmountReceive] = {
                            name: inputAmountReceive,
                            label: 'Quantité Réceptionnée',
                            textHelper: `Saisissez la quantité de produit réceptionné (max: ${data[index].amount}).`,
                            type: 'integer',
                            defaultValue: '',
                            options: {validation: ['required'], max: data[index].amount}
                        }

                        newForm[inputAmountCompliant] = {
                            name: inputAmountCompliant,
                            label: 'Quantité Conforme',
                            textHelper: `Saisissez la quantité de produit conforme (max: ${data[index].amount}).`,
                            type: 'integer',
                            defaultValue: '',
                            options: {validation: ['required'], max: data[index].amount}
                        };

                        newForm[inputIsRefund] = {
                            name: inputIsRefund,
                            label: 'Remboursement validé',
                            textHelper: `Cocher si remboursement accepté.`,
                            type: 'boolean',
                            defaultValue: false,
                            value: false,
                            options: {}
                        };

                        setFormByProductReceive({...newForm, ...f});
                        setByProductsReceive(response.data);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        setLoadingByProduct(false);
                    }
                });
            }
            else {
                setLoadingByProduct(true);
                setByProducts(null);
                ApiHandler.get({
                    route: 'api_inventory_management_return_order_by_product_list',
                    params: {uuid: orderClient}
                }, (response) => {
                    let newForm = {};
                    let data = response.data;

                    for (let index in data) {

                        let inputId = data[index].id;
                        let amountMax = data[index].amount;

                        newForm[inputId] = {
                            name: inputId,
                            label: 'Quantité',
                            textHelper: `Saisissez la quantité du produit en litige (max: ${amountMax}).`,
                            type: 'integer',
                            defaultValue: '',
                            options: {validation: ['required'], max: amountMax}
                        };
                        setByProducts(response.data);
                        setFormByProduct(newForm);

                        dispatch(LinearProgressMainLayoutDeactivate());
                        setLoadingByProduct(false);
                    }
                });
            }

        }
    };

    const saveReturn = () => {
        if (handlerForm.checkError() < 1 && handlerFormByProduct.checkError() < 1) {
            handlerForm.setFormLoading(true);
            handlerFormByProduct.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            let dataByProduct = handlerFormByProduct.getData();
            let data = handlerForm.getData();
            let amountByProducts = [];

            for (let index in dataByProduct) {
                if (parseInt(index)) {
                    amountByProducts.push({
                        'amount': dataByProduct[index],
                        'byProduct': parseInt(index)
                    });
                }
            }
            ApiHandler.post({
                route: 'api_inventory_management_return_order_add',
                data: {
                    reason: data.reason,
                    amountByProducts: amountByProducts
                },
                params: {uuid: data.orderClient}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Retour enregistré avec succès.',
                            variant: 'success',
                        })
                    );
                    setOpenDialog(false);
                    dispatch(TableReload('api_inventory_management_return_order_list'));
                }
                else if (response.status === 400) {
                    handlerForm.setErrorApi(response.error);
                    handlerFormByProduct.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
                handlerForm.setFormLoading(false);
                handlerFormByProduct.setFormLoading(false);
                setIsLoadingForm(false);
            });
        }
    };

    const saveReceive = () => {
        if (handlerFormByProductReceive.checkError() < 1) {
            handlerFormByProductReceive.setFormLoading(true);
            setIsLoadingFormReceive(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handlerFormByProductReceive.getData();

            let amountByProducts = [];
            for (let index in data) {
                if (!Object.keys(keyWord).some(word => index.includes(keyWord[word]))) {
                    if (parseInt(index)) {

                        let byProductReceive = byProductsReceive.find(item => parseInt(item.id) === parseInt(index));
                        if(byProductReceive !== undefined && byProductReceive !== null)
                        {
                            amountByProducts.push({
                                'amountReceived': data[index + '|' + keyWord.received],
                                'amountCompliant': data[index + '|' + keyWord.compliant],
                                'isRefund': data[index + '|' + keyWord.isRefund],
                                'maximum': byProductReceive.amount,
                                'byProductId': parseInt(index)
                            })
                        }
                    }
                }
            }

            ApiHandler.post({
                route: 'api_inventory_management_return_order_litige_receive',
                data: {
                    comment: data.comment,
                    amountByProducts: amountByProducts
                },
                params: {uuid: litigeUuid}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Réception enregistré avec succès.',
                            variant: 'success',
                        })
                    );
                    setOpenDialogReceive(false);
                    dispatch(TableReload('api_inventory_management_return_order_list'));
                }
                else if (response.status === 400) {
                    handlerFormByProductReceive.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormByProductReceive.setFormLoading(false);
                setIsLoadingFormReceive(false);
            });
        }
    };

    const getAction = (row) => {
        return (
            <>
                {!row.returnAt ?
                    <Tooltip title={'Réceptionner'} placement="left">
                        <IconButton onClick={() => {
                            handlerFormByProductReceive.reset();
                            setOpenDialogReceive(true);
                            loadByProduct(row.uuid, true, row.orderClientLitigeUuid);
                            setLitigeUuid(row.orderClientLitigeUuid);
                        }}>
                            <PlaylistAddCheck style={{color: '#17a2b8'}}/>
                        </IconButton>
                    </Tooltip> :
                    <IconButton style={{cursor: 'no-drop'}}>
                        <PlaylistAddCheck/>
                    </IconButton>
                }
            </>

        );
    };

    React.useEffect(() => {
        handlerFormSearch.start();
        handlerForm.start();
        handlerFormByProduct.start();
        handlerFormByProductReceive.start();
        ApiHandler.get({route: 'api_select_order_client_state'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientState(data);
        });
        ApiHandler.get({route: 'api_select_order_client_litige_state'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientLitigeState(data);
        });

        setBreadcrumbs({
            title: 'Retour Commande',
            context: 'Gestion des stocks',
            description: '',
            links: []
        });

        setLoadingContent(false);

    }, []);

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'number'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('number', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'clientEmail'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('clientEmail', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'clientName'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('clientName', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_inventory_management_return_order_list'}
                title={'Retours commande client'}
                columns={columns}
                actionFirst={{
                    label: 'Créer un retour',
                    onClick: () => {
                        handlerForm.reset();
                        handlerFormByProduct.reset();
                        setByProducts(null);
                        setOpenDialog(true);
                    }
                }}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        shopId: authenticationReducer.shopSelected,
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        number: formSearch.number.value ? formSearch.number.value : '',
                        clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
                        clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
                        orderClientState: (datas.orderClientState === 0 || !datas.orderClientState) ? '' : datas.orderClientState,
                        orderClientLitigeState: (datas.orderClientLitigeState === 0 || !datas.orderClientLitigeState) ? '' : datas.orderClientLitigeState,
                    };

                    ApiHandler.get({
                            route: 'api_inventory_management_return_order_list_by_shop',
                            data: filters,
                            params: {shopId: authenticationReducer.shopSelected}
                        },
                        (response) => {
                            let data = response.data ? response.data.data : [];
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].returnAt = data[index].returnAt ? moment(data[index].returnAt).format('lll') : '-';
                                data[index].authorReturn = data[index].authorReturn ?? '-';
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].dealAt = data[index].dealAt ? moment(data[index].dealAt).format('lll') : '-';
                                data[index].litigeAt = moment(data[index].litigeAt).format('lll');
                                data[index].number = data[index].number ?? '-';
                                data[index].clientEmail = data[index].clientEmail ?? '-';
                                data[index].clientName = data[index].clientName ?? '-';
                                data[index].orderClientState = data[index].orderClientState ?? '-';
                                data[index].orderClientLitigeState = data[index].orderClientLitigeState ?? '-';
                            }
                            resolve(data, (response.data ? response.data.count : 0));
                        });
                }}
                search={false}
            />

            <br/><br/>

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{'Créer un retour'}</DialogTitle>
                <DialogContent style={{minWidth: '40vw', minHeight: 160}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AutoCompleteComponent id={'orderClient'} handler={handlerForm} loadOptions={loadOrderClient} onClick={(val) => loadByProduct(val, false)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent id={'reason'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                    {loadingByProduct ?
                        <LinearProgress className={classes.linearProgress}/>
                        :
                        byProducts?.map((byProduct, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <p>{byProduct.name}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldComponent id={byProduct.id} handler={handlerFormByProduct}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} loading={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveReturn} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>


            <Dialog open={openDialogReceive} maxWidth={'xl'} onClose={() => setOpenDialogReceive(false)}>
                <DialogTitle style={{fontSize: 15}}>{'Réceptionner'}</DialogTitle>
                <DialogContent style={{minWidth: '40vw'}}>
                    {loadingByProduct ?
                        <LinearProgress className={classes.linearProgress}/>
                        :
                        byProductsReceive?.map((byProduct, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p>{byProduct.name}</p>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextFieldComponent id={byProduct.id + '|' + keyWord.received} handler={handlerFormByProductReceive}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextFieldComponent id={byProduct.id + '|' + keyWord.compliant} handler={handlerFormByProductReceive}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CheckboxComponent id={byProduct.id + '|' + keyWord.isRefund} handler={handlerFormByProductReceive} orientation={'right'}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                    <TextFieldComponent id={'comment'} handler={handlerFormByProductReceive} multiline={true}/>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialogReceive(false)} loading={isLoadingFormReceive}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveReceive} loading={isLoadingFormReceive}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    linearProgress: {
        width: '100%',
        height: '2px !important',
        backgroundColor: 'rgba(87,165,133,0.25) !important',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#57A585'
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    }
});

export default InventoryManagementReturnOrder;
