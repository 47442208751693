import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {hasRight} from "../../../../Common/UserCommon";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../../Action/LinearProgressMainLayoutAction";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid, Slide} from "@mui/material";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {makeStyles} from "@mui/styles";
import ClientBusinessPriceListProductsForm from "./ClientBusinessPriceListProductsForm";
import ClientBusinessPriceListFilterForm from "./ClientBusinessPriceListFilterForm";

function ClientBusinessPriceListForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');
    const [priceList, setPriceList] = React.useState(props.clientBusinessPriceList ? props.clientBusinessPriceList[0] : null);
    const [filters, setFilters] = React.useState(null);

    const [form, setForm] = React.useState({
        id: {
            name: 'id',
            label: 'Price List Identifiant',
            textHelper: 'Price List Id',
            type: 'integer',
            defaultValue: priceList ? priceList.id : null,
            options: {}
        },
        name: {
            name: 'name',
            label: 'Nom de la grille',
            textHelper: 'Saisissez le nom de la grille',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
    });

    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);

    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (priceList) {
                ApiHandler.post({
                    route: 'api_b2b_client_business_price_list_edit',
                    params: {id:  priceList.id},
                    data: handlerForm.getData(),
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Grille tarifaire modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_b2b_client_business_price_list_add',
                    data: handlerForm.getData(),
                }, (response) => {
                    if (response.status === 200) {
                        setPriceList(response.data.priceListDTO);
                        dispatch(
                            SnackbarOpen({
                                text: 'Grille tarifaire ajouté avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };

    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (priceList) {
            handlerForm.setDataApi(priceList);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <>
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information grille tarifaire'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'name'} handler={handlerForm}/>
                </Grid>
            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>

        {
            priceList &&
            <>
                <br/>
                <ClientBusinessPriceListFilterForm priceList={priceList} filters={filters} setFilters={setFilters} />
                <ClientBusinessPriceListProductsForm priceList={priceList} filters={filters}/>
            </>
        }
        </>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ClientBusinessPriceListForm;