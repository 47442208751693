import React from 'react';
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {hasRight} from "../../../../Common/UserCommon";

function InventoryManagementDepositForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') && !hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_EDIT');

    // Deposit Form
    const [owner, setOwner] = React.useState(null);
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du dépôt.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.deposit) {
                ApiHandler.post({
                    route: 'api_inventory_management_deposit_edit',
                    data: handlerForm.getData(),
                    params: {id: props.deposit.id}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Dépôt modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_inventory_management_deposit_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('inventory_management_deposit', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Dépôt créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.deposit) {
            handlerForm.setDataApi(props.deposit);
            setOwner(props.deposit.owner);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information dépôt'}/>
            {owner && <span className={classes.owner}>{owner}</span>}
            <TextFieldComponent id={'name'} handler={handlerForm}/>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default InventoryManagementDepositForm;
