import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Tooltip} from '@mui/material';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import moment from 'moment';
import {RestorePage, Print} from "@mui/icons-material";
import ButtonComponent from "../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";

function AdministratorLogCarrierList() {
    const [log, setLog] = React.useState(null);
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsIsSuccess] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: 'true', label: 'Succès'},
        {value: 'false', label: 'Échec'}
    ]);
    const [optionsCarrier, setOptionsCarrier] = React.useState([]);
    const [optionsLabelPrinter, setOptionsLabelPrinter] = React.useState([]);
    const moveColumns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "message",
            label: "Message",
            options: {filter: true, sort: true}
        },
        {
            name: "carrier",
            label: "Transporteur",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClient",
            label: "Commande",
            options: {filter: true, sort: true}
        },
        {
            name: "isSuccess",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        message: {
            name: 'message',
            label: 'Message',
            textHelper: 'Rechercher par messages.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_carrier_list'] && cacheTableServer['api_administrator_log_carrier_list'].message ? cacheTableServer['api_administrator_log_carrier_list'].message : '',
            options: {}
        },
        isSuccess: {
            name: 'isSuccess',
            label: 'État',
            textHelper: 'Rechercher par état.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_carrier_list'] && cacheTableServer['api_administrator_log_carrier_list'].isSuccess ? cacheTableServer['api_administrator_log_carrier_list'].isSuccess : 'all',
            options: {}
        },
        carrier: {
            name: 'carrier',
            label: 'Transporteur',
            textHelper: 'Rechercher par transporteur.',
            type: 'integer',
            defaultValue: cacheTableServer['api_administrator_log_carrier_list'] && cacheTableServer['api_administrator_log_carrier_list'].carrier ? cacheTableServer['api_administrator_log_carrier_list'].carrier : 0,
            options: {}
        },
        orderClient: {
            name: 'orderClient',
            label: 'Commande client',
            textHelper: 'Rechercher par commande.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_carrier_list'] && cacheTableServer['api_administrator_log_carrier_list'].orderClient ? cacheTableServer['api_administrator_log_carrier_list'].orderClient : '',
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_administrator_log_carrier_list'] = 0;
        cacheTableServer['api_administrator_log_carrier_list'].page = 0;
        cacheTableServer['api_administrator_log_carrier_list'][index] = value;
        dispatch(TableReload('api_administrator_log_carrier_list'))
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Rééditer le ZPL'} placement="left">
                    <IconButton onClick={() => {
                        dispatch(LinearProgressMainLayoutActivate());
                        dispatch(
                            SnackbarOpen({
                                text: 'Réédition du ZPL en cours...',
                                variant: 'info',
                            })
                        );
                        ApiHandler.get({
                                route: 'api_administrator_log_carrier_generate_print_label',
                                params: {id: row.id}
                            },
                            (response) => {
                                dispatch(TableReload('api_administrator_log_carrier_list'));
                                dispatch(LinearProgressMainLayoutDeactivate());
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Réédition du ZPL terminée',
                                        variant: 'success',
                                    })
                                );
                            });
                    }}><RestorePage style={{color: '#35A2EB'}}/></IconButton>
                </Tooltip>
                <Tooltip title={'Imprimer l\'étiquette'} placement="left">
                    <IconButton onClick={() => setLog(row.id)}><Print style={{color: '#17a2b8'}}/></IconButton>
                </Tooltip>
            </>
        );
    };

    // Form
    const [form, setForm] = React.useState({
        labelPrinter: {
            name: 'labelPrinter',
            label: 'Imprimante à étiquette',
            textHelper: 'Choisissez une imprimante à étiquette.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerForm = initFormHandler(form, setForm);

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des Log - Transporteur',
            context: 'Administrateur',
            description: '',
            links: []
        });

        ApiHandler.get({route: 'api_select_label_printer'}, (response) => setOptionsLabelPrinter(response.data));
        ApiHandler.get({route: 'api_select_carrier'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsCarrier(data);
        });

        setLoadingContent(false);
    }, []);

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <TextFieldComponent id={'message'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('message', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'isSuccess'} options={optionsIsSuccess} handler={handlerFormSearch} onChange={(val) => inputSearchChange('isSuccess', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'carrier'} options={optionsCarrier} handler={handlerFormSearch} onChange={(val) => inputSearchChange('carrier', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <TextFieldComponent id={'orderClient'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('orderClient', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_administrator_log_carrier_list'}
                title={'Log Transporteur'}
                columns={moveColumns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        message: formSearch.message.value ? formSearch.message.value : '',
                        isSuccess: (datas.isSuccess === 'all') ? '' : (datas.isSuccess === 'true'),
                        carrier: (datas.carrier === 0) ? '' : datas.carrier,
                        orderClient: formSearch.orderClient.value ? formSearch.orderClient.value : '',
                    };

                    ApiHandler.get({
                            route: 'api_administrator_log_carrier_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].isSuccess = data[index].isSuccess ? <span style={{color: '#28a745'}}>Succès</span> : <span style={{color: '#982525'}}>Échec</span>;
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/><br/>

            <Dialog open={!!log} maxWidth={'xl'} onClose={() => setLog(null)}>
                <DialogTitle style={{fontSize: 15}}>Choix de l'imprimante à étiquette</DialogTitle>
                <DialogContent style={{minWidth: '40vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectComponent id={'labelPrinter'} options={optionsLabelPrinter} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setLog(null)}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => {
                        dispatch(LinearProgressMainLayoutActivate());
                        dispatch(
                            SnackbarOpen({
                                text: 'Impression du ZPL en cours...',
                                variant: 'info',
                            })
                        );
                        setLog(null);
                        ApiHandler.get({
                                route: 'api_administrator_log_carrier_label_printer',
                                params: {id: log, labelPrinter: handlerForm.getData()['labelPrinter']}
                            },
                            () => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Impression du ZPL terminée',
                                        variant: 'success',
                                    })
                                );
                        });
                    }}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default AdministratorLogCarrierList;
