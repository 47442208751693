import React from 'react';
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {Link} from "react-router-dom";
import TableComponent from "../../../../Component/TableComponent";
import {IconButton, Tooltip} from "@mui/material";
import {Article, FaceRetouchingOff, Face} from "@mui/icons-material";
import {hasRight} from "../../../../Common/UserCommon";
import {isNumber} from "../../../../Util/NumberTool";
import { useSelector } from 'react-redux';
import moment from "moment/moment";

function SaleClientOrderClientList(props) {

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const columns = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "confirmedAt",
            label: "Date de commande",
            options: {filter: true, sort: true}
        },
        {
            name: "channel",
            label: "Canal",
            options: {filter: true, sort: true}
        },
        {
            name: "carrier",
            label: "Transporteur",
            options: {filter: true, sort: true}
        },
        {
            name: "state",
            label: "Statut",
            options: {filter: true, sort: true}
        },
        {
            name: "priceOriginalHTText",
            label: "Prix d'origine HT",
            options: {filter: true, sort: true}
        },
        {
            name: "priceOriginalTTCText",
            label: "Prix d'origine TTC",
            options: {filter: true, sort: true}
        },
        {
            name: "priceDiscountHTText",
            label: "Remise HT",
            options: {filter: true, sort: true}
        },
        {
            name: "priceDiscountTTCText",
            label: "Remise TTC",
            options: {filter: true, sort: true}
        },
        {
            name: "priceHTText",
            label: "Prix final HT",
            options: {filter: true, sort: true}
        },
        {
            name: "priceTTCText",
            label: "Prix final TTC",
            options: {filter: true, sort: true}
        },
        {
            name: "isGuest",
            label: "Guest",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SALE_ORDER_CLIENT') || hasRight(authenticationReducer, 'ROLE_SALE_ORDER_CLIENT_GET'))) {
            return (
                <>
                    <Tooltip title={'Visualiser'} placement="left">
                        <Link to={getRoutePathname('sale_order_client', {uuid: row.uuid})}>
                            <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                        </Link>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Article style={{color: '#17a2b8'}}/>
                    </IconButton>
                </>
            );
        }
    };

    return (
        <>
            <TableComponent
                id={'api_sale_client'}
                title={'Commandes'}
                columns={columns}
                noReload={true}
                promiseData={(resolve) => {
                    let data = props.client.orders;
                    for (let index in data) {
                        data[index].action = getAction(data[index]);
                        data[index].confirmedAt = data[index].confirmedAt ? moment(data[index].confirmedAt).format('lll') : '-';
                        data[index].priceOriginalHTText = isNumber(data[index].priceOriginalHT) ? parseFloat(data[index].priceOriginalHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].priceOriginalTTCText = isNumber(data[index].priceOriginalTTC) ? parseFloat(data[index].priceOriginalTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].priceDiscountHTText = isNumber(data[index].priceDiscountHT) ? parseFloat(data[index].priceDiscountHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].priceDiscountTTCText = isNumber(data[index].priceDiscountTTC) ? parseFloat(data[index].priceDiscountTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].priceHTText = isNumber(data[index].priceHT) ? parseFloat(data[index].priceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].priceTTCText = isNumber(data[index].priceTTC) ? parseFloat(data[index].priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        data[index].isGuest = data[index].isGuest ? <Tooltip title={'Guest'} placement="left"><FaceRetouchingOff style={{color: '#ffc107'}}/></Tooltip> : <Face style={{color: '#28a745'}}/>;
                    }
                    resolve(data);
                }}
            />
        </>
    );
}

export default SaleClientOrderClientList;
