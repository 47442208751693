import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {hasRight} from "../../../../Common/UserCommon";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {makeStyles} from "@mui/styles";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../../Action/LinearProgressMainLayoutAction";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid} from "@mui/material";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import CheckboxComponent from "../../../../Component/CheckboxComponent";

function ClientBusinessSellStats(props) {
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

    const [form, setForm] = React.useState({
        totalCredit: {
            name: 'totalCredit',
            label: 'En cours crédit',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        caThisYear: {
            name: 'caThisYear',
            label: 'CA Année en cours',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        caLastYear: {
            name: 'caLastYear',
            label: 'CA Année précédente',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handlerForm = initFormHandler(form, setForm);

    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.clientBusiness) {
            handlerForm.setDataApi(props.clientBusiness[0]);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Crédit en cours et CA'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'totalCredit'} handler={handlerForm} disabled={true}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'caThisYear'} handler={handlerForm} disabled={true}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldComponent id={'caLastYear'} handler={handlerForm} disabled={true}/>
                </Grid>
            </Grid>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ClientBusinessSellStats;