import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Box, Grid, Slide} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DataGrid, frFR, GridColDef} from "@mui/x-data-grid";

import {dispatch} from "../../../App";
import ApiHandler from "../../../Handler/ApiHandler";
import {listingStyles} from "../../../Style/useStyles";
import {displayErrorMessage} from "../../../Error/Errors";
import {initFormHandler} from "../../../Handler/FormHandler";
import {floatSortComparator} from "../../../Util/SortNumber";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {momentDateComparator} from "../../../Util/SortMomentDate";
import {formatNumberToEurCurrency} from "../../../Util/NumberTool";
import {isAllOptionChecked} from "../../../Filter/DropdownCheckbox";
import {momentDateFormatter} from "../../../Util/MomentDateFormatter";
import CustomToolbar from "../../../Component/DataGrid/CustomToolbar";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import DoubleRowHeader from "../../../Component/DataGrid/DoubleRowHeader";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {CustomPagination} from "../../../Component/DataGrid/CustomPagination";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import FooterWithTotalGeneral from "../../../Component/DataGrid/FooterWithTotalGeneral";
import {transformObjectToDataGridRowsArray} from "../../../Util/DataGridDataTransformer";
import {fetchPaymentMethods, fetchStatsChannels,} from "../../../Filter/FetchDropdownData";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";

function StatisticSalePayment() {
    const [loadingContent, setLoadingContent] = useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState({});

    const [optionsChannels, setOptionsChannels] = useState([]);
    const [optionsPaymentMethods, setOptionsPaymentMethods] = useState([]);

    const [cellsTable, setCellsTable] = useState([]);

    const [totalGeneral, setTotalGeneral] = useState([]);

    let totalGeneralValue = 0;
    if (Object.values(cellsTable).length > 0) {
        totalGeneralValue = totalGeneral;
    }

    const classes = listingStyles();

    const [formSearch, setFormSearch] = useState({
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            options: {validation: ['date']}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            options: {validation: ['date']}
        },
        channels: {
            name: 'channels',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'array',
            options: {}
        },
        paymentMethods: {
            name: 'paymentMethods',
            label: 'Moyen de paiement',
            textHelper: 'Rechercher par moyen de paiement.',
            type: 'array',
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        value = isAllOptionChecked(value)

        handlerFormSearch.setValue(index, value);
    }

    useEffect(() => {
        handlerFormSearch.start();
        fetchPaymentMethods(setOptionsPaymentMethods)
        fetchStatsChannels(setOptionsChannels)

        setBreadcrumbs({
            title: 'Stats Encaissements commandes',
            context: 'Statistiques',
            description: '',
            links: []
        });

    }, [])

    const onSearch = () => {
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();

        let filters = {
            startDate: formSearch.startDate.value ? momentDateFormatter.formatStartOfDay(formSearch.startDate.value) : '',
            endDate: formSearch.endDate.value ? momentDateFormatter.formatEndOfDay(formSearch.endDate.value) : '',
            channels: datas.channels[0] === null ? [] : datas.channels,
            paymentMethods: datas.paymentMethods[0] === null ? [] : datas.paymentMethods,
        };

        ApiHandler.get({
                route: 'api_statistic_sale_payment',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    const {totalGeneral, ...data} = response.data;

                    setCellsTable(data);
                    setTotalGeneral(totalGeneral);
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    setRowsTableDetails([]);

                    dispatch(LinearProgressMainLayoutDeactivate());
                } else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    displayErrorMessage(response)
                }
            }
        );
    };

    const exportFileName = 'stats_sale_payment';

    const columns: GridColDef[] = [
        {
            field: "confirmedAt",
            headerName: "Date de confirmation",
            headerAlign: "right",
            minWidth: 150,
            align: "right",
            sortComparator: momentDateComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Date de" secondLineText="confirmation"/>,
            valueGetter: (params) => {
                return momentDateFormatter.dateTimeToFormatFr(params.value.date);
            },
        },
        {
            field: "channel",
            headerName: "Canal",
            minWidth: 150,
        },
        {
            field: "paymentMethod",
            headerName: "Moyen de paiement",
            minWidth: 150,
            renderHeader: () => <DoubleRowHeader firstLineText="Moyen de" secondLineText="paiement"/>,
        },
        {
            field: "ttcGroupedOrders",
            headerName: "Total TTC",
            headerAlign: "right",
            flex: 1,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="TOTAL TTC" secondLineText="commandes"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
    ];

    const columnsTableDetails: GridColDef[] = [
        {
            field: "orderRef",
            headerName: "Cmd Référence",
            minWidth: 160,
        },
        {
            field: "confirmedAt",
            headerName: "Date de confirmation",
            headerAlign: "right",
            minWidth: 120,
            align: "right",
            sortComparator: momentDateComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Date de" secondLineText="confirmation"/>,
            valueGetter: (params) => {
                return momentDateFormatter.dateTimeToFormatFr(params.value.date);
            },
        },
        {
            field: "channel",
            headerName: "Canal",
            minWidth: 130,
        },
        {
            field: "paymentMethod",
            headerName: "Moyen de paiement",
            minWidth: 150,
            renderHeader: () => <DoubleRowHeader firstLineText="Moyen de" secondLineText="paiement"/>,
        },
        {
            field: "shopName",
            headerName: "Nom de boutique",
            minWidth: 250,
        },
        {
            field: "amountShopCard",
            headerName: "Montant payé en boutique en CB",
            headerAlign: "right",
            minWidth: 150,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Boutique" secondLineText="payé en CB"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
        {
            field: "amountShopCash",
            headerName: "Montant payé en boutique en espèce",
            headerAlign: "right",
            minWidth: 150,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Boutique" secondLineText="payé en espèce"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
        {
            field: "amountShopAvoir",
            headerName: "Montant avoir en boutique",
            headerAlign: "right",
            minWidth: 150,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Boutique" secondLineText="Montant Avoir"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
        {
            field: "amountCashBack",
            headerName: "Montant rendu en boutique",
            headerAlign: "right",
            minWidth: 150,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Boutique" secondLineText="Montant Rendu"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
        {
            field: "ttcOrder",
            headerName: "Montant TTC commande",
            headerAlign: "right",
            minWidth: 150,
            flex: 1,
            align: "right",
            sortComparator: floatSortComparator,
            renderHeader: () => <DoubleRowHeader firstLineText="Montant TTC" secondLineText="commande"/>,
            valueGetter: (params) => {
                return formatNumberToEurCurrency(params.value);
            },
        },
    ];

    const rows = transformObjectToDataGridRowsArray(cellsTable)

    const [orderDetailsTitle, setOrderDetailsTitle] = React.useState('');
    const [rowsTableDetails, setRowsTableDetails] = React.useState([]);
    const loadCommandDetails = (params) => {
        const selectedRowDetails = transformObjectToDataGridRowsArray(params.row.details)
        setRowsTableDetails(selectedRowDetails);

        setOrderDetailsTitle(`
        Détails des commandes du:
            "${momentDateFormatter.dateTimeToFormatFr(params.row.confirmedAt.date, 'DD/MM/YYYY')} - 
            ${params.row.channel} - 
            ${params.row.paymentMethod} - 
            Total: ${formatNumberToEurCurrency(params.row.ttcGroupedOrders)}"
            `);
    };

    const ignoreDiacritics = true
    const disableRowSelectionOnClick = true
    const sxDataGrid = {
        '& .MuiSvgIcon-root': {
            fontSize: 'large',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
    }

    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'startDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('startDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'endDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('endDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <MultipleSelectComponent id={'channels'}
                                                         options={optionsChannels}
                                                         handler={handlerFormSearch}
                                                         onChange={(val) => inputSearchChange('channels', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <MultipleSelectComponent id={'paymentMethods'}
                                                         options={optionsPaymentMethods}
                                                         handler={handlerFormSearch}
                                                         onChange={(val) => inputSearchChange('paymentMethods', val)}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={2}>
                                <ButtonComponent className={classes.buttonActionFirst}
                                                 label={"Rechercher"}
                                                 onClick={() => onSearch()}
                                                 disabled={disabledStatusButtonSearch}
                                                 loading={loadingContent}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <Stack spacing={2}>
                <Box sx={{height: 420, width: '100%'}}>
                    <DataGrid
                        sx={sxDataGrid}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        ignoreDiacritics={ignoreDiacritics}
                        columns={columns}
                        rows={rows}
                        disableColumnMenu
                        rowHeight={25}
                        slots={{
                            toolbar: () => <CustomToolbar fileName={exportFileName}/>,
                            pagination: CustomPagination,
                            footer: () => <FooterWithTotalGeneral totalGeneralValue={totalGeneralValue}/>,
                        }}
                        initialState={{
                            pagination: {paginationModel: {pageSize: 50}},
                        }}
                        disableRowSelectionOnClick={disableRowSelectionOnClick}
                        onRowClick={loadCommandDetails} {...rows}
                    />
                </Box>

                {
                    orderDetailsTitle &&
                    <Box>
                        <Alert severity="info" sx={{paddingTop: 0, paddingBottom: 0}}>{orderDetailsTitle}</Alert>
                        <DataGrid
                            sx={sxDataGrid}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            ignoreDiacritics={ignoreDiacritics}
                            disableRowSelectionOnClick={disableRowSelectionOnClick}
                            columns={columnsTableDetails}
                            rows={rowsTableDetails}
                            disableColumnMenu
                            rowHeight={25}
                            slots={{
                                toolbar: () => <CustomToolbar fileName={exportFileName}/>,
                                pagination: CustomPagination,
                            }}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 50}},
                            }}
                        />
                    </Box>
                }
            </Stack>
        </ContentViewComponent>
    )
}

export default StatisticSalePayment;
