import React from 'react'
import {Box} from "@mui/material";

export default function DoubleRowHeader({firstLineText, secondLineText}) {
    return (
        <Box
            sx={{
                lineHeight: '20px',
                textAlign: 'center',
            }}>
            <Box>
                <strong>{firstLineText}</strong>
            </Box>
            <Box>
                <strong>{secondLineText}</strong>
            </Box>
        </Box>
    );
}
