import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {Article, Info} from "@mui/icons-material";
import TableComponent from "../../../../Component/TableComponent";
import {hasRight} from "../../../../Common/UserCommon";
import {useDispatch, useSelector} from "react-redux";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {initFormHandler} from "../../../../Handler/FormHandler";
import AutoCompleteComponent from "../../../../Component/AutoCompleteComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../../Action/TableAction";
import {PublishedWithChanges} from '@mui/icons-material';
import SelectComponent from "../../../../Component/SelectComponent";
import UseAnimations from "react-useanimations";
import arrowDown from "react-useanimations/lib/arrowDown";
import {isNumber} from "../../../../Util/NumberTool";

function InventoryManagementDepositByProductList(props) {
    const dispatch = useDispatch();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') && !hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_EDIT');

    // LIST
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum / Couleur",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Taille du conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité",
            options: {filter: true, sort: true}
        },
        {
            name: "amountReservedText",
            label: "Quantité réservée",
            options: {filter: true, sort: true}
        },
        {
            name: "amountAvailableText",
            label: "Quantité disponible",
            options: {filter: true, sort: true}
        },
        {
            name: "amountCurrentSupplyText",
            label: "Quantité en cours d’approvisionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "amountTermText",
            label: "Quantité à terme",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if (!justWatch) {
            return (
                <div style={{width: 100}}>
                    <Tooltip title={
                        <div>
                            {
                                row.batchs.map((batch, key) => {
                                    return <div key={key}>
                                        <div>{batch.name} <br/>{batch.amount} (Prix unitaire : {batch.price} €)</div>
                                        <br/>
                                    </div>;
                                })
                            }
                        </div>
                    } placement="left">
                        <IconButton><Info style={{color: '#007bff'}}/></IconButton>
                    </Tooltip>

                    <Tooltip title={'Visualiser l\'article'} placement="left">
                        <Link to={getRoutePathname('inventory_management_product', {id: row.productId})}>
                            <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title={'Mouvement de stock'} placement="left">
                        <IconButton onClick={() => {
                            formMoveStock['deposit']['options'] = {};
                            formMoveStock['price']['options'] = {validation: ['required']};
                            handlerFormMoveStock.reset();
                            handlerFormMoveStock.setValue('action', 1);
                            setActionSelected(1);
                            setByProductSelected(row);

                            let batchs = [];
                            for(let index in row.batchs ){
                                batchs.push({value: row.batchs[index].id, label: row.batchs[index].name});
                            }

                            setOptionsBatch(batchs);
                        }}><PublishedWithChanges style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                </div>
            );
        }
        else {
            return (
                <div style={{width: 100}}>
                    <Tooltip title={
                        <div>
                            {
                                row.batchs.map((batch, key) => {
                                    return <div key={key}>{batch.name} : {batch.amount}</div>;
                                })
                            }
                        </div>
                    } placement="left">
                        <IconButton><Info style={{color: '#007bff'}}/></IconButton>
                    </Tooltip>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Article/>
                    </IconButton>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <PublishedWithChanges/>
                    </IconButton>
                </div>
            );
        }
    };

    // ADD
    const [showAdd, setShowAdd] = React.useState(false);
    const [isLoadingAdd, setIsLoadingAdd] = React.useState(false);
    const [formByProduct, setFormByProduct] = React.useState({
        byProduct: {
            name: 'byProduct',
            label: 'Sous article',
            textHelper: 'Choisissez un sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        amount: {
            name: 'amount',
            label: 'Quantité',
            textHelper: 'Saisissez la quantité du sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'], min: 1}
        },
        batch: {
            name: 'batch',
            label: 'Lot',
            textHelper: 'Saisissez le nom du lot.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        price: {
            name: 'price',
            label: 'Prix unitaire',
            textHelper: 'Saisissez le prix unitaire du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerFormByProduct = initFormHandler(formByProduct, setFormByProduct);
    const loadByProduct = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_by_product',
            data: {text: text}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const saveAdd = () => {
        if (handlerFormByProduct.checkError() < 1) {
            handlerFormByProduct.setFormLoading(true);
            setIsLoadingAdd(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_inventory_management_deposit_by_product_add_stock',
                params: {depositId: props.deposit.id},
                data: handlerFormByProduct.getData()
            }, (response) => {
                if (response.status === 200) {
                    dispatch(TableReload('api_inventory_management_deposit_by_product_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Stock ajouté créé avec succès.',
                            variant: 'success',
                        })
                    );
                    handlerFormByProduct.reset();
                    setShowAdd(false);
                }
                else if (response.status === 400) {
                    handlerFormByProduct.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormByProduct.setFormLoading(false);
                setIsLoadingAdd(false);
            });
        }
    };

    // Move Stock
    const [isLoadingMoveStock, setIsLoadingMoveStock] = React.useState(false);
    const [actionSelected, setActionSelected] = React.useState(1);
    const [byProductSelected, setByProductSelected] = React.useState(null);
    const [formMoveStock, setFormMoveStock] = React.useState({
        action: {
            name: 'action',
            label: 'Action',
            textHelper: 'Choisissez l\'action du mouvement.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        batch: {
            name: 'batch',
            label: 'Lot',
            textHelper: 'Saisissez le nom du lot.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        batchSelect: {
            name: 'batchSelect',
            label: 'Lot',
            textHelper: 'Saisissez le nom du lot.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        amount: {
            name: 'amount',
            label: 'Quantité',
            textHelper: 'Saisissez la quantité du mouvement.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'], min: 1}
        },
        price: {
            name: 'price',
            label: 'Prix unitaire',
            textHelper: 'Saisissez le prix unitaire du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        deposit: {
            name: 'deposit',
            label: 'Dépôt',
            textHelper: 'Recherchez un dépôt.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerFormMoveStock = initFormHandler(formMoveStock, setFormMoveStock);
    const saveMoveStock = () => {
        if (handlerFormMoveStock.checkError() < 1) {
            handlerFormMoveStock.setFormLoading(true);
            setIsLoadingMoveStock(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_inventory_management_deposit_by_product_move_stock',
                params: {depositId: props.deposit.id, byProductId: byProductSelected.id},
                data: handlerFormMoveStock.getData()
            }, (response) => {
                if (response.status === 200) {
                    dispatch(TableReload('api_inventory_management_deposit_by_product_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Le mouvement de stock a bien été pris en compte.',
                            variant: 'success',
                        })
                    );
                    setByProductSelected(null);
                }
                else if (response.status === 400) {
                    handlerFormMoveStock.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormMoveStock.setFormLoading(false);
                setIsLoadingMoveStock(false);
            });
        }
    };

    // Move Stock Options
    const [optionsBatch, setOptionsBatch] = React.useState([]);
    const [optionsDeposit, setOptionsDeposit] = React.useState([]);
    const [optionsDepositMoveAction, setOptionsDepositMoveAction] = React.useState([]);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_deposit'}, (response) => setOptionsDeposit(response.data));
        ApiHandler.get({route: 'api_select_movement_action'}, (response) => {
            let d = [];
            for (let index in response.data) {
                if (response.data[index].value === 1 || response.data[index].value === 2) {
                    d.push(response.data[index]);
                }
            }

            setOptionsDepositMoveAction(d);
        });
    }, []);

    return (
        <>
            <TableComponent
                id={'api_inventory_management_deposit_by_product_list'}
                title={'Sous articles'}
                columns={columns}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_DEPOSIT_EDIT')) ?
                        {label: 'Ajouter du stock', onClick: () => setShowAdd(true)} : false
                }
                promiseData={(resolve) => {
                    ApiHandler.get({
                            route: 'api_inventory_management_deposit_by_product_list',
                            params: {depositId: props.deposit.id}
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].amountText = isNumber(data[index].amount) ? data[index].amount.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].amountReservedText = isNumber(data[index].amountReserved) ? data[index].amountReserved.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].amountAvailableText = isNumber(data[index].amountAvailable) ? data[index].amountAvailable.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].amountCurrentSupplyText = isNumber(data[index].amountCurrentSupply) ? data[index].amountCurrentSupply.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].amountTermText = isNumber(data[index].amountTerm) ? data[index].amountTerm.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={showAdd} maxWidth={'xl'} onClose={() => setShowAdd(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter du stock</DialogTitle>
                <DialogContent style={{minWidth: '30vw', minHeight: 170}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AutoCompleteComponent id={'byProduct'} handler={handlerFormByProduct} loadOptions={loadByProduct}/>
                            <TextFieldComponent id={'amount'} handler={handlerFormByProduct}/>
                            <TextFieldComponent id={'batch'} handler={handlerFormByProduct}/>
                            <TextFieldComponent id={'price'} handler={handlerFormByProduct}/>
                            <TextFieldComponent id={'comment'} handler={handlerFormByProduct} multiline/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowAdd(false)} disabled={isLoadingAdd}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveAdd} loading={isLoadingAdd}/>
                </DialogActions>
            </Dialog>

            <Dialog open={byProductSelected !== null} maxWidth={'xl'} onClose={() => setByProductSelected(null)}>
                <DialogTitle style={{fontSize: 15}}>Mouvement de stock - {byProductSelected !== null ? byProductSelected.sku : ''}</DialogTitle>
                <DialogContent style={{minWidth: '30vw', minHeight: (actionSelected === 3) ? 300 : 125}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent id={'action'} options={optionsDepositMoveAction} handler={handlerFormMoveStock} onChange={(v) => {
                                    setActionSelected(v);
                                    if (v === 3) formMoveStock['deposit']['options'] = {validation: ['required']};
                                    else formMoveStock['deposit']['options'] = {};

                                    if (v !== 2 && v !== 3) formMoveStock['price']['options'] = {validation: ['required']};
                                    else formMoveStock['price']['options'] = {};

                                    if (v === 1) formMoveStock['batch']['options'] = {validation: ['required']};
                                    else formMoveStock['batch']['options'] = {};

                                    if (v === 2) formMoveStock['batchSelect']['options'] = {validation: ['required']};
                                    else formMoveStock['batchSelect']['options'] = {};

                                    setFormMoveStock({...formMoveStock});
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent id={'amount'} handler={handlerFormMoveStock}/>
                            </Grid>
                            {(actionSelected === 2) ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectComponent id={'batchSelect'} handler={handlerFormMoveStock} options={optionsBatch}/>
                                </Grid> :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent id={'batch'} handler={handlerFormMoveStock}/>
                                </Grid>
                            }
                            {(actionSelected === 3) && <>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center'}}>
                                    <UseAnimations animation={arrowDown} size={35} wrapperStyle={{margin: 'auto'}}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerFormMoveStock}/>
                                </Grid>
                            </>}
                            {(actionSelected !== 2 && actionSelected !== 3) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent id={'price'} handler={handlerFormMoveStock}/>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent id={'comment'} handler={handlerFormMoveStock} multiline/>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setByProductSelected(null)} disabled={isLoadingMoveStock}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveMoveStock} loading={isLoadingMoveStock}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InventoryManagementDepositByProductList;
