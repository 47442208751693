import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import React from "react";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Fade, Grid} from "@mui/material";
import ClientBusinessForm from "./ClientBusinessComponents/ClientBusinessForm";
import ClientBusinessContactList from "../ClientBusinessContact/ClientBusinessContactList";
import ClientBusinessIdentifiantConnexionForm from "./ClientBusinessComponents/ClientBusinessIdentifiantConnexionForm";
import ClientBusinessSellStats from "./ClientBusinessComponents/ClientBusinessSellStats";
import ClientBusinessPriceListForm from "./ClientBusinessComponents/ClientBusinessPriceListForm";
import ClientBusinessOrderList from "../ClientBusinessOrder/ClientBusinessOrderList";

function ClientBusiness() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [clientBusiness, setClientBusiness] = React.useState(null);

    React.useEffect(() => {
        if (params.id === 'add') {
            setClientBusiness(null);

            setBreadcrumbs({
                title: 'Ajouter un business',
                context: 'Gestion Business',
                description: '',
                links: [
                    {path: getRoutePathname('b2b_client_business_list'), label: 'Liste des business'}
                ]
            });
            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else {
            setClientBusiness(null);
            setIsLoadingContent(true)
            setBreadcrumbs({
                title: 'Éditer un business',
                context: 'Gestion Business',
                description: '',
                links: [
                    {path: getRoutePathname('b2b_client_business_list'), label: 'Liste des business'}
                ]
            });

            ApiHandler.get({
                route: 'api_b2b_client_business_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setClientBusiness(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>

            <Fade in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ClientBusinessForm clientBusiness={clientBusiness}/>
                    {params.id !== 'add' &&
                        <>
                            <br/><br/>
                            <ClientBusinessIdentifiantConnexionForm clientBusiness={clientBusiness}/>
                            <br/><br/>
                            <ClientBusinessContactList clientBusiness={clientBusiness}/>
                            <br/><br/>
                            <ClientBusinessPriceListForm clientBusiness={clientBusiness}/>
                            <br/><br/>
                            <ClientBusinessOrderList clientBusiness={clientBusiness} />
                            <br/><br/>
                            <ClientBusinessSellStats clientBusiness={clientBusiness}/>
                        </>
                    }
                </Grid>
            </Fade>

            <br/><br/>

        </ContentViewComponent>
    )
}

export default ClientBusiness;