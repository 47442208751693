import React from 'react';
import {makeStyles} from "@mui/styles";
import {CloudDownload, CloudUpload} from "@mui/icons-material";
import axios from "axios";
import {SnackbarOpen} from "../Action/SnackbarAction";
import {dispatch} from "../App";

function InputFileComponent(props) {
    const classes = useStyles();

    const inputRef = React.useRef();
    const [file, setFile] = React.useState(props.defaultPDF);

    const onChange = (e) => {
        let val = e.target.files[0];

        if (!val) {
            let v = (e.dataTransfer && e.dataTransfer.items) ? e.dataTransfer.items[0].getAsFile() : null;
            if (v && v.type === 'application/pdf') val = v;
            if (v && v.type === 'text/csv') val = v;
        }

        if (props.setIsLoadingButton) props.setIsLoadingButton(true);

        if (val) {
            setFile('');

            let data = new FormData();
            data.append('file', val);

            axios.post(props.urlUpload, data)
                .then(function (response) {
                    if (response.status === 200) {
                        if (props.setShowModal) props.setShowModal(false);
                        if (props.setIsLoadingButton) props.setIsLoadingButton(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Fichier bien enregistré.',
                                variant: 'success',
                            })
                        );

                        setTimeout(() => setFile(props.urlDownload), 50);
                        if (props.onUpload) props.onUpload(props.urlDownload);
                    }
                    else {
                        if (props.setShowModal) props.setShowModal(false);
                        if (props.setIsLoadingButton) props.setIsLoadingButton(false);
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                })
                .catch(err => {
                    if (props.setShowModal) props.setShowModal(false);
                    if (props.setIsLoadingButton) props.setIsLoadingButton(false);
                    dispatch(
                        SnackbarOpen({
                            text: err.error && err.error.message ? err.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                });
        }
    };

    return (
        <div className={classes.contentInput}>
            <span style={{position: 'absolute', top: -17, width: '100%', fontSize: 10}}>{props.label}</span>

            {(props.cantUpload && !file) && <CloudUpload style={{color: '#35A2EB', position: 'absolute', bottom: 30, right: 72, fontSize: 40, cursor: 'pointer'}}/>}
            {(props.cantUpload) && <input accept="application/pdf" style={{opacity: 0, width: '100%', height: '100%', position: 'absolute', left: 0, cursor: 'pointer'}} type="file" ref={inputRef} onChange={onChange} onDrop={onChange}/>}

            {(props.csvUpload && !file) && <CloudUpload style={{color: '#35A2EB', position: 'absolute', bottom: 30, right: 72, fontSize: 40, cursor: 'pointer'}}/>}
            {(props.csvUpload ) && <input accept="text/csv" style={{opacity: 0, width: '100%', height: '100%', position: 'absolute', left: 0, cursor: 'pointer'}} type="file" ref={inputRef} onChange={onChange} onDrop={onChange}/>}
            <embed src={file} style={{width: '100%', height: '100%', overflow: 'hidden'}}/>

            {(props.id !== 'add' && file) && <CloudDownload style={{color: '#28a745', position: 'absolute', top: 10, right: 15, cursor: 'pointer'}} onClick={() => {
                fetch(props.urlDownload, {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                    .then(res => res.blob())
                    .then(res => {
                        const aElement = document.createElement('a');
                        aElement.setAttribute('download', props.namePDF);
                        const href = URL.createObjectURL(res);
                        aElement.href = href;
                        aElement.setAttribute('target', '_blank');
                        aElement.click();
                        URL.revokeObjectURL(href);
                    });
            }}/>}
        </div>
    );
}

const useStyles = makeStyles({
    contentInput: {
        border: '1px #585858 dashed',
        padding: 5,
        marginBottom: 15,
        height: 100,
        width: 177,
        margin: 'auto',
        textAlign: 'center',
        position: 'relative'
    }
});

export default InputFileComponent;
