import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import {useDispatch, useSelector} from "react-redux";
import {inArray} from "../../../Common/ArrayCommon";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {DeleteOutline, Edit} from "@mui/icons-material";
import {hasRight} from "../../../Common/UserCommon";
import {initFormHandler} from "../../../Handler/FormHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import TableComponent from "../../../Component/TableComponent";
import AutoCompleteComponent from "../../../Component/AutoCompleteComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {Article} from "@mui/icons-material";
import {isNumber} from "../../../Util/NumberTool";

function ShopListByProducts() {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

    // LIST
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum / Couleur",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Taille du conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité",
            options: {filter: true, sort: true}
        },
        {
            name: "amountReservedText",
            label: "Quantité réservée",
            options: {filter: true, sort: true}
        },
        {
            name: "amountAvailableText",
            label: "Quantité disponible",
            options: {filter: true, sort: true}
        },
        {
            name: "amountCurrentSupplyText",
            label: "Quantité en cours d’approvisionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "amountTermText",
            label: "Quantité à terme",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    return (
        <>

        </>
    );
}

export default ShopListByProducts;
