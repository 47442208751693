import React from 'react';
import {Fade, Grid} from "@mui/material";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import BuyOrderPurveyorForm from "./BuyOrderPurveyorComponents/BuyOrderPurveyorForm";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";

function BuyOrderPurveyor() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [purveyorOrder, setPurveyorOrder] = React.useState(null);
    React.useEffect(() => {
        if (params.id === 'add') {
            setPurveyorOrder(null);

            setBreadcrumbs({
                title: 'Créer une commande fournisseur',
                context: 'Achat',
                description: '',
                links: [
                    {path: getRoutePathname('buy_order_purveyor_list'), label: 'Liste des commandes fournisseurs'}
                ]
            });

            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else {
            setBreadcrumbs({
                title: 'Éditer une commande fournisseur',
                context: 'Achat',
                description: '',
                links: [
                    {path: getRoutePathname('buy_order_purveyor_list'), label: 'Liste des commandes fournisseurs'}
                ]
            });

            ApiHandler.get({
                route: 'api_buy_order_purveyor_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setPurveyorOrder(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setPurveyorOrder(null);
                }

                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>
            <Grid container spacing={2}>
                <Fade in={true} {...{timeout: 750}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <BuyOrderPurveyorForm purveyorOrder={purveyorOrder}/>
                    </Grid>
                </Fade>
            </Grid>

            <br/><br/>
        </ContentViewComponent>
    );
}

export default BuyOrderPurveyor;
