import React from 'react';
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid} from "@mui/material";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import CheckboxComponent from "../../../../Component/CheckboxComponent";
import {hasRight} from "../../../../Common/UserCommon";

function BuyProductByProductForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') && !hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT');

    // By Product Form
    const [form, setForm] = React.useState({
        sku: {
            name: 'sku',
            label: 'SKU du sous article',
            textHelper: 'Génération du SKU du sous article.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'], equal: 4}
        },
        isActive: {
            name: 'isActive',
            label: 'Activé',
            textHelper: '',
            type: 'boolean',
            defaultValue: true,
            options: {}
        },
        sellB2B: {
            name: 'sellB2B',
            label: 'Vente B2B',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        ean: {
            name: 'ean',
            label: 'EAN du sous article',
            textHelper: 'Saisissez le code EAN du sous article.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        amazonCode: {
            name: 'amazonCode',
            label: 'Code Amazon',
            textHelper: 'Saisissez le code Amazon du sous article.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        theoreticalWeight: {
            name: 'theoreticalWeight',
            label: 'Poids théorique',
            textHelper: 'Saisissez le poids théorique du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        packSize: {
            name: 'packSize',
            label: 'Taille du conditionnement',
            textHelper: 'Choisissez le conditionnement du sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        flavor: {
            name: 'flavor',
            label: 'Parfum / Couleur',
            textHelper: 'Choisissez le parfum/couleur du sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lifeCycle: {
            name: 'lifeCycle',
            label: 'Cycle de vie',
            textHelper: 'Choisissez le cycle de vie du sous article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        sellPrice: {
            name: 'sellPrice',
            label: 'Prix de vente HT',
            textHelper: 'Saisissez le prix de vente HT du sous article.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        sellPriceTTC: {
            name: 'sellPriceTTC',
            label: 'Prix de vente TTC',
            textHelper: 'Saisissez le prix de vente TTC du sous article.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        dimensionX: {
            name: 'dimensionX',
            label: 'Dimension sur axe X',
            textHelper: 'Saisissez la dimension de l\'axe X du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        dimensionY: {
            name: 'dimensionY',
            label: 'Dimension sur axe Y',
            textHelper: 'Saisissez la dimension de l\'axe Y du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        dimensionZ: {
            name: 'dimensionZ',
            label: 'Dimension sur axe Z',
            textHelper: 'Saisissez la dimension de l\'axe Z du sous article.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerForm = initFormHandler(form, setForm);
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (!props.byProduct) {
                ApiHandler.post({
                    route: 'api_buy_product_by_product_add',
                    params: {productId: props.product.id},
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Sous article créé avec succès.',
                                variant: 'success',
                            })
                        );
                        handlerForm.reset();
                        props.reload();
                        props.setIsUpdateSylius(null);
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_buy_product_by_product_edit',
                    data: handlerForm.getData(),
                    params: {productId: props.product.id, id: props.byProduct.id},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Sous article modifié avec succès.',
                                variant: 'success',
                            })
                        );
                        props.setIsUpdateSylius(null);
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    const remove = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.byProduct) {
                ApiHandler.delete({
                    route: 'api_buy_product_by_product_delete',
                    data: handlerForm.getData(),
                    params: {productId: props.product.id, id: props.byProduct.id},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Sous article supprimé avec succès.',
                                variant: 'success',
                            })
                        );
                        props.setIsUpdateSylius(null);
                        handlerForm.setFormLoading(false);
                        setIsLoadingForm(false);
                        props.reload();
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    const generateSku = () => {
        if (props.byProduct) return;

        let data = handlerForm.getData();

        let flavorCode = '';
        for (let index in optionsFlavor) {
            if (optionsFlavor[index].value === data.flavor) flavorCode = optionsFlavor[index].code ?? '';
        }

        let packSizeCode = '';
        for (let index in optionsPackSize) {
            if (optionsPackSize[index].value === data.packSize) packSizeCode = optionsPackSize[index].code ?? '';
        }

        handlerForm.setValue('sku', (packSizeCode + flavorCode).toUpperCase());
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.byProduct) handlerForm.setDataApi(props.byProduct);
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    // By Product Options
    const [optionsFlavor, setOptionsFlavor] = React.useState([]);
    const [optionsPackSize, setOptionsPackSize] = React.useState([]);
    const [optionsLifeCycle, setOptionsLifeCycle] = React.useState([]);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_flavor'}, (response) => setOptionsFlavor(response.data));
        ApiHandler.get({route: 'api_select_pack_size'}, (response) => setOptionsPackSize(response.data));
        ApiHandler.get({route: 'api_select_life_cycle'}, (response) => setOptionsLifeCycle(response.data));
    }, []);
    React.useEffect(() => {
        let sellPriceTTC = handlerForm.form.sellPriceTTC.value;
        let sellPrice = handlerForm.form.sellPrice.value;

        if (sellPriceTTC) {
            sellPrice = sellPriceTTC / (1 + (props.product.tvaValue / 100));
            handlerForm.setValue('sellPrice', sellPrice);
        }
    }, [form.sellPriceTTC.value]);
    React.useEffect(() => {
        let sellPrice = handlerForm.form.sellPrice.value;
        handlerForm.setValue('sellPriceTTC', (sellPrice * (1 + (props.product.tvaValue / 100))));
    }, []);

    return (
        <>
            <TitleComponent title={'Information du sous article'}/>

            <Grid container spacing={1}>
                <CheckboxComponent orientation={'left'} id={'isActive'} handler={handlerForm} style={{position: 'absolute', top: 6, right: -6, width: 'auto'}}/>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <TextFieldComponent id={'sku'} handler={handlerForm} disabled={true} adornment={<span>{props.product.sku}</span>} adornmentOrientation={'left'}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'ean'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'amazonCode'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'sellPriceTTC'} handler={handlerForm} adornment={<span>€</span>}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <TextFieldComponent id={'sellPrice'} handler={handlerForm} adornment={<span>€</span>} disabled={true}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <SelectComponent id={'packSize'} options={optionsPackSize} handler={handlerForm} onBlur={generateSku} disabled={!!props.byProduct}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <SelectComponent id={'flavor'} options={optionsFlavor} handler={handlerForm} onBlur={generateSku} disabled={!!props.byProduct}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <SelectComponent id={'lifeCycle'} options={optionsLifeCycle} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextFieldComponent id={'dimensionX'} handler={handlerForm} adornment={<span>cm</span>}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextFieldComponent id={'dimensionY'} handler={handlerForm} adornment={<span>cm</span>}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextFieldComponent id={'dimensionZ'} handler={handlerForm} adornment={<span>cm</span>}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextFieldComponent id={'theoreticalWeight'} handler={handlerForm} adornment={<span>kg</span>}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CheckboxComponent id={'sellB2B'} handler={handlerForm}/>
                </Grid>

                {!justWatch && <>
                    <ButtonComponent label={'Supprimer'} color={'#982525'} className={classes.buttonDelete} onClick={remove} loading={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>
                </>}
            </Grid>
        </>
    );
}

const useStyles = makeStyles({
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    }, buttonDelete: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '120px !important',
        position: 'absolute !important'
    },
});

export default BuyProductByProductForm;
