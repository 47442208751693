import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {Edit} from "@mui/icons-material";
import TableComponent from "../../../../Component/TableComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {TableReload} from "../../../../Action/TableAction";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {isNumber} from "../../../../Util/NumberTool";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {LinearProgressMainLayoutActivate} from "../../../../Action/LinearProgressMainLayoutAction";

function ClientBusinessPriceListProductsForm(props) {

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
    const [priceListItem, setPriceListItem] = React.useState(null);

    const columns = [
        {
            name: "sku",
            label: "SKU",
            options: {filter: false, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: false, sort: true}
        },
        {
            name: "byProductName",
            label: "Nom du produit",
            options: {filter: false, sort: true}
        },
        {
            name: "theoreticalWeight",
            label: "Poids",
            options: {filter: false, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum",
            options: {filter: false, sort: true}
        },
        {
            name: "officialSellPriceHT",
            label: "Prix officiel",
            options: {filter: false, sort: true}
        },
        {
            name: "sellerSellPriceHT",
            label: "Prix revendeur",
            options: {filter: false, sort: true}
        },
        {
            name: "percentage",
            label: "% Réduction",
            options: {filter: false, sort: true}
        },
        {
            name: "marge",
            label: "Marge",
            options: {filter: false, sort: true}
        },
        {
            name: "carrierTax",
            label: "Frais de port",
            options: {filter: false, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ]

    const handleSelectRow = (row) => {
        handlerFormPrice.reset();
        handlerFormPrice.setValue('sellerSellPriceHT', (isNumber(row.sellerSellPriceHTValue) ? row.sellerSellPriceHTValue : 0));
        handlerFormPrice.setValue('carrierTax', (isNumber(row.carrierTaxValue) ? row.carrierTaxValue : 0));
        setPriceListItem(row);
        setOpenDialogEdit(true)
    }

    const closeModal = () => {
        setPriceListItem(null);
        setOpenDialogEdit(false)
    }

    let getAction = (row) => {
        return (
            <>
                <IconButton onClick={() => handleSelectRow(row)}><Edit style={{color: '#17a2b8'}}/></IconButton>
            </>
        );
    }

    const save = () => {
        if (handlerFormPrice.checkError() < 1) {
            handlerFormPrice.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handlerFormPrice.getData();
            data.idPriceList = props.priceList.id;
            data.idByProduct = priceListItem.byProductId;
            data.idPriceListItem = priceListItem.id;

            ApiHandler.post({
                route: 'api_b2b_client_business_price_list_compute_price_list_item',
                data: data,
                params: {id: priceListItem.id}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Modification enregistrée avec succès.',
                            variant: 'success',
                        })
                    );
                    setPriceListItem(null);
                    dispatch(TableReload('api_b2b_client_business_price_list_products_get'));
                }
                else if (response.status === 400) {
                    handlerFormPrice.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                handlerFormPrice.setFormLoading(false);
                setLoading(false);
                setOpenDialogEdit(false);
            });
        }
    }

    const [formPrice, setFormPrice] = React.useState({
        sellerSellPriceHT: {
            name: 'sellerSellPriceHT',
            label: 'Prix revendeur HT',
            textHelper: 'Saisissez le prix.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        carrierTax: {
            name: 'carrierTax',
            label: 'Frais de port',
            textHelper: 'Saisissez le montant.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
    })

    const handlerFormPrice = initFormHandler(formPrice, setFormPrice);

    React.useEffect(() => {
        dispatch(TableReload('api_b2b_client_business_price_list_products_get'));
    }, [props.filters])

    return (
        <>
                <TableComponent
                    id={'api_b2b_client_business_price_list_products_get'}
                    title={'Liste des produits disponibles'}
                    columns={columns}
                    search={false}

                    promiseServerData={(resolve) => {

                        ApiHandler.get({
                                route: 'api_b2b_client_business_price_list_products_get',
                                params: {id: props.priceList.id},
                                data: props.filters
                            },
                            (response) => {

                                let data = response.data.data ?? [];

                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                    data[index].sellerSellPriceHTValue = data[index].sellerSellPriceHT;
                                    data[index].carrierTaxValue = data[index].carrierTax;
                                    data[index].officialSellPriceHT = isNumber(data[index].officialSellPriceHT) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].officialSellPriceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                    data[index].sellerSellPriceHT = isNumber(data[index].sellerSellPriceHT) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].sellerSellPriceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                    data[index].carrierTax = isNumber(data[index].carrierTax) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].carrierTax).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                    data[index].percentage = isNumber(data[index].percentage) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].percentage).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                                }

                                resolve(data, response.data.count);
                            });
                    }}
                />


                <Dialog open={openDialogEdit} maxWidth={'xl'} onClose={() => setOpenDialogEdit(false)}>
                    <DialogTitle style={{fontSize: 15}}>Modifier le prix de vente</DialogTitle>
                    <DialogContent style={{minWidth: '30vw'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p style={{fontSize: 14, margin: 2}}>{priceListItem ? priceListItem.byProductName : null}</p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent id={'sellerSellPriceHT'} handler={handlerFormPrice}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent id={'carrierTax'} handler={handlerFormPrice}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => closeModal()} disabled={loading}/>
                        <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                    </DialogActions>
                </Dialog>
            </>
    );
}

export default ClientBusinessPriceListProductsForm;
