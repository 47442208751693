import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import {Grid, Slide} from '@mui/material';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {TableReload} from '../../../Action/TableAction';
import {dispatch} from '../../../App';
import moment from 'moment';

function AdministratorLogSyliusList() {
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsIsSuccess] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: 'true', label: 'Succès'},
        {value: 'false', label: 'Échec'}
    ]);
    const moveColumns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        },
        {
            name: "method",
            label: "Méthode",
            options: {filter: true, sort: true}
        },
        {
            name: "url",
            label: "Url",
            options: {filter: true, sort: true}
        },
        {
            name: "payload",
            label: "Payload",
            options: {filter: true, sort: true}
        },
        {
            name: "message",
            label: "Message",
            options: {filter: true, sort: true}
        },
        {
            name: "isSuccess",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Rechercher par libellés.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_sylius_list'] && cacheTableServer['api_administrator_log_sylius_list'].label ? cacheTableServer['api_administrator_log_sylius_list'].label : '',
            options: {}
        },
        method: {
            name: 'method',
            label: 'Méthode',
            textHelper: 'Rechercher par méthodes.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_sylius_list'] && cacheTableServer['api_administrator_log_sylius_list'].method ? cacheTableServer['api_administrator_log_sylius_list'].method : '',
            options: {}
        },
        message: {
            name: 'message',
            label: 'Message',
            textHelper: 'Rechercher par messages.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_sylius_list'] && cacheTableServer['api_administrator_log_sylius_list'].message ? cacheTableServer['api_administrator_log_sylius_list'].message : '',
            options: {}
        },
        isSuccess: {
            name: 'isSuccess',
            label: 'État',
            textHelper: 'Rechercher par état.',
            type: 'text',
            defaultValue: cacheTableServer['api_administrator_log_sylius_list'] && cacheTableServer['api_administrator_log_sylius_list'].isSuccess ? cacheTableServer['api_administrator_log_sylius_list'].isSuccess : 'all',
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_administrator_log_sylius_list'] = 0;
        cacheTableServer['api_administrator_log_sylius_list'].page = 0;
        cacheTableServer['api_administrator_log_sylius_list'][index] = value;
        dispatch(TableReload('api_administrator_log_sylius_list'))
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des Log - Sylius',
            context: 'Administrateur',
            description: '',
            links: []
        });
        setLoadingContent(false);
    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <TextFieldComponent id={'label'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('label', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <TextFieldComponent id={'method'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('method', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <TextFieldComponent id={'message'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('message', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'isSuccess'} options={optionsIsSuccess} handler={handlerFormSearch} onChange={(val) => inputSearchChange('isSuccess', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_administrator_log_sylius_list'}
                title={'Log Sylius'}
                columns={moveColumns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        label: formSearch.label.value ? formSearch.label.value : '',
                        method: formSearch.method.value ? formSearch.method.value : '',
                        message: formSearch.message.value ? formSearch.message.value : '',
                        isSuccess: (datas.isSuccess === 'all') ? '' : (datas.isSuccess === 'true'),
                    };

                    ApiHandler.get({
                            route: 'api_administrator_log_sylius_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].isSuccess = data[index].isSuccess ? <span style={{color: '#28a745'}}>Succès</span> : <span style={{color: '#982525'}}>Échec</span>;
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/><br/>
        </ContentViewComponent>
    );
}

export default AdministratorLogSyliusList;

/*
    UPDATE `request_async` SET `count_trials` = '0';
    UPDATE `request_async` SET `is_success` = null;
    UPDATE `request_async` SET `is_done` = '0';
*/
