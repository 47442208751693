import React from 'react';
import {makeStyles} from "@mui/styles";
import {LoadingButton} from '@mui/lab';
import {useSelector} from "react-redux";

function ButtonComponent(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <LoadingButton
            className={`${isTall ? (props.small ? classes.buttonSmall : classes.button) : (props.small ? classes.buttonVerySmall : classes.buttonSmall)} ${props.className ? props.className : ''}`}
            loading={props.loading}
            disabled={props.disabled}
            variant={props.outlined ? 'outlined' : 'contained'}
            style={props.color ? {
                ...props.style,
                backgroundColor: props.outlined ? 'unset' : props.color,
                color: props.loading ? 'rgba(255,255,255,0)' : (props.outlined ? props.color : '#FFFFFF'),
                borderColor: props.color
            } : {
                ...props.style,
                backgroundColor: props.outlined ? 'unset' : '#0484c7',
                color: props.loading ? 'rgba(255,255,255,0)' : (props.outlined ? props.color : '#FFFFFF'),
            }}
            onClick={props.onClick}>
            {props.label}
        </LoadingButton>
    );
}

const useStyles = makeStyles({
    button: {
        fontSize: '10px !important',
        opacity: 0.9,
    },
    buttonSmall: {
        fontSize: '10px !important',
        opacity: 0.9,
        transform: 'scale(0.8)'
    },
    buttonVerySmall: {
        fontSize: '10px !important',
        opacity: 0.9,
        transform: 'scale(0.6)'
    }
});


export default ButtonComponent;
