import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {makeStyles} from '@mui/styles';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {getPathDefault, getRoutes, updateRouting} from "./Handler/RouteHandler";
import {ScreenChange} from "./Action/ScreenAction";
import SnackbarComponent from "./Component/SnackbarComponent";
import ApiHandler from "./Handler/ApiHandler";
import {AuthenticationLogout} from "./Action/AuthenticationAction";
import momentFR from "moment-locale-fr";
import moment from 'moment';

// Version
const VERSION = 'v1.0.0';

// Dispatch
export let dispatch;

// Moment
moment.updateLocale('fr', momentFR);
moment.locale('fr');

function App() {
    dispatch = useDispatch();
    const classes = useStyles();

    const [isReady, setReady] = React.useState(false);

    // Screen
    const resize = () => {
        let screen = '';

        if (window.innerWidth < 576) screen = 'XS';
        if (window.innerWidth >= 576 && window.innerWidth < 768) screen = 'SM';
        if (window.innerWidth >= 768 && window.innerWidth < 992) screen = 'MD';
        if (window.innerWidth >= 992 && window.innerWidth < 1200) screen = 'LG';
        if (window.innerWidth >= 1200) screen = 'XL';

        dispatch(ScreenChange({
            screen: screen,
            size: window.innerWidth,
            orientation: window.screen.orientation && window.screen.orientation.angle === 90
        }));
    };
    React.useEffect(() => {
        window.removeEventListener('resize', resize)
        window.addEventListener('resize', resize);
        window.addEventListener('orientationchange', resize);
        resize();
    }, []);

    // Security & Routes
    const [routes, setRoutes] = React.useState(false);
    const [routeDefault, setRouteDefault] = React.useState('');
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    React.useEffect(() => {
        ApiHandler.get({route: 'api_default'}, (response) => {
            if (!response.data.authenticated && authenticationReducer.email) dispatch(AuthenticationLogout());
            setReady(true);
        });
    }, []);
    React.useEffect(() => {
        updateRouting(authenticationReducer);
        setRoutes(getRoutes());
        setRouteDefault(getPathDefault());
        setReady(true);
    }, [authenticationReducer]);

    if (isReady && routes) {
        return <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <BrowserRouter>
                    <Routes>
                        {
                            Object.keys(routes).map((key) => {
                                let route = routes[key];
                                return <Route
                                    key={'route_' + key}
                                    path={route.path}
                                    element={<route.layout view={route.view}/>}
                                />;
                            })
                        }
                        <Route path="*" element={<Navigate to={routeDefault} replace/>}/>
                    </Routes>
                </BrowserRouter>
            </LocalizationProvider>

            <SnackbarComponent/>
            <div className={classes.version}>{VERSION} by MakerSystem</div>
        </>
    }
}

const useStyles = makeStyles({
    version: {
        position: 'absolute',
        zIndex: 1000,
        bottom: 0,
        right: 0,
        fontSize: 10,
        padding: '5px 10px',
        color: 'rgb(104 104 104)'
    }
});

export default App;
