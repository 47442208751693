import React from 'react';
import {makeStyles} from "@mui/styles";
import {Link, useNavigate} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {hasRight} from "../../../../Common/UserCommon";
import {Grid, IconButton} from '@mui/material';
import SelectComponent from "../../../../Component/SelectComponent";
import {Article} from "@mui/icons-material";
import CheckboxComponent from "../../../../Component/CheckboxComponent";

function ShopCreateClientForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionsCountry, setOptionsCountry] = React.useState([]);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_SHOP_SALES') && !hasRight(authenticationReducer, 'ROLE_SHOP_SELLER');

    const [form, setForm] = React.useState({
        uuid: {
            name: 'uuid',
            label: 'id automatique',
            textHelper: 'ne pas remplir.',
            type: 'text',
            defaultValue: ''
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez le nom.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l adresse email.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        country: {
            name: 'country',
            label: 'Pays',
            textHelper: 'Selectionnez le pays du client.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le téléphone.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        optinSms: {
            name: 'optinSms',
            label: 'Recevoir sms TLN',
            textHelper: 'cocher si ok',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        optinEmail: {
            name: 'optinEmail',
            label: 'Recevoir emails TLN',
            textHelper: 'cocher si ok',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.shopId) {
                ApiHandler.post({
                    route: 'api_shop_sales_create_client',
                    data: handlerForm.getData(),
                    params: {shopId: props.shopId}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Client modifié avec succès.',
                                variant: 'success',
                            })
                        );
                        navigate(getRoutePathname('shop_sales_select_products', {clientId: response.data.uuid, shopId: props.shopId}));
                    } else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_country'}, (response) => { setOptionsCountry(response.data)});

        if (props.client) {
            handlerForm.setDataApi(props.client);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Nouveau client'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'uuid'} handler={handlerForm} disabled={true}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'firstname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'lastname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'zipCode'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'email'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <SelectComponent id={'country'} options={optionsCountry} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'phone'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CheckboxComponent id={'optinSms'} handler={handlerForm} orientation={'right'}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CheckboxComponent id={'optinEmail'} handler={handlerForm} orientation={'right'}/>
                </Grid>
            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
            <Link to={getRoutePathname('shop_sales_select_products', {clientId: '2bc2f19a-df2b-4cfc-9e62-84a40b41d395', shopId: authenticationReducer.shopSelected})}>
                <IconButton><Article style={{color: '#17a2b8'}}/></IconButton> Compte anonyme
            </Link>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ShopCreateClientForm;
