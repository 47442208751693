import React from 'react';
import {Fade, Grid} from "@mui/material";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import BuyPurveyorForm from "./BuyPurveyorComponents/BuyPurveyorForm";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import BuyPurveyorByProductList from "./BuyPurveyorComponents/BuyPurveyorByProductList";
import BuyPurveyorOrderList from './BuyPurveyorComponents/BuyPurveyorOrderList';
import TabsComponent from '../../../Component/TabsComponent';
import BuyPurveyorContactList from './BuyPurveyorComponents/BuyPurveyorContactList';

function BuyPurveyor() {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [purveyor, setPurveyor] = React.useState(null);
    React.useEffect(() => {
        if (params.id === 'add') {
            setPurveyor(null);

            setBreadcrumbs({
                title: 'Ajouter un fournisseur',
                context: 'Achat',
                description: '',
                links: [
                    {path: getRoutePathname('buy_purveyor_list'), label: 'Liste des fournisseurs'}
                ]
            });

            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else {
            setPurveyor(null);
            setIsLoadingContent(true)

            setBreadcrumbs({
                title: 'Éditer un fournisseur',
                context: 'Achat',
                description: '',
                links: [
                    {path: getRoutePathname('buy_purveyor_list'), label: 'Liste des fournisseurs'}
                ]
            });

            ApiHandler.get({
                route: 'api_buy_purveyor_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setPurveyor(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setIsLoadingContent(false);
            });
        }
    }, [location]);

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>
            <Grid container spacing={2}>
                <Fade in={true} {...{timeout: 750}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <BuyPurveyorForm purveyor={purveyor}/>
                    </Grid>
                </Fade>
            </Grid>

            <br/>

            {purveyor && <TabsComponent
                tabDefault={'purveyor_by_product'}
                tabs={[
                    { key: 'purveyor_by_product', label: 'Produits' },
                    { key: 'purveyor_order', label: 'Commandes' },
                    { key: 'purveyor_contact', label: 'Contacts' },
                ]}
            >
                <div key={'purveyor_by_product'}>
                    <BuyPurveyorByProductList purveyor={purveyor}/>
                </div>
                <div key={'purveyor_order'}>
                    <BuyPurveyorOrderList purveyor={purveyor}/>
                </div>
                <div key={'purveyor_contact'}>
                    <BuyPurveyorContactList purveyor={purveyor}/>
                </div>
            </TabsComponent>}

            <br/><br/>
        </ContentViewComponent>
    );
}

export default BuyPurveyor;
