import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {hasRight} from "../../../Common/UserCommon";
import {IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Article, Delete, Edit} from "@mui/icons-material";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";
import TableComponent from "../../../Component/TableComponent";

function ClientBusinessContactList(props) {

    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom de famille",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "phone",
            label: "Téléphone",
            options: {filter: true, sort: true}
        },
        {
            name: "role",
            label: "Rôle",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') ||
            hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET'))) {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <>
                                <Tooltip title={'Modifier'} placement="left">
                                    <Link to={getRoutePathname('b2b_client_business_contact_edit', {clientBusinessContactId: row.id})}>
                                        <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                                    </Link>
                                </Tooltip>
                                <Tooltip title={'Supprimer'} placement="left">
                                    <IconButton  onClick={() => {
                                            ApiHandler.delete({route: 'api_b2b_client_business_contact_delete', params: {id: row.id}}, (response) => {
                                                if (response.status === 200) {
                                                    dispatch(
                                                        SnackbarOpen({
                                                            text: 'Contact supprimé.',
                                                            variant: 'success',
                                                        })
                                                    );
                                                    dispatch(TableReload('api_b2b_client_business_contact_list'))
                                                }
                                                else {
                                                    dispatch(
                                                        SnackbarOpen({
                                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                                            variant: 'error',
                                                        })
                                                    );
                                                }
                                            });
                                        }}><Delete style={{color: 'red'}}/></IconButton>
                                </Tooltip>
                            </>
                        : (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET')) ?
                                <Tooltip title={'Visualiser'} placement="left">
                                    <Link to={getRoutePathname('b2b_client_business_contact', {clientBusinessContactId: row.id})}>
                                        <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                                    </Link>
                                </Tooltip> : <IconButton disabled={true}><Edit style={{color: '#17a2b8'}}/></IconButton>
                    }
                </>
            );
        }
        else {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Edit/>
                            </IconButton> :
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Article/>
                            </IconButton>
                    }
                </>
            );
        }
    };

    return (
        <TableComponent
            id={'api_b2b_client_business_contact_list'}
            title={props.clientBusiness[0].contacts.length > 0 ? 'Liste des contacts' : 'Liste des contacts (Vous devez ajouter au moins un contact avant de pouvoir créer une proposition commerciale)'}
            columns={columns}
            actionFirst={hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD') ? {
                label: 'Ajouter un contact',
                link: getRoutePathname('b2b_client_business_contact_add', {clientBusinessId: props.clientBusiness[0].id})
            } : false}
            promiseData={(resolve) => {
                ApiHandler.get({route: 'api_b2b_client_business_contact_list', params: {id: props.clientBusiness[0].id}},
                    (response) => {
                        let data = response.data[0];
                        for (let index in data) {
                            data[index].action = getAction(data[index]);
                            data[index].firstname = data[index].firstname ? data[index].firstname : '-';
                            data[index].lastname = data[index].lastname ? data[index].lastname : '-';
                            data[index].phone = data[index].phone ? data[index].phone : '-';
                            data[index].email = data[index].email ? <a href={"mailto:" + data[index].email}>{data[index].email}</a> : '-';
                            data[index].role = data[index].role ? data[index].role : '-';
                        }
                        resolve(data);
                    });
            }}
        />
    );
}

export default ClientBusinessContactList;