import React, {useRef} from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {useNavigate, useParams} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Paper from '@mui/material/Paper';
import {initFormHandler} from "../../../Handler/FormHandler";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {makeStyles} from "@mui/styles";
import ButtonComponent from "../../../Component/ButtonComponent";
import {QrCodeScanner} from "@mui/icons-material";
import useSound from "use-sound";
import {dispatch} from "../../../App";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useSelector} from "react-redux";
import {hasRight} from "../../../Common/UserCommon";
import TitleComponent from "../../../Component/TitleComponent";
import SelectComponent from "../../../Component/SelectComponent";

function InventoryManagementInventoryDepositInventory() {
    const [beep] = useSound('/mp3/beep.mp3');
    const [boop] = useSound('/mp3/boop.mp3');

    const classes = useStyles();
    const params = useParams();
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [byProducts, setByProducts] = React.useState([]);
    const [isFocus, setFocus] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [mark, setMark] = React.useState(null);

    const ref = useRef(null);
    const [formRef, setFormRef] = React.useState({
        picking: {
            name: 'picking',
            label: 'piking',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handlerFormRef = initFormHandler(formRef, setFormRef);

    // Form
    const [inputs, setInputs] = React.useState({});
    let handlerForm = initFormHandler(inputs, setInputs);

    const onChange = (value) => {
        for (let index in byProducts) {
            if (byProducts[index].ean) {
                if (value === byProducts[index].ean) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Article enregistré.',
                            variant: 'success',
                        })
                    );
                    beep();

                    handlerForm.setValue('amount_' + byProducts[index].batch.id, (inputs['amount_' + byProducts[index].batch.id].value ?? 0) + 1);
                    return;
                }
            }
        }
        boop();
        dispatch(
            SnackbarOpen({
                text: 'Article introuvable !',
                variant: 'error',
            })
        );
    };
    const save = (callback = false) => {
        if (handlerForm.checkError() < 1) {
            setLoadingForm(true);
            ApiHandler.post({
                route: 'api_inventory_management_inventory_deposit_inventory_add',
                params: {deposit: params.deposit},
                data: {'byProduct': handlerForm.getData()}
            }, (response) => {
                setLoadingForm(false);
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Inventaire enregistré.',
                            variant: 'success',
                        })
                    );

                    if (callback) callback();
                    else navigate(getRoutePathname('inventory_management_inventory_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };
    const validated = () => {
        save(() => {
            setLoadingForm(true);
            ApiHandler.post({
                route: 'api_inventory_management_inventory_deposit_inventory_validated',
                params: {deposit: params.deposit}
            }, (response) => {
                setLoadingForm(false);
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Inventaire Validé.',
                            variant: 'success',
                        })
                    );
                    navigate(getRoutePathname('inventory_management_inventory_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setLoadingForm(true);
                }
            });
        });
    };

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Choisissez une marque.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        ApiHandler.get({
                route: 'api_inventory_management_inventory_deposit_inventory_info',
                params: {deposit: params.deposit}
            },
            (response) => {
                setBreadcrumbs({
                    title: 'Inventaire (' + response.data.name + ')',
                    context: 'Gestion des stocks',
                    description: '',
                    links: [
                        {path: getRoutePathname('inventory_management_inventory_list'), label: 'Liste des inventaires'}
                    ]
                });

                let byProductsInput = {};
                let marksTmp = {};
                for (let index in response.data.byProducts) {
                    marksTmp[response.data.byProducts[index].markId] = {label: response.data.byProducts[index].markLabel, value: response.data.byProducts[index].markId};
                    byProductsInput['amount_' + response.data.byProducts[index].batch.id] = {
                        name: 'amount_' + response.data.byProducts[index].batch.id,
                        label: 'Quantité',
                        textHelper: 'Saisissez la quantité de l\'article.',
                        type: 'integer',
                        defaultValue: response.data.byProducts[index].batch.amountEntered ?? '',
                        value: response.data.byProducts[index].batch.amountEntered ?? '',
                        options: {min: 0}
                    };
                }

                let marks = [{label: '', value: ''}];
                for (let index in marksTmp) {
                    marks.push(marksTmp[index]);
                }

                setOptionsMark(marks);

                setInputs(byProductsInput);
                setByProducts(response.data.byProducts);
                setLoading(false);
            });
    }, []);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {isFocus && <QrCodeScanner style={{color: '#28a745', position: 'absolute', top: 25, right: 50, fontSize: 35}}/>}
            <TextFieldComponent
                style={{position: 'absolute', right: 0, top: 0, height: 0, width: 50, opacity: 0, zIndex: 1000}}
                id={'picking'}
                handler={handlerFormRef}
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
                inputRef={ref}
                onScan={onChange}
                autoComplete={false}
            />

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormSearch} onChange={(val) => setMark(val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <ShadowBoxComponent className={classes.shadowBox}>
                {
                    isFocus ?
                        <ButtonComponent color={'#a47b00'} onClick={() => {
                            ref.current.blur();
                        }} label={'Désactiver le lecteur code barre'} className={classes.buttonTop}/> :
                        <ButtonComponent onClick={() => {
                            ref.current.focus();
                        }} label={'Activer le lecteur code barre'} className={classes.buttonTop}/>
                }

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 900, fontSize: 12}}>SKU</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Nom</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Parfum / Couleur</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Conditionnement</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Marque</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Lot</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Date du lot</TableCell>
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">Quantité</TableCell>
                                {(hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED')) &&
                                <TableCell style={{fontWeight: 900, fontSize: 12}} align="right">En stock</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(byProducts.length > 0) ? byProducts.map((row) => {
                                if (mark && row.markId !== mark) return;
                                return (
                                    <TableRow key={row.batch.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell style={{fontSize: 11}} component="th" scope="row">{row.sku}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.name}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.flavor}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.packSize}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.markLabel}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.batch.name}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">{row.batch.createdAt}</TableCell>
                                        <TableCell style={{fontSize: 11}} align="right">
                                            <TextFieldComponent id={'amount_' + row.batch.id} handler={handlerForm} disabled={loadingForm || !(hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD'))}/>
                                        </TableCell>
                                        {(hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED')) &&
                                        <TableCell style={{fontWeight: 800, fontSize: 13, color: (!inputs['amount_' + row.batch.id].value ? '#17a2b8' : (inputs['amount_' + row.batch.id].value < row.batch.amount) ? '#982525' : '#006500')}} align="right">
                                            {row.batch.amount} {inputs['amount_' + row.batch.id].value ? '(' + (inputs['amount_' + row.batch.id].value - row.batch.amount) + ')' : ''}
                                        </TableCell>}
                                    </TableRow>
                                )
                            }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{position: 'absolute', bottom: 0, right: 0}}>
                    {(hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED')) &&
                    <ButtonComponent color={'#35A2EB'} label={'Valider l\'inventaire'} className={classes.button} onClick={() => setOpenDialog(true)} loading={loadingForm}/>}
                    {(hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD')) &&
                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={() => save()} loading={loadingForm}/>}
                </div>
            </ShadowBoxComponent>

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{'Confirmation'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    Confirmez-vous l'inventaire et la mise à jour des stocks ?
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} loading={loadingForm}/>
                    <ButtonComponent label={'Oui'} onClick={validated} loading={loadingForm}/>
                </DialogActions>
            </Dialog>
            <br/><br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60,
        paddingTop: 60
    },
    button: {
        margin: '15px 15px 15px 0 !important',
    },
    buttonTop: {
        margin: '15px !important',
        top: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    }
});

export default InventoryManagementInventoryDepositInventory;
