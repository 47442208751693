import React from 'react';
import {makeStyles} from "@mui/styles";
import AppBar from "./Component/AppBar";
import {IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getRoutePathname} from "../../Handler/RouteHandler";
import {Edit} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import ApiHandler from "../../Handler/ApiHandler";
import {TableReload} from "../../Action/TableAction";
import {SnackbarOpen} from "../../Action/SnackbarAction";

function ShopLayout(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const location = useLocation();

    ApiHandler.post({
        route: 'api_shop_sales_pending_sign',
        params: {shopId: authenticationReducer.shopSelected}
    }, (response) => {
        if (response.status === 200) {
            if(response.data.blockToSign == 1 && location.pathname != "/shop/sign") {
               navigate(getRoutePathname('shop_sales_sign_opening'));
            }
        }
    });

    return (
        <div className={classes.mainLayout}>
            {/* <== AppBar */}
            <AppBar/>
            {/* AppBar ==> */}

            {/* <== View */}
            <div className={classes.view}>
                <div style={{padding: '2px 10px', background: '#1d90cc', color: '#fff'}}>
                   <p>Vous êtes connecté à la boutique de : {authenticationReducer.shopNameSelected}</p>
                </div>
                <br/>
                <props.view/>
            </div>
            {/* View ==> */}
        </div>
    );
}

const useStyles = makeStyles({
    mainLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex'
    },
    view: {
        flexGrow: 1,
        overflowX: 'scroll',
        paddingTop: 58,
        boxSizing: 'border-box'
    }
});

export default ShopLayout;
