const AuthenticationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'AuthenticationLogin':
            return {
                ...state,
                ...action.data
            };
        case 'AuthenticationLogout':
            return {};
        default:
            return state
    }
};

export default AuthenticationReducer;
