import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {Grid, IconButton, Slide, Tooltip} from "@mui/material";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import {cacheTableServer} from '../../../Cache';
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import SelectComponent from "../../../Component/SelectComponent";
import {isNumber} from "../../../Util/NumberTool";
import {dispatch} from "../../../App";
import {TableReload} from "../../../Action/TableAction";
import ButtonComponent from "../../../Component/ButtonComponent";
import {makeStyles} from "@mui/styles";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Link} from "react-router-dom";
import moment from "moment";
import {Edit, CloudDownload} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {hasRight} from "../../../Common/UserCommon";
import {floatSortComparatorForTableComponent} from "../../../Util/SortNumber";

function InventoryManagementInventoryList() {
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [optionsDeposit, setOptionsDeposit] = React.useState([]);
    const [hasInventoryCurrent, setHasInventoryCurrent] = React.useState(false);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum / Couleur",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Taille du conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        },
        {
            name: "amountReservedText",
            label: "Quantité réservée",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        },
        {
            name: "amountAvailableText",
            label: "Quantité disponible",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        },
        {
            name: "amountCurrentSupplyText",
            label: "Quantité en cours d’approvisionnement",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        },
        {
            name: "amountTermText",
            label: "Quantité à terme",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (val1, val2) => {
                        return floatSortComparatorForTableComponent(order, val1, val2);
                    };
                },
            }
        }
    ];
    const columnsInventory = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "validatedAt",
            label: "Validation",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: true, sort: true}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        deposit: {
            name: 'deposit',
            label: 'Dépôt',
            textHelper: 'Choisissez un dépôt.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const getAction = (row) => {
        if (row.validatedAt) {
            return <div>
                {
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED')) ?
                        <Tooltip title={'Télécharger l\'inventaire'} placement="left">
                            <IconButton onClick={() => {
                                fetch(ApiHandler.route({route: 'api_inventory_management_inventory_deposit_inventory_download', params: {deposit: formSearch.deposit.value, inventory: row.id}}), {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                                    .then(res => res.blob())
                                    .then(res => {
                                        const aElement = document.createElement('a');
                                        aElement.setAttribute('download', 'inventaire_' + row.id + '.xls');
                                        const href = URL.createObjectURL(res);
                                        aElement.href = href;
                                        aElement.setAttribute('target', '_blank');
                                        aElement.click();
                                        URL.revokeObjectURL(href);
                                    });
                            }}><CloudDownload style={{color: '#28a745'}}/></IconButton>
                        </Tooltip> : <IconButton style={{cursor: 'no-drop'}}>
                            <CloudDownload/>
                        </IconButton>
                }
            </div>;
        }
        else {
            return <div>
                {
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_VALIDATED')) ?
                        <Tooltip title={'Visualiser l\'inventaire'} placement="left">
                            <Link to={getRoutePathname('inventory_management_inventory_deposit_inventory', {deposit: formSearch.deposit.value})}>
                                <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                            </Link>
                        </Tooltip> : <IconButton style={{cursor: 'no-drop'}}>
                            <Edit/>
                        </IconButton>
                }
            </div>;
        }
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des sous articles et inventaires',
            context: 'Gestion des stocks',
            description: '',
            links: []
        });

        ApiHandler.get({route: 'api_select_deposit', data: {'limited': true}}, (response) => {
            setOptionsDeposit(response.data);
            let val = cacheTableServer['api_inventory_management_inventory_deposit_by_product_list'] && cacheTableServer['api_inventory_management_inventory_deposit_by_product_list'].deposit ? cacheTableServer['api_inventory_management_inventory_deposit_by_product_list'].deposit : '';
            handlerFormSearch.setValue('deposit', val ? val : response.data[0] ? response.data[0].value : '');
            setLoading(false);
        });
    }, []);
    React.useEffect(() => {
        dispatch(TableReload('api_inventory_management_inventory_deposit_by_product_list'));
        setTimeout(() => dispatch(TableReload('api_inventory_management_inventory_deposit_inventory_list')), 50);
    }, [formSearch.deposit.value]);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerFormSearch}/>
                            </Grid>
                        </Grid>
                        {
                            (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_INVENTORY_ADD')) &&
                            <Link to={getRoutePathname('inventory_management_inventory_deposit_inventory', {deposit: formSearch.deposit.value})}>
                                <ButtonComponent color={!formSearch.deposit.value ? 'rgba(132,132,132,0.48)' : null} disabled={!formSearch.deposit.value} label={hasInventoryCurrent ? 'Reprendre l\'inventaire en cours' : 'Faire un inventaire'} className={classes.button}/>
                            </Link>
                        }
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                    <TableComponent
                        id={'api_inventory_management_inventory_deposit_by_product_list'}
                        title={'Sous article'}
                        columns={columns}
                        promiseData={(resolve) => {
                            let datas = handlerFormSearch.getData();
                            if (datas['deposit']) {
                                cacheTableServer['api_inventory_management_inventory_deposit_by_product_list'] = {'deposit': datas['deposit']};
                                ApiHandler.get({
                                        route: 'api_inventory_management_inventory_deposit_by_product_list',
                                        params: {deposit: datas['deposit']}
                                    },
                                    (response) => {
                                        let data = response.data;
                                        for (let index in data) {
                                            data[index].amountText = isNumber(data[index].amount) ? data[index].amount.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                            data[index].amountReservedText = isNumber(data[index].amountReserved) ? data[index].amountReserved.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                            data[index].amountAvailableText = isNumber(data[index].amountAvailable) ? data[index].amountAvailable.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                            data[index].amountCurrentSupplyText = isNumber(data[index].amountCurrentSupply) ? data[index].amountCurrentSupply.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                            data[index].amountTermText = isNumber(data[index].amountTerm) ? data[index].amountTerm.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                        }
                                        resolve(data);
                                    });
                            }
                            else {
                                resolve([]);
                            }
                        }
                        }/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TableComponent
                        id={'api_inventory_management_inventory_deposit_inventory_list'}
                        title={'Inventaires'}
                        columns={columnsInventory}
                        promiseData={(resolve) => {
                            let datas = handlerFormSearch.getData();
                            if (datas['deposit']) {
                                ApiHandler.get({
                                        route: 'api_inventory_management_inventory_deposit_inventory_list',
                                        params: {deposit: datas['deposit']}
                                    },
                                    (response) => {
                                        let data = response.data;
                                        setHasInventoryCurrent(false);

                                        for (let index in data) {
                                            if (!data[index].validatedAt) setHasInventoryCurrent(true);

                                            data[index].action = getAction(data[index]);
                                            data[index].link = data[index].link ?? '-';
                                            data[index].validatedAt = data[index].validatedAt ? moment(data[index].validatedAt).format('lll') : 'En cours...';
                                        }

                                        resolve(data);
                                    });
                            }
                            else {
                                resolve([]);
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <br/><br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    }
});

export default InventoryManagementInventoryList;
