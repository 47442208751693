import {dispatch} from "../App";
import {SnackbarOpen} from "../Action/SnackbarAction";

export function displayErrorMessage(response) {
    return dispatch(
        SnackbarOpen({
            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
            variant: 'error',
        })
    );
}
