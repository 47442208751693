import {useDispatch} from "react-redux";
import React from "react";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ApiHandler from "../../../../Handler/ApiHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid, Slide} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {cacheTablePage, cacheTableServer} from "../../../../Cache";
import {TableReload} from "../../../../Action/TableAction";
import SelectComponent from "../../../../Component/SelectComponent";
import {useLocation} from "react-router-dom";

function ClientBusinessPriceListFilterForm(props) {
    const [optionsMark, setOptionsMark] = React.useState([]);
    const location = useLocation();
    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marques.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
    })

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        props.setFilters({markId : value});
    };

    React.useEffect(() => {
        handlerFormSearch.start();
        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Toutes'}, ...response.data];
            setOptionsMark(data)
        });
    }, [location])

    return (
        <>
            {
                props.priceList &&
                <>
                    <Slide direction={'left'} in={true} {...{timeout: 750}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent>
                                <TitleComponent title={'Filtres'}/>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                        <SelectComponent
                                            id={'mark'}
                                            options={optionsMark}
                                            handler={handlerFormSearch}
                                            onChange={(val) => inputSearchChange('mark', val)}
                                        />
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>
                    </Slide>
                    <br/>
                </>
            }
        </>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ClientBusinessPriceListFilterForm;