import React, {useEffect, useState} from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import moment from "moment";
import {Box, Grid, Slide} from '@mui/material';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {initFormHandler} from '../../../Handler/FormHandler';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import {isNumber} from "../../../Util/NumberTool";
import {DataGrid, frFR, GridColDef} from "@mui/x-data-grid";
import {setEndDateFilter, setStartDateFilter} from "../../../Filter/DateFilters";
import {displayErrorMessage} from "../../../Error/Errors";
import {CustomPagination} from "../../../Component/DataGrid/CustomPagination";
import {styleDataGridDefault} from "../../../Style/styleDataGridDefault";
import ButtonComponent from "../../../Component/ButtonComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import {listingStyles} from "../../../Style/useStyles";
import CustomToolbar from "../../../Component/DataGrid/CustomToolbar";
import FooterWithTotalGeneral from "../../../Component/DataGrid/FooterWithTotalGeneral";

function InventoryManagementMovementList() {
    const [loadingContent, setLoadingContent] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState({});
    const [optionsPurveyor, setOptionsPurveyor] = useState([]);
    const [optionsDeposit, setOptionsDeposit] = useState([]);
    const [optionsChannel, setOptionsChannel] = useState([]);
    const [optionsMovementAction, setOptionsMovementAction] = useState([]);
    const [optionsMark, setOptionsMark] = useState([]);
    const [optionsPackSize, setOptionsPackSize] = useState([]);
    const [optionsFlavor, setOptionsFlavor] = useState([]);
    const [cellsTable, setCellsTable] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const [formSearch, setFormSearch] = useState({
        byProductSku: {
            name: 'byProductSku',
            label: 'SKU',
            textHelper: 'Rechercher par codes.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        purveyor: {
            name: 'purveyor',
            label: 'Fournisseur',
            textHelper: 'Rechercher par fournisseurs.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        deposit: {
            name: 'deposit',
            label: 'Dépot',
            textHelper: 'Rechercher par dépots.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        channel: {
            name: 'channel',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        movementAction: {
            name: 'movementAction',
            label: 'Action',
            textHelper: 'Rechercher par actions.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        batch: {
            name: 'batch',
            label: 'Lot',
            textHelper: 'Rechercher par lots.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marque d\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        byProduct: {
            name: 'byProduct',
            label: 'Article',
            textHelper: 'Rechercher par nom d\'article.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        packSize: {
            name: 'packSize',
            label: 'Conditionnement',
            textHelper: 'Rechercher par conditionnement.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        flavor: {
            name: 'flavor',
            label: 'Parfum',
            textHelper: 'Rechercher par parfum.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            defaultValue: '',
            options: {validation: ['date']}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            defaultValue: '',
            options: {validation: ['date']}
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
    };

    useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des mouvements',
            context: 'Gestion des stocks',
            description: '',
            links: []
        });

        // get options MovementList
        ApiHandler.get({route: 'api_select_purveyor'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsPurveyor(data)
        });
        ApiHandler.get({route: 'api_select_deposit'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsDeposit(data)
        });
        ApiHandler.get({route: 'api_select_channel'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsChannel(data)
        });
        ApiHandler.get({route: 'api_select_movement_action'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMovementAction(data)
        });
        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMark(data)
        });
        ApiHandler.get({route: 'api_select_pack_size'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsPackSize(data)
        });
        ApiHandler.get({route: 'api_select_flavor'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsFlavor(data)
        });

        setLoadingContent(false);
    }, []);

    const onSearch = () => {
        setLoadingContent(true);

        let datas = handlerFormSearch.getData();

        let filters= {
            byProductSku: formSearch.byProductSku.value ? formSearch.byProductSku.value : '',
            purveyor: (datas.purveyor === 0 || !datas.purveyor) ? '' : datas.purveyor,
            deposit: (datas.deposit === 0 || !datas.deposit) ? '' : datas.deposit,
            channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
            movementAction: (datas.movementAction === 0 || !datas.movementAction) ? '' : datas.movementAction,
            batch: formSearch.batch.value ? formSearch.batch.value : '',
            byProduct: formSearch.byProduct.value ? formSearch.byProduct.value : '',
            mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark,
            flavor: (datas.flavor === 0 || !datas.flavor) ? '' : datas.flavor,
            packSize: (datas.packSize === 0 || !datas.packSize) ? '' : datas.packSize,
            startDate: formSearch.startDate.value ? setStartDateFilter(formSearch.startDate.value) : '',
            endDate: formSearch.endDate.value ? setEndDateFilter(formSearch.endDate.value) : '',
        };

        ApiHandler.get({
                route: 'api_inventory_management_movement_list',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    let data = response.data.data;
                    for (let index in data) {
                        data[index].purveyor = data[index].purveyor ?? '-';
                        data[index].channel = data[index].channel ?? '-';
                        data[index].comment = data[index].comment ?? '-';
                        data[index].createdAt = moment(data[index].createdAt).format('lll');
                        data[index].amountText = isNumber(data[index].amount) ? data[index].amount.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                    }
                    setCellsTable(data);
                    const totalAmount = response.data.totalAmount;
                    setTotalAmount(totalAmount);
                    setLoadingContent(false);
                } else {
                    setLoadingContent(false);
                    displayErrorMessage(response);
                }
            }
        );
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Référence",
            minWidth: 100,
        },
        {
            field: "byProductSku",
            headerName: "SKU",
            minWidth: 180,
        },
        {
            field: "productName",
            headerName: "Article",
            minWidth: 200,
        },
        {
            field: "mark",
            headerName: "Marque",
            minWidth: 100,
        },
        {
            field: "packSize",
            headerName: "Conditionnement",
            minWidth: 150,
        },
        {
            field: "flavor",
            headerName: "Parfum",
            minWidth: 100,
        },
        {
            field: "amountText",
            headerName: "Quantité",
            minWidth: 80,
        },
        {
            field: "purveyor",
            headerName: "Fournisseur",
            minWidth: 150,
        },
        {
            field: "deposit",
            headerName: "Dépôt",
            minWidth: 100,
        },
        {
            field: "channel",
            headerName: "Canal",
            minWidth: 100,
        },
        {
            field: "comment",
            headerName: "Commentaire",
            minWidth: 150,
        },
        {
            field: "batch",
            headerName: "Lot",
            minWidth: 100,
        },
        {
            field: "movementAction",
            headerName: "Action",
            minWidth: 100,
        },
        {
            field: "createdAt",
            headerName: "Date",
            minWidth: 150,
        }
    ];

    const rows = Object.values(cellsTable).map((row, index) => (
        {...row}
    ));

    const ignoreDiacritics = true;
    const disableRowSelectionOnClick = true;
    const exportFileName = 'movement_list';

    const sxDataGrid = {
        ...styleDataGridDefault,
    };

    const classes = listingStyles();

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={4}>
                                <TextFieldComponent id={'byProductSku'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProductSku', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'purveyor'} options={optionsPurveyor} handler={handlerFormSearch} onChange={(val) => inputSearchChange('purveyor', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerFormSearch} onChange={(val) => inputSearchChange('deposit', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} onChange={(val) => inputSearchChange('channel', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'movementAction'} options={optionsMovementAction} handler={handlerFormSearch} onChange={(val) => inputSearchChange('movementAction', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'byProduct'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProduct', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormSearch} onChange={(val) => inputSearchChange('mark', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'packSize'} options={optionsPackSize} handler={handlerFormSearch} onChange={(val) => inputSearchChange('packSize', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'flavor'} options={optionsFlavor} handler={handlerFormSearch} onChange={(val) => inputSearchChange('flavor', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <TextFieldComponent id={'batch'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('batch', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <DatePickerComponent id={'startDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('startDate', val)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <DatePickerComponent id={'endDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('endDate', val)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <ButtonComponent
                                    className={classes.buttonActionFirst}
                                    label={"Rechercher"}
                                    loading={loadingContent}
                                    onClick={() => onSearch()}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br></br>

            <Box sx={{height: 600, width: '100%'}}>
                <DataGrid
                    sx={sxDataGrid}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={rows}
                    disableColumnMenu
                    rowHeight={25}
                    slots={{
                        toolbar: () => <CustomToolbar fileName={exportFileName}/>,
                        pagination: CustomPagination,
                        footer: () => <FooterWithTotalGeneral totalGeneralValue={totalAmount} label="Total quantité" valueType="integer"/>,
                    }}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    ignoreDiacritics={ignoreDiacritics}
                    disableRowSelectionOnClick={disableRowSelectionOnClick}
                />
            </Box>
        </ContentViewComponent>
    );
}

export default InventoryManagementMovementList;
