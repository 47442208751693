import React from 'react';
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {hasRight} from "../../../../Common/UserCommon";
import { Grid } from '@mui/material';
import SelectComponent from '../../../../Component/SelectComponent';
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";

function ShopManagementShopForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [optionsShopRegion, setOptionsShopRegion] = React.useState([]);
    const [optionsUser, setOptionsUser] = React.useState([]);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la boutique.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        openingTime: {
            name: 'openingTime',
            label: 'Horaire d\'ouverture',
            textHelper: 'Saisissez l\'horaire d\'ouverture de la boutique.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        closingTime: {
            name: 'closingTime',
            label: 'Horaire de fermeture',
            textHelper: 'Saisissez l\'horaire de fermeture de la boutique.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        shopRegion: {
            name: 'shopRegion',
            label: 'Région boutique',
            textHelper: 'Choisissez la région de la boutique.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        userRegion: {
            name: 'userRegion',
            label: 'Responsable régional',
            textHelper: 'Choisissez l\'utilisateur région boutique.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        userNational: {
            name: 'userNational',
            label: 'Responsable national',
            textHelper: 'Choisissez l\'utilisateur national boutique.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        userLogistique: {
            name: 'userLogistique',
            label: 'Responsable logistique',
            textHelper: 'Choisissez l\'utilisateur logistique boutique.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        userSellers: {
            name: 'userSellers',
            label: 'Vendeurs',
            textHelper: 'Choisissez les vendeurs boutique.',
            type: 'array',
            defaultValue: '',
            options: {}
        }
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.shop) {
                ApiHandler.post({
                    route: 'api_shop_management_shop_edit',
                    data: handlerForm.getData(),
                    params: {id: props.shop.id}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Boutique modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_shop_management_shop_add',
                    data: handlerForm.getData()
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('shop_management_shop', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Boutique créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.shop) {
            handlerForm.setDataApi(props.shop);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_shop_region'}, (response) => {
            let data = [{value: '', label: ''}, ...response.data];
            setOptionsShopRegion(data)
        });
        ApiHandler.get({route: 'api_select_user'}, (response) => {
            let data = [{value: '', label: ''}, ...response.data];
            setOptionsUser(data)
        });
    }, []);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information boutique'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'name'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'openingTime'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'closingTime'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <MultipleSelectComponent id={'userSellers'} handler={handlerForm} options={optionsUser}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'shopRegion'} options={optionsShopRegion} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'userRegion'} options={optionsUser} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'userNational'} options={optionsUser} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'userLogistique'} options={optionsUser} handler={handlerForm}/>
                </Grid>
            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ShopManagementShopForm;
