export const isInt = (n) => {
    return Number(n) === n && n % 1 === 0;
}

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}

export const isNumber = (n) => {
    n = parseFloat(n);
    return isFloat(n) || isInt(n);
}

export const formatNumberToEurCurrency = (number, decimals = 2) => {
    if (!isNaN(number)) {
        return parseFloat(number).toLocaleString('fr-FR', {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals
        })
    }
    return '-';
};

export const formatCurrencyToFloat = (val) => {
    let decimals = findNumberOfDecimals(val)
    let divider = calculateDivider(decimals);
    let cleanedVal = removeSpecialCharsFromCurrency(val)

    return parseFloat(cleanedVal) / divider; // formatting deletes comma "," and "€". We should divide to get the base value according to decimals
};

export const removeSpecialCharsFromCurrency = (val) => {
    val = val.toString()
    return val.replace(/[^\d.-]/g, '')
}

export const findNumberOfDecimals = (val) => {
    val = val.toString()
    if (val.includes(',')) {
        let decimalPart = val.split(",")[1];
        decimalPart = decimalPart.replace(/\D/g, '');

        return decimalPart.length;
    } else {
        return 0;
    }
}

export const calculateDivider = (decimals) => {
    // Determining the divisor based on the number of decimal places
    return Math.pow(10, decimals);
}

// Use only for displaying int/float values
export const formatNumberWithThousandsSeparator = (number) => {
    return new Intl.NumberFormat().format(number);
};
