import React from 'react';
import {makeStyles} from "@mui/styles";
import {Link, useNavigate, useParams} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {hasRight} from "../../../../Common/UserCommon";
import {Grid, IconButton} from '@mui/material';
import SelectComponent from "../../../../Component/SelectComponent";
import {Article} from "@mui/icons-material";
import CheckboxComponent from "../../../../Component/CheckboxComponent";
import {cacheTableServer} from "../../../../Cache";

function SaleCreatePromoForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionsCountry, setOptionsCountry] = React.useState([]);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SALE_PROMO_GET');
    const params = useParams();

    const optionsPromoType = [
        { value: "0", label: "Promo sans code à saisir" },
        { value: "1", label: "Promo remise" },
        { value: "2", label: "Promo palier" },
        { value: "3", label: "Promo produit offert" },
    ];

    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Label',
            textHelper: 'Saisissez le nom de la promo.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        type: {
            name: 'type',
            label: 'Type promo',
            textHelper: '',
            type: 'integer',
            defaultValue: props.promo.type,
            options: {validation: ['required']}
        },
        fixless: {
            name: 'fixless',
            label: 'Montant remise en euro ( sans signe euro ni virgule )',
            textHelper: 'Saisir le montant fixe a remiser',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        percent: {
            name: 'percent',
            label: 'Montant remise en % ( sans signe % )',
            textHelper: 'Saisir le pourcentage a remiser',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        fixlessp1: {
            name: 'fixlessp1',
            label: 'Palier 1 remise en euros',
            textHelper: 'Saisir le montant palier 1',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        fixlessp2: {
            name: 'fixlessp2',
            label: 'Palier 2 remise en euros',
            textHelper: 'Saisir le montant palier 2',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        fixlessp3: {
            name: 'fixlessp3',
            label: 'Palier 3 remise en euros',
            textHelper: 'Saisir le montant palier 3',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        percentp1: {
            name: 'percentp1',
            label: 'Palier 1 remise %',
            textHelper: 'Saisir le montant palier 1',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        percentp2: {
            name: 'percentp2',
            label: 'Palier 2 remise %',
            textHelper: 'Saisir le montant palier 2',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        percentp3: {
            name: 'percentp3',
            label: 'Palier 3 remise %',
            textHelper: 'Saisir le montant palier 3',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        palier1: {
            name: 'palier1',
            label: 'Palier 1 ',
            textHelper: 'Saisir le montant palier 1',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        palier2: {
            name: 'palier2',
            label: 'Palier 2',
            textHelper: 'Saisir le montant palier 2',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        palier3: {
            name: 'palier3',
            label: 'Palier 3',
            textHelper: 'Saisir le montant palier 3',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        byProductIdOffered: {
            name: 'byProductIdOffered',
            label: 'Produit offert',
            textHelper: "Saisir l'ID du produit offert",
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        code: {
            name: 'code',
            label: 'Code',
            textHelper: 'Saisissez le coupon.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        productList: {
            name: 'productList',
            label: 'Skus',
            textHelper: 'Saisissez les skus de la promo.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        enable: {
            name: 'enable',
            label: 'Actif ?',
            textHelper: 'cocher si oui',
            type: 'boolean',
            defaultValue: true,
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début',
            textHelper: 'Saisir début promo',
            type: 'date',
            defaultValue: props.promo.startAt ?? '',
            options: {validation: ['date']}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin',
            textHelper: 'Saisir fin promo',
            type: 'date',
            defaultValue: props.promo.endAt ?? '',
            options: {validation: ['date']}
        },
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_sale_update_promo',
                data: handlerForm.getData(),
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Promo modifié avec succès.',
                            variant: 'success',
                        })
                    );
                    navigate(getRoutePathname('sale_promo_list'));

                } else if (response.status === 400) {
                    handlerForm.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
                handlerForm.setFormLoading(false);
                setIsLoadingForm(false);
            });
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {

        if (props.promo) {
            handlerForm.setDataApi(props.promo);
            if(props.promo.enable == 1) {
                handlerForm.setValue('enable', true);
            }
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Code promo'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <CheckboxComponent id={'enable'} handler={handlerForm} orientation={'right'} />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextFieldComponent id={'label'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextFieldComponent id={'code'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePickerComponent id={'startAt'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePickerComponent id={'endAt'} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/>
            <TitleComponent title={'Liste des SKUS concernes'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent id={'productList'} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/>
            <TitleComponent title={'Conditions de la promo'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <SelectComponent id={'type'} options={[{value: 0, label: 'Promo sans code à saisir'},{value: 1, label: 'Promo remise'},{value: 2, label: 'Promo palier'}, {value: 3, label: 'Produit offert'}]} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/>
            <TitleComponent title={'Montant promo remise'}/>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <TextFieldComponent id={'fixless'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextFieldComponent id={'percent'} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/>
            <div style={{display: ''}}>
                <TitleComponent title={'Configuration des paliers'}/>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>Définition palier</Grid>
                    <Grid item xs={4} sm={4}>Définition montant fixe</Grid>
                    <Grid item xs={4} sm={4}>Définition remise en %</Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'palier1'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'fixlessp1'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'percentp1'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'palier2'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'fixlessp2'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'percentp2'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'palier3'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'fixlessp3'} handler={handlerForm}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldComponent id={'percentp3'} handler={handlerForm}/>
                    </Grid>
                </Grid>
            </div>
            <br/>
            <div style={{display: ''}}>
                <TitleComponent title={'Produit offert'}/>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextFieldComponent id={'byProductIdOffered'} handler={handlerForm}/>
                    </Grid>
                </Grid>
            </div>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default SaleCreatePromoForm;
