import React from 'react';
import {Fade, Grid} from "@mui/material";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import ApiHandler from "../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import InventoryManagementDepositForm from "./InventoryManagementDepositComponents/InventoryManagementDepositForm";
import InventoryManagementDepositByProductList from "./InventoryManagementDepositComponents/InventoryManagementDepositByProductList";
import InventoryManagementDepositDepositAddressList from "./InventoryManagementDepositComponents/InventoryManagementDepositDepositAddressList";

function InventoryManagementDeposit() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    // Global
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isLoadingContent, setIsLoadingContent] = React.useState(true);
    const [deposit, setDeposit] = React.useState(null);
    React.useEffect(() => {
        if (params.id === 'add') {
            setDeposit(null);

            setBreadcrumbs({
                title: 'Ajouter un dépôt',
                context: 'Gestion des stocks',
                description: '',
                links: [
                    {path: getRoutePathname('inventory_management_deposit_list'), label: 'Liste des dépôts'}
                ]
            });

            setTimeout(() => setIsLoadingContent(false), 1500);
        }
        else {
            setDeposit(null);
            setIsLoadingContent(true)

            setBreadcrumbs({
                title: 'Éditer un dépôt',
                context: 'Gestion des stocks',
                description: '',
                links: [
                    {path: getRoutePathname('inventory_management_deposit_list'), label: 'Liste des dépôts'}
                ]
            });

            ApiHandler.get({
                route: 'api_inventory_management_deposit_get',
                params: {id: params.id}
            }, (response) => {
                if (response.status === 200) {
                    setDeposit(response.data);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setIsLoadingContent(false);
            });
        }
    }, [location])

    return (
        <ContentViewComponent loading={isLoadingContent} breadcrumbs={breadcrumbs}>
            <Grid container spacing={2}>
                <Fade in={true} {...{timeout: 750}}>
                    <Grid item xs={12} sm={12} md={deposit ? 4 : 12} lg={deposit ? 4 : 12} xl={deposit ? 4 : 12}>
                        <InventoryManagementDepositForm deposit={deposit}/>
                    </Grid>
                </Fade>
                {deposit && <Fade in={true} {...{timeout: 750}}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <InventoryManagementDepositDepositAddressList deposit={deposit}/>
                    </Grid>
                </Fade>}
            </Grid>

            <br/>

            {deposit && <InventoryManagementDepositByProductList deposit={deposit}/>}

            <br/><br/>
        </ContentViewComponent>
    );
}

export default InventoryManagementDeposit;
