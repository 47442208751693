import React from 'react';
import {makeStyles} from '@mui/styles';
import {Checkbox} from "@mui/material";

function CheckboxComponent(props) {

    const classes = useStyles();

    // Info
    const id = props.id;
    const input = props.handler.form[id];
    const label = input.label;
    const orientation = props.orientation ?? 'right';

    // Value
    const [value, setValue] = React.useState(false);
    React.useEffect(() => {
        if (input.value !== undefined && input.value !== value) setValue(!!input.value);
    }, [input.value]);
    const onChange = () => {
        setValue(!value);
        props.handler.setValue(id, !value);

        if (props.onChange) props.onChange(!value)
    };

    return (
        <div className={classes.content} style={props.style ?? {}}>
            {(orientation === 'left') && <span className={classes.label}>{label}</span>}
            <Checkbox checked={value} onChange={onChange} disabled={input.disabled || props.disabled}/>
            {(orientation === 'right') && <span className={classes.label}>{label}</span>}
            <p className={classes.textHelper}>{input.textHelper}</p>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        width: '100%',
        textAlign: 'left',
        marginTop: 0,
        height: 55,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.6)',
        display: 'inline-block',
        cursor: 'pointer',
        verticalAlign: 'middle'
    },
    checkbox: {
        color: 'rgba(0, 0, 0, 0.6)',
        display: 'inline-block',
        cursor: 'pointer'
    },
    textHelper: {
        margin: '-4px 14px',
        fontSize: 9,
        minHeight: 9,
        lineHeight: '9px',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 400
    }
});

export default CheckboxComponent;
