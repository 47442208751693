import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {hasRight} from "../../../Common/UserCommon";
import {IconButton, Tooltip} from "@mui/material";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {Article, Edit, Email} from "@mui/icons-material";
import ApiHandler from "../../../Handler/ApiHandler";
import TableComponent from "../../../Component/TableComponent";
import {isNumber} from "../../../Util/NumberTool";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import clientBusiness from "../ClientBusiness/ClientBusiness";

function ClientBusinessOrderList(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "numberOrderClient",
            label: "Numéro",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date",
            options: {filter: true, sort: true}
        },
        {
            name: "contact",
            label: "Contact",
            options: {filter: true, sort: true}
        },
        {
            name: "labelState",
            label: "Statut",
            options: {filter: true, sort: true}
        },
        {
            name: "amountHT",
            label: "Montant HT",
            options: {filter: true, sort: true}
        },
        {
            name: "marge",
            label: "Marge",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') ||
            hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_GET'))) {
            return (
                <>
                    <Tooltip title={'Envoyer la facture par mail ?'} placement="left">
                        <IconButton onClick={() => {
                            ApiHandler.get({route: 'api_b2b_client_business_order_send_proposal_pdf', params: {id: row.id}}, (response) => {
                                if (response.status === 200) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Email envoyé.',
                                            variant: 'success',
                                        })
                                    );
                                }
                                else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                        }}><Email style={{color: '#9c27b0'}}/></IconButton>
                    </Tooltip>
                </>
            );
        }
        else {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT')) ?
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Edit/>
                            </IconButton> :
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Article/>
                            </IconButton>
                    }
                </>
            );
        }
    };

    return (
        <TableComponent
            id={'api_b2b_client_business_contact_list'}
            title={'Affaires en cours'}
            columns={columns}
            actionFirst={(hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') || hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_ADD')) && (props.clientBusiness[0].contacts.length > 0 && props.clientBusiness[0].priceList !== null)  ? {
                label: 'Créer une proposition',
                link: getRoutePathname('b2b_client_business_order_add', {clientBusinessId: props.clientBusiness[0].id})
            } : false}
            promiseData={(resolve) => {
                ApiHandler.get({route: 'api_b2b_client_business_order_list', params: {id: props.clientBusiness[0].id}},
                    (response) => {
                        let data = response.data[0];
                        for (let index in data) {
                            data[index].action = getAction(data[index]);
                            data[index].numberOrderClient = data[index].numberOrderClient ? data[index].numberOrderClient : '-';
                            data[index].createdAt = data[index].createdAt ? data[index].createdAt : '-';
                            data[index].contact = data[index].contact ? data[index].contact : '-';
                            data[index].labelState = data[index].labelState ? data[index].labelState : '-';
                            data[index].amountHT = isNumber(data[index].amountHT) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].amountHT).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                            data[index].marge = isNumber(data[index].marge) ? <span style={{display: 'flex',justifyContent: 'end'}}>{parseFloat(data[index].marge).toLocaleString('fr-FR', {minimumFractionDigits: 2})}</span> : '-';
                        }
                        resolve(data);
                    });
            }}
        />
    );
}

export default ClientBusinessOrderList;
