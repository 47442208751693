import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Snackbar} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import {SnackbarClose} from "../Action/SnackbarAction";

let timeoutClose = false;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarComponent() {
    const snackbarReducer = useSelector(state => state.SnackbarReducer);
    const dispatch = useDispatch();

    const close = () => dispatch(SnackbarClose());

    React.useEffect(() => {
        if (timeoutClose) clearTimeout(timeoutClose)
        timeoutClose = setTimeout(close, snackbarReducer.duration);
    }, [snackbarReducer]);

    return (
        <Snackbar open={snackbarReducer.isOpen} onClose={close} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Alert onClose={close} severity={snackbarReducer.variant} sx={{width: '100%'}}>
                {snackbarReducer.text}
            </Alert>
        </Snackbar>
    );
}
