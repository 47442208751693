import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React from "react";
import {hasRight} from "../../../../Common/UserCommon";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../../Action/LinearProgressMainLayoutAction";
import ApiHandler from "../../../../Handler/ApiHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import {Grid} from "@mui/material";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {makeStyles} from "@mui/styles";
import SelectComponent from "../../../../Component/SelectComponent";

function ClientBusinessContactForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP') && !hasRight(authenticationReducer, 'ROLE_SHOP_MANAGEMENT_SHOP_EDIT');

    const [form, setForm] = React.useState({
        id: {
            name: 'id',
            label: 'Client Business Contact Identifiant',
            textHelper: 'Client Business Contact Id',
            type: 'integer',
            defaultValue: props.clientBusinessContact ? props.clientBusinessContact.id : null,
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom de famille',
            textHelper: 'Saisissez le nom de famille',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'email',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le numéro de téléphone',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        role: {
            name: 'role',
            label: 'Rôle',
            textHelper: 'Choisir le type de contact',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        clientBusinessId: {
            name: 'clientBusinessId',
            label: 'Client Business Identifiant',
            textHelper: 'Client Business Id',
            type: 'integer',
            defaultValue: props.clientBusinessId,
            options: {}
        },
    });

    const [optionsRole, setOptionsRole] = React.useState([]);
    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_client_business_contact_role'}, (response) => setOptionsRole(response.data));
    }, []);

    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);

    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.clientBusinessContact) {
                ApiHandler.post({
                    route: 'api_b2b_client_business_contact_edit',
                    data: handlerForm.getData(),
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('b2b_client_business', {id: props.clientBusinessId}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact modifié avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_b2b_client_business_contact_add',
                    data: handlerForm.getData(),
                }, (response) => {
                    if (response.status === 200) {
                        navigate(getRoutePathname('b2b_client_business', {id: props.clientBusinessId}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact ajouté avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };

    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.clientBusinessContact) {
            handlerForm.setDataApi(props.clientBusinessContact);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Information contact'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'firstname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'lastname'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'phone'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'email'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectComponent id={'role'} options={optionsRole} handler={handlerForm}/>
                </Grid>
            </Grid>

            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ClientBusinessContactForm;