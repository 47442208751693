import React from 'react';
import TableComponent from "../../../../Component/TableComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {hasRight} from "../../../../Common/UserCommon";
import {useSelector} from "react-redux";
import {initFormHandler} from "../../../../Handler/FormHandler";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../../Action/TableAction";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {dispatch} from "../../../../App";
import {DeleteOutline, Edit,DoneAll} from "@mui/icons-material";
import CheckboxComponent from "../../../../Component/CheckboxComponent";

function InventoryManagementDepositDepositAddressList(props) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columnsAddresses = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "address",
            label: "Adresse",
            options: {filter: true, sort: true}
        },
        {
            name: "city",
            label: "Ville",
            options: {filter: true, sort: true}
        },
        {
            name: "zipCode",
            label: "Code postale",
            options: {filter: true, sort: true}
        },
        {
            name: "country",
            label: "Pays",
            options: {filter: true, sort: true}
        },
        {
            name: "defaultDelivery",
            label: "Par défaut",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const [loading, setLoading] = React.useState(false);
    const [address, setAddress] = React.useState(null);
    const [addressDelete, setAddressDelete] = React.useState(null);
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de l\'adresse.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez l\'adresse.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville de l\'adresse.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal de l\'adresse.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        country: {
            name: 'country',
            label: 'Pays',
            textHelper: 'Saisissez le pays de l\'adresse.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        defaultDelivery: {
            name: 'defaultDelivery',
            label: 'Par défaut',
            textHelper: 'Adresse par défaut.',
            type: 'boolean',
            defaultValue: '',
            options: {}
        }
    });
    const handlerForm = initFormHandler(form, setForm);

    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (address === 0) {
                ApiHandler.post({
                    route: 'api_inventory_management_deposit_address_add',
                    data: handlerForm.getData(),
                    params: {depositId: props.deposit.id},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(TableReload('api_inventory_management_deposit_address_list'));
                        setAddress(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Adresse ajoutée avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setLoading(false);
                });
            }
            else {
                ApiHandler.post({
                    route: 'api_inventory_management_deposit_address_edit',
                    data: handlerForm.getData(),
                    params: {depositId: props.deposit.id, id: address},
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(TableReload('api_inventory_management_deposit_address_list'));
                        setAddress(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Adresse modifiée avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setLoading(false);
                });
            }
        }
    };
    const remove = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);

        ApiHandler.delete({
            route: 'api_inventory_management_deposit_address_delete',
            data: handlerForm.getData(),
            params: {depositId: props.deposit.id, id: addressDelete},
        }, (response) => {
            if (response.status === 200) {
                dispatch(TableReload('api_inventory_management_deposit_address_list'));
                setAddressDelete(null);
                dispatch(
                    SnackbarOpen({
                        text: 'Adresse supprimée avec succès.',
                        variant: 'success',
                    })
                );
            }
            else if (response.status === 400) {
                handlerForm.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            setLoading(false);
        });
    };
    const getAction = (row) => {
        return (
            <div style={{width: 65}}>
                <Tooltip title={'Modifier l\'adresse'} placement="left">
                    <IconButton onClick={() => {
                        handlerForm.reset();
                        handlerForm.setValue('name', row.name);
                        handlerForm.setValue('address', row.address);
                        handlerForm.setValue('city', row.city);
                        handlerForm.setValue('zipCode', row.zipCode);
                        handlerForm.setValue('country', row.country);
                        handlerForm.setValue('defaultDelivery', row.defaultDelivery !== '-');
                        setAddress(row.id);
                    }}>
                        <Edit style={{color: '#17a2b8'}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer l\'adresse'} placement="left">
                    <IconButton onClick={() => setAddressDelete(row.id)}>
                        <DeleteOutline style={{color: '#982525'}}/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    return (
        <>
            <TableComponent
                id={'api_inventory_management_deposit_address_list'}
                title={'Adresse'}
                columns={columnsAddresses}
                actionFirst={
                    (hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_INVENTORY_MANAGEMENT_RECEIPT_ORDER_PURVEYOR_EDIT')) ?
                        {
                            label: 'Ajouter une adresse',
                            onClick: () => {
                                handlerForm.reset();
                                setAddress(0);
                            }
                        } : false
                }
                promiseData={(resolve) => {
                    if (props.deposit) {
                        ApiHandler.get({
                                route: 'api_inventory_management_deposit_address_list',
                                params: {depositId: props.deposit.id},
                            },
                            (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                    data[index].defaultDelivery = data[index].defaultDelivery ? <DoneAll style={{color: '#28a745'}}/> : '-';
                                }
                                resolve(data);
                            });
                    }
                    else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={address !== null} maxWidth={'xl'} onClose={() => setAddress(null)}>
                <DialogTitle style={{fontSize: 15}}>{address ? 'Modifier une adresse' : 'Ajouter une adresse'}</DialogTitle>
                <DialogContent style={{minWidth: '40vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <CheckboxComponent orientation={'right'} id={'defaultDelivery'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextFieldComponent id={'name'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent id={'address'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldComponent id={'city'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldComponent id={'zipCode'} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldComponent id={'country'} handler={handlerForm}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setAddress(null)} loading={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                </DialogActions>
            </Dialog>

            <Dialog open={!!addressDelete} maxWidth={'xl'} onClose={() => setAddressDelete(null)}>
                <DialogTitle style={{fontSize: 15}}>Supprimer l'adresse</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Êtes-vous sûr de vouloir supprimer l'adresse ?
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Non'} onClick={() => setAddressDelete(null)}/>
                    <ButtonComponent label={'Oui'} onClick={remove}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InventoryManagementDepositDepositAddressList;
