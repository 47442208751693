import React from 'react';
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {hasRight} from "../../../../Common/UserCommon";
import { Grid } from '@mui/material';
import {TableReload} from "../../../../Action/TableAction";

function ShopCashClosingForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_SHOP_SHOP_SALES') && !hasRight(authenticationReducer, 'ROLE_SHOP_SELLER');

    const [form, setForm] = React.useState({
        b500: {
            name: 'b500',
            label: '500€',
            textHelper: 'Saisissez le nombre de billet de 500 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b200: {
            name: 'b200',
            label: '200€',
            textHelper: 'Saisissez le nombre de billet de 200 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b100: {
            name: 'b100',
            label: '100€',
            textHelper: 'Saisissez le nombre de billet de 100 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b50: {
            name: 'b50',
            label: '50€',
            textHelper: 'Saisissez le nombre de billet de 50 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b20: {
            name: 'b20',
            label: '20€',
            textHelper: 'Saisissez le nombre de billet de 20 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b10: {
            name: 'b10',
            label: '10€',
            textHelper: 'Saisissez le nombre de billet de 10 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        b5: {
            name: 'b5',
            label: '5€',
            textHelper: 'Saisissez le nombre de billet de 5 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env500: {
            name: 'env500',
            label: '500€',
            textHelper: 'Saisissez le nombre de billet de 500 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env200: {
            name: 'env200',
            label: '200€',
            textHelper: 'Saisissez le nombre de billet de 200 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env100: {
            name: 'env100',
            label: '100€',
            textHelper: 'Saisissez le nombre de billet de 100 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env50: {
            name: 'env50',
            label: '50€',
            textHelper: 'Saisissez le nombre de billet de 50 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env20: {
            name: 'env20',
            label: '20€',
            textHelper: 'Saisissez le nombre de billet de 20 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env10: {
            name: 'env10',
            label: '10€',
            textHelper: 'Saisissez le nombre de billet de 10 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        env5: {
            name: 'env5',
            label: '5€',
            textHelper: 'Saisissez le nombre de billet de 5 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p2: {
            name: 'p2',
            label: '2€',
            textHelper: 'Saisissez le nombre de pièces de 2 euros.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p1: {
            name: 'p1',
            label: '1€',
            textHelper: 'Saisissez le nombre de pièces de 1 euro.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p050: {
            name: 'p050',
            label: '0.5€',
            textHelper: 'Saisissez le nombre de pièces de 50 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p020: {
            name: 'p020',
            label: '0.2€',
            textHelper: 'Saisissez le nombre de pièces de 20 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p010: {
            name: 'p010',
            label: '0.1€',
            textHelper: 'Saisissez le nombre de pièces de 10 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p005: {
            name: 'p005',
            label: '0.5€',
            textHelper: 'Saisissez le nombre de pièces de 5 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p002: {
            name: 'p002',
            label: '0.2€',
            textHelper: 'Saisissez le nombre de pièces de 2 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        p001: {
            name: 'p001',
            label: '0.1€',
            textHelper: 'Saisissez le nombre de pièces de 1 cents.',
            type: 'text',
            defaultValue: '0',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez vos remarques.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
    });
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const handlerForm = initFormHandler(form, setForm);
    const save = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.shopId) {
                ApiHandler.post({
                    route: 'api_shop_sales_close',
                    data: handlerForm.getData(),
                    params: {id: props.shopId}
                }, (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Caisse fermée avec succès.',
                                variant: 'success',
                            })
                        );
                        navigate(getRoutePathname('shop_sales_sign_opening'));

                    } else if (response.status === 400) {
                        handlerForm.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerForm.setFormLoading(false);
                    setIsLoadingForm(false);
                });
            }
        }
    };
    React.useEffect(handlerForm.start, []);
    React.useEffect(() => {
        if (props.shop) {
            handlerForm.setDataApi(props.shop);
        }
    }, []);
    React.useEffect(() => {
        handlerForm.setFormLoading(justWatch);
    }, [justWatch]);

    const calculateTotalCash = (value) => {
        var b500 = document.getElementById("b500").value;
        var b200 = document.getElementById("b200").value;
        var b100 = document.getElementById("b100").value;
        var b50 = document.getElementById("b50").value;
        var b20 = document.getElementById("b20").value;
        var b10 = document.getElementById("b10").value;
        var b5 = document.getElementById("b5").value;

        var p2 = document.getElementById("p2").value;
        var p1 = document.getElementById("p1").value;
        var p050 = document.getElementById("p050").value;
        var p020 = document.getElementById("p020").value;
        var p010 = document.getElementById("p010").value;
        var p005 = document.getElementById("p005").value;
        var p002 = document.getElementById("p002").value;
        var p001 = document.getElementById("p001").value;

        var total = b500 + b200 + b100 + b50 + b20 + b10 + b5 + p2 + p1 + p050 + p020 + p010 + p005 + p002 + p001;

        document.getElementById("caisseEspece").value = total;
    };

    return (
        <ShadowBoxComponent className={classes.shadowBox}>
            <TitleComponent title={'Fonds de caisse à la cloture'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b500'} handler={handlerForm} onChange={(val) => calculateTotalCash(val)} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b200'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b100'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b50'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b20'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b10'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'b5'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p2'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p1'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p050'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p020'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p010'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p005'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p002'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'p001'} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/><br/>
            <TitleComponent title={'Caisses, valeur à l\'encaissement (espèces dans l\'enveloppe du jour)'}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env500'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env200'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env100'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env50'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env20'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env10'} handler={handlerForm}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextFieldComponent id={'env5'} handler={handlerForm}/>
                </Grid>
            </Grid>
            <br/>
            <hr/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextFieldComponent id={'comment'} handler={handlerForm}/>
                </Grid>
            </Grid>
            {!justWatch && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} loading={isLoadingForm}/>}
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 45,
        height: '100%'
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    },
    owner: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 7,
        right: 14
    }
});

export default ShopCashClosingForm;
