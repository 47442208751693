import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {dispatch} from "../../../App";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {Link, useParams} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {initFormHandler} from '../../../Handler/FormHandler';
import SelectComponent from '../../../Component/SelectComponent';
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from '../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../Component/ButtonComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TableComponent from '../../../Component/TableComponent';
import moment from "moment";
import {Article} from "@mui/icons-material";
import {TableReload} from "../../../Action/TableAction";
import CheckboxComponent from "../../../Component/CheckboxComponent";

function ClientServiceOrderClientLitige() {
    const params = useParams();
    const [loading, setLoading] = React.useState(true);
    const [isRepaidOrForwardingLegit, setIsRepaidOrForwardingLegit] = React.useState(false);
    const [litige, setLitige] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsCarrier, setOptionsCarrier] = React.useState([]);
    const [optionsOrderClientRefund, setOptionsOrderClientRefund] = React.useState([]);
    const [optionsOrderClientForwarding, setOptionsOrderClientForwarding] = React.useState([]);

    const [openDialogReshipment, setOpenDialogReshipment] = React.useState(false);
    const [openDialogReshipmentAddress, setOpenDialogReshipmentAddress] = React.useState(false);
    const [openDialogRefund, setOpenDialogRefund] = React.useState(false);
    const [isLoadingFormReshipment, setIsLoadingFormReshipment] = React.useState(false);
    const [isLoadingFormReshipmentAddress, setIsLoadingFormReshipmentAddress] = React.useState(false);
    const [isLoadingFormRefund, setIsLoadingFormRefund] = React.useState(false);
    const [isLoadingFormForceClosing, setIsLoadingFormForceClosing] = React.useState(false);
    const columnsByProducts = [
        {
            name: "name",
            label: "Nom de l\'article",
            options: {filter: true, sort: true}
        },
        {
            name: "amount",
            label: "Quantité commandée",
            options: {filter: true, sort: true}
        },
        {
            name: "amountReturnReceived",
            label: "Quantité retournée reçue",
            options: {filter: true, sort: true}
        },
        {
            name: "amountReturnCompliant",
            label: "Quantité retournée conforme",
            options: {filter: true, sort: true}
        },
        {
            name: "amountRefund",
            label: "Quantité remboursée",
            options: {filter: true, sort: true}
        },
        {
            name: "amountForwarding",
            label: "Quantité réexpédiée",
            options: {filter: true, sort: true}
        }
    ];
    const [form, setForm] = React.useState({
        orderClientLitigeState: {
            name: 'orderClientLitigeState',
            label: 'État du litige',
            textHelper: 'Changer l\'état du litige.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        commentClosing: {
            name: 'commentClosing',
            label: 'Commentaire litige envoyé au client',
            textHelper: 'Saisissez un commentaire litige envoyé au client.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = initFormHandler(form, setForm);

    const [formReshipment, setFormReshipment] = React.useState({
        carrier: {
            name: 'carrier',
            label: 'Transporteur',
            textHelper: 'Saisissez le transporteur.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        orderClientForwarding: {
            name: 'orderClientForwarding',
            label: 'Statut de réexpédition',
            textHelper: 'Saisissez le statut de réexpédition.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Nom du client.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Prénom du client.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        address1: {
            name: 'address1',
            label: 'Adresse 1',
            textHelper: 'Adresse principale du client.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        address2: {
            name: 'address2',
            label: 'Adresse 2',
            textHelper: 'Adresse secondaire du client.',
            type: 'text',
            defaultValue: '',
            options: {validation: [],}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Ville',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Code postal',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        countryCode: {
            name: 'countryCode',
            label: 'Code Pays',
            textHelper: 'Code de référence du pays',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        company: {
            name: 'company',
            label: 'Entreprise',
            textHelper: 'Entreprise à livrée',
            type: 'text',
            defaultValue: '',
            options: {validation: [],}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Téléphone du client',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
    });
    const handlerReshipment = initFormHandler(formReshipment, setFormReshipment);

    const [formRefund, setFormRefund] = React.useState({
        orderClientRefund: {
            name: 'orderClientRefund',
            label: 'Remboursement',
            textHelper: 'Choisissez un motif de remboursement.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        isCarrier: {
            name: 'isCarrier',
            label: 'Transport',
            textHelper: 'Remboursement du transport.',
            type: 'boolean',
            defaultValue: false,
            value: false,
            options: {}
        },
        isPayment: {
            name: 'isPayment',
            label: 'Paiement',
            textHelper: 'Remboursement des frais de paiement.',
            type: 'boolean',
            defaultValue: false,
            value: false,
            options: {}
        },
    });
    const handlerRefund = initFormHandler(formRefund, setFormRefund);

    const [showClosing, setShowClosing] = React.useState(false);

    const forceClosing = (index, value) => {
        handler.setValue(index, value);

        setIsLoadingFormForceClosing(true);

        dispatch(LinearProgressMainLayoutActivate());

        ApiHandler.post({
            route: 'api_client_service_order_client_litige_state',
            params: {uuid: params.uuid},
            data: handler.getData()
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Litige clôturé avec succès.',
                        variant: 'success',
                    })
                );
                setReload(!reload);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            setIsLoadingFormForceClosing(false);
            setShowClosing(false);
        });
    }

    const reshipmentAddress = () => {
        if (handlerReshipment.checkError() > 0) {
            return;
        }
        setOpenDialogReshipment(false);
        setOpenDialogReshipmentAddress(true);
    }

    const reshipment = () => {
        if (handlerReshipment.checkError() > 0) {
            return;
        }

        setIsLoadingFormReshipmentAddress(true);
        setIsLoadingFormReshipment(true);
        dispatch(LinearProgressMainLayoutActivate());

        let data = handlerReshipment.getData();
        let byProducts = [];

        for (let index in data) {
            if (parseInt(index)) {
                byProducts.push({
                    'id': parseInt(index),
                    'amount': data[index]
                });
            }
        }

        let reshipmentAddress = {
            "firstname": data.firstname,
            "lastname": data.lastname,
            "address1": data.address1,
            "address2": data.address2,
            "city": data.city,
            "zipCode": data.zipCode,
            "countryCode": data.countryCode,
            "company": data.company,
            "phone": data.phone,
        };

        ApiHandler.post({
            route: 'api_client_service_order_client_litige_reshipment',
            data: {
                byProducts: byProducts,
                carrier: data.carrier,
                orderClientForwarding: data.orderClientForwarding,
                reshipmentAddress: reshipmentAddress
            },
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Réexpédition enregistré avec succès.',
                        variant: 'success',
                    })
                );

                setOpenDialogReshipment(false);
                setOpenDialogReshipmentAddress(false);
                setReload(!reload);
            }
            else if (response.status === 400) {
                handlerReshipment.setErrorApi(response.error);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
                setOpenDialogReshipment(false);
                setOpenDialogReshipmentAddress(false);
                setIsLoadingFormReshipmentAddress(false);
                setIsLoadingFormReshipment(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerReshipment.setFormLoading(false);
            setIsLoadingFormReshipment(false);
        });
    };

    const refund = () => {
        if (handlerRefund.checkError() > 0) {
            return;
        }

        setIsLoadingFormRefund(true);
        dispatch(LinearProgressMainLayoutActivate());

        let data = handlerRefund.getData();
        let byProducts = [];

        for (let index in data) {
            if (parseInt(index)) {
                byProducts.push({
                    'id': parseInt(index),
                    'amount': data[index]
                });
            }
        }

        ApiHandler.post({
            route: 'api_client_service_order_client_litige_refund',
            data: {
                byProducts: byProducts,
                orderClientRefund: data.orderClientRefund,
                isPayment: data.isPayment,
                isCarrier: data.isCarrier,
                carrierPriceHT: data.carrierPriceHT
            },
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Motif de remboursement enregistré avec succès.',
                        variant: 'success',
                    })
                );

                setOpenDialogRefund(false);
                setReload(!reload);
            }
            else if (response.status === 400) {
                handlerRefund.setErrorApi(response.error);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
                setOpenDialogRefund(false);
                setIsLoadingFormRefund(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            handlerRefund.setFormLoading(false);
            setIsLoadingFormRefund(false);
        });
    };

    React.useEffect(() => {
        ApiHandler.get({
            route: 'api_client_service_order_client_litige',
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                setLitige(response.data);
                setIsRepaidOrForwardingLegit(response.data.isRepaidOrForwardingLegit);
                setTimeout(() => dispatch(TableReload('api_client_service_order_client_litige_by_product_list')), 100);
                handler.setValue('orderClientLitigeState', response.data.stateId);
                if (response.data.carrier) {
                    handlerReshipment.setValue('carrier', response.data.carrier);
                }
                handlerReshipment.setDataApi(response.data.currentShippingAddress);

                let newForm = {};

                newForm['carrierPriceHT'] = {
                    name: 'carrierPriceHT',
                    label: 'Prix HT du transport',
                    textHelper: 'Remboursement du prix HT du transport.',
                    type: 'float',
                    defaultValue: '',
                    value: response.data.carrierPrices.carrierPriceHT,
                    disabled: response.data.carrierPrices.carrierPriceHT <= 0,
                    options: {validation: ['required'], max: response.data.carrierPrices.carrierPriceHT}
                };
                setFormRefund({...formRefund, ...newForm});

                let data = response.data.byProducts;
                for (let index in data) {
                    newForm[data[index].byProductId.toString()] = {
                        name: data[index].byProductId,
                        label: 'Quantité',
                        textHelper: `Saisissez la quantité du produit en litige (max: ${data[index].amountMax}).`,
                        type: 'integer',
                        defaultValue: data[index].amountMax < 1 ? data[index].amountMax : '',
                        value: data[index].amountMax < 1 ? data[index].amountMax : '',
                        disabled: data[index].amountMax < 1,
                        options: {validation: ['required'], max: data[index].amountMax}
                    };
                    setFormReshipment({...formReshipment, ...newForm});
                    setFormRefund({...formRefund, ...newForm});
                }

                setLoading(false);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, [reload]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Visualisation du litige',
            context: 'Sercice client',
            description: '',
            links: [
                {path: getRoutePathname('client_service_order_client_litige_list'), label: 'Liste des litiges'}
            ]
        });

        handler.start();
        handlerReshipment.start();
        handlerRefund.start();

        ApiHandler.get({route: 'api_select_carrier_dpd'}, (response) => {
            setOptionsCarrier(response.data);
        });
        ApiHandler.get({route: 'api_select_order_client_refund'}, (response) => {
            setOptionsOrderClientRefund(response.data);
        });
        ApiHandler.get({route: 'api_select_order_client_forwarding'}, (response) => {
            setOptionsOrderClientForwarding(response.data);
        });
    }, []);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {litige && <>
                <ShadowBoxComponent style={{height: '100%'}}>
                    <TitleComponent title={'Information litige'}/>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p style={{fontSize: 14, margin: 2}}>
                                <span style={{fontWeight: 700}}>Référence Litige</span> : {litige.ref}<br/>
                                <span style={{fontWeight: 700}}>Référence Commande</span> : {litige.orderClientNumber}
                                <Tooltip title={'Visualisation la commande'} placement="right">
                                    <Link to={getRoutePathname('sale_order_client', {uuid: litige.orderClient})}>
                                        <IconButton><Article style={{color: '#17a2b8', fontSize: 17, position: 'absolute', right: -5, top: -2}}/></IconButton>
                                    </Link>
                                </Tooltip>
                            </p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Type de litige</span> : {litige.type}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>État</span> : {litige.state}{litige.stateId === 3 && ' par : ' + litige.authorClosing}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Auteur</span> : {litige.author}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Motif</span> : {litige.reason}</p>
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Crée le</span> : {litige.litigeAt}</p>
                            {litige.dealAt ?
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Cloturé le</span> : {litige.dealAt}</p>
                                :
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Litige non cloturé</span></p>
                            }
                            <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Commentaire litige envoyé au client</span> : {litige.comment ? litige.comment : '-'}</p>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={1}>
                        {litige.return.returnAt &&
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ShadowBoxComponent style={{border: '1px solid #bbb', boxShadow: 'none'}}>
                                <p style={{fontSize: 14, margin: 2, fontWeight: 700}}>Retour - {litige.return.type ? litige.return.type : '-'}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Réceptionné par </span> : {litige.return.authorLitigeReturn}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>le </span> : {moment(litige.return.returnAt).format('lll')}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Commentaire </span> : {litige.return.commentReturn ? litige.return.commentReturn : '-'}</p>
                            </ShadowBoxComponent>
                        </Grid>}
                        {litige.forwarding.forwardingAt &&
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ShadowBoxComponent style={{border: '1px solid #bbb', boxShadow: 'none'}}>
                                <p style={{fontSize: 14, margin: 2, fontWeight: 700}}>Demande de réexpédition</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Demandé par </span> : {litige.forwarding.authorLitigeForwarding}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>le </span> : {moment(litige.forwarding.forwardingAt).format('lll')}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Commande réexpédiée </span> : {litige.forwarding.orderClientNumber ? litige.forwarding.orderClientNumber : '-'}
                                    <Tooltip title={'Visualisation la commande'} placement="right">
                                        <Link to={getRoutePathname('sale_order_client', {uuid: litige.forwarding.orderClient})}>
                                            <IconButton><Article style={{color: '#17a2b8', fontSize: 17, position: 'absolute', right: -5, top: -2}}/></IconButton>
                                        </Link>
                                    </Tooltip>
                                </p>
                            </ShadowBoxComponent>
                        </Grid>}
                        {litige.refund.refundAskingAt &&
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ShadowBoxComponent style={{border: '1px solid #bbb', boxShadow: 'none'}}>
                                <p style={{fontSize: 14, margin: 2, fontWeight: 700}}>Demande de remboursement</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Demandé par </span> : {litige.refund.authorLitigeRefundAsking}</p>
                                <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>le </span> : {moment(litige.refund.refundAskingAt).format('lll')}</p>
                                <br/>
                            </ShadowBoxComponent>
                        </Grid>}
                        {litige.refund.repaidAt &&
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ShadowBoxComponent style={{border: '1px solid #bbb', boxShadow: 'none'}}>
                                    <p style={{fontSize: 14, margin: 2, fontWeight: 700}}>Demande de remboursement</p>
                                    <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>Remboursé par </span> : {litige.refund.authorRepaid}</p>
                                    <p style={{fontSize: 14, margin: 2}}><span style={{fontWeight: 700}}>le </span> : {moment(litige.refund.repaidAt).format('lll')}</p>
                                    <br/>
                                </ShadowBoxComponent>
                            </Grid>}
                    </Grid>
                    <br/>
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        {(litige.stateId !== 3 && litige.stateId !== 4) && <ButtonComponent color={'#B00020'} style={{margin: 5}} label={'Clôturer'} onClick={() => setShowClosing(true)}/>}
                        {litige.stateId !== 3 && !litige.forwarding.forwardingAt && litige.fromEntrepot != 1 && isRepaidOrForwardingLegit && <ButtonComponent style={{margin: 5}} label={'Réexpédition'} onClick={() => setOpenDialogReshipment(true)}/>}
                        {!litige.refund.refundAskingAt && litige.fromEntrepot != 1 && isRepaidOrForwardingLegit && <ButtonComponent disabled={litige.return.returnAt} color={!litige.return.returnAt ? '#5E6E82' : ''} style={{margin: 5}} label={'Remboursement'} onClick={() => setOpenDialogRefund(true)}/>}
                    </Grid>

                </ShadowBoxComponent>
                <br/>
                <TableComponent
                    id={'api_client_service_order_client_litige_by_product_list'}
                    title={'Articles en litige'}
                    columns={columnsByProducts}
                    promiseData={(resolve) => {
                        resolve(litige.byProducts);
                    }}
                />
                <br/>
                <br/>
            </>}
            <Dialog open={openDialogReshipmentAddress} maxWidth={'xl'} onClose={() => setOpenDialogReshipmentAddress(false)}>
                <DialogTitle style={{fontSize: 15}}>Adresse de Réexpédition</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'lastname'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'firstname'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'address1'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'address2'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'city'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'zipCode'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'countryCode'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'company'} handler={handlerReshipment}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent id={'phone'} handler={handlerReshipment}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialogReshipmentAddress(false)} disabled={isLoadingFormReshipmentAddress}/>
                    <ButtonComponent label={'Enregistrer'} onClick={reshipment} loading={isLoadingFormReshipmentAddress}/>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogReshipment} maxWidth={'xl'} onClose={() => setOpenDialogReshipment(false)}>
                <DialogTitle style={{fontSize: 15}}>Réexpédition</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SelectComponent id={'carrier'} options={optionsCarrier} handler={handlerReshipment}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SelectComponent id={'orderClientForwarding'} options={optionsOrderClientForwarding} handler={handlerReshipment}/>
                    </Grid>
                    <Grid container spacing={2}>
                        {litige && litige.byProducts?.map((byProduct, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <p style={{fontSize: 14, margin: 2}}>{byProduct.name}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextFieldComponent id={byProduct.byProductId.toString()} handler={handlerReshipment}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialogReshipment(false)} disabled={isLoadingFormReshipment}/>
                    <ButtonComponent label={'Enregistrer'} onClick={reshipmentAddress} loading={isLoadingFormReshipment}/>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogRefund} maxWidth={'xl'} onClose={() => setOpenDialogRefund(false)}>
                <DialogTitle style={{fontSize: 15}}>Motif de remboursement</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectComponent id={'orderClientRefund'} options={optionsOrderClientRefund} handler={handlerRefund}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CheckboxComponent id={'isCarrier'} handler={handlerRefund}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent id={'carrierPriceHT'} handler={handlerRefund} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CheckboxComponent id={'isPayment'} handler={handlerRefund}/>
                        </Grid>
                        {litige && litige.byProducts?.map((byProduct, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <p style={{fontSize: 14, margin: 2}}>{byProduct.name}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextFieldComponent id={byProduct.byProductId.toString()} handler={handlerRefund}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialogRefund(false)} disabled={isLoadingFormRefund}/>
                    <ButtonComponent label={'Valider'} onClick={refund} loading={isLoadingFormRefund}/>
                </DialogActions>
            </Dialog>

            <Dialog open={showClosing} maxWidth={'xl'} onClose={() => setShowClosing(true)}>
                <DialogTitle style={{fontSize: 15}}>Cloturer le litige</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent id={'commentClosing'} handler={handler} multiline={true}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowClosing(false)} disabled={isLoadingFormForceClosing} />
                    <ButtonComponent
                        label={'Valider'}
                        loading={isLoadingFormForceClosing}
                        onClick={() => {forceClosing('orderClientLitigeState', 3);}}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default ClientServiceOrderClientLitige;
